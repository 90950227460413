import { useMutation } from '@tanstack/react-query';
import { RotationReport } from '@/types/entities/RotationReport';
import type { CreateRotationReportDTO } from '@/types/dto';
import type { ErrorResponse } from '@/types';
import Api from '@/services/api';

async function createRotationReport(params: CreateRotationReportDTO): Promise<RotationReport> {
	const { data } = await Api.post('/rotation/report', params);
	return data;
}

export const useCreateRotationReport = () => {
	return useMutation<RotationReport, ErrorResponse, CreateRotationReportDTO>(createRotationReport);
};
