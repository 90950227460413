import React, { useEffect } from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ModalBorder } from '@/components/ui';
import { Title } from '@/components/Typography';
import { useMtmReportContext } from '@/components/views/MtmReport/context';
import { useUpdateMtmToOperation } from '@/hooks';
import {
	Coefficient,
	DescriptionInput,
	DisabledTextInput,
	Frequency,
	Quantity,
	Sequence
} from '../../../components/Inputs';
import { MtmEditItem } from '../MtmItems';
import { CustomButton } from '../../../components/styles';

const { useForm } = Form;

interface ModalCodeProps {
	open?: boolean;
	item?: MtmEditItem;
	onClose: () => void;
	operation_id?: string;
}

export function ModalEditMtmItem({ operation_id = '', onClose, item, open = false }: ModalCodeProps) {
	const { basicParams } = useMtmReportContext();
	const { mutateAsync: updateMtmToOperation } = useUpdateMtmToOperation();
	const [form] = useForm();

	function handleOnCancel() {
		onClose();
	}

	async function handleOnFinish(values: MtmEditItem) {
		if (!item) {
			return;
		}

		await updateMtmToOperation({
			mtm_aggregation_value_id: item.mtm_aggregation_value_id,
			coefficient: values.coefficient,
			description: values.description,
			mtm_code_id: item.mtm_code_id,
			frequency: values.frequency,
			quantity: values.quantity,
			sequence: values.sequence,
			seconds: values.seconds,
			...basicParams,
			operation_id,
			id: item.id
		});
		onClose();
	}

	const placeholder = (0).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

	useEffect(() => {
		if (!item) {
			return form.resetFields();
		}
		const value_added = `${item?.mtm_aggregation_value?.name} - ${item?.mtm_aggregation_value?.description}`;
		form.setFieldValue(['seconds'], item?.seconds);
		form.setFieldValue(['tmu'], item?.mtm_code.tmu);
		form.setFieldValue(['quantity'], item?.quantity);
		form.setFieldValue(['value_added'], value_added);
		form.setFieldValue(['sequence'], item?.sequence);
		form.setFieldValue(['code'], item?.mtm_code.code);
		form.setFieldValue(['frequency'], item?.frequency);
		form.setFieldValue(['coefficient'], item?.coefficient);
		form.setFieldValue(['description'], item?.description);
	}, [item]);

	return (
		<ModalBorder
			open={open}
			width={'90vw'}
			footer={false}
			$borderSize="15px"
			onCancel={handleOnCancel}
			style={{ maxWidth: '620px' }}
		>
			<Form form={form} onFinish={handleOnFinish}>
				<Row gutter={[30, 0]}>
					<Col span={24}>
						<Title level={2}>Activity</Title>
					</Col>
					<Col span={8}>
						<DisabledTextInput namePlace={[]} name="code" title="Code" placeholder="######" />
					</Col>
					<Col span={8}>
						<Sequence namePlace={[]} isRequired={true} />
					</Col>
					<Col span={8}>
						<Quantity namePlace={[]} isRequired={true} />
					</Col>
					<Col span={12}>
						<DisabledTextInput namePlace={[]} name="tmu" title="TMU" placeholder={placeholder} />
					</Col>
					<Col span={12}>
						<Frequency namePlace={[]} isRequired={true} />
					</Col>
					<Col span={12}>
						<DisabledTextInput namePlace={[]} name="seconds" title="Seconds" placeholder={placeholder} />
					</Col>
					<Col span={12}>
						<Coefficient namePlace={[]} isRequired={true} />
					</Col>
					<Col span={24}>
						<DisabledTextInput
							namePlace={[]}
							name="value_added"
							title="Value-added"
							placeholder={'Value-added'}
						/>
					</Col>
					<Col span={24}>
						<DescriptionInput namePlace={[]} />
					</Col>
				</Row>
				<Row justify="center" align="middle" gutter={[20, 0]}>
					<Col>
						<CustomButton htmlType="button" size="large" type="ghost" onClick={handleOnCancel}>
							{I18n.get('Cancel')}
						</CustomButton>
					</Col>
					<Col>
						<CustomButton htmlType="submit" size="large" type="primary">
							{I18n.get('Save')}
						</CustomButton>
					</Col>
				</Row>
			</Form>
		</ModalBorder>
	);
}
