import { combineReducers } from 'redux';
import auth from './auth';
import user from './user';
import upload from './upload';
import report from './report';
import reports from './reports';
import workspace from './workspace';
import checklist from './checklist';
import organization from './organization';

export const rootReducer = combineReducers({
	auth,
	user,
	upload,
	report,
	reports,
	checklist,
	workspace,
	organization
});

export type RootState = ReturnType<typeof rootReducer>;
