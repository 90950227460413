import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { CardContainer, Counter, IconContainer, Title } from './styles';

export function CounterCard({ title, Icon, count, size, loading, handleClick, active }) {
	return (
		<CardContainer
			onClick={handleClick ? () => handleClick() : null}
			size={size}
			className={active ? 'active' : null}
		>
			{loading ? (
				<LoadingOutlined style={{ margin: 'auto', fontSize: '1.6rem' }} />
			) : (
				<>
					<Counter>{count}</Counter>
					<IconContainer>{Icon}</IconContainer>
					<Title>{title}</Title>
				</>
			)}
		</CardContainer>
	);
}

CounterCard.propTypes = {
	Icon: PropTypes.node,
	title: PropTypes.string.isRequired,
	count: PropTypes.number,
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	loading: PropTypes.bool,
	handleClick: PropTypes.func,
	active: PropTypes.bool,
};

CounterCard.defaultProps = {
	count: 0,
	size: 'medium',
	loading: false,
};
