import React from 'react';
import { Col } from 'antd';
import { Text, Title } from '@/components/Typography';
import * as S from './styled';

interface InfoItemProps {
	title: string;
	value: string;
	marginBottom?: number;
}

export function InfoItem({ title, value, marginBottom = 8 }: Readonly<InfoItemProps>) {
	return (
		<S.CustomRow
			align="middle"
			style={{
				marginBottom
			}}
		>
			<Col>
				<Title style={{ color: '#262626', marginBottom: 0, fontSize: 16 }} level={5}>
					{title}
				</Title>
			</Col>
			<Col>
				<Title style={{ color: '#262626', marginBottom: 0, fontSize: 16 }} level={5}>
					:
				</Title>
			</Col>
			<Col>
				<Text ellipsis style={{ marginLeft: '5px', color: '#262626', marginBottom: 0, fontSize: 16 }}>
					{value}
				</Text>
			</Col>
		</S.CustomRow>
	);
}
