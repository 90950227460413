import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import Api from '@/services/api';
import { BaseContext } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';

export type MtmReportDeleteDTO = BaseContext & {
	id: string;
};

const deleteMtmReport = async (params: MtmReportDeleteDTO): Promise<void> => {
	const url = `/mtm/report/${params.id}`;
	const { data } = await Api.delete<void>(url, { params });
	return data;
};

export const useDeleteMtmReport = () => {
	const queryClient = useQueryClient();
	return useMutation((body: MtmReportDeleteDTO) => deleteMtmReport(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: (deleted_report_params) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORTS_PAGINATED]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORT, deleted_report_params]);
			message.success(I18n.get('MTM deleted successfully'));
		}
	});
};
