import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CreateWorkstationDTO } from '@/components/ui/Inputs/types/workstation';
import { Workstation } from '@/types';
import Api from '@/services/api';

const createWorkstation = async (body: CreateWorkstationDTO): Promise<Workstation> => {
	const url = `/workstation/create`;
	const { data } = await Api.post<Workstation>(url, body);
	return data;
};

export const useCreateWorkstation = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: CreateWorkstationDTO }) => createWorkstation(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['workstations']);
			queryClient.invalidateQueries(['workstations_list']);
			message.success(I18n.get('Workstation created successfully'));
		}
	});
};
