import { useQuery } from '@tanstack/react-query';
import type { Organization } from '@/types/entities';
import { QUERY_KEYS } from '@/utils/query-keys';
import { ErrorResponse } from '@/types';
import Api from '@/services/api';

const { GET_ORGANIZATIONS } = QUERY_KEYS;

const getOrganizations = async (): Promise<Organization[]> => {
	const { data } = await Api.get<Organization[]>('/organization');
	return data;
};

export function useGetOrganizations() {
	return useQuery<Organization[], ErrorResponse>([GET_ORGANIZATIONS], () => getOrganizations());
}
