import React, { MouseEventHandler } from 'react';
import { I18n } from '@aws-amplify/core';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

type ChartData = {
	unit: string;
	value: number;
	note: number;
}
export interface IDoughnutChart {
	data?: Array<any>
	title: string;
	height?: string;
	onClick?: MouseEventHandler;
	ref?: any;
}

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
		datalabels: {
			display: false,
		},
	},
};

export const DoughnutChart: React.FC<IDoughnutChart> = React.forwardRef<any, any>(({ data, title, onClick }, ref) => {
	const dataset = {
		labels: data?.map((item: ChartData) => I18n.get(item.unit)),
		datasets: [
			{
				label: I18n.get(title),
				data: data?.map((item: ChartData) => I18n.get(item.value)),
				backgroundColor: [
					'rgba(64, 191, 95)',
					'rgba(252, 176, 51)',
					'rgba(240, 117, 117)',
				],
				borderColor: [
					'rgba(64, 191, 95)',
					'rgba(252, 176, 51)',
					'rgba(240, 117, 117)',
				],
				borderWidth: 1,
			},
		],
	};

	return (
		<div>
			<Doughnut data={dataset} options={options} onClick={onClick} ref={ref} height={'280px'} />
		</div>
	);
});
