import React from "react";

export const ArrowDown: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="21"
      fill="none"
      viewBox="0 0 16 21"
	  {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="3"
        d="M8 2v16m0 0l6-5.714M8 18l-6-5.714"
      ></path>
    </svg>
  );
}

export default ArrowDown;
