import React, { Fragment } from 'react'
import { List, Avatar, Empty } from 'antd'
import styled from 'styled-components'
import { I18n } from '@aws-amplify/core'

export const TitleItem = styled.span`
	padding-left: 15px;
`

export const Container = styled.div`
	min-height: 120px;
`

export function EmptyList({ description, footer }) {
	return (
		<Container>
			<Empty
				image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
				imageStyle={{ height: 60 }}
				description={<span>{I18n.get(description)}</span>}
			/>
		</Container>
	)
}

export default function Item({ item, onAction }) {
	function selectItem(e, item) {
		e.preventDefault()
		onAction(item)
	}

	return (
		<Fragment>
			<Avatar size="large" src={item.url_logo} />
			<a href="!#" onClick={(e) => selectItem(e, item)}>
				<TitleItem>{item.name}</TitleItem>
			</a>
		</Fragment>
	)
}

export function ListItens({ data, onAction }) {
	return (
		<List
			dataSource={data || []}
			itemLayout="horizontal"
			renderItem={(item) => (
				<List.Item>
					<List.Item.Meta
						title={<Item onAction={onAction} item={item} />}
					/>
				</List.Item>
			)}
		/>
	)
}
