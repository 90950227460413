import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { notification } from 'antd';

import { I18n } from '@aws-amplify/core';

import { setCompany, setOrganization } from '@/redux/organization/actions';
import { useDispatch } from 'react-redux';

import { useApplicationContext } from '@/context/application';
import { Context } from './index.types';

import {
	CompanyResponse,
	CreateOrganizationRequest,
	CreateRequest,
	DeleteOrganizationRequest,
	DeleteRequest,
	OrganizationDTO,
	OrganizationResponse,
	UpdateOrganizationRequest,
	UpdateRequest,
	ValidationError
} from './types';
import hooks from './hooks';

type ManageOrganizationProviderProps = {
	children: ReactNode;
};

const {
	useCreateOrganization,
	useUpdateOrganization,
	useDeleteOrganization,
	useCreate,
	useUpdate,
	useDelete,
	useGetOrganizations,
	useGetCompanies
} = hooks;

const ManageOrganizationContext = React.createContext<Context>({} as Context);

export function ManageOrganizationProvider({ children }: ManageOrganizationProviderProps) {
	const dispatch = useDispatch();

	const [organizationId, setOrganizationId] = useState<string>('');
	const [companyId, setCompanyId] = useState<string>('');
	const [companyName, setCompanyName] = useState<string>('');
	const [sectorId, setSectorId] = useState<string>('');
	const [sectorName, setSectorName] = useState<string>('');
	const [lineId, setLineId] = useState<string>('');
	const [lineName, setLineName] = useState<string>('');
	const [workstationId, setWorkstationId] = useState<string>('');
	const [workstationGHE, setWorkstationGHE] = useState<string>('');
	const [workstationName, setWorkstationName] = useState<string>('');
	const [productId, setProductId] = useState<string>('');
	const [productName, setProductName] = useState<string>('');
	const [organizations, setOrganizations] = useState<OrganizationDTO[]>([]);
	const [isEditing, setIsEditing] = useState<boolean>(false);

	const { mutateAsync: manageOrganizationUpdate, isLoading: updatingOrganizationData } = useUpdate();
	const { mutateAsync: manageOrganizationDelete, isLoading: deletingOrganizationData } = useDelete();
	const { mutateAsync: manageOrganizationCreate, isLoading: creatingOrganizationData } = useCreate();
	const { mutateAsync: createOrganization } = useCreateOrganization();
	const { mutateAsync: updateOrganization } = useUpdateOrganization();
	const { mutateAsync: deleteOrganization } = useDeleteOrganization();

	const {
		organization,
		setOrganization: setOrganizationContext,
		setCompany: setCompanyContext
	} = useApplicationContext();

	const { data: organizationsList, refetch } = useGetOrganizations();

	const { data: companiesList } = useGetCompanies(organizations[0]?.id || '');

	useEffect(() => {
		if (organizations?.length > 0) {
			dispatch(setOrganization(organizationsList[0]));
			setOrganizationContext(organizationsList[0]);

			dispatch(setCompany({ organization: organization, company: companiesList[0] }));
			setCompanyContext(companiesList[0]);
		}
	}, [companiesList]);

	function handleOrganizationId(id: string): void {
		setOrganizationId(id);
	}

	function handleCompany(
		id: string,
		resetFields: (fields?: NamePath[] | undefined) => void,
		setFieldValue: (name: NamePath, value: any) => void
	) {
		resetFields([
			'sector_id',
			'sector_name',
			'line_id',
			'line_name',
			'workstation_id',
			'workstation_name',
			'product_id',
			'product_name'
		]);
		setFieldValue('company_name', '');
		setFieldValue('create_company_name', '');
		setFieldValue('company_id', id);
		setCompanyId(id);
		setSectorId('');
		setLineId('');
		setWorkstationId('');
		setProductId('');
	}

	function handleCompanyId(id: string): void {
		setCompanyId(id);
	}

	function handleCompanyName(name: string): void {
		setCompanyName(name);
	}

	function handleSector(
		id: string,
		resetFields: (fields?: NamePath[] | undefined) => void,
		setFieldValue: (name: NamePath, value: any) => void
	) {
		resetFields(['line_id', 'line_name', 'workstation_id', 'workstation_name', 'product_id', 'product_name']);
		setFieldValue('sector_name', '');
		setFieldValue('create_sector_name', '');
		setFieldValue('sector_id', id);
		setSectorId(id);
		setLineId('');
		setWorkstationId('');
		setProductId('');
	}

	function handleSectorId(id: string): void {
		setSectorId(id);
	}

	function handleSectorName(name: string): void {
		setSectorName(name);
	}

	function handleLine(
		id: string,
		resetFields: (fields?: NamePath[] | undefined) => void,
		setFieldValue: (name: NamePath, value: any) => void
	) {
		resetFields(['workstation_id', 'workstation_name', 'select_workstation_ghe', 'product_id', 'product_name']);
		setFieldValue('line_name', '');
		setFieldValue('create_line_name', '');
		setFieldValue('line_id', id);
		setLineId(id);
		setWorkstationGHE('');
		setWorkstationId('');
		setProductId('');
	}

	function handleLineId(id: string): void {
		setLineId(id);
	}

	function handleLineName(name: string): void {
		setLineName(name);
	}

	function handleWorkstation(
		id: string,
		ghe: string,
		resetFields: (fields?: NamePath[] | undefined) => void,
		setFieldValue: (name: NamePath, value: any) => void
	) {
		resetFields(['product_id', 'product_name']);
		setFieldValue('workstation_ghe', '');
		setFieldValue('workstation_name', '');
		setFieldValue('create_workstation_name', '');
		setFieldValue('workstation_id', id);
		setFieldValue('select_workstation_ghe', ghe);
		setWorkstationId(id);
		setWorkstationGHE(ghe);
		setProductId('');
	}

	function handleWorkstationId(id: string): void {
		setWorkstationId(id);
	}

	function handleWorkstationGHE(name: string): void {
		setWorkstationGHE(name);
	}

	function handleWorkstationName(name: string): void {
		setWorkstationName(name);
	}

	function handleProduct(id: string, setFieldValue: (name: NamePath, value: any) => void) {
		setFieldValue('product_name', '');
		setFieldValue('create_product_name', '');
		setFieldValue('product_id', id);
		setProductId(id);
	}

	function handleProductId(id: string): void {
		setProductId(id);
	}

	function handleProductName(name: string): void {
		setProductName(name);
	}

	async function handleCreateOrganization(body: CreateOrganizationRequest): Promise<OrganizationResponse> {
		return await createOrganization(body);
	}

	async function handleUpdateOrganization(body: UpdateOrganizationRequest): Promise<OrganizationResponse> {
		return await updateOrganization(body);
	}

	async function handleDeleteOrganization(body: DeleteOrganizationRequest): Promise<OrganizationResponse> {
		const deleted = await deleteOrganization(body);
		if (organization?.id === body.organization_id) {
			refetch().then(({ data }) => {
				if (data) {
					setOrganizations(data);
				}
			});
		}
		return deleted;
	}

	async function createOrganizationData(body: CreateRequest): Promise<CompanyResponse> {
		return await manageOrganizationCreate(body);
	}

	async function updateOrganizationData(body: UpdateRequest): Promise<CompanyResponse> {
		return await manageOrganizationUpdate(body);
	}

	async function deleteOrganizationData(body: DeleteRequest): Promise<void> {
		await manageOrganizationDelete(body);
	}

	function treatFieldValidation(error: ValidationError) {
		if (error?.errorFields?.length > 0) {
			error.errorFields.forEach((error) => {
				notification.error({
					message: I18n.get(error.errors[0])
				});
			});
		} else {
			notification.error({
				message: I18n.get('Oops... Something went wrong')
			});
		}
	}

	function handleIsEditing(editing: boolean): void {
		setIsEditing(editing);
	}

	const contextValue: Context = {
		organizationId,
		companyId,
		companyName,
		sectorId,
		sectorName,
		lineId,
		lineName,
		workstationId,
		workstationGHE,
		workstationName,
		productId,
		productName,
		updatingOrganizationData,
		deletingOrganizationData,
		creatingOrganizationData,
		organizationsList,
		isEditing,
		handleOrganizationId,
		handleCompany,
		handleCompanyId,
		handleCompanyName,
		handleSector,
		handleSectorId,
		handleSectorName,
		handleLine,
		handleLineId,
		handleLineName,
		handleWorkstation,
		handleWorkstationId,
		handleWorkstationGHE,
		handleWorkstationName,
		handleProduct,
		handleProductId,
		handleProductName,
		handleCreateOrganization,
		handleUpdateOrganization,
		handleDeleteOrganization,
		createOrganizationData,
		updateOrganizationData,
		deleteOrganizationData,
		treatFieldValidation,
		handleIsEditing
	};

	return <ManageOrganizationContext.Provider value={contextValue}>{children}</ManageOrganizationContext.Provider>;
}

export function useManageOrganization() {
	const context = useContext(ManageOrganizationContext);
	return context;
}
