import React, { useState } from 'react';

import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import hooks from '../../../hooks';

import { CreateLine } from './CreateLine';
import { SelectLine } from './SelectLine';
import { EditLine } from './EditLine';

const { useGetLines } = hooks;

export const ManageLine = () => {
	const [lineName, setLineName] = useState('');
	const [isEditing, setIsEditing] = useState(false);

	const { organizationId, companyId, sectorId } = useManageOrganization();

	const {
		data: lines,
		isError: errorGettingLines,
		isLoading: gettingLines,
		isFetching: fetchingLines,
	} = useGetLines(organizationId, companyId, sectorId);

	function handleIsEditing(editing: boolean) {
		setIsEditing(editing);
	}

	function handleLineNameChange(name: string) {
		setLineName(name);
	}

	if (errorGettingLines) {
		return I18n.get('Oops... Something went wrong!');
	}

	if (fetchingLines || gettingLines) {
		return `${I18n.get('Loading')}...`;
	}

	return lines.length === 0 ? (
		<CreateLine />
	) : isEditing ? (
		<EditLine
			lineName={lineName}
			onLineNameChange={handleLineNameChange}
			onEditing={handleIsEditing}
		/>
	) : (
		<SelectLine
			lines={lines}
			lineName={lineName}
			onLineNameChange={handleLineNameChange}
			onEditing={handleIsEditing}
		/>
	);
};
