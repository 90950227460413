import { useMutation } from '@tanstack/react-query';
import type { UpdateRotationReportDTO, UpdateSuccess } from '@/types/dto';
import type { ErrorResponse } from '@/types';
import Api from '@/services/api';

async function updateRotationReport(params: UpdateRotationReportDTO): Promise<UpdateSuccess> {
	const { data } = await Api.put('/rotation/report', params);
	return data;
}

export const useUpdateRotationReport = () => {
	return useMutation<UpdateSuccess, ErrorResponse, UpdateRotationReportDTO>(updateRotationReport);
};
