import Types from './types';

const initState = {
	organizations: []
};

const workspaceReducer = (state = initState, action) => {
	switch (action.type) {
		case Types.WORKSPACE_SET_LIST_ORGANIZATION: {
			return {
				...state,
				organizations: action.payload
			};
		}

		case Types.WORKSPACE_FILTER_COMPANY_LIST_ORGANIZATION: {
			const { organization_id, company_id } = action.payload;

			const data = state.organizations;

			// get item id updated;
			const currentIndex = data.findIndex(({ id }) => id === organization_id);

			// not found item updated;
			if (currentIndex === -1) {
				return state;
			}

			const organization = data[currentIndex];

			const updatedArray = {
				...organization,
				companies: organization.companies.filter((company) => company !== company_id)
			};

			return {
				...state,
				organizations: [...data.slice(0, currentIndex), updatedArray, ...data.slice(currentIndex + 1)]
			};
		}

		case Types.WORKSPACE_REMOVE_ORGANIZATION: {
			const { id } = action.payload;
			return {
				...state,
				organizations: state.organizations.filter((item) => item.id !== id)
			};
		}

		case Types.WORKSPACE_ADD_ORGANIZATION: {
			const { id, name } = action.payload;

			const body = {
				id,
				name,
				url_logo: null,
				companies: []
			};

			return {
				organizations: [body, ...state.organizations].sort((a, b) => (a.name < b.name ? -1 : 1))
			};
		}

		case Types.WORKSPACE_ADD_COMPANY_LIST_ORGANIZATION: {
			const { organization_id, company_id } = action.payload;

			const data = state.organizations;

			// get item id updated;
			const currentIndex = data.findIndex(({ id }) => id === organization_id);

			// not found item updated;
			if (currentIndex === -1) {
				return state;
			}

			const organization = data[currentIndex];

			const updatedArray = {
				...organization,
				...(data[currentIndex].companies
					? {
							companies: [...data[currentIndex].companies, company_id]
					  }
					: { companies: [] })
			};

			return {
				...state,
				organizations: [...data.slice(0, currentIndex), updatedArray, ...data.slice(currentIndex + 1)]
			};
		}

		default:
			return state;
	}
};

export default workspaceReducer;
