import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { Droppable } from 'react-beautiful-dnd';
import { ActionCard } from './ActionCard';
import { Col, Divider } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { BoardContainer, Counter, ActionList, AddCardBtn } from './styles';
import { ActionPlansContext } from './context';

export function BoardColumn({ title, bg_color, actionPlans }) {
	const {
		showCreateActionPlan,
		onDeleteActionPlan,
		onEditActionPlan,
		calcProgress,
	} = useContext(ActionPlansContext);

	return (
		<Col sm={8} md={8}>
			<BoardContainer style={{ backgroundColor: bg_color }}>
				<Divider style={{ fontSize: '1rem', fontWeight: 'bold' }}>
					{I18n.get(title)}
				</Divider>
				<Counter>{actionPlans.length}</Counter>
				<AddCardBtn onClick={() => showCreateActionPlan(title)}>
					<PlusCircleOutlined />
				</AddCardBtn>
				<Droppable droppableId={title}>
					{(provided) => (
						<ActionList
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{actionPlans.map((actionPlan, index) => (
								<ActionCard
									key={actionPlan.id}
									index={index}
									actionPlan={actionPlan}
									onDelete={(e) => {
										e.stopPropagation();
										onDeleteActionPlan(actionPlan.id);
									}}
									onEdit={onEditActionPlan}
									calcProgress={calcProgress}
								/>
							))}
							{provided.placeholder}
						</ActionList>
					)}
				</Droppable>
			</BoardContainer>
		</Col>
	);
}
