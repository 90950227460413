import React from 'react';
import { Button, Col, Form, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useChecklistEIContext } from '../context';

const { useFormInstance } = Form;

export const Buttons = () => {
	const { validateFields } = useFormInstance();
	const { currentStep, setStep } = useChecklistEIContext();

	async function handleNextPageClick() {
		const values = await validateFields();
		if (values) {
			setStep(currentStep + 1);
		}
	}

	return (
		<Row justify="center" style={{ marginTop: '1rem' }}>
			<Col sm={6}>
				<Row justify="center">
					<Col flex={1}>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								style={{ float: 'right' }}
								onClick={handleNextPageClick}
							>
								{I18n.get('Next')}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
