import React from 'react';
import { Form, Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import * as S from '../../styles';

export function BreakDescription() {
	return (
		<S.ContainerInput $borderRadius="8px">
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				rules={[{ required: true, message: I18n.get('Provide a description') }]}
				name={['cycle_result', 'break_description']}
				label={<RequiredLabel fontSize={'16px'} title="Pause Identification" isRequired={false} />}
			>
				<Input size="large" placeholder={I18n.get('Pause Identification')} />
			</Form.Item>
		</S.ContainerInput>
	);
}
