import React from 'react';
import { Col, Divider, Row } from 'antd';
import { Title, Text } from '@/components/Typography';
import { OverallExposureChart } from './OverallExposureChart';
import { ChartLegend, ChartLegendBadge } from '../styles';

const HORIZONTAL_DIVIDER_INDEXES = [1, 2, 4, 5];
const VERTICAL_DIVIDER_INDEXES = [2];
const BODY_PARTS = ['neck', 'left_upper_arm', 'right_upper_arm', 'trunk', 'left_lower_arm', 'right_lower_arm'];
const LEGEND = [
	{ text: 'Not identified', color: 'rgba(179, 179, 179, 1)' },
	{ text: 'Low risk', color: 'rgba(75, 192, 86, 1)' },
	{ text: 'Moderate risk', color: 'rgba(255, 206, 86, 1)' },
	{ text: 'High risk', color: 'rgba(255, 40, 72, 1)' }
];

export function OverallExposure() {
	return (
		<div style={{ marginTop: 40 }}>
			<Title level={4}>Overall exposure</Title>
			<Row justify={'space-between'} style={{ marginTop: 20 }}>
				{BODY_PARTS?.map((body_part_name, index) => (
					<React.Fragment key={body_part_name}>
						{HORIZONTAL_DIVIDER_INDEXES.includes(index) && (
							<Col span={1}>
								<Divider type="vertical" style={{ height: '100%', borderColor: '#26262680' }} />
							</Col>
						)}
						<Col span={7}>
							<OverallExposureChart bodyPart={body_part_name} />
						</Col>
						{VERTICAL_DIVIDER_INDEXES.includes(index) && (
							<Col span={24}>
								<Divider style={{ borderColor: '#26262680' }} />
							</Col>
						)}
					</React.Fragment>
				))}
				<Col span={24} style={{ display: 'flex' }}>
					{LEGEND.map(({ text, color }) => (
						<ChartLegend key={text}>
							<ChartLegendBadge key={text} $color={color} />
							<Text>{text}</Text>
						</ChartLegend>
					))}
				</Col>
			</Row>
		</div>
	);
}
