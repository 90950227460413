import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
`;

interface IProps {
	size: number;
};

export const Loading: React.FC<IProps> = ({ size }) => (
	<Container>
		<Spin indicator={<LoadingOutlined style={{ fontSize: size }} spin />} />
	</Container>
);
