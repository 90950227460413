import React from 'react';
import { Row, Col } from 'antd';

import { Organization } from './components/Inputs/Organization';
import { AnalysisDate } from './components/Inputs/AnalysisDate';
import { ReportName } from './components/Inputs/ReportName';
import { Paragraph, Title } from '@/components/Typography';
import { FooterButtons } from './components/FooterButtons';
import { Evaluator } from './components/Inputs/Evaluator';
import { RiskRange } from './components/Inputs/RiskRange';
import { Company } from './components/Inputs/Company';
import { useWatch } from 'antd/lib/form/Form';

export function GeneralInformations() {
	const organizationId = useWatch(['informations', 'organization_id']);
	const companyId = useWatch(['informations', 'company_id']);

	return (
		<Row justify="center" gutter={[0, 30]}>
			<Col span={24}>
				<Row>
					<Col span={24}>
						<Title level={4}>General Informations</Title>
					</Col>
					<Col span={24}>
						<Paragraph>Enter the data related to the new analysis below</Paragraph>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row justify="center" gutter={[0, 40]}>
					<Col span={16}>
						<Row>
							<ReportName namePlace="informations" fontSize="16px" isRequired />
						</Row>
					</Col>
					<Col span={16}>
						<Row>
							<Organization namePlace="informations" fontSize="16px" isRequired />
						</Row>
						<Row>
							<Company namePlace="informations" fontSize="16px" isRequired />
						</Row>
					</Col>
					<Col span={16}>
						<Row>
							<Evaluator
								namePlace="informations"
								fontSize="16px"
								isRequired
								organizationId={organizationId}
								companyId={companyId}
							/>
						</Row>
						<Row>
							<AnalysisDate namePlace="informations" fontSize="16px" isRequired />
						</Row>
					</Col>
					<Col span={16}>
						<Row>
							<RiskRange namePlace="informations" fontSize="16px" isRequired />
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<FooterButtons />
			</Col>
		</Row>
	);
}
