import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { QUERY_KEYS } from '@/utils/query-keys';
import { queryClient } from '@/store/query';
import Api from '@/services/api';

export type UpdateRotationReportRestActivityDTO = {
	rotation_activity_id?: string;
	organization_id?: string;
	company_id?: string;
	break_time?: number;
	id?: string;
};

export type RotationReportRestActivityResponse = {
	message: string;
};

async function updateRotationReportRestActivity(params: UpdateRotationReportRestActivityDTO): Promise<any> {
	const url = `/rotation/report/${params.id}/rest`;
	const { data } = await Api.put(url, params);
	return data;
}

export const useUpdateRotationReportRestActivity = () => {
	return useMutation((prams: UpdateRotationReportRestActivityDTO) => updateRotationReportRestActivity(prams), {
		onSuccess: ({ message: txt }: RotationReportRestActivityResponse) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_ACTIVITIES]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_RESULT]);
			message.success(I18n.get(txt));
		}
	});
};
