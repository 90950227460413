import React, { ReactNode } from 'react';
import * as S from './styled';

interface LogoProps {
	src?: string;
	children?: ReactNode;
}

export function Logo({ src = '', children }: Readonly<LogoProps>) {
	if (children) {
		return <S.CustomContainer>{children}</S.CustomContainer>;
	}
	return <S.Image className="kinebot-logo-report" src={src} />;
}
