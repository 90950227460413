import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import { useGetProducts } from '@/hooks/useGetProducts';
import { CreateProduct } from './CreateProduct';
import { SelectProduct } from './SelectProduct';
import { EditProduct } from './EditProduct';

export function ManageProduct() {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [productName, setProductName] = useState<string>('');
	const { organizationId, companyId, sectorId, lineId, workstationId } = useManageOrganization();

	const { isFetching, isLoading, data, isError } = useGetProducts({
		organization_id: organizationId,
		company_id: companyId,
		sector_id: sectorId,
		line_id: lineId,
		workstation_id: workstationId
	});

	function handleEditProduct(editing: boolean): void {
		setIsEditing(editing);
	}

	function handleChangeProductName(name: string): void {
		setProductName(name);
	}

	if (isError) {
		return I18n.get('Oops... Something went wrong!');
	}

	if (isFetching || isLoading) {
		return `${I18n.get('Loading')}...`;
	}

	if (data?.length === 0) {
		return <CreateProduct />;
	}

	if (isEditing) {
		return (
			<EditProduct
				productName={productName}
				onEditing={handleEditProduct}
				onProductNameChange={handleChangeProductName}
			/>
		);
	}

	return (
		<SelectProduct
			products={data}
			productName={productName}
			onEditing={handleEditProduct}
			onProductNameChange={handleChangeProductName}
		/>
	);
}
