import styled from 'styled-components';
import { Collapse } from 'antd';

export const CollapseWrapper = styled(Collapse)`
	border-radius: 10px;

	.ant-collapse-item:last-child {
		border-radius: 10px;
	}
	.ant-collapse-item:last-child {
		.ant-collapse-content {
			border-radius: 0 0 10px 10px;
		}
	}
`;
