import { useQuery } from '@tanstack/react-query';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { BaseContextOptional } from '@/types';

export type GetMtmReportResponse = {
	id: string;
	file_ids: string[];
	current_step: number;
	is_completed: boolean;
	consolidated: boolean;
	informations: Informations;
	comment: string;
	operation_product: { [key: string]: OperationProduct };
};

type Informations = {
	line_id: string;
	report_name: string;
	sector_id: string;
	evaluator_id: string;
	analysis_date: Date;
	collection_date: Date;
};

type OperationProduct = {
	workstation_id: null | string;
	products: string[];
};

export type GetMtmReportDTO = BaseContextOptional & {
	id?: string;
};

async function getMtmReport(params: GetMtmReportDTO): Promise<GetMtmReportResponse> {
	const url = `/mtm/report/${params.id}`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useGetMtmReport = (params: GetMtmReportDTO) => {
	return useQuery([QUERY_KEYS.GET_MTM_REPORT, params], () => getMtmReport(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.id
	});
};
