import styled from 'styled-components';

export const SubTitle = styled.p`
	text-align: left;
	font-weight: bold;
`;

export const Counter = styled.div`
	width: 25px;
	height: 25px;
	background-color: #58aaf5;
	color: white;
	border-radius: 5px;
	text-align: center;
	font-weight: bold;
	font-size: 0.8rem;
	line-height: 1.6rem;
	position: absolute;
	margin-right: 20px;
	right: 0px;
	top: 0px;
	cursor: default;
	float: right;
`;
