import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';
import type { ErrorResponse } from '@/types';
import type { CreateProductDTO, ProductDTO } from '@/types/dto/product';

async function createProduct(params: CreateProductDTO): Promise<ProductDTO> {
	const { data } = await Api.post('/product', params);
	return data;
}

export const useCreateProduct = () => {
	return useMutation<ProductDTO, ErrorResponse, CreateProductDTO>(createProduct);
};
