import React, { useState, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, List, Button, Form, Modal, message } from 'antd';

import { CreateCustom } from './Customization';
import { useAsync } from '@/hooks/useAsync';
import { Card, Title } from './styles';

const { useForm } = Form;

export function Preferences() {
	const [form] = useForm();

	const [visible, setVisible] = useState(false);

	const [rangeRiskCreate, doFetchCreate] = useAsync();
	const [rangeDelete, doFetchDelete] = useAsync();
	const [rangeRiskList, doFetchList] = useAsync(
		'/customization/risk-ranges/'
	);

	useEffect(() => {
		if (rangeRiskCreate.data) {
			doFetchList('/customization/risk-ranges/');
			message.success(I18n.get('Risk track created successfully!'));
		}
		if (rangeRiskCreate.errors) {
			message.error(rangeRiskCreate.errors.message);
		}
		return () => setVisible(false);
	}, [rangeRiskCreate.data, rangeRiskCreate.errors]);

	useEffect(() => {
		if (rangeDelete.data?.message) {
			doFetchList('/customization/risk-ranges/');
			message.success(I18n.get(rangeDelete.data.message));
		}
		if (rangeDelete.errors) {
			message.error(rangeDelete.errors.message);
		}
		return () => setVisible(false);
	}, [rangeDelete.data, rangeDelete.errors]);

	const onFinish = async (values) => {
		console.log(values, 'values');

		doFetchCreate('/customization/risk-ranges/create', values, 'post');
	};

	const onCreate = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const onDelete = (id) => {
		Modal.confirm({
			title: I18n.get('Do you really want to delete these tracks?'),
			icon: <ExclamationCircleOutlined />,
			content: I18n.get('Heads up! the deletion is permanent'),
			okText: I18n.get('Confirm'),
			okType: 'danger',
			cancelText: I18n.get('Cancel'),
			onOk() {
				doFetchDelete(`/customization/risk-ranges/${id}`, {}, 'delete');
			},
			onCancel() {},
		});
	};

	const itemListRender = (item) => {
		// <a key="list-loadmore-edit">editar</a>
		return (
			<List.Item
				actions={
					!item.standard && [
						<a
							key="list-loadmore-more"
							onClick={() => onDelete(item.id)}
							style={{ color: 'red' }}
						>
							excluir
						</a>,
					]
				}
			>
				<List.Item.Meta
					title={<p>{item.name}</p>}
					description={item.description}
				/>
			</List.Item>
		);
	};

	return (
		<Row>
			<CreateCustom
				form={form}
				visible={visible}
				onClose={onClose}
				onFinish={onFinish}
				loading={rangeRiskCreate.loading}
			/>
			<Col sm={24}>
				<Title>
					<h2>{I18n.get('Preferences')}</h2>
				</Title>
			</Col>
			<Col sm={24}>
				<Title>
					<h2 style={{ fontSize: '20px' }}>
						{I18n.get('Risk ranges')}
					</h2>
				</Title>
				<Button
					size="large"
					type="primary"
					disabled={true}
					onClick={onCreate}
					style={{ display: 'inline', float: 'right' }}
				>
					{I18n.get('Create')}
				</Button>
			</Col>
			<Col sm={24} style={{ paddingTop: '10px' }}>
				<Card>
					<List
						itemLayout="horizontal"
						renderItem={itemListRender}
						dataSource={rangeRiskList.data || []}
						loading={rangeRiskList.loading}
						// loading={{
						// 	indicator: <Spin indicator={antIcon} />,
						// 	spinning: true
						// 	spinning: rangeRiskList.loading
						// }}
					/>
				</Card>
			</Col>
		</Row>
	);
}
