import { Row } from 'antd';
import styled from 'styled-components';

export const RowCustom = styled(Row)`
	background-color: #f8f8f8;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 7px;
	padding: 5px 5px 5px 15px;
	.ant-divider {
		background-color: rgba(0, 0, 0, 0.2);
		height: 30px;
		margin: 0 10px 0 6px;
	}
`;
