export enum QUERY_KEYS {
	GET_WORKERS = 'get-workers',
	GET_SECTORS = 'get-sectors',
	GET_MTM_CODE = 'get-mtm-code',
	GET_PRODUCTS = 'get-products',
	GET_MTM_CODES = 'get-mtm-codes',
	GET_COMPANIES = 'get-companies',
	GET_CHECKLISTS = 'get-checklists',
	GET_COMPLAINTS = 'get-complaints',
	GET_MTM_REPORT = 'get-mtm-report',
	GET_EVALUATORS = 'get-evaluators',
	GET_ACTIVITIES = 'get-activities',
	GET_RISK_RANGES = 'get-risk-ranges',
	GET_RESTRICTIONS = 'get-restrictions',
	GET_ORGANIZATIONS = 'get-organizations',
	GET_WORKER_HISTORY = 'get-worker-history',
	GET_ROTATION_REPORT = 'get-rotation-report',
	GET_ROTATION_REPORTS = 'get-rotation-reports',
	GET_MTM_REPORT_FILES = 'get-mtm-report-files',
	GET_MTM_TO_OPERATIONS = 'get-mtm-to-operations',
	GET_MTM_REPORT_RESULT = 'get-mtm-report-result',
	GET_FILE_ACTION_PLANS = 'get-file-action-plans',
	GET_WORKER_RESTRICTION = 'get-worker-restriction',
	GET_FILES_WITH_ACTIVITY = 'get-files-with-activity',
	GET_ROTATION_OBJECTIVES = 'get-rotation-objectives',
	GET_MEDICAL_CERTIFICATES = 'get-medical-certificates',
	GET_AGGREGATION_VALUES = 'get-mtm-aggregation-values',
	GET_ROTATION_REPORT_FILES = 'get-rotation-report-files',
	GET_MTM_REPORT_OPERATIONS = 'get-mtm-report-operations',
	GET_FILES_WITH_OPERATIONS = 'get-files-with-operations',
	GET_BUSINESS_INFORMATIONS = 'get-business-informations',
	GET_MTM_REPORTS_PAGINATED = 'get-mtm-reports-paginated',
	GET_ROTATION_REPORT_RESULT = 'get-rotation-report-result',
	GET_ROTATION_REPORT_ACTIVITIES = 'get-rotation-report-activities',
	GET_ERGONOMIC_WORK_ANALYSIS_LIST = 'get-ergonomic-work-analysis-list',
	GET_ROTATION_REPORT_CYCLE_TOTAL_TIME = 'get-rotation-report-cycle-total-time',
	GET_ROTATION_REPORT_BODY_PART_EXPOSURE = 'get-rotation-report-body-part-exposure',
	GET_ROTATION_REPORT_ACTIVITY_RISK_RANGES = 'get-rotation-report-activity-risk-ranges'
}
