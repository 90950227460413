import React from 'react';
import {  Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DefaultOptionType } from 'antd/lib/select';
import { MtmAggregationValue } from '@/types';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import * as S from '../styles';

interface AggregationValuesProps {
	fontSize?: string;
	isRequired?: boolean;
	loading?: boolean;
	disabled?: boolean;
	namePlace: string[];
	aggregationValues: MtmAggregationValue[] | undefined;
}

type OptionType = { label: string; value: string } | undefined;

export function AggregationValues({
	fontSize,
	loading,
	disabled,
	isRequired,
	namePlace,
	aggregationValues = []
}: Readonly<AggregationValuesProps>) {
	function filterOption(option: OptionType | DefaultOptionType, input: string): boolean {
		return (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
	}

	const options = aggregationValues.map(({ id, name, description }) => ({
		label: `${name} - ${description}`,
		value: id
	}));

	return (
		<Form.Item
			required={false}
			labelCol={{ span: 24 }}
			name={[...namePlace, 'mtm_aggregation_value_id']}
			rules={[{ required: true, message: I18n.get('Set the value-added') }]}
			label={<RequiredLabel bold fontSize={fontSize} title="Value-added" isRequired={false} />}
		>
			<S.CustomSelect
				showSearch
				allowClear
				size="large"
				loading={loading}
				disabled={disabled}
				placeholder={I18n.get('Value-added')}
				filterOption={(input, option) => filterOption(option as OptionType, input)}
				options={options}
			/>
		</Form.Item>
	);
}
