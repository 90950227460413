import React, { MouseEventHandler } from 'react';
import { Bar } from 'react-chartjs-2';
import { I18n } from '@aws-amplify/core';
import ZoomPlugin from 'chartjs-plugin-zoom';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ZoomPlugin
);

interface IBarChart {
	data?: Array<any>,
	showValue?: boolean,
	orientation?: 'horizontal' | 'vertical'
	height?: string;
	onClick?: MouseEventHandler;
	ref?: any;
};

export const BarChart: React.FC<IBarChart> = React.forwardRef<any, any>(({ data, showValue = false, orientation = 'vertical', height = '100%', onClick }, ref) => {

	const disableDatalabels = {
		datalabels: {
			display: false,
		}
	};

	const showDatalabelsValue = {
		datalabels: {
			color: 'white',
			formatter: (value: number) => {
				return value < 4 ? '' : value
			}
		}
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		indexAxis: orientation === 'vertical' ? 'x' : 'y',
		scales: {
			x: {
				min: 0,
				stacked: true,
				grid: {
					display: false,
				},
				ticks: {
					precision: 0,
					source: 'data',
					autoSkip: false,
				},
			},
			y: {
				min: 0,
				...(orientation === 'horizontal' && { max: 15 }),
				stacked: true,
				grid: {
					display: false,
				},
				ticks: {
					precision: 0,
					source: 'data',
					autoSkip: false,
				},
			}
		},
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
			...(showValue ? { ...showDatalabelsValue } : { ...disableDatalabels }),
			...(orientation === 'horizontal' && {
				zoom: {
					pan: {
						mode: 'y',
						enabled: true,
					},
				},
			}),
		},
	};

	const dataset = {
		labels: data?.map((item: any) => item.name),
		datasets: [
			{
				maxBarThickness: 40,
				label: I18n.get('Low'),
				backgroundColor: ['rgba(64, 191, 95)'],
				borderColor: ['rgba(64, 191, 95)'],
				data: data?.map((item: any) => item.Low),
			},
			{
				maxBarThickness: 40,
				label: I18n.get('Average'),
				backgroundColor: ['rgba(252, 176, 51)'],
				borderColor: ['rgba(252, 176, 51)'],
				data: data?.map((item: any) => item.Average),
			},
			{
				maxBarThickness: 40,
				label: I18n.get('High'),
				backgroundColor: ['rgba(240, 117, 117)'],
				borderColor: ['rgba(240, 117, 117)'],
				data: data?.map((item: any) => item.High),
			},
		],
	};

	return (
		<div style={{ height: '100%', paddingTop: '10px', paddingBottom: '35px' }}>
			<Bar options={options as any} data={dataset} height={'100%'} onClick={onClick} ref={ref} />
		</div>
	);
})
