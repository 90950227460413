import React from 'react';
import { Container, Spinner } from './styles';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
	<LoadingOutlined
		style={{
			fontSize: 24,
		}}
		spin
	/>
);

export const Loading = () => (
	<Container>
		<Spinner indicator={antIcon} />
	</Container>
);
