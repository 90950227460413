import React from 'react';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { Form, Input, Checkbox, Button } from 'antd';

export interface FormValues {
	email: string;
	password: string;
	remember?: boolean;
}

interface LoginFormProps {
	isLoading?: boolean;
	onFinish: (values: FormValues) => Promise<void>;
}

export function LoginForm({ isLoading = false, onFinish }: LoginFormProps) {
	return (
		<Form onFinish={onFinish}>
			<Form.Item
				name="email"
				hasFeedback
				rules={[
					{
						type: 'email',
						message: I18n.get('Enter a valid email')
					},
					{
						message: I18n.get('Enter your email'),
						required: true
					}
				]}
			>
				<Input placeholder="E-mail" prefix={<Icons.UserOutlined className="site-form-item-icon" />} />
			</Form.Item>
			<Form.Item
				name="password"
				hasFeedback
				rules={[
					{
						message: I18n.get('Enter your password'),
						required: true
					}
				]}
			>
				<Input.Password
					placeholder={I18n.get('Password')}
					prefix={<Icons.LockOutlined />}
					iconRender={(visible) => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
				/>
			</Form.Item>
			<Form.Item>
				<Form.Item name="remember" valuePropName="checked" noStyle>
					<Checkbox style={{ float: 'left' }}>{I18n.get('Remember me')}</Checkbox>
				</Form.Item>
				<a style={{ float: 'right' }} href="/forgot-password">
					{I18n.get('Forgot password')}
				</a>
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit" loading={isLoading} style={{ width: '100%' }}>
					{I18n.get('Login')}
				</Button>
			</Form.Item>
		</Form>
	);
}
