import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { OrganizationSelect } from '@/components/ui/Inputs/OrganizationSelect';
import { useApplicationContext } from '@/context/application';
import { useOrganization } from '@/hooks/v1';
import * as S from '../styles';

const { useFormInstance } = Form;

export const Organization: React.FC = () => {
	const { setFieldValue } = useFormInstance();

	const { organization } = useApplicationContext();
	const { organizations } = useOrganization();

	setFieldValue('organization_id', organization?.id);

	return (
		<Col xs={24}>
			<Form.Item name={['organization_id']} label={I18n.get('Company')} labelCol={{ span: 24 }}>
				<S.ContainerSelect $borderRadius="6px">
					<OrganizationSelect
						organizations={organizations.data}
						loading={organizations.isLoading || organizations.isFetching}
						organizationId={organization?.id}
						disabled
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
};
