import React from 'react';
import { Col, Form } from 'antd';

import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { useWorkstations } from '@/hooks';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

interface WorkstationProps {
	namePlace?: string;
	fontSize?: string;
	isRequired?: boolean;
}

export function Workstation({ namePlace = 'filter', fontSize, isRequired = false }: WorkstationProps) {
	const { setFieldValue } = useFormInstance();

	const organizationId = useWatch([namePlace, 'organization_id']);
	const companyId = useWatch([namePlace, 'company_id']);
	const lineId = useWatch([namePlace, 'line_id']);
	const workstationId = useWatch([namePlace, 'workstation_id']);

	const { isFetching, data } = useWorkstations({
		organization_id: organizationId,
		company_id: companyId,
		line_id: lineId
	});

	function handleOnChange(id: string): void {
		setFieldValue([namePlace, 'workstation_id'], id);
		setFieldValue([namePlace, 'products'], undefined);
	}

	return (
		<Col span={23}>
			<S.CustomFormItem
				required={false}
				labelCol={{ span: 24 }}
				style={{ marginBottom: 0 }}
				name={[namePlace, 'workstation_id']}
				label={<RequiredLabel fontSize={fontSize} title="Workstation" isRequired={isRequired} />}
			>
				<S.ContainerSelect $borderRadius="8px">
					<WorkstationSelect
						size="large"
						lineId={lineId}
						workstations={data}
						disabled={!lineId}
						loading={isFetching}
						allowCreation={false}
						onChange={handleOnChange}
						workstationId={workstationId}
						fieldName={[namePlace, 'workstation_id']}
					/>
				</S.ContainerSelect>
			</S.CustomFormItem>
		</Col>
	);
}
