import React from 'react';

const defaultState = {
	workers: null,
	complaints: null,
	certificates: null,
	pwds: null,
	restricts: null,
	women: null,
	buWorkers: null,
	ccWorkers: null,
	lineWorkers: null,
	workstationWorkers: null,
	ergonomicGrade: null,
	companies: null,
	selectCompanyId: null,
	sectors: null,
	selectSectorId: null,
	lines: null,
	selectLineId: null,
	workstations: null,
	selectWorkstationId: null,
	errors: null,
};

const defaultContext = {
	...defaultState,
	getWorkers: () => {},
	getComplaints: () => {},
	getCertificates: () => {},
	getPwds: () => {},
	getRestricts: () => {},
	getWomen: () => {},
	getErgonomicGrade: () => {},
	setSelectCompanyId: () => {},
	setSelectSectorId: () => {},
	setSelectLineId: () => {},
	setErrors: () => {},
	getCompanies: () => {},
	getSectors: () => {},
	getLines: () => {},
	getWorkstations: () => {},
	getBuErgonomicQuote: () => {},
	getCcErgonomicQuote: () => {},
	getLineErgonomicQuote: () => {},
	getWorkstationErgonomicQuote: () => {},
	getTotalWorkstations: () => {},
	getWorkstationsBySegment: () => {},
};

export const NewAppointmentContext = React.createContext(defaultContext);
