import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { Error } from './Error';

import { CounterCard } from '@/components/ui/CounterCard';

export function Card({
	icon,
	title,
	attribute,
	onFetch,
	onClick,
	filter = {},
	active = false,
}) {
	const { data, isError, isLoading } = useQuery([attribute, filter], () =>
		onFetch(filter)
	);

	if (isError) {
		return <Error />;
	}

	return (
		<CounterCard
			handleClick={onClick}
			active={active}
			size="small"
			key={title}
			Icon={icon}
			title={title}
			count={data?.total ?? 0}
			loading={isLoading}
		/>
	);
}
