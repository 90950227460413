import React from 'react';
import { Row, Col, Form, Divider } from 'antd';

import { useWorkstations } from '@/hooks';
import { Workstation } from '../../../components/Inputs';
import { SearchProduct } from './components/SearchProduct';
import { ProductContainer } from './components/ProductContainer';

const { useFormInstance } = Form;

interface OperationCardProps {
	operation_id?: string;
}

export function OperationCard({ operation_id = '' }: OperationCardProps) {
	const { getFieldValue } = useFormInstance();

	const organization_id = getFieldValue(['informations', 'organization_id']);
	const company_id = getFieldValue(['informations', 'company_id']);
	const line_id = getFieldValue(['informations', 'line_id']);

	const { isFetching: workstationsIsFetching, data: workstations } = useWorkstations({
		organization_id,
		company_id,
		line_id
	});

	return (
		<Row style={{ marginBottom: '30px' }}>
			<Col offset={1} span={10}>
				<Workstation
					isRequired
					fontSize="16px"
					data={workstations}
					isFetching={workstationsIsFetching}
					namePlace={['operation_product', operation_id]}
				/>
			</Col>
			<Col span={1}>
				<Divider type="vertical" style={{ height: '100%' }} />
			</Col>
			<Col span={10}>
				<SearchProduct operation_id={operation_id} />

				<ProductContainer loadingWorkstation={workstationsIsFetching} operationId={operation_id} />
			</Col>
		</Row>
	);
}
