import React from 'react';
import { Button } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useDashboardContext } from '../context';

export function Cleaner() {
	const { setFilter } = useDashboardContext();

	const onClick = () => {
		setFilter(null);
	};

	return (
		<Button type="link" onClick={onClick}>
			{I18n.get('Clear')}
		</Button>
	);
}
