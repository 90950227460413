import React, { useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row as Container } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import Api from '@/services/api';
import { Parameters } from './Parameters';
import { Diagnostic } from './Diagnostic';
import { ModalBorder } from '@/components/ui';
import { Informations } from './Informations';
import { sectorJSON } from '@/redux/reports/selectors';
import { Title, HeaderStep, Main } from '../Components/Modal';
import { setFile, setRecoveryReport } from '@/redux/reports/actions';

export function ScoreParts({ item, visible, onClose }) {
	const dispatch = useDispatch();

	const { organization, company } = useSelector((state) => state.organization);

	const sector = useSelector(sectorJSON);
	const file = useSelector((state) => state.reports.file);
	const recoveryReport = useSelector((state) => state.reports.recovery_data);

	const { current, informations, parameters } = useSelector((state) => state.reports.customized);

	useEffect(() => {
		if (item) {
			dispatch(setFile(item));
		}
	}, [item]);

	useLayoutEffect(() => {
		if (organization.id && company.id && file) {
			const url = `/recovery_report/${organization.id}/${company.id}/${file.id}/score_parts`;
			Api.get(url)
				.then(({ data }) => dispatch(setRecoveryReport(data)))
				.catch((error) => console.log(error));
		}
	}, [organization, company, file]);

	useEffect(() => {
		if (file && organization.id && company.id && _.isEmpty(recoveryReport)) {
			const body = {
				file_id: file.id,
				type: 'score_parts',
				company_id: company.id,
				organization_id: organization.id
			};
			Api.post('/recovery_report/create', body)
				.then(({ data }) => dispatch(setRecoveryReport(data)))
				.catch((error) => console.log(error));
		}
	}, [file, organization, company, recoveryReport]);

	useEffect(() => {
		if (informations.is_valid && current === 1) {
			if (file && sector && organization.id && company.id && recoveryReport) {
				const url = '/recovery_report/update';
				const body = {
					recovery_report_id: recoveryReport.id,
					organization_id: organization.id,
					company_id: company.id,
					file_id: file.id,
					data: {
						collection_date: moment(informations.collection_date).format(),
						workstation: informations.workstation,
						sector_id: sector.id
					}
				};

				Api.put(url, body).catch((error) => console.log(error, 'error'));
			}
		}
	}, [file, organization, company, informations, current, recoveryReport, sector]);

	const steps = [
		{
			title: 'Informations',
			content: <Informations />,
			size: 750,
			is_valid: informations.is_valid
		},
		{
			title: 'Parameters',
			content: <Parameters />,
			size: 750,
			is_valid: parameters.is_valid
		},
		{
			title: 'Diagnostic',
			content: <Diagnostic />,
			size: 1000,
			is_valid: true
		}
	];

	return (
		<ModalBorder footer={null} visible={visible} onCancel={onClose} width={steps[current].size}>
			<Container>
				<Title>Movement by score</Title>
				<HeaderStep current={current} steps={steps} />
				<Main>{steps[current].content}</Main>
			</Container>
		</ModalBorder>
	);
}
