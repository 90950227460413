import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Text } from '../../Typography';
import * as S from './styles';

interface ErrorNotificationProps {
	text: string;
}

export const ErrorNotification: React.FC<ErrorNotificationProps> = ({ text }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<S.ErrorMessageCol xs={6}>
			<Row align="bottom" gutter={[8, 8]}>
				<Col>
					<ExclamationCircleOutlined style={{ color: '#ffffff' }} />
				</Col>
				<Col>
					<Text>{text}</Text>
				</Col>
			</Row>
		</S.ErrorMessageCol>
	);
};
