import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, Modal, Select, DatePicker, Button, Input, message } from 'antd';
import { I18n } from '@aws-amplify/core';

import Api from '@/services/api';
import { ActionPlansContext } from './context';
import moment from 'moment';
import { useSelector } from 'react-redux';

export function CreateActionPlan({ visible, onClose }) {
	const {
		companies,
		setSelectCompanyId,
		sectors,
		setSelectSectorId,
		lines,
		setSelectLineId,
		workstations,
		setSelectWorkstationId,
		complaints,
		setSelectComplaintId,
		responsible_workers,
		creationBoard,
		errors,
		setErrors,
	} = useContext(ActionPlansContext);

	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const organizationId = useSelector(
		({ organization }) => organization.organization?.id
	);

	function resetFieldsForm(field) {
		const options = {
			company: ['sector', 'line', 'workstation_id', 'complaint_id'],
			sector: ['line', 'workstation_id', 'complaint_id'],
			line: ['workstation_id', 'complaint_id'],
			workstation: ['complaint_id'],
		};
		form.resetFields(options[field] || []);
	}

	useEffect(() => {
		if (errors) {
			const result = errors.response?.data?.message || errors.message;
			const description = I18n.get(result);
			message.error(description);
			setErrors(null);
		}
	}, [errors, setErrors]);

	const onResponsibleChange = () => {};

	const onCloseModal = () => {
		onClose();
		form.resetFields([
			'description',
			'responsible_worker_id',
			'deadline',
			'company',
			'sector',
			'line',
			'workstation_id',
			'complaint_id',
		]);
	};

	const filterOption = (input, option) => {
		return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
	};

	const onCreateActionPlan = (values) => {
		const { description, responsible_worker_id, deadline, workstation_id } = values;
		const complaint_id = values.complaint_id ?? null;

		const action_plan = {
			description,
			responsible_worker_id,
			deadline,
			board: creationBoard.toUpperCase(),
			workstation_id,
			complaint_id,
		};

		const url = '/action_plan';
		const body = {
			action_plan,
			organization_id: organizationId,
		};

		Api.post(url, body)
			.then(setLoading(true))
			.then((res) => {
				message.success(I18n.get('Action plan created successfully'));
				onCloseModal();
			})
			.catch((err) => setErrors(err))
			.finally(() => setLoading(false));
	};

	const onSubmit = () => {
		form.validateFields()
			.then(onCreateActionPlan)
			.catch((errInfo) => {});
	};

	const Footer = () => [
		<Button key="cancel" onClick={onCloseModal}>
			{I18n.get('Cancel')}
		</Button>,
		<Button key="submit" type="primary" onClick={onSubmit} loading={loading}>
			{I18n.get('Save')}
		</Button>,
	];

	return (
		<Modal
			style={{ top: 20 }}
			visible={visible}
			width={'1000px'}
			title={I18n.get('Create action plan')}
			footer={<Footer />}
			onCancel={onCloseModal}
		>
			<Form form={form} layout="vertical" name="create_action_plan">
				<Row gutter={[50, 10]}>
					<Col span={12}>
						<Form.Item
							name="description"
							label={I18n.get('Description')}
							rules={[
								{
									required: true,
									message: I18n.get('Please enter a valid description'),
								},
							]}
						>
							<Input placeholder={I18n.get('Action plan description')} />
						</Form.Item>
						<Row justify="space-between">
							<Form.Item
								name="responsible_worker_id"
								label={I18n.get('Person responsible')}
								rules={[
									{
										message: I18n.get(
											'Please enter a person responsible'
										),
										required: true,
									},
								]}
								style={{
									width: '70%',
									display: 'inline-block',
								}}
							>
								<Select
									placeholder={I18n.get(
										'Who is responsible for this action plan?'
									)}
									onChange={onResponsibleChange}
									allowClear
								>
									{responsible_workers.map((person, index) => (
										<Select.Option key={index} value={person.id}>
											{person.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								name="deadline"
								label={I18n.get('Deadline')}
								rules={[
									{
										required: true,
										message: I18n.get('Please enter a valid date'),
									},
								]}
								style={{
									display: 'inline-block',
									width: '25%',
								}}
							>
								<DatePicker
									format={'DD/MM/YYYY'}
									placeholder={I18n.get('Date')}
									disabledDate={(current) =>
										current < moment().subtract(1, 'day')
									}
								/>
							</Form.Item>
						</Row>
					</Col>
					<Col span={12}>
						<Form.Item
							name="company"
							label={I18n.get('Industrial site')}
							rules={[
								{
									required: true,
									message: I18n.get('Select a industrial site'),
								},
							]}
						>
							<Select
								style={{ width: '100%' }}
								onChange={(value) => {
									setSelectCompanyId(value);
									resetFieldsForm('company');
								}}
								placeholder={I18n.get('Select a industrial site')}
							>
								{companies.map((company, index) => (
									<Select.Option key={index} value={company.id}>
										{company.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							name="sector"
							label={I18n.get('Sector')}
							rules={[
								{
									required: true,
									message: I18n.get('Select a sector'),
								},
							]}
						>
							<Select
								style={{ width: '100%' }}
								onChange={(value) => {
									setSelectSectorId(value);
									resetFieldsForm('sector');
								}}
								placeholder={I18n.get('Select a industrial site')}
							>
								{sectors.map((sector, index) => (
									<Select.Option key={index} value={sector.id}>
										{sector.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							name="line"
							label={I18n.get('Line')}
							rules={[
								{
									required: true,
									message: I18n.get('Select a line'),
								},
							]}
						>
							<Select
								style={{ width: '100%' }}
								onChange={(value) => {
									setSelectLineId(value);
									resetFieldsForm('line');
								}}
								placeholder={I18n.get('Select a line')}
							>
								{lines.map((line, index) => (
									<Select.Option key={index} value={line.id}>
										{line.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							name="workstation_id"
							label={I18n.get('Workstation')}
							rules={[
								{
									required: true,
									message: I18n.get('Select a workstation'),
								},
							]}
						>
							<Select
								showSearch
								style={{ width: '100%' }}
								filterOption={filterOption}
								onChange={(value) => {
									setSelectWorkstationId(value);
									resetFieldsForm('workstation');
								}}
								placeholder={I18n.get('Select a workstation')}
							>
								{workstations.map((item, index) => (
									<Select.Option key={index} value={item.id}>
										{item.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							name="complaint_id"
							label={I18n.get('Complaint')}
							rules={[
								{
									required: false,
									message: I18n.get('Select a complaint'),
								},
							]}
						>
							<Select
								style={{ width: '100%' }}
								onChange={(value) => {
									setSelectComplaintId(value);
								}}
								placeholder={I18n.get('Select a complaint')}
							>
								{complaints.map((complaint, index) => (
									<Select.Option key={index} value={complaint.id}>
										{complaint.description}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
