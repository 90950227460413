import React from 'react';
import { Col, Row } from 'antd';
import { OperationElement } from '@/hooks';
import { Title, Text } from '@/components/Typography';
import { DividerCustom } from '@/components/ui/DividerCustom';
import { DownloadPDFParams } from '@/components/views/MtmReport/types';
import { MtmOperationCard } from './MtmOperationCard';
import { ResultCard } from '../../../../components/ResultCard';
import { ButtonsCustom } from '../../../../components/ReportTemplates/Footer/ButtonsCustom';
import * as S from './style';

type OperationCardProps = {
	operation: OperationElement;
	onDownloadPDF: (params: DownloadPDFParams) => Promise<void>;
	isLoadingPDF: boolean;
};

export const OperationCard = ({ operation, onDownloadPDF, isLoadingPDF }: OperationCardProps) => {
	const { name, total_time, aggregation_values, total_technical_time, items } = operation;

	return (
		<S.OperationCardRow>
			<Col span={24}>
				<Row align={'middle'} justify={'space-between'}>
					<Col>
						<Title level={3}>{name}</Title>
					</Col>
					<Col style={{ marginBottom: '1rem' }}>
						<ButtonsCustom
							isLoadingPDF={isLoadingPDF}
							isConsolidating={false}
							consolidated={false}
							hasConsolidated={false}
							onDownloadPDF={onDownloadPDF}
							onConsolidatePDF={() => {}}
						/>
					</Col>
				</Row>
			</Col>
			<Col xxl={5} xl={12}>
				<Text className="font-600">Operation total time</Text>
				<ResultCard
					style={{ marginTop: 10 }}
					padding="1rem"
					height="35px"
					fontSize="15px"
					borderRadius="7px"
					value={`${total_time}`}
				/>
			</Col>
			<Col xxl={4} xl={12}>
				<Text className="font-600">Technical total time</Text>
				<ResultCard
					style={{ marginTop: 10 }}
					padding="1rem"
					height="35px"
					fontSize="15px"
					borderRadius="7px"
					value={`${total_technical_time}`}
				/>
			</Col>
			<Col xxl={1} xl={{ span: 0, offset: 0 }} offset={1}>
				<Row style={{ height: '100%' }}>
					<DividerCustom span={24} color="#D9D9D9" type="vertical" thickness="98%" />
				</Row>
			</Col>
			<Col xxl={13} xl={{ span: 24, offset: 0 }} offset={1}>
				<Text className="font-600">Aggregation value</Text>
				<Row justify={'space-between'}>
					<S.AggregationValue>
						<div className="VAA item">
							<Text className="text-white">VAA</Text>
						</div>
						<div className="item">
							<Text>{aggregation_values?.VAA}</Text>
						</div>
					</S.AggregationValue>
					<S.AggregationValue>
						<div className="SVAA item">
							<Text className="text-white">SVAA</Text>
						</div>
						<div className="item">
							<Text>{aggregation_values?.SVAA}</Text>
						</div>
					</S.AggregationValue>
					<S.AggregationValue>
						<div className="NVAA item">
							<Text className="text-white">NVAA</Text>
						</div>
						<div className="item">
							<Text>{aggregation_values?.NVAA}</Text>
						</div>
					</S.AggregationValue>
				</Row>
			</Col>
			<Col span={24} style={{ marginTop: 20 }} />
			{items?.map((item) => (
				<MtmOperationCard key={item.code} mtm_operation={item} />
			))}
		</S.OperationCardRow>
	);
};
