import React from 'react';
import { Title } from '@/components/Typography';
import { Col, Row } from 'antd';

import { MtmReportResult } from '@/hooks';
import { ProductCard } from './ProductCard';

interface OperationsProductProps {
	operations_product?: MtmReportResult['product_operations'];
}

export function OperationsProduct({ operations_product }: Readonly<OperationsProductProps>) {
	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col span={22}>
				<Title level={4} style={{ color: '#262626' }}>
					Operations by product
				</Title>
			</Col>
			<Col span={22}>
				<Row gutter={[50, 40]} justify="start">
					{operations_product?.map(({ id, name, operations, total_time }) => (
						<ProductCard
							key={id}
							product_id={id}
							operations={operations}
							product_name={name}
							total_time={total_time}
						/>
					))}
				</Row>
			</Col>
		</Row>
	);
}
