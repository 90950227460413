import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { updateBodyPart } from '@/redux/reports/actions';

export function BodyParts() {
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const { body_parts } = useSelector((state) => state.reports.customized);

	function onChange([{ name, value }]) {
		dispatch(updateBodyPart({ [[name]]: value }));
	}

	return (
		<Form
			form={form}
			onFieldsChange={onChange}
			initialValues={body_parts}
			style={{ padding: '25px 10px 10px 10px' }}
		>
			<Row>
				<Col sm={24} style={{ paddingBottom: '15px' }}>
					<p>{I18n.get('Select the body parts to display')}:</p>
				</Col>
				<Col sm={12}>
					<Form.Item name="trunk" valuePropName="checked">
						<Checkbox>{I18n.get('Trunk')}</Checkbox>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item name="neck" valuePropName="checked">
						<Checkbox>{I18n.get('Neck')}</Checkbox>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item name="left_lower_arm" valuePropName="checked">
						<Checkbox>{I18n.get('Left elbow')}</Checkbox>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item name="right_lower_arm" valuePropName="checked">
						<Checkbox>{I18n.get('Right elbow')}</Checkbox>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item name="left_upper_arm" valuePropName="checked">
						<Checkbox>{I18n.get('Left shoulder')}</Checkbox>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item name="right_upper_arm" valuePropName="checked">
						<Checkbox>{I18n.get('Right shoulder')}</Checkbox>
					</Form.Item>
				</Col>
				{/*  */}
				<Col sm={12}>
					<Form.Item name="left_knee" valuePropName="checked">
						<Checkbox>{I18n.get('Left knee')}</Checkbox>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item name="right_knee" valuePropName="checked">
						<Checkbox>{I18n.get('Right knee')}</Checkbox>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item name="left_ankle" valuePropName="checked">
						<Checkbox>{I18n.get('Left ankle')}</Checkbox>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item name="right_ankle" valuePropName="checked">
						<Checkbox>{I18n.get('Right ankle')}</Checkbox>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item name="left_hand" valuePropName="checked">
						<Checkbox>{I18n.get('Left hand')}</Checkbox>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item name="right_hand" valuePropName="checked">
						<Checkbox>{I18n.get('Right hand')}</Checkbox>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item name="hip" valuePropName="checked">
						<Checkbox>{I18n.get('Hip')}</Checkbox>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
}
