import Types from './types';
import Service from './services';

export function expirationPlanValidate(parameters) {
	return { type: Types.EXPIRATION_PLAN_NOTIFICATION, payload: parameters };
}

export function changePasswordUser(parameters) {
	return async (dispatch) => {
		try {
			dispatch(onLoading());
			const result = await Service.changePasswordUser(parameters);
			return dispatch(onSuccess(result));
		} catch (err) {
			dispatch(onError(err));
		} finally {
			dispatch(onLoading());
		}
	}
	function onLoading() {
		return { type: Types.SET_CHANGE_PASSWORD_LOADING };
	}
	function onSuccess(data) {
		return { type: Types.SET_CHANGE_PASSWORD_SUCCESS, payload: data };
	}
	function onError(error) {
		return { type: Types.SET_CHANGE_PASSWORD_ERROR, payload: error };
	}
}

export function clearState() {
	return (dispatch) => dispatch({ type: Types.CLEAR_STATE_USER });
}
