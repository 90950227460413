import React from 'react';
import { useDispatch } from 'react-redux';
import { Col } from 'antd';

import { logoutUserSession } from '@/redux/auth/actions';
import history from '@/store/history';
import { MenuPopover } from './Menu';

export function Popover() {
	const dispatch = useDispatch();

	function handlingMyAccount() {
		history.push('/settings/account');
	}

	function handlingLogout() {
		dispatch(logoutUserSession());
	}

	return (
		<Col>
			<MenuPopover onLogout={handlingLogout} onMyAccount={handlingMyAccount} />
		</Col>
	);
}
