import React from 'react';
import { Row, Col, Grid } from 'antd';

import { DIRECTION, StepsNavigation } from '@/components/ui/StepsNavigation';
import { ErrorNotification } from '@/components/ui/ErrorNotification';
import { Steps } from '@/components/ui/StepsNavigation/types';
import { Header } from './Header';
import { Informations, SelectFiles, OperationProduct, MtmStep, Results } from './Steps';
import { useMtmReportContext } from '../context';

import * as S from './styles';

const steps: Steps[] = [
	{
		id: '0',
		name: 'video_selection',
		sequence: 0,
		description: '',
		component: <SelectFiles />
	},
	{
		id: '1',
		name: 'informations',
		sequence: 1,
		description: '',
		component: <Informations />
	},
	{
		id: '2',
		name: 'operation_product',
		sequence: 2,
		description: '',
		component: (
			<OperationProduct
				title="Operation and product"
				description="Fill in the specific characteristics of the operations and relate them to the products"
			/>
		)
	},
	{
		id: '3',
		name: 'mtm',
		sequence: 3,
		description: '',
		component: <MtmStep title="MTM" description="Add activities to your operations" />
	},
	{
		id: '4',
		name: 'results',
		sequence: 4,
		description: '',
		component: <Results />
	}
];

const stepsName = {
	operation_product: 'Operation and product',
	video_selection: 'Video selection',
	informations: 'Informations',
	results: 'Results',
	mtm: 'MTM'
};

export function Report() {
	const breakpoints = Grid.useBreakpoint();
	const { hasError, currentStep, handleStepNavigationClick, mtmReport } = useMtmReportContext();

	return (
		<Row justify="center">
			{hasError && <ErrorNotification text="Select a required field" />}
			<Col span={24} style={{ marginTop: '2rem' }}>
				<Row justify="start" gutter={[16, 0]}>
					<Col xs={24} xl={{ offset: 4, span: 20 }} style={{ marginBottom: '2rem', padding: 0 }}>
						<Row justify="start">
							<Col xs={24} xl={20}>
								<Header />
							</Col>
						</Row>
					</Col>
					<Col xs={24} xl={4} xxl={4}>
						<StepsNavigation
							steps={steps}
							stepsName={stepsName}
							currentStep={currentStep}
							isComplete={mtmReport?.is_completed ?? false}
							isConsolidated={mtmReport?.consolidated ?? false}
							onStepNavigationClick={handleStepNavigationClick}
							direction={!breakpoints.xl ? DIRECTION.HORIZONTAL : DIRECTION.VERTICAL}
						/>
					</Col>
					<S.CustomFormContainer xs={24} xl={20} xxl={19} $isSmallScreen={`${breakpoints.lg}`}>
						{steps && steps[currentStep].component}
					</S.CustomFormContainer>
				</Row>
			</Col>
		</Row>
	);
}
