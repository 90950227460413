import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';

import { Error } from './Error';
import { Loading } from './Loading';
import { CardContainer, Title, Value } from './styles';

export function Card({
	icon,
	title,
	attribute,
	onFetch,
	onClick,
	filter = {},
	active = false,
}) {
	const { data, isError, isLoading } = useQuery([attribute, filter], () =>
		onFetch(filter)
	);

	if (isLoading) {
		return <Loading />;
	}

	if (isError) {
		return <Error />;
	}

	return (
		<CardContainer onClick={onClick} className={active ? 'active' : null}>
			{icon}
			<Title>{I18n.get(title)}</Title>
			<Value>{data?.total ?? 0}</Value>
		</CardContainer>
	);
}
