import styled from 'styled-components';
import { Col, Radio } from 'antd';

export const PreviousCol = styled(Col)`
	padding: 0;
	margin-right: 1rem;

	@media (min-width: 576px) {
		margin-right: 4rem;
	}
`;

export const CustomFormContainer = styled(Col)<{ $isSmallScreen?: string }>`
	padding: 1rem 1rem 1rem 1rem;
	margin-left: ${({ $isSmallScreen }) => ($isSmallScreen ? '0' : '2rem')};
	border-radius: 1rem;
	background-color: #f8f8f8;
`;

export const CustomRadioGroup = styled(Radio.Group)`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const Card = styled.div`
	padding: 1.8rem;
	border-radius: 1rem;
	background-color: #f8f8f8;
`;
