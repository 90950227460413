import React from 'react';
import { I18n } from '@aws-amplify/core';

import { Chart } from './Chart';
import { FetchData } from './FetchData';
import { Wrapper, Title } from './styles';

export const Comparative = () => (
	<Wrapper>
		<Title>{I18n.get('Jobs and reviews')}</Title>
		<FetchData>
			<Chart />
		</FetchData>
	</Wrapper>
);
