import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';

export type DownloadFile = {
	id: string;
	organization_id: string;
	original_name: string;
	company_id: string;
}

async function downloadFile({ id, organization_id, original_name, company_id }: DownloadFile): Promise<string> {
	const { data } = await Api.post('/upload/create-url-download', {
		organization_id,
		original_name,
		company_id,
		file_id: id
	});
	return data;
}

export const useDownloadFile = () => {
	return useMutation(downloadFile);
};
