import React from 'react';
import { Row, Col, Grid } from 'antd';
import { I18n } from '@aws-amplify/core';

import { RotationReportResultResponse } from '@/hooks/useGetRotationReportResult';
import { Title } from '@/components/Typography';
import { ResultExposureChart } from './Chart';
import * as S from './styles';

interface ExposureProps {
	exposures: RotationReportResultResponse['exposures'];
	legends: RotationReportResultResponse['exposures_legend'];
}

export function Exposure({ exposures, legends }: Readonly<ExposureProps>) {
	const breakpoint = Grid.useBreakpoint();
	const level = breakpoint.xxl ? 4 : 5;

	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col span={22}>
				<Title level={4} style={{ color: '#262626' }}>
					Exposure
				</Title>
			</Col>
			<Col span={22} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
				<S.Exposures gutter={[30, 0]}>
					{exposures.map((exposure) => (
						<Col span={8} className="chart-item">
							<Title level={level} style={{ color: '#262626', marginBottom: 15 }}>
								{exposure.name}
							</Title>
							<ResultExposureChart data={exposure.scores} title={exposure.name} key={exposure.id} />
						</Col>
					))}
					<Col span={24} className="align-items-center">
						{legends.map((legend) => (
							<S.LegendItem $color={legend.color} key={legend.name}>
								{I18n.get(legend.description)}
							</S.LegendItem>
						))}
					</Col>
				</S.Exposures>
			</Col>
		</Row>
	);
}
