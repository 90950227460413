import React, { useState } from 'react';
import { Col, Form, Row } from 'antd';
import { debounce } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';

import { useMtmReportContext } from '@/components/views/MtmReport/context';
import { useAggregationValues, useCreateMtmToOperation, useMtmCodes } from '@/hooks';
import { CreateMtmToOperationDTO } from '@/hooks';
import { OptionType } from '@/components/views/MtmReport/context/types';
import { Code, Coefficient, Description, Frequency, Quantity, Seconds, Tmu } from '../../../components/Inputs';
import { AggregationValues } from '../../../components/Inputs/AggregationValues';
import { ModalCode } from '../Modals/ModalCode';

import * as S from './styles';

const { useForm } = Form;

type OperationItem = Omit<CreateMtmToOperationDTO, 'mtm_code_id'> & {
	mtm_code_id: { value: string; label: string } | string;
};

type FormValues = {
	operation_mtm: {
		[key: string]: OperationItem;
	};
};
interface OperationCardProps {
	operation_id?: string;
}

const DYNAMIC_CODE = 'PTSEC';

export function MtmCard({ operation_id = '' }: OperationCardProps) {
	const [form] = useForm();
	const { basicParams } = useMtmReportContext();
	const [modalCode, setModalCode] = useState<boolean>(false);
	const [frequencyDisabled, setFrequencyDisabled] = useState<boolean>(true);
	const [code, setCode] = useState<string>('');

	const namePlace = ['operation_mtm', operation_id];

	const { mutateAsync: createMtmToOperation, isLoading } = useCreateMtmToOperation();
	const { data: aggregationValues, isLoading: isLoadingAggregationValues } = useAggregationValues(basicParams);
	const { data: mtmCodes, isFetching: gettingMtmCodes } = useMtmCodes({
		...basicParams,
		code: code.toUpperCase().trim()
	});

	const handleDebounced = debounce((value) => {
		setCode(value);
	}, 1000);

	function handleCloseModalCode() {
		setModalCode(false);
	}

	function handleOpenModal() {
		setModalCode(true);
	}

	async function handleAddMtmOperation(operation_id: string): Promise<void> {
		const values: FormValues = await form.validateFields();
		const body = values.operation_mtm[operation_id];
		const mtm_code_id = typeof body.mtm_code_id === 'string' ? body.mtm_code_id : body.mtm_code_id.value;

		await createMtmToOperation({
			...body,
			mtm_code_id: mtm_code_id,
			operation_id,
			...basicParams
		});
		resetFields();
	}

	function resetFields() {
		disableFrequency();
		form.setFieldValue([...namePlace, 'tmu'], undefined);
		form.setFieldValue([...namePlace, 'seconds'], undefined);
		form.setFieldValue([...namePlace, 'quantity'], undefined);
		form.setFieldValue([...namePlace, 'frequency'], undefined);
		form.setFieldValue([...namePlace, 'coefficient'], undefined);
		form.setFieldValue([...namePlace, 'description'], undefined);
		form.setFieldValue([...namePlace, 'mtm_aggregation_value_id'], undefined);
		form.setFieldValue([...namePlace, 'mtm_code_id'], undefined);
		form.setFieldValue([...namePlace, 'mtm_code'], undefined);
	}

	function disableFrequency(code?: string) {
		setFrequencyDisabled(code !== DYNAMIC_CODE);
	}

	function setAggregationValue(code_type?: string) {
		const aggregation_value = aggregationValues?.find(f => f.type.substring(0,3) === code_type);
		form.setFieldValue([...namePlace, 'mtm_aggregation_value_id'], aggregation_value?.id);
	}

	function handleSelect(value: OptionType) {
		if (!value) {
			return resetFields();
		}
		const mtm_code = mtmCodes?.find(({ id }) => id === value.value);
		const code_type = mtm_code?.aggregation_value.substring(0, 3);
		form.setFieldValue([...namePlace, 'tmu'], mtm_code?.tmu);
		form.setFieldValue([...namePlace, 'seconds'], mtm_code?.seconds);
		disableFrequency(mtm_code?.code);
		setAggregationValue(code_type);
	}

	return (
		<Form form={form}>
			<S.CustomRow gutter={[20, 0]}>
				<Col xxl={22} xl={22} lg={24}>
					<Row gutter={[20, 10]}>
						<Col xxl={4} xl={7} lg={7}>
							<Code
								onClick={handleOpenModal}
								loading={gettingMtmCodes}
								codes={mtmCodes}
								required
								namePlace={namePlace}
								onSearch={handleDebounced}
								onChange={handleSelect}
							/>
						</Col>
						<Col xxl={4} xl={5} lg={5}>
							<Quantity namePlace={namePlace} />
						</Col>
						<Col xxl={16} xl={12} lg={12}>
							<AggregationValues
								namePlace={namePlace}
								loading={isLoadingAggregationValues}
								aggregationValues={aggregationValues}
							/>
						</Col>
						<Col xxl={8} xl={12} lg={12}>
							<Row gutter={[20, 10]}>
								<Col xxl={12} xl={14} lg={14}>
									<Tmu namePlace={namePlace} />
								</Col>
								<Col xxl={12} xl={10} lg={10}>
									<Frequency namePlace={namePlace} />
								</Col>
								<Col xxl={12} xl={14} lg={14}>
									<Seconds namePlace={namePlace} disabled={frequencyDisabled} />
								</Col>
								<Col xxl={12} xl={10} lg={10}>
									<Coefficient namePlace={namePlace} />
								</Col>
							</Row>
						</Col>
						<Col xxl={16} xl={12} lg={12}>
							<Description namePlace={namePlace} />
						</Col>
					</Row>
				</Col>
				<Col
					xxl={2}
					xl={2}
					lg={24}
					style={{ paddingTop: '40px', paddingBottom: '0px', display: 'flex', justifyContent: 'end' }}
				>
					<S.AddButton loading={isLoading} type="primary" onClick={() => handleAddMtmOperation(operation_id)}>
						<PlusOutlined />
					</S.AddButton>
				</Col>
			</S.CustomRow>

			<ModalCode
				operation_id={operation_id}
				open={!!modalCode}
				onClose={handleCloseModalCode}
				disableFrequency={disableFrequency}
				setAggregationValue={setAggregationValue}
			/>
		</Form>
	);
}
