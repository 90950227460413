import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Doughnut } from 'react-chartjs-2';

type Score = {
	id: string;
	level?: string;
	color: string;
	percentage: number;
	text: string;
};

interface ResultExposureChartProps {
	data: Score[];
	title: string;
	redraw?: boolean;
}

export function ResultExposureChart({ data, title, redraw }: ResultExposureChartProps) {
	const options = {
		responsive: true,
		cutout: '30%',
		plugins: {
			title: {
				display: false
			},
			legend: {
				display: false
			}
		}
	};

	const items = data.filter((item) => item.percentage > 0);
	const colors = items.map((item) => item.color);

	return (
		<div style={{ maxHeight: 210 }}>
			<Doughnut
				height={210}
				redraw={redraw}
				options={{
					...options,
					maintainAspectRatio: false,
					plugins: {
						...options.plugins,
						datalabels: {
							display: (c) => {
								const data = Number(c.dataset.data[c.dataIndex]);
								return data > 5;
							},
							color: "#fff",
							formatter: (value) =>
								Number(value / 100).toLocaleString(navigator.language, {
									maximumSignificantDigits: 2,
									style: 'percent'
								}),
							font: {
								weight: 'bold',
								size: 16,
							}
						}
					}
				}}
				data={{
					labels: items?.map((item) => I18n.get(item.text)),
					datasets: [
						{
							label: I18n.get(title),
							backgroundColor: colors,
							data: items?.map((item) => item.percentage)
						}
					]
				}}
			/>
		</div>
	);
}
