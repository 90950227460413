import React from 'react';
import { Typography } from 'antd';
import { I18n } from '@aws-amplify/core';
import type { TextProps } from 'antd/lib/typography/Text';
import type { TitleProps } from 'antd/lib/typography/Title';
import type { ParagraphProps } from 'antd/lib/typography/Paragraph';

export const Title: React.FC<TitleProps> = (props) => (
	<Typography.Title {...props}>{I18n.get(props.children)}</Typography.Title>
);

export const Paragraph: React.FC<ParagraphProps> = (props) => (
	<Typography.Paragraph {...props}>{I18n.get(props.children)}</Typography.Paragraph>
);

export const Text: React.FC<TextProps> = (props) => (
	<Typography.Text {...props}>{I18n.get(props.children)}</Typography.Text>
);
