import styled from 'styled-components';
import { Row, Col, Button } from 'antd';

export const ReportContainer = styled(Col)`
	margin-bottom: 20px;
	border-radius: 0.5rem;
	background-color: white;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const SectionTitle = styled.h2`
	font-weight: bold;
	margin-left: 40px;
	margin-bottom: 30px;
`;

export const SectionContainer = styled(Col)`
	margin-left: 60px;
	margin-right: 20px;
	padding-bottom: 20px;
`;

export const FooterStyled = styled(Col)`
	display: flex;
	align-items: end;
	padding: 0 2.5rem;
	margin-bottom: 2.5rem;

	label {
		font-weight: bold;
		font-size: 1.2rem;
	}

	.ant-form-item {
		width: 100%;
	}

	textArea {
		width: 100%;
	}
`;

export const StyledRow = styled(Row)`
	margin-bottom: 0.5rem;
`;

export const Topic = styled.span`
	font-weight: 600;
	font-size: 0.8rem;
	margin-right: 0.5rem;

	@media (min-width: 1600px) {
		font-size: 1rem;
	}
`;

export const Value = styled.span`
	font-weight: 400;
	font-size: 0.8rem;

	@media (min-width: 1600px) {
		font-size: 1rem;
	}
`;

export const DownloadButton = styled(Button)`
	width: 11rem;
	margin: 0 0 1.5rem 0rem;
`;

export const ConsolidatedButton = styled(Button)`
	color: #2cc852;
	width: 11rem;
	height: 40px;
	margin: 0 0 1.5rem 0rem;
	border: 1px solid #2cc852;
	span:last-child {
		font-size: 15px;
	}
`;

export const DesconsolidatedButton = styled(Button)`
	color: #828282;
	width: 11rem;
	height: 40px;
	margin: 0 0 1.5rem 0rem;
	border: 1px solid #828282;
`;
