import Api from '@/services/api';
import { useIsMountedRef } from '@/hooks/useIsMountedRef';
import { useState, useEffect } from 'react';

export const useAsync = (url, params = null, method = 'get') => {

	const firstRender = useIsMountedRef();

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [errors, setErrors] = useState(null);

	const [parameters, setParameters] = useState({ url, data: params, method });

	function doFetch(url, params = null, method = 'get') {
		setParameters({ url, data: params, method });
	}

	useEffect(() => {
		async function fetchData(params) {
			try {
				firstRender.current && setLoading(true);
				const { data } = await Api(params);
				firstRender.current && setData(data);
			} catch (error) {
				const parseErrors = error?.response?.data || error.message;
				firstRender.current && setErrors(parseErrors);
			} finally {
				firstRender.current && setLoading(false) && setParameters(null);
			}
		}

		if (parameters && parameters.url) {
			fetchData(parameters);
		}

		return () => {
			setParameters(null);
			setData(null);
			setErrors(null);

		};
	}, [parameters]);

	return [{ loading, data, errors }, doFetch]
};
