import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/application';
import { AnalysisReviewInput } from './AnalysisReviewInput';
import { WorkstationsSelect } from './WorkstationsSelect';
import { CollectionDate } from './CollectionDate';
import { InformationsContainer } from './styles';
import { ActivityInput } from './ActivityInput';
import { SectorSelect } from './SectorSelect';
import { CompanyInput } from './CompanyInput';
import { TCMIdInput } from './TCMIdInput';
import { LineSelect } from './LineSelect';
import { SOPInput } from './SOPInput';
import { Buttons } from './Buttons';

const { useForm } = Form;

export function Informations() {
	const [form] = useForm();

	const { company } = useApplicationContext();

	const initialValues = {
		company: company?.name,
		sector: null,
		line: null,
		workstation: null,
		sop: null,
		activity: null,
		tcm_id: null,
		analysis_review: null,
		collection_date: null
	};

	const validateMessages = {
		required: I18n.get('Field is required'),
		types: {
			number: I18n.get('Must be a valid number')
		}
	};

	return (
		<Form
			form={form}
			layout="vertical"
			initialValues={initialValues}
			validateMessages={validateMessages}
			scrollToFirstError
			autoComplete="off"
		>
			<InformationsContainer justify="center" gutter={[5, 20]}>
				<Col style={{ textAlign: 'center' }} sm={24}>
					<h2>{I18n.get('Informations')}</h2>
					<label>{I18n.get('Enter the data related to the selected video below')}</label>
				</Col>
				<Col sm={12}>
					<Row gutter={[10, 20]}>
						<CompanyInput />
						<SectorSelect />
						<LineSelect />
						<WorkstationsSelect />
						<SOPInput />
						<ActivityInput />
						<TCMIdInput />
						<AnalysisReviewInput />
						<CollectionDate />
					</Row>
				</Col>
				<Col sm={24}>
					<Buttons />
				</Col>
			</InformationsContainer>
		</Form>
	);
}
