import { Filter } from './index.types';

export function mountURL(
	prefix: string,
	{
		step,
		organization,
		companyId,
		sectorId,
		lineId,
		filter,
	}: {
		step: number;
		organization: { id: string };
		companyId: string;
		sectorId: string;
		lineId: string;
		filter: Filter | null;
	}
): string {
	const url = `${prefix}?${filter ? `filter=${filter}&` : ''}`;

	switch (step) {
		case 1:
			return `${url}organization_id=${organization.id}&company_id=${companyId}`;
		case 2:
			return `${url}organization_id=${organization.id}&company_id=${companyId}&sector_id=${sectorId}`;
		case 3:
			return `${url}organization_id=${organization.id}&company_id=${companyId}&sector_id=${sectorId}&line_id=${lineId}`;
	}

	return `${url}organization_id=${organization.id}`;
}
