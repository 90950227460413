import Api from '@/services/api';
import {
	IConsolidateChecklist,
	IChecklistDownload,
	ChecklistDownloadDTO,
	ConsolidateChecklistResultDTO,
} from './types';

// export class ResultService {
// public async consolidateChecklist(payload: IConsolidateChecklist) {
// 	const url: string = '/report/checklist/consolidate';
// 	const { data } = await Api.post(url, payload);
// 	return data;
// }

// public async desconsolidateChecklist(payload: IConsolidateChecklist) {
// 	const url: string = '/report/checklist/desconsolidate';
// 	const { data } = await Api.post(url, payload);
// 	return data;
// }
// }

async function consolidateChecklist(payload: IConsolidateChecklist) {
	const url: string = '/report/checklist/consolidate';
	const { data } = await Api.post<ConsolidateChecklistResultDTO>(url, payload);
	return data;
}

async function desconsolidateChecklist(payload: IConsolidateChecklist) {
	const url: string = '/report/checklist/desconsolidate';
	const { data } = await Api.post<ConsolidateChecklistResultDTO>(url, payload);
	return data;
}

async function downloadChecklist(payload: IChecklistDownload) {
	const url: string = '/report/checklist/download-pdf';
	const { data } = await Api.post<ChecklistDownloadDTO>(url, payload);
	return data;
}

export const ResultService = {
	consolidateChecklist,
	desconsolidateChecklist,
	downloadChecklist,
};
