import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { ErrorResponse } from '@/types';
import type { GerProductsDTO, ProductDTO } from '@/types/dto/product';

const { GET_PRODUCTS } = QUERY_KEYS;

async function getProducts(params: GerProductsDTO): Promise<ProductDTO[]> {
	const endpoint = '/product';
	const { data } = await Api.get(endpoint, { params });
	return data;
}

export const useGetProducts = (params: GerProductsDTO) => {
	const { organization_id, company_id, sector_id, line_id } = params;
	return useQuery<ProductDTO[], ErrorResponse>([GET_PRODUCTS, params], () => getProducts(params), {
		enabled: !!organization_id && !!company_id && !!sector_id && !!line_id
	});
};
