import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Context, Filter, State } from './index.types';

interface DashboardProviderProps {
	children: ReactNode;
}

const DashboardContext = React.createContext<Context>({} as Context);

export const DashboardProvider = ({ children }: DashboardProviderProps) => {
	const {
		step,
		filter,
		sectorId,
		sectorTitle,
		companyId,
		companyTitle,
		lineId,
		menuItems,
		lineTitle,
	} = JSON.parse(localStorage.getItem('dashboard') as string) || {};

	const [state, setState] = useState<State>({
		step: step ?? 0,
		lineId: lineId ?? '',
		filter: filter ?? null,
		sectorId: sectorId ?? '',
		menuItems: menuItems ?? [],
		lineTitle: lineTitle ?? '',
		companyId: companyId ?? '',
		sectorTitle: sectorTitle ?? '',
		companyTitle: companyTitle ?? '',
	});

	function setStep(step: number) {
		setState((state) => ({ ...state, step }));
	}

	function setFilter(filter: Filter | null) {
		setState((state) => ({ ...state, filter }));
	}

	function setSectorId(sectorId: string) {
		setState((state) => ({ ...state, sectorId }));
	}

	function setSectorTitle(sectorTitle: string) {
		setState((state) => ({ ...state, sectorTitle }));
	}

	function setCompanyId(companyId: string) {
		setState((state) => ({ ...state, companyId }));
	}

	function setCompanyTitle(companyTitle: string) {
		setState((state) => ({ ...state, companyTitle }));
	}

	function setLineId(lineId: string) {
		setState((state) => ({ ...state, lineId }));
	}

	function setLineTitle(lineTitle: string) {
		setState((state) => ({ ...state, lineTitle }));
	}

	function handleMenuItems(menuItems: string[]) {
		setState((state) => ({ ...state, menuItems }));
	}

	useEffect(() => {
		const persistedState = JSON.parse(localStorage.getItem('dashboard') as string);
		if (!persistedState) return void 0;
		setState(persistedState);
	}, []);

	useEffect(() => {
		localStorage.setItem('dashboard', JSON.stringify(state));
	}, [state]);

	const context: Context = {
		...state,
		setStep,
		setFilter,
		setLineId,
		setSectorId,
		setCompanyId,
		setLineTitle,
		setSectorTitle,
		setCompanyTitle,
		handleMenuItems,
	};

	return (
		<DashboardContext.Provider value={context}>{children}</DashboardContext.Provider>
	);
};

export function useDashboardContext() {
	const context = useContext(DashboardContext);
	return context;
}
