import styled from 'styled-components';

interface ValueProps {
	size?: number;
	color?: string;
}

export const HighSphere = styled.figure`
	display: block;
	background: #f07575;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	margin: 0;
	float: left;
	margin-right: 15px;
`;

export const ModerateSphere = styled.figure`
	display: block;
	background: #fdc365;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	margin: 0;
	float: left;
	margin-right: 15px;
`;

export const LowSphere = styled.figure`
	display: block;
	background: #40bf5f;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	margin: 0;
	float: left;
	margin-right: 15px;
`;

export const Label = styled.p`
	float: left;
`;

export const Value = styled.p<ValueProps>`
	float: right;
	font-weight: bold;
	font-size: ${({ size }) => size || 17}px;
	color: ${({ color }) => color || undefined};
`;
