import React from 'react';
import { CardChart, FrameLoading, Spinner } from './styles';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
	<LoadingOutlined
		style={{
			fontSize: 24,
		}}
		spin
	/>
);

export const Loading = ({ minHeight }) => (
	<CardChart minHeight={minHeight}>
		<FrameLoading>
			<Spinner indicator={antIcon} />
		</FrameLoading>
	</CardChart>
);
