import React, { ReactNode } from 'react';
import { I18n } from '@aws-amplify/core';
import { useQueryClient } from '@tanstack/react-query';
import { Row, Col, Form, Input, Button, Divider, Modal, Select, message } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { QUERY_KEYS } from '@/utils/query-keys';
import { useRemoveProduct } from '@/hooks/useRemoveProduct';
import { useCreateProduct } from '@/hooks/useCreateProduct';
import { useBulkDeleteProduct } from '@/hooks/useBulkDeleteProduct';
import { useBulkCreateProduct } from '@/hooks/useBulkCreateProducts';
import type { CreateProductDTO, ProductDTO, RemoveProductDTO } from '@/types/dto/product';

import { useManageOrganization } from '../../../context';
import { Create, Delete, Edit, SelectId } from '../styles';

const { useFormInstance } = Form;
const { GET_PRODUCTS } = QUERY_KEYS;

type OptionType = {
	label: string;
	value: string;
};

interface SelectProductProps {
	products: ProductDTO[];
	productName: string;
	onProductNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
}

export function SelectProduct({ products, productName, onProductNameChange, onEditing }: SelectProductProps) {
	const queryClient = useQueryClient();
	const createProduct = useCreateProduct();
	const removeProduct = useRemoveProduct();
	const bulkCreateProduct = useBulkCreateProduct();
	const bulkRemoveProduct = useBulkDeleteProduct();
	const { resetFields, setFieldValue, validateFields } = useFormInstance();

	const {
		organizationId,
		companyId,
		sectorId,
		lineId,
		workstationId,
		productId,
		updatingOrganizationData,
		creatingOrganizationData,
		isEditing,
		handleProduct,
		handleProductId,
		handleIsEditing
	} = useManageOrganization();

	async function handleCreateProduct(payload: CreateProductDTO): Promise<void> {
		try {
			await createProduct.mutateAsync(payload);
			message.success(I18n.get('Product created successfully'));
			queryClient.invalidateQueries([GET_PRODUCTS]);
			resetFields(['create_product_name']);
		} catch (error: any) {
			const err = error?.response?.data?.message || 'Failed to create product';
			message.error(I18n.get(err));
		}
	}

	async function handleBulkCreateProduct(payload: CreateProductDTO): Promise<void> {
		try {
			await bulkCreateProduct.mutateAsync(payload);
			message.success(I18n.get('Product created successfully'));
			queryClient.invalidateQueries([GET_PRODUCTS]);
			resetFields(['create_product_name']);
		} catch (error: any) {
			const err = error?.response?.data?.message || 'Failed to create product';
			message.error(I18n.get(err));
		}
	}

	function getCreateProductPayload(name: string): CreateProductDTO {
		return {
			organization_id: organizationId,
			company_id: companyId,
			sector_id: sectorId,
			line_id: lineId,
			workstation_id: workstationId,
			name: name
		};
	}

	function getRemoveProductPayload(id: string): RemoveProductDTO {
		return {
			id: id,
			organization_id: organizationId,
			company_id: companyId,
			sector_id: sectorId,
			line_id: lineId,
			workstation_id: workstationId
		};
	}

	async function handleOnCreateProduct(): Promise<void> {
		const { create_product_name } = await validateFields(['create_product_name']);
		const payload = getCreateProductPayload(create_product_name);
		!payload.workstation_id || payload.workstation_id === ''
			? await handleBulkCreateProduct(payload)
			: await handleCreateProduct(payload);
	}

	async function handleEditProduct(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		e.stopPropagation();
		e.preventDefault();

		const editingProduct = products.find((product) => product.id === productId);

		if (editingProduct) {
			setFieldValue('edit_product_name', editingProduct.name);
			onProductNameChange(editingProduct.name);
			handleIsEditing(true);
			onEditing(true);
		}
	}

	async function handleOnDeleteProduct(e: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> {
		e.stopPropagation();
		e.preventDefault();

		Modal.confirm({
			title: I18n.get('Warning!'),
			icon: <ExclamationCircleOutlined />,
			content: I18n.get(
				`All data will be deleted, along with their respective uploads, reports and contents. Do you wish to proceed?`
			),
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: async () => {
				try {
					const payload = getRemoveProductPayload(productId);
					payload.workstation_id && payload.workstation_id !== ''
						? await removeProduct.mutateAsync(payload)
						: await bulkRemoveProduct.mutateAsync(payload);
					queryClient.invalidateQueries([GET_PRODUCTS]);
					message.success(I18n.get('Product removed successfully'));
					onProductNameChange('');
					handleProductId('');
					resetFields(['product_id']);
				} catch (error: any) {
					const err = error?.response?.data?.message || 'Failed to remove product';
					message.error(I18n.get(err));
				}
			}
		});
	}

	function filterProduct(option: OptionType | undefined, input: string): boolean {
		return (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function handleSelectProduct(id: string) {
		handleProduct(id, setFieldValue);
	}

	function manageProductDropdown(menu: ReactNode) {
		return (
			<Col span={24}>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<Create>
					<Form.Item name="create_product_name" style={{ margin: '0' }}>
						<Input
							style={{
								flex: 'auto',
								borderRadius: '7px'
							}}
							value={productName}
							onChange={(e) => onProductNameChange(e.target.value)}
						/>
					</Form.Item>
					<Button
						type="link"
						onClick={handleOnCreateProduct}
						loading={creatingOrganizationData}
						disabled={!productName || productName.length < 3}
					>
						{I18n.get('Create product')}
					</Button>
				</Create>
			</Col>
		);
	}

	const productsList = products.map(({ id, name }) => ({
		label: name,
		value: id
	}));

	const actions = (
		<Col span={4}>
			<Row align="bottom" justify="space-between">
				<Edit span={12}>
					<Button
						type="text"
						size="middle"
						icon={<EditOutlined />}
						onClick={handleEditProduct}
						disabled={!productId || isEditing}
					/>
				</Edit>
				<Delete span={12}>
					<Button
						type="text"
						size="middle"
						icon={<DeleteOutlined />}
						onClick={handleOnDeleteProduct}
						disabled={!productId || isEditing}
					/>
				</Delete>
			</Row>
		</Col>
	);

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<SelectId
						selected={!!productId || isEditing}
						name="product_id"
						label={
							workstationId === '' || !workstationId
								? I18n.get('Products in line')
								: I18n.get('Products in workstation')
						}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
					>
						<Select
							showSearch
							allowClear
							options={productsList}
							onChange={handleSelectProduct}
							loading={updatingOrganizationData}
							onClear={() => handleProductId('')}
							placeholder={I18n.get('Manage products')}
							filterOption={(input, option) => filterProduct(option, input)}
							dropdownRender={(menu: ReactNode) => manageProductDropdown(menu)}
						/>
					</SelectId>
				</Col>
				{actions}
			</Row>
		</Col>
	);
}
