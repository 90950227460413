import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Button, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';
import { RootStateOrAny, useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useApplicationContext } from '@/context/application';
import { onChangeProjectSelected } from '@/redux/organization/actions';

export function SelectWorkspace() {
	const history = useHistory();
	const dispatch = useDispatch();
	const { clearProject } = useApplicationContext();

	const { organization, company } = useSelector(
		(state: RootStateOrAny) => ({
			organization: state.organization.organization,
			company: state.organization.company
		}),
		shallowEqual
	);

	function onSelectProject() {
		history.push('/ehs');
		dispatch(onChangeProjectSelected());
		clearProject();
	}

	return (
		<Col>
			<Tooltip title={I18n.get('Select project')}>
				<Button ghost type="primary" onClick={onSelectProject}>
					{organization?.name ?? I18n.get('Select project')}
					{organization?.name && company?.name && ` / ${company.name}`}
					<SyncOutlined />
				</Button>
			</Tooltip>
		</Col>
	);
}
