import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';
import { BaseContext } from '@/types';

export type MtmReportSpreadsheetDTO = BaseContext & {
	id: string;
	locale?: string;
};

type MtmReportSpreadsheetResponse = string[][];

async function mtmReportSpreadsheet(body: MtmReportSpreadsheetDTO): Promise<MtmReportSpreadsheetResponse> {
	const { data } = await Api.get<MtmReportSpreadsheetResponse>(`/mtm/report/${body.id}/spreadsheet`,  {
		params: body
	});
	return data;
};

export const useMtmReportSpreadsheet = () => {
	return useMutation((body: MtmReportSpreadsheetDTO) => mtmReportSpreadsheet(body));
};
