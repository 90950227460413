import styled, { css } from 'styled-components';

// export const Container = styled.div<any>`
// 	min-height: ${(props) => props.height || '40vh'};
// `;

export const Title = styled.h1`
	font-weight: bold;
	padding-bottom: 1%;
`;

export const Card = styled.div<any>`
	padding: 15px;
	border-radius: 5px;
	background-color: white;
	box-shadow: 1px 1px 1px #888888;
	${({ noHeight }) =>
		!noHeight &&
		css`
			height: 400px;
		`}
`;
