import { Row } from 'antd';
import styled from 'styled-components';


export const CustomRow = styled(Row)`
	@media (max-width: 1336px) {
		.ant-typography {
			font-size: 0.8rem;
		}
	}
`;
