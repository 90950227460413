import styled from 'styled-components';
import { Row } from 'antd';

interface LegendItemProps {
	$color?: string;
}

export const Exposures = styled(Row)`
	border: 1px solid #d9d9d9;
	border-radius: 10px;
	.chart-item {
		padding: 10px;
		border-bottom: 1px solid #d9d9d9;
	}

	.align-items-center {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}

	span + span {
		margin-left: 15px;
	}

	.chart-item {
		padding: 15px !important;

		& + .chart-item:not(:nth-child(4)) {
			border-left: 1px solid #d9d9d9;
		}
	}
`;

export const LegendItem = styled.span<LegendItemProps>`
	display: flex;
	margin: 10px 0px;
	&:before {
		content: '';
		width: 24px;
		height: 24px;
		display: block;
		margin-right: 5px;
		border-radius: 6px;
		background-color: ${({ $color }) => $color ?? 'unset'};
	}
`;
