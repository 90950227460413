import React from 'react';
import { Col, ColProps, Row } from 'antd';

import { DividerCustom } from '@/components/ui/DividerCustom';
import { Text } from '@/components/Typography';
import * as S from './styled';

interface ListCardProps {
	operation: string;
	duration: string;
}

export function ListCard({ operation, duration, ...restColProps }: Readonly<ListCardProps & ColProps>) {
	return (
		<S.ColCustom {...restColProps}>
			<Row>
				<Col xs={24} lg={17} xl={16} xxl={19}>
					<Text ellipsis style={{ color: '#262626' }}>
						{operation}
					</Text>
				</Col>
				<DividerCustom xs={0} lg={1} type="vertical" thickness="100%" color="#E6E8E8" />
				<Col xs={24} lg={6} xl={7} xxl={4}>
					<Text style={{ color: '#262626' }}>{duration}min</Text>
				</Col>
			</Row>
		</S.ColCustom>
	);
}
