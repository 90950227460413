import React, { useState, useEffect, useCallback } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Modal, message, Input } from 'antd';
import { TaskManager } from './TaskManager';
import { Sidebar } from './Sidebar';
import Spinner from '@/components/SpinnerDeprecated';
import { TitleContainer, EditButton } from './styles';
import { EditOutlined } from '@ant-design/icons';
import Api from '@/services/api';
import { useSelector } from 'react-redux';

export function EditActionPlan({
	actionPlan,
	responsible_workers,
	visible,
	onClose,
	calcProgress,
}) {
	const [descriptionInput, setDescriptionInput] = useState('');
	const [editingDescription, setEditingDescription] = useState(false);
	const [error, setError] = useState(null);
	const [reloadHistory, setReloadHistory] = useState(false);
	const [loadingTasks, setLoadingTasks] = useState(false);
	const [loadingHistory, setLoadingHistory] = useState(false);

	const organizationId = useSelector(
		({ organization }) => organization.organization?.id
	);

	const triggerReloadHistory = () => {
		setReloadHistory((prev) => !prev);
	};

	const onEditDescription = () => {
		setDescriptionInput(actionPlan.description);
		setEditingDescription(true);
	};

	const onFinishEditing = async () => {
		const action_plan = { ...actionPlan, description: descriptionInput };
		const body = { action_plan };
		const url = `/action_plan/${actionPlan.id}?organization_id=${organizationId}`;
		try {
			await Api.put(url, body);
			actionPlan.description = descriptionInput;
			setEditingDescription(false);
			triggerReloadHistory();
		} catch (err) {
			setError(err);
		}

		setDescriptionInput('');
	};

	const handleChange = (e) => {
		setDescriptionInput(e.target.value);
	};

	useEffect(() => {
		if (error) {
			const result = error.response?.data?.message || error.message;
			const description = I18n.get(result);
			message.error(description);
			setError(null);
		}
	}, [error]);

	const editInputRef = useCallback((node) => {
		if (node) {
			node.focus();
		}
	}, []);

	return (
		<Modal
			style={{ top: 20 }}
			visible={visible}
			destroyOnClose={true}
			onCancel={onClose}
			width="1000px"
			title={
				<TitleContainer>
					{editingDescription ? (
						<Input
							ref={editInputRef}
							style={{ fontSize: '1.4rem' }}
							value={descriptionInput}
							onChange={handleChange}
							onPressEnter={onFinishEditing}
						></Input>
					) : (
						<>
							<h2 style={{ margin: '0px' }}>
								{actionPlan.description}
							</h2>
							<EditButton onClick={onEditDescription}>
								<EditOutlined />
							</EditButton>
						</>
					)}
				</TitleContainer>
			}
			footer={null}
		>
			<Row>
				<Col span={14}>
					{loadingTasks && <Spinner />}
					<TaskManager
						actionPlan={actionPlan}
						calcProgress={calcProgress}
						triggerReloadHistory={triggerReloadHistory}
						loadingTasks={loadingTasks}
						setLoadingTasks={setLoadingTasks}
					></TaskManager>
				</Col>
				<Col span={10}>
					{loadingHistory && <Spinner />}
					<Sidebar
						actionPlan={actionPlan}
						responsible_workers={responsible_workers}
						reloadHistory={reloadHistory}
						triggerReloadHistory={triggerReloadHistory}
						loadingHistory={loadingHistory}
						setLoadingHistory={setLoadingHistory}
					></Sidebar>
				</Col>
			</Row>
		</Modal>
	);
}
