import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { BaseContext, BaseContextOptional } from '@/types';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

type MtmToOperationResponse = BaseContext & {
	operation_id: string;
};

export type DeleteMtmToOperationDTO = BaseContextOptional & {
	id: string;
};

const deleteMtmToOperation = async (body: DeleteMtmToOperationDTO): Promise<MtmToOperationResponse> => {
	const url = `/mtm/operation/${body.id}`;
	const { data } = await Api.delete<MtmToOperationResponse>(url, { params: body });
	return data;
};

export const useDeleteMtmToOperation = () => {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteMtmToOperationDTO) => deleteMtmToOperation(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: ({ operation_id, company_id, organization_id }: MtmToOperationResponse) => {
			queryClient.invalidateQueries([
				QUERY_KEYS.GET_MTM_TO_OPERATIONS,
				{ operation_id, company_id, organization_id }
			]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORT_RESULT]);
			message.success(I18n.get('MTM operation deleted successfully'));
		}
	});
};
