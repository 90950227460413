import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import { Cancel, Confirm, NameInput } from '../styles';

const { useFormInstance } = Form;

type EditCompanyProps = {
	companyName: string;
	onCompanyNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
};

export const EditCompany: React.FC<EditCompanyProps> = ({
	companyName,
	onCompanyNameChange,
	onEditing,
}) => {
	const [editingName] = useState(companyName);
	const {
		updateOrganizationData,
		treatFieldValidation,
		handleIsEditing,
		companyId,
		organizationId,
	} = useManageOrganization();

	const { setFieldValue, validateFields } = useFormInstance();

	function handleCompanyNameChange(name: string) {
		onCompanyNameChange(name);
	}

	function handleCancel() {
		onCompanyNameChange('');
		onEditing(false);
		handleIsEditing(false);
		setFieldValue('company_id', companyId);
	}

	async function handleUpdateCompany(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();
		try {
			await validateFields(['edit_company_name']);

			updateOrganizationData({
				organization_id: organizationId,
				company_id: companyId,
				name: companyName,
			}).then(({ id }) => {
				onCompanyNameChange('');
				onEditing(false);
				handleIsEditing(false);
				setFieldValue('company_id', id);
				setFieldValue('edit_company_name', '');
			});
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<NameInput
						name="edit_company_name"
						label={I18n.get('Edit industrial site')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get(
									'Please, enter a valid industrial site name'
								),
							},
						]}
					>
						<Input
							style={{
								flex: 'auto',
								borderRadius: '7px',
								border: '2px solid #000',
							}}
							value={companyName}
							onChange={(e) => handleCompanyNameChange(e.target.value)}
						/>
					</NameInput>
				</Col>
				<Col span={4}>
					<Row align="bottom" justify="space-between">
						<Cancel span={12}>
							<Button
								disabled={!companyId}
								size="middle"
								type="text"
								onClick={() => handleCancel()}
								icon={<CloseOutlined />}
							/>
						</Cancel>
						<Confirm span={12}>
							<Button
								disabled={
									!companyId ||
									!companyName ||
									editingName === companyName
								}
								size="middle"
								type="text"
								onClick={(e) => handleUpdateCompany(e)}
								icon={<CheckOutlined />}
							/>
						</Confirm>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
