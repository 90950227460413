import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Button, Spin } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';

import { CHECK_OPTIONS } from '../../RotationReport/Report/Steps/Results/ReportTemplates/Footer/ButtonsCustom';
import { useApplicationContext } from '@/context/application';
import { DeleteModal } from '../components/DeleteModal';
import { useDownloadRotationReport } from '@/hooks';
import type { RotationDTO } from '@/types';
import { TooltipCustom } from './Tooltip';
import * as S from './styles';

interface ActionsProps {
	data: RotationDTO;
	onShowFiles: (rotation_id: string) => void;
	onEditActionClick: (rowData: RotationDTO) => void;
}

export function Actions({ data, onShowFiles, onEditActionClick }: ActionsProps) {
	const { organization, company } = useApplicationContext();
	const { mutateAsync: downloadPdf, isLoading: isDownloading } = useDownloadRotationReport();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

	function handleDeleteModalVisivility(): void {
		setIsDeleteModalOpen(!isDeleteModalOpen);
	}

	async function handleDownloadReport(id: string): Promise<void> {
		const { url } = await downloadPdf({
			organization_id: organization?.id,
			company_id: company?.id,
			id,
			sections: CHECK_OPTIONS.map((m) => m.name)
		});

		window.open(url, '_blank');
	}

	function handleEditReport(): void {
		onEditActionClick(data);
	}

	return (
		<>
			<Row justify="center" align="middle">
				<S.ActionsInline xs={24}>
					<Row justify="center" align="middle" gutter={[10, 0]}>
						<Col span={6}>
							<TooltipCustom title={I18n.get('Edit')}>
								<Button type="link" size="large" icon={<S.EditIcon />} onClick={handleEditReport} />
							</TooltipCustom>
						</Col>
						<Col span={6}>
							<TooltipCustom title={I18n.get('Download')}>
								<Button
									type="link"
									size="large"
									icon={isDownloading ? <Spin /> : <S.DownloadFileIcon />}
									disabled={!data.is_completed}
									onClick={() => handleDownloadReport(data.id)}
								/>
							</TooltipCustom>
						</Col>
						<Col span={6}>
							<TooltipCustom title={I18n.get('Files')}>
								<Button
									type="link"
									size="large"
									icon={<Icon component={S.FilesIcon} />}
									onClick={() => onShowFiles(data.id)}
								/>
							</TooltipCustom>
						</Col>
						<Col span={6}>
							<TooltipCustom title={I18n.get('Delete')}>
								<Button
									type="link"
									size="large"
									icon={<S.DeleteFileIcon />}
									onClick={() => handleDeleteModalVisivility()}
								/>
							</TooltipCustom>
						</Col>
					</Row>
				</S.ActionsInline>
			</Row>
			<DeleteModal
				report={data}
				isOpen={isDeleteModalOpen}
				onDeleteModalVisivility={handleDeleteModalVisivility}
			/>
		</>
	);
}
