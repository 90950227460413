import React, { useState, useEffect } from 'react';
import { Form, message, Button } from 'antd';
import Api from '@/services/api';
import { CreateModal } from './CreateModal';
import { ListItens, EmptyList } from './List';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { CreateForm } from './CreateForm';

export function Company({
	data,
	onSelect,
	visibleCreate,
	closeCreate,
	onReload,
}) {
	const [form] = Form.useForm();

	const [success, setSuccess] = useState(null);
	const [errors, setErrors] = useState(null);
	const [loading, setLoading] = useState(false);

	const organization = useSelector(
		(state) => state.organization.organization
	);

	useEffect(() => {
		if (success) {
			message.success(I18n.get('Company created successfully'));
			closeCreate();
			setSuccess(false);
		}
	}, [success]);

	useEffect(() => {
		if (errors) {
			const err = errors.response?.data?.message || errors.message;
			message.error(I18n.get(err));
			setErrors(null);
		}
	}, [errors]);

	function onSubmit() {
		form.validateFields()
			.then((values) => {
				const body = {
					...values,
					url_logo: '',
					organization_id: organization.id,
				};
				setLoading(true);

				Api.post('/company/create', body)
					.then((res) => {
						onReload(res.data);
						setSuccess(true);
						closeCreate();
						onSelect(res.data);
					})
					.catch((err) => setErrors(err))
					.finally(() => setLoading(false));
			})
			.catch(() => {});
	}

	if (data?.length === 0) {
		return (
			<>
				<EmptyList description="Parabéns! Agora crie uma companhia que pertence a essa organização e comece a utilizar a plataforma" />
				<div style={{ padding: '10px 20px 0' }}>
					<CreateForm form={form} type="organization" />
					<Button type="primary" onClick={onSubmit} loading={loading}>
						{I18n.get('Create industrial site')}
					</Button>
				</div>
			</>
		);
	}

	return (
		<div>
			<CreateModal
				form={form}
				loading={loading}
				type="company"
				onSubmit={onSubmit}
				onCancel={closeCreate}
				visible={visibleCreate}
			/>
			<ListItens data={data} onAction={onSelect} />
		</div>
	);
}
