import React from 'react'
import { I18n } from '@aws-amplify/core'
import { Wrapper, Title, CardsList } from './index.styles'
import { FilterCard, Props as FilterCardProps } from './FilterCard'
import { Cleaner } from '../Cleaner'

export function Filters() {
	const filters: Array<Array<FilterCardProps>> = [
		[
			{
				name: 'effort',
				title: I18n.get('Effort'),
				type: 'single',
			},
			{
				name: 'reaction',
				title: I18n.get('Reaction'),
				type: 'single',
			},
			{
				name: 'vibration',
				title: I18n.get('Vibration'),
				type: 'single',
			},
			{
				name: 'beats_against',
				title: I18n.get('Beats against'),
				type: 'single',
			},
			{
				name: 'percussion',
				title: I18n.get('Percussion'),
				type: 'single',
			},
		],
		[
			{
				name: 'hand',
				title: I18n.get('Hands'),
				type: 'dualBodyPart',
			},
			{
				name: 'upper_arm',
				title: I18n.get('Shoulders/Arms'),
				type: 'dualBodyPart',
			},
			{
				name: 'trunk',
				title: I18n.get('Trunk'),
				type: 'bodyPart',
			},
			{
				name: 'neck',
				title: I18n.get('Neck'),
				type: 'bodyPart',
			},
			{
				name: 'hip',
				title: I18n.get('Lower members'),
				type: 'lowerMember'
			}
		],
	]

	return (
		<Wrapper>
			<Title>
				{I18n.get('Filters')} <Cleaner />
			</Title>
			{filters.map(($filters, index) => (
				<CardsList key={index}>
					{$filters.map((filter, index) => (
						<FilterCard key={index} {...filter} />
					))}
				</CardsList>
			))}
		</Wrapper>
	)
}
