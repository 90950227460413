import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

export type GetRotationReportActivityRiskRangesDTO = {
	organization_id?: string;
	company_id?: string;
	id?: string;
	activity_id?: string;
};

export type RotationReportActivityRiskRangesResponse = {
	id: number;
	name: string;
	scores: Score[];
	body_part: BodyPart;
};

type BodyPart = 'trunk' | 'neck' | 'left_lower_arm' | 'left_upper_arm'| 'right_lower_arm' | 'right_upper_arm'

type Score = {
	id: string;
	level: string;
	color: string;
	percentage: number;
	text: string;
};

async function getRotationReportActivityRiskRanges(
	params: GetRotationReportActivityRiskRangesDTO
): Promise<RotationReportActivityRiskRangesResponse[]> {
	const url = `/rotation/report/${params.id}/risk-ranges`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useGetRotationReportActivityRiskRanges = (params: GetRotationReportActivityRiskRangesDTO) => {
	const { organization_id, company_id, id, activity_id } = params;
	return useQuery(
		[QUERY_KEYS.GET_ROTATION_REPORT_ACTIVITY_RISK_RANGES, params],
		() => getRotationReportActivityRiskRanges(params),
		{
			enabled: !!organization_id && !!company_id && !!id && !!activity_id
		}
	);
};
