import React from 'react';
import { Spin } from 'antd';
import { ContainerChart } from './styles';
import { LoadingOutlined } from '@ant-design/icons';

const chartIcon = <LoadingOutlined style={{ fontSize: 38 }} spin />;

export const ChartSpinner = () => (
	<ContainerChart>
		<Spin indicator={chartIcon} />
	</ContainerChart>
);
