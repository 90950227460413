import React from 'react';
import { Row, Col } from 'antd';
import { NextButton, FileTable, Filter } from './components';
import { Paragraph, Title } from '@/components/Typography';
import { SelectFilesProvider } from './context';

export function SelectFiles() {
	return (
		<SelectFilesProvider>
			<Row gutter={[0, 30]} justify="center" align="middle">
				<Col span={24}>
					<Row>
						<Col span={24}>
							<Title level={4}>Video selection</Title>
						</Col>
						<Col span={24}>
							<Paragraph>
								Select the video files for the operations that make up the workstation below
							</Paragraph>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Filter />
				</Col>
				<Col span={24}>
					<FileTable />
				</Col>
				<Col span={24}>
					<NextButton />
				</Col>
			</Row>
		</SelectFilesProvider>
	);
}
