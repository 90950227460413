import React, { useState, useEffect } from 'react';
import { Scatter } from 'react-chartjs-2';
import { I18n } from '@aws-amplify/core';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Legend,
	Tooltip,
	Filler
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import _ from 'lodash';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	annotationPlugin
);

export function LineChartRange({ data, config, title }) {
	const [settings, setSettings] = useState([]);
	const [annotations, setAnnotations] = useState({});

	const [dataSet, setDataset] = useState({
		labels: [],
		datasets: []
	});

	const orderByArray = (a, b) => (a.angle > b.angle ? -1 : a.angle < b.angle ? 1 : 0);

	useEffect(() => {
		if (data && data[0].angles.length > 0) {
			let data_set = [];
			for (let x = 0; x < data[0].angles.length; x++) {
				data_set.push({
					x,
					y: data[0].angles[x]
				});
			}

			setDataset((current) => ({
				...current,
				labels: [...data_set.map((_, index) => index)],
				datasets: [
					{
						label: I18n.get(title),
						data: data_set,
						showLine: true,
						borderWidth: 3,
						lineTension: 0.4,
						pointRadius: 0,
						borderColor: '#3e95cd'
					}
				]
			}));
		}

		if (config && settings.length === 0) {
			setSettings(config.sort(orderByArray));
		}

		if (settings.length > 0 && _.isEmpty(annotations)) {
			settings.map((item, index) => {
				setAnnotations((current) => ({
					...current,
					[`line${index + 1}`]: {
						type: 'box',
						backgroundColor: item.color,
						borderWidth: 0,
						yMax: item.max,
						yMin: item.min,
						xMin: 0
					}
				}));
			});
		}
	}, [data, config, settings, annotations]);

	return (
		<Scatter
			height={120}
			data={dataSet}
			plugins={annotationPlugin}
			options={{
				responsive: true,
				plugins: {
					autocolors: false,
					datalabels: false,
					annotation: {
						drawTime: 'beforeDraw',
						annotations: annotations
					}
				},
				scales: {
					x: {
						ticks: {
							stepSize: 1
						}
					}
				}
			}}
		/>
	);
}
