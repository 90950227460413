import React from 'react';
import { Col, Form } from 'antd';

import { CompanySelect } from '@/components/ui/Inputs/CompanySelect';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { useApplicationContext } from '@/context/application';
import { useGetCompanies } from '@/hooks';
import * as S from '../styles';

const { useFormInstance } = Form;

interface CompanyProps {
	namePlace?: string;
	fontSize?: string;
	isRequired?: boolean;
}

export function Company({ namePlace = 'filter', fontSize, isRequired = false }: CompanyProps) {
	const { setFieldValue } = useFormInstance();

	const { organization, company } = useApplicationContext();

	const { data: companies } = useGetCompanies({ organization_id: organization?.id });

	setFieldValue([namePlace, 'company_id'], company?.id);

	return (
		<Col span={23}>
			<S.CustomFormItem
				required={false}
				labelCol={{ span: 24 }}
				style={{ marginBottom: 0 }}
				name={[namePlace, 'company_id']}
				label={<RequiredLabel fontSize={fontSize} title="Industrial Site" isRequired={isRequired} />}
			>
				<S.ContainerSelect $borderRadius="8px">
					<CompanySelect
						disabled
						size="large"
						loading={false}
						companies={companies}
						companyId={company?.id}
						fieldName={[namePlace, 'company_id']}
					/>
				</S.ContainerSelect>
			</S.CustomFormItem>
		</Col>
	);
}
