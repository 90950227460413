import React from 'react';
import { Col, Divider, Row } from 'antd';
import { MtmItem } from '@/hooks';
import { Text } from '@/components/Typography';
import { ResultCard } from '../../../../components/ResultCard';
import * as S from './style';

type MtmOperationCardProps = {
	mtm_operation: MtmItem;
};

export const MtmOperationCard = ({ mtm_operation }: MtmOperationCardProps) => {
	const { code, description, quantity, frequency, coefficient, time, type, percentage } = mtm_operation;

	return (
		<S.MtmOperationCardStyled span={24}>
			<Row >
				<Col span={3} className="bg-blue px-2">
					{code}
				</Col>
				<Col span={21}>
					<Row>
						<Col span={24} className="px-2 py-1 border-bottom">
							<Row justify={'space-between'} align={'middle'} gutter={[0, 5]}>
								<Col>
									<Text className="font-600">Quantity</Text>
									<Text className="font-600">:</Text>
									<Text className="pl-2">{quantity}</Text>
								</Col>
								<VerticalDivider />
								<Col>
									<Text className="font-600">Frequency</Text>
									<Text className="font-600">:</Text>
									<Text className="pl-2">{frequency}</Text>
								</Col>
								<VerticalDivider />
								<Col>
									<Text className="font-600">Coefficient</Text>
									<Text className="font-600">:</Text>
									<Text className="pl-2">{coefficient}</Text>
								</Col>
								<VerticalDivider />
								<Col>
									<span className={type}>{type}</span>
								</Col>
								<VerticalDivider />
								<Col>
									<ResultCard
										padding="1rem"
										height="35px"
										minWidth={'84px'}
										fontSize="15px"
										borderRadius="7px"
										value={`${percentage}`}
									/>
								</Col>
								<VerticalDivider />
								<Col>
									<ResultCard
										padding="1rem"
										height="35px"
										fontSize="15px"
										minWidth={'97px'}
										borderRadius="7px"
										value={`${time}min`}
									/>
								</Col>
							</Row>
						</Col>
						<Col span={24} className="d-flex px-2 py-1">
							<div className="description">
								<Text className="font-600">Description</Text>
								<Text className="font-600">:</Text>
							</div>
							<div>
								<Text>{description}</Text>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</S.MtmOperationCardStyled>
	);
};

function VerticalDivider() {
	return (
		<Col>
			<Divider type={'vertical'} style={{ borderLeft: '2px solid #f5f5f5', height: '30px' }} />
		</Col>
	);
}
