import styled from 'styled-components';
import { Col, Row } from 'antd';

export const RowCustom = styled(Row)`
	padding: 20px 33px;
	border-radius: 15px;
	background: #f8f8f8;
	height: 308px;
	overflow-y: auto;

	::-webkit-scrollbar {
		width: 10px;
	}

	::-webkit-scrollbar-track {
	}

	::-webkit-scrollbar-thumb {
		border-left: 3px solid #9c9c9c;
		border-radius: 1.5px;
	}
	::-webkit-scrollbar-button {
		border-radius: 0 20px 20px 0;
	}
	::-webkit-scrollbar-button:single-button:vertical:decrement {
		border-width: 0 8px 8px 8px;
		background-color: transparent;
	}
	::-webkit-scrollbar-button:single-button:vertical:increment {
		border-width: 0 8px 8px 8px;
		background-color: transparent;
	}
`;
export const ColCustom = styled(Col)`
	border-radius: 7px;
	background-color: #ffffff;
	border: 2px solid rgba(235, 235, 235, 0.85);
	padding: 10px 5px 10px 24px;
	.ant-col:last-child {
		text-align: center;
	}
`;
