import { useMutation } from '@tanstack/react-query';
import type { CreateSuccess, CreateRotationGoalsDTO } from '@/types/dto';
import type { ErrorResponse } from '@/types';
import Api from '@/services/api';

async function createRotationObjectives(params: CreateRotationGoalsDTO): Promise<CreateSuccess> {
	const { rotation_report_id } = params;
	const { data } = await Api.post(`/rotation/report/${rotation_report_id}/objective`, params);
	return data;
}

export const useCreateRotationObjectives = () => {
	return useMutation<CreateSuccess, ErrorResponse, CreateRotationGoalsDTO>(createRotationObjectives);
};
