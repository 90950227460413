import React, { useState } from 'react';

import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import hooks from '../../../hooks';

import { CreateWorkstation } from './CreateWorkstation';
import { SelectWorkstation } from './SelectWorkstation';
import { EditWorkstation } from './EditWorkstation';

const { useGetWorkstations } = hooks;

export const ManageWorkstation = () => {
	const [workstationName, setWorkstationName] = useState('');
	const [isEditing, setIsEditing] = useState(false);

	const { organizationId, companyId, lineId } = useManageOrganization();

	const {
		data: workstations,
		isError: errorGettingWorkstations,
		isLoading: gettingWorkstations,
		isFetching: fetchingWorkstations,
	} = useGetWorkstations(organizationId, companyId, lineId);

	function handleIsEditing(editing: boolean) {
		setIsEditing(editing);
	}

	function handleWorkstationNameChange(name: string) {
		setWorkstationName(name);
	}

	if (errorGettingWorkstations) {
		return I18n.get('Oops... Something went wrong!');
	}

	if (fetchingWorkstations || gettingWorkstations) {
		return `${I18n.get('Loading')}...`;
	}

	return workstations.length === 0 ? (
		<CreateWorkstation />
	) : isEditing ? (
		<EditWorkstation
			workstationName={workstationName}
			onWorkstationNameChange={handleWorkstationNameChange}
			onEditing={handleIsEditing}
		/>
	) : (
		<SelectWorkstation
			workstations={workstations}
			workstationName={workstationName}
			onWorkstationNameChange={handleWorkstationNameChange}
			onEditing={handleIsEditing}
		/>
	);
};
