import React from 'react';
import { Row, Col } from 'antd';
import { DownloadOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Text } from '@/components/Typography';
import { ConsolidatedButton, DesconsolidatedButton, DownloadButton } from './styles';

type onConsolidatePDFFunction = {
	file: any;
	status: boolean;
};

interface ButtonsCustomProps {
	isLoadingPDF: boolean;
	isConsolidating: boolean;
	isDownloadingSpreadsheet?: boolean;
	consolidated?: boolean;
	hasConsolidated: boolean;
	fileData?: any;
	onDownloadPDF(file: any): Promise<void>;
	onDownloadSpreadsheet?: () => Promise<void>;
	onConsolidatePDF(values: onConsolidatePDFFunction): void;
}

export function ButtonsCustom({
	isLoadingPDF,
	isConsolidating,
	isDownloadingSpreadsheet = false,
	consolidated = false,
	onDownloadPDF,
	hasConsolidated,
	onConsolidatePDF,
	onDownloadSpreadsheet,
	fileData = {}
}: Readonly<ButtonsCustomProps>) {
	return (
		<Row justify="end" gutter={[30, 0]}>
			{onDownloadSpreadsheet && (
				<Col>
					<DownloadButton
						type="primary"
						size="large"
						ghost
						disabled={isConsolidating || isLoadingPDF}
						loading={isDownloadingSpreadsheet}
						onClick={onDownloadSpreadsheet}
						icon={<DownloadOutlined />}
					>
						<Text>Download XLSX</Text>
					</DownloadButton>
				</Col>
			)}
			<Col>
				<DownloadButton
					type="primary"
					size="large"
					ghost
					disabled={isConsolidating || isDownloadingSpreadsheet}
					loading={isLoadingPDF}
					onClick={onDownloadPDF}
					icon={<DownloadOutlined />}
				>
					<Text>Download PDF</Text>
				</DownloadButton>
			</Col>
			{hasConsolidated && (
				<Col>
					{!consolidated ? (
						<ConsolidatedButton
							loading={isConsolidating}
							type="primary"
							size="large"
							ghost
							disabled={isLoadingPDF || isDownloadingSpreadsheet}
							onClick={() => onConsolidatePDF({ file: fileData, status: true })}
							icon={<LockOutlined style={{ color: '#2cc852' }} />}
						>
							<Text>Consolidate report</Text>
						</ConsolidatedButton>
					) : (
						<DesconsolidatedButton
							type="primary"
							ghost
							size="large"
							disabled={isLoadingPDF}
							loading={isConsolidating || isDownloadingSpreadsheet}
							onClick={() => onConsolidatePDF({ file: fileData, status: false })}
							icon={<UnlockOutlined />}
						>
							<Text>Deconsolidate</Text>
						</DesconsolidatedButton>
					)}
				</Col>
			)}
		</Row>
	);
}
