import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import hooks from '../../../hooks';

import { CreateSector } from './CreateSector';
import { SelectSector } from './SelectSector';
import { EditSector } from './EditSector';

const { useGetSectors } = hooks;

export const ManageSector = () => {
	const [sectorName, setSectorName] = useState('');
	const [isEditing, setIsEditing] = useState(false);

	const { organizationId, companyId } = useManageOrganization();

	const {
		data: sectors,
		isError: errorgettingSectors,
		isLoading: gettingSectors,
		isFetching: fetchingSectors,
	} = useGetSectors(organizationId, companyId);

	function handleIsEditing(editing: boolean) {
		setIsEditing(editing);
	}

	function handleSectorNameChange(name: string) {
		setSectorName(name);
	}

	if (errorgettingSectors) {
		return I18n.get('Oops... Something went wrong!');
	}

	if (fetchingSectors || gettingSectors) {
		return `${I18n.get('Loading')}...`;
	}

	return sectors.length === 0 ? (
		<CreateSector />
	) : isEditing ? (
		<EditSector
			sectorName={sectorName}
			onSectorNameChange={handleSectorNameChange}
			onEditing={handleIsEditing}
		/>
	) : (
		<SelectSector
			sectors={sectors}
			sectorName={sectorName}
			onSectorNameChange={handleSectorNameChange}
			onEditing={handleIsEditing}
		/>
	);
};
