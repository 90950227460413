import styled from 'styled-components';
import { Col, Row } from 'antd';

export const Card = styled.div`
	padding: 25px;
	text-align: ${(props) => props.center || 'center'};
	border-radius: 5px;
	background-color: white;
	box-shadow: 1px 1px 1px #888888;
	min-height: ${(props) => props.minHeight}vw;
	max-height: ${(props) => props.maxHeight}vw;
	overflow-y: ${(props) => (props.scrollY ? 'auto' : 'unset')};
`;

// -----

export const Title = styled.h2`
	font-size: 20px;
`;

// export const Card = styled.div`
// 	background-color: white;
// 	border-radius: 5px;
// 	padding: 5px;
// 	box-shadow: 1px 1px 1px #888888;
// 	text-align: center;
// 	font-size: 30px;
// `;

export const StyledSpan = styled.span`
	font-size: 13px;
	font-weight: bold;
`;

export const Styledh2 = styled.h2`
	margin-bottom: -10px;
	font-size: 25px;
`;

export const StyledRow = styled(Row)`
	width: 100%;
	margin: 0px !important;
	padding: 0px !important;
`;

export const StyledColLeft = styled(Col)`
	padding-left: 0px !important;
	padding-right: 10px !important;
	padding-top: 0px !important;
`;

export const StyledColRight = styled(Col)`
	padding-right: 0px !important;
	padding-left: 10px !important;
	padding-top: 0px !important;
`;

export const StyledColInner = styled(Col)`
	padding-left: 10px !important;
	padding-right: 10px !important;
	padding-top: 0px !important;
`;

export const ChartCard = styled.div`
	background-color: white;
	border-radius: 5px;
	padding: 25px;
	box-shadow: 1px 1px 1px #888888;
	text-align: center;
`;

export const PieCard = styled.div`
	background-color: white;
	border-radius: 5px;
	padding-left: 80px;
	padding-right: 80px;
	padding-top: 18px;
	padding-bottom: 30px;
	box-shadow: 1px 1px 1px #888888;
	overflow: auto;
	height: 100%;
`;

export const ListCard = styled.div`
	background-color: white;
	border-radius: 5px;
	box-shadow: 1px 1px 1px #888888;
	overflow: auto;
	height: 100%;
`;

export const StyledDiv = styled.div`
	width: 100%;
	display: flex;
`;
