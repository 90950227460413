import styled from 'styled-components';

export const Image = styled.img`
	width: 100%;
	max-width: 200px;
	@media (max-width: 600px) {
		display: none;
	}
`;

export const CustomContainer = styled.div`
	@media (max-width: 650px) {
		svg {
			display: none;
		}
	}
`;
