import React from 'react';
import { Button } from 'antd';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';

interface ButtonConsolidatedProps {
	isLoading?: boolean;
	consolidated: boolean;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ButtonConsolidated: React.FC<ButtonConsolidatedProps> = ({
	onClick,
	isLoading = false,
	consolidated = false,
}) => {
	const isDanger = !!consolidated;
	const description = consolidated ? 'Desconsolidate data' : 'Consolidate data';
	const icon = consolidated ? <Icons.RollbackOutlined /> : <Icons.SaveOutlined />;

	return (
		<Button
			size="large"
			type="primary"
			icon={icon}
			ghost={isDanger}
			danger={isDanger}
			onClick={onClick}
			loading={isLoading}
			disabled={isLoading}
		>
			{I18n.get(description)}
		</Button>
	);
};
