import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DefaultOptionType } from 'antd/lib/select';
import { MtmCode } from '@/types';
import * as S from '../styles';
import { DebouncedFunc } from 'lodash';

interface MtmActivityProps {
	fontSize?: string;
	isRequired?: boolean;
	loading?: boolean;
	disabled?: boolean;
	namePlace: string[];
	onChange?: (option: OptionType) => void;
	onSearch?: (text: string) => void | DebouncedFunc<() => void>;
	codes: MtmCode[] | undefined;
}

type OptionType = { label: string; value: string } | undefined;

export function MtmActivity({
	loading,
	disabled,
	onChange,
	onSearch,
	namePlace,
	codes = []
}: Readonly<MtmActivityProps>) {
	function filterOption(option: OptionType | DefaultOptionType, input: string): boolean {
		return (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
	}

	const options = codes.map(({ id, meaning }) => ({
		label: `${meaning}`,
		value: id
	}));

	const handleChange = (option: OptionType) => {
		if (onChange) {
			return onChange(option);
		}
	};
	const handleSearch = (text: string) => {
		if (onSearch) {
			return onSearch(text);
		}
	};

	return (
		<Form.Item
			required={false}
			labelCol={{ span: 24 }}
			name={[...namePlace, 'mtm_code_id']}
			rules={[{ required: false, message: I18n.get('Provide a activity') }]}
			label={null}
		>
			<S.CustomSelect
				showSearch
				allowClear
				size="large"
				loading={loading}
				disabled={disabled}
				onChange={(_, option) => handleChange(option as OptionType)}
				onSearch={(e) => handleSearch(e)}
				filterOption={(input, option) => filterOption(option as OptionType, input)}
				placeholder={I18n.get('Search for a activity')}
				options={options}
			/>
		</Form.Item>
	);
}
