import React from 'react';
import { Col, Form, Input } from 'antd';

import { I18n } from '@aws-amplify/core';

export const SOPInput = () => {
	return (
		<Col sm={12}>
			<Form.Item
				rules={[{ required: true }]}
				name="sop"
				label={I18n.get('SOP')}
			>
				<Input placeholder={I18n.get('SOP')} />
			</Form.Item>
		</Col>
	);
};
