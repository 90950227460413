import React from 'react';
import { Form } from 'antd';
import { DebouncedFunc } from 'lodash';
import { I18n } from '@aws-amplify/core';
import { DefaultOptionType } from 'antd/lib/select';

import { RequiredLabel } from '@/components/ui/RequiredLabel';
import TableIcon from '@/components/ui/icons/Table';
import { MtmCode } from '@/types';
import { OptionType } from '../../../context/types';
import * as S from '../styles';

interface CodeProps {
	fontSize?: string;
	namePlace: string[];
	codes?: MtmCode[];
	disabled?: boolean;
	hasModal?: boolean;
	required?: boolean;
	loading?: boolean;
	onClick?: () => void;
	onChange?: (option: OptionType) => void;
	onSearch?: (text: string) => void | DebouncedFunc<() => void>;
}

export function Code({
	fontSize,
	namePlace,
	loading,
	disabled,
	codes = [],
	onClick,
	onChange,
	required,
	onSearch,
	hasModal = true
}: Readonly<CodeProps>) {
	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};
	const handleChange = (option: OptionType) => {
		if (onChange) {
			return onChange(option);
		}
	};
	const handleSearch = (text: string) => {
		if (onSearch) {
			return onSearch(text);
		}
	};

	function filterOption(option: OptionType | DefaultOptionType, input: string): boolean {
		return (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
	}

	const options = codes.map(({ id, code }) => ({
		label: code,
		value: id
	}));

	return (
		<Form.Item
			required={false}
			labelCol={{ span: 24 }}
			name={[...namePlace, 'mtm_code_id']}
			rules={[{ required: required, message: I18n.get('Select the code') }]}
			label={
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<RequiredLabel bold fontSize={fontSize} title="Code" isRequired={false} />
					{hasModal && <TableIcon style={{ marginLeft: 10, cursor: 'pointer' }} onClick={handleClick} />}
				</div>
			}
		>
			<S.CustomSelect
				showSearch
				allowClear
				size="large"
				showArrow={false}
				loading={loading}
				disabled={disabled}
				aria-disabled={disabled}
				placeholder={I18n.get('#####')}
				onChange={(_, option) => handleChange(option as OptionType)}
				onSearch={(e) => handleSearch(e)}
				filterOption={(input, option) => filterOption(option as OptionType, input)}
				options={options}
			/>
		</Form.Item>
	);
}
