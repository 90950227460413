import { useQuery } from '@tanstack/react-query';
import { GetRotationReportsDTO, GetRotationReportsResponse } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

async function getRotationReports(params: GetRotationReportsDTO): Promise<GetRotationReportsResponse> {
	const { data } = await Api.get('/rotation/report', { params });
	return data;
}

export const useGetRotationReports = (params: GetRotationReportsDTO) => {
	const { organization_id, company_id } = params;
	return useQuery([QUERY_KEYS.GET_ROTATION_REPORTS, params], () => getRotationReports(params), {
		enabled: !!organization_id && !!company_id
	});
};
