import styled from 'styled-components';

export const Card = styled.div`
	background-color: white;
	/* min-height: 200px; */
	border-radius: 15px;

	div {
		padding: 5px 5px 5px 5px;
	}
`;

export const Title = styled.div`
	display: inline-block;
	margin-right: 5px;
`;

export const Icon = styled.div`
	display: inline-block;
`;

// export const Value = styled.div`
// 	label {
// 		font-family: Arial,sans-serif;
// 		font-size: 15px;
// 	}
// `;

// export const Description = styled(Value)`
// 	label {
// 		opacity: 0.7;
// 	}
// `;
