import { useMutation } from '@tanstack/react-query';

import type { BulkCreateProductDTO, ProductDTO } from '@/types/dto/product';
import type { ErrorResponse } from '@/types';
import Api from '@/services/api';

async function bulkCreateProduct(params: BulkCreateProductDTO): Promise<ProductDTO> {
	const { data } = await Api.post('/product/bulk', params);
	return data;
}

export function useBulkCreateProduct() {
	return useMutation<ProductDTO, ErrorResponse, BulkCreateProductDTO>(bulkCreateProduct);
}
