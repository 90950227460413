import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getLines({ organizationId, companyId, sectorId }) {
	if (!organizationId || !companyId || !sectorId) return;
	const url = `/line/${organizationId}/${companyId}/${sectorId}`;
	const { data } = await Api.get(url);
	return data;
}

export function useLine(payload) {
	const lines = useQuery(['lines', payload], () => getLines(payload));
	return { lines };
}
