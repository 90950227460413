import React from 'react';
import { Col, Form } from 'antd';

import { OrganizationSelect } from '@/components/ui/Inputs/OrganizationSelect';
import { useGetOrganizations } from '@/hooks/useGetOrganizations';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { useApplicationContext } from '@/context/application';
import * as S from '../styles';

const { useFormInstance } = Form;

interface OrganizationProps {
	namePlace?: string;
	fontSize?: string;
	isRequired?: boolean;
}

export function Organization({ namePlace = 'filter', fontSize, isRequired = false }: OrganizationProps) {
	const { setFieldValue } = useFormInstance();
	const { organization } = useApplicationContext();
	const { data, isLoading } = useGetOrganizations({ organization_id: organization?.id });

	const organizations = [data];

	setFieldValue([namePlace, 'organization_id'], organization?.id);

	return (
		<Col span={23}>
			<S.CustomFormItem
				required={false}
				labelCol={{ span: 24 }}
				style={{ marginBottom: 0 }}
				name={[namePlace, 'organization_id']}
				label={<RequiredLabel fontSize={fontSize} title="Company" isRequired={isRequired} />}
			>
				<S.ContainerSelect $borderRadius="8px">
					<OrganizationSelect
						disabled
						size="large"
						loading={isLoading}
						organizations={organizations}
						organizationId={organization?.id}
					/>
				</S.ContainerSelect>
			</S.CustomFormItem>
		</Col>
	);
}
