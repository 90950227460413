import styled from 'styled-components';
import { Modal } from 'antd';

type ModalBorderProps = {
	$borderSize?: string;
};

export const ModalBorder = styled(Modal)<ModalBorderProps>`
	.ant-modal-content {
		border-radius: ${({ $borderSize }) => $borderSize ?? '15px'};
	}
`;
