import { I18n } from '@aws-amplify/core';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	padding: 0 40px;
	display: flex;
	align-items: center;
`;
const Title = styled.h3`
	font-size: 16px;
	color: #706c6c;
	padding-right: 25px;
	margin-bottom: 0;
`;

const Value = styled.h4`
	font-size: 36px;
	font-weight: bold;
	padding-right: 25px;
	margin-bottom: 0;
`;

export function GlobalClassification({ value }) {
	const valueString = [
		I18n.get('Low risk'),
		I18n.get('Moderate risk'),
		I18n.get('High risk'),
		'',
	].reverse()[value?.note ?? value];

	const icon = [
		'/assets/icons/low-risk.svg',
		'/assets/icons/medium-risk.svg',
		'/assets/icons/high-risk.svg',
		'',
	].reverse()[value?.note ?? value];

	return (
		<Wrapper>
			<div>
				<Title>{I18n.get('Global Rating')}</Title>
				<Value>{valueString}</Value>
			</div>
			<div>
				<img src={icon} />
			</div>
		</Wrapper>
	);
}
