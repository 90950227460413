import React, { MouseEvent, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Card, CardTitle } from './index.styles';
import { Filter } from '../index.types';
import { CardIcon } from './CardIcon';
import styled from 'styled-components';
import { useDashboardContext } from '../context';

export type Props = {
	name: string;
	title: string;
	type: 'single' | 'bodyPart' | 'dualBodyPart' | 'lowerMember';
};

const SplitedContent = styled.div`
	display: flex;
	width: 100%;
	text-align: center;
	height: 100%;
	align-items: center;
	background: #fff;
	margin-bottom: 5px;

	& > div {
		width: 50%;
		padding: 5px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(196, 196, 196, 0.6);

		&:hover {
			background: #c4c4c4;
		}
	}
`;

export function FilterCard({ name, title, type }: Props) {
	const { filter, setFilter } = useDashboardContext();
	const [side, setSide] = useState<'right' | 'left'>();
	const [step, setStep] = useState<'default' | 'side' | 'property' | 'movement'>(
		'default'
	);

	function handleClick() {
		if (type === 'single') {
			setFilter(name as Filter);
		} else if (type === 'dualBodyPart') {
			setStep('side');
		} else if (type === 'bodyPart') {
			setStep('property');
		} else if (type === 'lowerMember') {
			setStep('movement');
		}
	}

	function handlePropertyClick(property: 'frequency' | 'duration', e: MouseEvent) {
		e.stopPropagation();
		if (type === 'bodyPart') {
			setFilter(`${property}_${name}` as Filter);
		} else {
			setFilter(`${property}_${side}_${name}` as Filter);
		}
		setStep('default');
	}

	function handleSideClick(side: 'right' | 'left', e: MouseEvent) {
		e.stopPropagation();
		setSide(side);
		setStep('property');
	}

	function handleMovementClick(movement: 'squat' | 'displacement', e: MouseEvent) {
		e.stopPropagation();
		setFilter(movement as Filter);
		setStep('default');
	}

	return (
		<Card key={name} selected={!!filter?.includes(name)} onClick={handleClick}>
			{step === 'default' && (
				<>
					<CardIcon name={name} />
				</>
			)}

			{step === 'side' && (
				<SplitedContent>
					<div onClick={(e) => handleSideClick('left', e)}>
						<span>{I18n.get('Left')}</span>
					</div>
					<div onClick={(e) => handleSideClick('right', e)}>
						<span>{I18n.get('Right')}</span>
					</div>
				</SplitedContent>
			)}

			{step === 'property' && (
				<SplitedContent>
					<div onClick={(e) => handlePropertyClick('frequency', e)}>
						<span>{I18n.get('Frequency')}</span>
					</div>
					<div onClick={(e) => handlePropertyClick('duration', e)}>
						<span>{I18n.get('Duration')}</span>
					</div>
				</SplitedContent>
			)}

			{step === 'movement' && (
				<SplitedContent>
					<div onClick={(e) => handleMovementClick('squat', e)}>
						<span>{I18n.get('Squat')}</span>
					</div>
					<div onClick={(e) => handleMovementClick('displacement', e)}>
						<span>{I18n.get('Displacement')}</span>
					</div>
				</SplitedContent>
			)}
			<CardTitle>{title}</CardTitle>
		</Card>
	);
}
