import React from 'react';
import { Row, Col } from 'antd';
import { MtmCard } from '../MtmCard';
import { MtmItems } from '../MtmItems';

interface MtmContainerProps {
	operation_id?: string;
}

export function MtmContainer({ operation_id = '' }: MtmContainerProps) {
	return (
		<Row style={{ paddingLeft: 30 }}>
			<Col span={24}>
				<MtmCard operation_id={operation_id} />
			</Col>
			<Col span={24}>
				<MtmItems operation_id={operation_id} />
			</Col>
		</Row>
	);
}
