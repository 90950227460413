import React, { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import { Col, Row, Steps } from 'antd';

import { useApplicationContext } from '@/context/application';

import { Informations } from './Informations/index';
import { useChecklistEIContext } from './context';
import { TCM } from './TCM';

type StepProps = {
	title: string;
	content: ReactNode;
};

export function ChecklistEIForm() {
	const { currentStep } = useChecklistEIContext();
	const { company, organization } = useApplicationContext();

	const history = useHistory();

	useEffect(() => {
		if (!organization && !company) {
			history.push('/');
		}
	}, [organization, company]);

	const steps: StepProps[] = [
		{
			title: 'Informations',
			content: <Informations />
		},
		{
			title: 'Estrutura TCM',
			content: <TCM />
		}
		// {
		// 	title: 'Result',
		// 	content: <Result />,
		// },
	];

	return (
		<Row>
			<Col sm={24} style={{ paddingTop: '20px', paddingBottom: '30px' }}>
				<Title level={2}>Checklist Engenharia Industrial - Análise ergonômica do trabalho</Title>
			</Col>
			<Col sm={24}>
				<Row justify="center">
					<Col sm={2}>
						<Steps
							direction="vertical"
							size="default"
							current={currentStep}
							items={steps.map((_step) => ({
								title: ''
							}))}
						/>
					</Col>
					<Col sm={22}>{steps[currentStep].content}</Col>
				</Row>
			</Col>
		</Row>
	);
}
