import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import type { OperationsDTO } from '@/components/ui/Inputs/types/operation';
import type { Operation } from '@/types';

type PostureType = {
	name: string;
	itsSuggestion?: boolean;
	percentage: number;
};

type PostureValues = PostureType & {
	posture_type: PostureType[];
};

export type PosturesSuggestions = {
	posture: PostureValues[];
};

export type OperationsResponse = Operation & {
	suggestion_values?: PosturesSuggestions;
	total_time?: string;
	file_id: string;
};

const getOperations = async (params: OperationsDTO): Promise<{ count: Number; rows: OperationsResponse[] }> => {
	const url = `/operation`;
	const { data } = await Api.get<{ count: Number; rows: OperationsResponse[] }>(url, { params });
	return data;
};

export const useOperations = ({ organization_id, company_id }: OperationsDTO) => {
	const enabled = !!organization_id && !!company_id;

	const operations = useQuery(
		['operation_list', { organization_id, company_id }],
		() => getOperations({ organization_id, company_id }),
		{ enabled }
	);
	return operations;
};
