import React, { useContext } from 'react';
import { Col, Button, Radio } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';

import { AppointmentContext } from './context';

export const Title = styled.div`
	display: inline-block;
	margin-right: 5px;
`;

export const Header = () => {
	const { onClearFilter, selectToggle, setSelectToggle } =
		useContext(AppointmentContext);

	const options = [
		{ label: I18n.get('Ergonomic grade'), value: false },
		{ label: I18n.get('Workers'), value: true },
	];

	return (
		<Col sm={24} style={{ paddingBottom: '15px' }}>
			<Title>
				<h2>{I18n.get('Dashboard')}</h2>
			</Title>
			<Radio.Group
				options={options}
				optionType="button"
				value={selectToggle}
				onChange={setSelectToggle}
				style={{ display: 'inline', float: 'right' }}
			/>
			<Button type="link" onClick={onClearFilter} style={{ float: 'right' }}>
				{I18n.get('Clear')}
			</Button>
		</Col>
	);
};
