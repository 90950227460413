import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';
import { File, FileStatus } from '@/types';

type FileDTO = Pick<File, 'organization_id' | 'company_id'> & { offset: number; limit: number; filter?: FilterDTO };

export type FileResponse = {
	page: number;
	total: number;
	result: File[];
};

export type FilterDTO = {
	end_date?: Date;
	offset?: number;
	line_id?: string;
	start_date?: Date;
	sector_id?: string;
	company_id?: string;
	file_ids?: React.Key[];
	original_name?: string;
	organization_id?: string;
	status?: FileStatus;
};

const getFiles = async ({ organization_id, company_id, filter, limit, offset }: FileDTO): Promise<FileResponse> => {
	const params = { limit, offset };
	if (filter) {
		_.assign(params, filter);
	}
	const url = `/upload/${organization_id}/${filter?.company_id ?? company_id}`;
	const { data } = await Api.get<FileResponse>(url, { params });
	return data;
};

export const useGetFiles = ({ organization_id, company_id, offset, limit, filter }: FileDTO) => {
	return useQuery(
		[QUERY_KEYS.GET_FILES_WITH_OPERATIONS, { organization_id, company_id, offset, limit, ...filter }],
		() => getFiles({ organization_id, company_id, filter, offset, limit }),
		{ enabled: !!organization_id && !!company_id }
	);
};
