import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import { NameInput } from '../styles';

const { useFormInstance } = Form;

export function CreateCompany() {
	const [companyName, setCompanyName] = useState('');

	const { validateFields, resetFields, setFieldValue } = useFormInstance();

	const {
		organizationId,
		creatingOrganizationData,
		isEditing,
		createOrganizationData,
		handleCompany,
		treatFieldValidation,
	} = useManageOrganization();

	async function handleCreateCompany() {
		try {
			await validateFields(['company_name']);
			const created = await createOrganizationData({
				organization_id: organizationId,
				company_name: companyName,
			});

			setCompanyName('');
			handleCompany(created.id, resetFields, setFieldValue);
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	function handleCompanyNameChange(name: string) {
		setCompanyName(name);
	}
	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[16, 0]}>
				<Col span={20}>
					<NameInput
						disabled={isEditing}
						name="company_name"
						label={I18n.get('Create industrial site')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please enter a valid name'),
							},
						]}
					>
						<Input
							disabled={isEditing}
							placeholder={I18n.get('Industrial site name')}
							onChange={(e) => handleCompanyNameChange(e.target.value)}
						/>
					</NameInput>
				</Col>
				<Col span={4}>
					<Button
						disabled={isEditing}
						loading={creatingOrganizationData}
						type="primary"
						htmlType="submit"
						onClick={() => handleCreateCompany()}
					>
						{I18n.get('Create')}
					</Button>
				</Col>
			</Row>
		</Col>
	);
}
