import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { queryClient } from '@/store/query';
import { Form, message } from 'antd';
import { I18n } from '@aws-amplify/core';

import { QUERY_KEYS } from '@/utils/query-keys';
import { useApplicationContext } from '@/context/application';
import { useCreateActivities } from '@/hooks/useCreateActivities';
import { useCreateFilesActivities } from '@/hooks/useCreateFilesActivities';
import { useCreateRotationReportFiles } from '@/hooks/useCreateRotationReportFiles';
import { useRotationReportContext } from '@/components/views/RotationReport/context';
import { useUpdateRotationReportExposure } from '@/hooks/useUpdateRotationReportExposure';

import type { Context, Methods, States } from './types';
import { useGetFilesWithActivity, type GetFilesActivityDTO } from '@/hooks/useGetFilesWithActivity';

const { useFormInstance } = Form;
const SelectFilesContext = createContext<Context>({} as Context);

export const LIMIT = 8;

type ActivityParams = {
	file_id: string;
	activity_id: string;
};

type ActivityItem = {
	name: string;
	file_id: string;
};

export type SelectedRows = {
	[key: string]: {
		activity_id: string;
	};
};

export type HandleSelectedRows = {
	newSelectedRows: SelectedRows;
	hasUndefinedOperation: boolean;
};

export type GetFieldsValue = SelectedRows & {
	selectedRowKeys: React.Key[];
	[key: string]: Array<string> | Object | string;
};

interface SelectFilesProviderProps {
	children: ReactNode;
}

function hasDuplicateNames(operations_name: string[]): boolean {
	const names = new Set<string>();

	for (const name of operations_name) {
		if (names.has(name)) {
			return true;
		}
		names.add(name);
	}

	return false;
}

export function SelectFilesProvider({ children }: Readonly<SelectFilesProviderProps>) {
	const { getFieldsValue, getFieldValue, validateFields, setFieldValue } = useFormInstance();
	const { handleNextStep, rotationReport } = useRotationReportContext();
	const { organization, company } = useApplicationContext();

	const initialSelectedRowKeys: React.Key[] = getFieldValue(['selectedRowKeys']);

	const [page, setPage] = useState<number>(1);
	const [filter, setFilter] = useState<GetFilesActivityDTO>({} as GetFilesActivityDTO);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(initialSelectedRowKeys ?? ([] as React.Key[]));

	const baseParameters = {
		organization_id: filter.organization_id ?? organization?.id ?? '',
		company_id: filter.company_id ?? company?.id ?? ''
	};

	const { mutateAsync: createActivities } = useCreateActivities();
	const { mutateAsync: createFilesActivities } = useCreateFilesActivities();
	const { mutateAsync: createRotationReportFiles } = useCreateRotationReportFiles();
	const { mutate: updateRotationExposure } = useUpdateRotationReportExposure();

	const { data: allFiles, isLoading: loadingAllFiles } = useGetFilesWithActivity({
		...baseParameters,
		...filter,
		offset: (page - 1) * LIMIT,
		limit: LIMIT
	});

	function handleFilter(value: GetFilesActivityDTO): void {
		setFilter(value);
	}

	function handlePage(value: number): void {
		setPage(value);
	}

	function handlingSelectRowKeys(value: React.Key[]) {
		setSelectedRowKeys(value);
		value.forEach((id) => {
			const file = allFiles?.rows.find((f) => f.id === id);
			if (file) {
				setFieldValue([id], file);
			}
		});
	}

	function handleSelectedRows() {
		const allValues: GetFieldsValue = getFieldsValue(true);
		validateFields();

		const { filesSelectedArray, activitiesToCreate } = verifyIfActivityIsUndefined(allValues);

		if (!selectedRowKeys.length || activitiesToCreate.some((m) => !m.name)) {
			const error = {
				field_message: 'Fill the required fields'
			};

			throw error;
		}

		return { allValues, activitiesToCreate, filesSelectedArray };
	}

	function verifyIfActivityIsUndefined(allValues: GetFieldsValue) {
		let activitiesToCreate: ActivityItem[] = [];
		let filesSelectedArray: ActivityParams[] = [];

		selectedRowKeys.map(async (fileId) => {
			if (allValues[fileId] && !!allValues[fileId].activity_id) {
				const filesSelected = fixObjectResponseValues(fileId, allValues);
				filesSelectedArray.push(filesSelected);
			} else {
				const fileActivity = normalizerActivityToCreate(fileId);
				activitiesToCreate.push(fileActivity);
			}
		});

		return { filesSelectedArray, activitiesToCreate };
	}

	function fixObjectResponseValues(fileId: React.Key, allValues: GetFieldsValue) {
		let filesSelected = {} as ActivityParams;

		filesSelected.file_id = fileId.toString();
		filesSelected.activity_id = allValues[fileId].activity_id;

		return filesSelected;
	}

	function normalizerActivityToCreate(fileId: React.Key) {
		let fileOperation = {} as ActivityItem;

		fileOperation.file_id = fileId.toString();
		fileOperation.name = getFieldValue([fileId, 'activity_name'])?.trim();

		return fileOperation;
	}

	async function handleValidation() {
		try {
			const { activitiesToCreate, allValues } = handleSelectedRows();
			const selectedFilesNames: string[] = allValues.selectedRowKeys.map((id) => {
				const file_activity = allValues[id] as any;

				return file_activity?.activity_name;
			});

			if (hasDuplicateNames(selectedFilesNames)) {
				const duplicate_name_error = {
					field_message: 'File operations must have unique names for same report'
				};
				throw duplicate_name_error;
			}

			const createdActivities = await createInputtedActivities(activitiesToCreate);

			if (createdActivities.length) {
				await createFilesActivities({ files_activities: createdActivities, ...baseParameters });
			}

			const { data } = await createRotationReportFiles({
				files: selectedRowKeys as string[],
				id: rotationReport?.id as string,
				...baseParameters
			});
			if (data.need_to_recalculate) {
				updateRotationExposure({ id: rotationReport?.id, ...baseParameters });
				queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_ACTIVITIES]);
			}
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_BODY_PART_EXPOSURE]);
			handleNextStep();
		} catch (error: any) {
			message.error(
				I18n.get(error?.response?.data?.message || error?.field_message || 'Oops... Something went wrong!')
			);

			const filter = getFieldValue(['filter']) || {};

			if (filter.select_button !== 'selected_files') {
				setFieldValue(['filter', 'select_button'], 'selected_files');
				handleFilter({ ...filter, file_ids: selectedRowKeys, offset: 0 });
				setTimeout(() => {
					validateFields();
				}, 600);
			}
		}
	}

	async function createInputtedActivities(activitiesToCreate: ActivityItem[]) {
		if (!activitiesToCreate.length) {
			return [];
		}

		const createdOperations = await createActivities({
			...baseParameters,
			names: activitiesToCreate.map((m) => m.name)
		});
		const formattedFilesToActivities = createdOperations.map((m) => {
			const previousValue = activitiesToCreate.find((f) => f.name === m.name);
			return {
				file_id: previousValue?.file_id as string,
				activity_id: m.id
			};
		});

		return formattedFilesToActivities;
	}

	useEffect(() => {
		if (filter.offset === 0) {
			setPage(1);
		}
	}, [filter.offset]);

	const states: States = { allFiles, loadingAllFiles, page, selectedRowKeys, baseParameters };
	const methods: Methods = { handlePage, handleFilter, handleValidation, handlingSelectRowKeys };

	const contextValue = {
		...states,
		...methods
	};

	return <SelectFilesContext.Provider value={contextValue}>{children}</SelectFilesContext.Provider>;
}

export function useSelectFilesContext() {
	const context = useContext(SelectFilesContext);
	return context;
}
