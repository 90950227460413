import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useQueryClient } from '@tanstack/react-query';
import { Row, Col, Form, Button, Input, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { QUERY_KEYS } from '@/utils/query-keys';
import { Cancel, Confirm, NameInput } from '../styles';
import { useManageOrganization } from '../../../context';
import { useUpdateProduct } from '@/hooks/useUpdateProduct';
import type { UpdateProductDTO } from '@/types/dto/product';

const { useFormInstance } = Form;
const { GET_PRODUCTS } = QUERY_KEYS;

interface EditProductProps {
	productName: string;
	onProductNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
}

export function EditProduct({ productName, onProductNameChange, onEditing }: EditProductProps) {
	const [editingName] = useState<string>(productName);
	const queryClient = useQueryClient();
	const updateProduct = useUpdateProduct();
	const { setFieldValue, validateFields } = useFormInstance();
	const { organizationId, companyId, sectorId, lineId, workstationId, productId, handleIsEditing } =
		useManageOrganization();

	function handleProductNameChange(name: string): void {
		onProductNameChange(name);
	}

	function handleCancel(): void {
		onProductNameChange('');
		handleIsEditing(false);
		onEditing(false);
		setFieldValue('product_id', productId);
	}

	function getUpdateProductPayload(id: string, name: string): UpdateProductDTO {
		return {
			id: id,
			name: name,
			organization_id: organizationId,
			company_id: companyId,
			sector_id: sectorId,
			line_id: lineId,
			workstation_id: workstationId
		};
	}

	async function handleUpdateProduct(payload: UpdateProductDTO): Promise<void> {
		try {
			await updateProduct.mutateAsync(payload);
			message.success(I18n.get('Product update successfully'));
			queryClient.invalidateQueries([GET_PRODUCTS]);
			onProductNameChange('');
			handleIsEditing(false);
			onEditing(false);
		} catch (error: any) {
			const err = error?.response?.data?.message || 'Failed to update product';
			message.error(I18n.get(err));
		}
	}

	async function handleOnUpdateProduct(e: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> {
		e.stopPropagation();
		e.preventDefault();
		const { edit_product_name } = await validateFields(['edit_product_name']);
		const payload = getUpdateProductPayload(productId, edit_product_name);
		await handleUpdateProduct(payload);
	}

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<NameInput
						name="edit_product_name"
						label={I18n.get('Edit product')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								message: I18n.get('Please, enter a valid product name'),
								required: true
							}
						]}
					>
						<Input
							style={{
								flex: 'auto',
								borderRadius: '7px',
								border: '2px solid #000'
							}}
							value={productName}
							onChange={(e) => handleProductNameChange(e.target.value)}
						/>
					</NameInput>
				</Col>
				<Col span={4}>
					<Row align="bottom" justify="space-between">
						<Cancel span={12}>
							<Button
								type="text"
								size="middle"
								onClick={handleCancel}
								icon={<CloseOutlined />}
								disabled={!companyId || !productId}
							/>
						</Cancel>
						<Confirm span={12}>
							<Button
								type="text"
								size="middle"
								icon={<CheckOutlined />}
								onClick={handleOnUpdateProduct}
								disabled={!companyId || !productId || !productName || editingName === productName}
							/>
						</Confirm>
					</Row>
				</Col>
			</Row>
		</Col>
	);
}
