import React from 'react';
import { Row, Col } from 'antd';
import { Paragraph, Title } from '@/components/Typography';

import { BodyPartGoal } from './components/Inputs/BodyPartGoal';
import { FooterButtons } from './components/FooterButtons';
import * as S from './styles';

export function Goals() {
	return (
		<Row justify="center" gutter={[0, 16]}>
			<Col span={24}>
				<Row>
					<Col span={24}>
						<Title level={4}>Goals</Title>
					</Col>
					<Col span={24}>
						<Paragraph>Indicate the minimum acceptable exposure levels for the rotation</Paragraph>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row justify="center">
					<Col span={18}>
						<S.Card>
							<Row gutter={[0, 24]}>
								<Col span={24}>
									<Title level={5}>Minimum acceptable exposure level per body part</Title>
								</Col>
								<Col span={24}>
									<Row>
										<Col span={24}>
											<BodyPartGoal bodyPart="neck" />
										</Col>
										<Col span={24}>
											<BodyPartGoal bodyPart="trunk" />
										</Col>
										<Col span={24}>
											<BodyPartGoal bodyPart="left_upper_arm" />
										</Col>
										<Col span={24}>
											<BodyPartGoal bodyPart="right_upper_arm" />
										</Col>
										<Col span={24}>
											<BodyPartGoal bodyPart="left_lower_arm" />
										</Col>
										<Col span={24}>
											<BodyPartGoal bodyPart="right_lower_arm" />
										</Col>
									</Row>
								</Col>
							</Row>
						</S.Card>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<FooterButtons />
			</Col>
		</Row>
	);
}
