import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { BaseContext, BaseContextOptional } from '@/types';

export type UpdateMtmReportMtmStepDTO = BaseContextOptional & {
	id: string;
	current_step: number;
};

type MtmReportResponse = BaseContext & {
	message: string;
	id: string;
};

const updateMtmReportMtmStep = async (body: UpdateMtmReportMtmStepDTO): Promise<MtmReportResponse> => {
	const { data } = await Api.put<MtmReportResponse>(`/mtm/report/${body.id}/mtm-step`, body);
	return data;
};

export const useUpdateMtmReportMtmStep = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateMtmReportMtmStepDTO) => updateMtmReportMtmStep(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: ({ id, company_id, organization_id }) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORT_RESULT]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORT, {  id, company_id, organization_id}]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORTS_PAGINATED]);
			message.success(I18n.get('MTM updated successfully'));
		}
	});
};
