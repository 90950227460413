import React from 'react';
import { Button, Col, Divider, Tooltip } from 'antd';

import { Clapperboard } from '@/assets/icons/Clapperboard';
import { Text } from '@/components/Typography';
import * as S from './styles';

type DownloadFile = {
	id: string;
	original_name: string;
};
interface CardFileProps {
	id: string;
	loading: boolean;
	original_name: string;
	activity?: string;
	onDownloadFile: (values: DownloadFile) => void;
}

export function CardFile({ id, original_name, activity, loading, onDownloadFile }: CardFileProps) {
	return (
		<Col span={24} style={{ marginBottom: '2px' }}>
			<S.RowCustom justify="center" align="middle">
				<Col xs={10} sm={11}>
					<Tooltip title={activity}>
						<Text ellipsis>{activity}</Text>
					</Tooltip>
				</Col>
				<Col span={1}>
					<Divider type="vertical" />
				</Col>
				<Col xs={8} sm={9}>
					<Tooltip title={original_name}>
						<Text ellipsis>{original_name}</Text>
					</Tooltip>
				</Col>
				<Col span={1}>
					<Divider type="vertical" />
				</Col>
				<Col xs={1} sm={1} style={{ marginLeft: '-6px', marginTop: '-1px' }}>
					<Button
						type="link"
						loading={loading}
						onClick={() => onDownloadFile({ id, original_name })}
						icon={<Clapperboard style={{ fontSize: '1.5rem' }} />}
					/>
				</Col>
			</S.RowCustom>
		</Col>
	);
}
