import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { Button } from '@/components/ui/Button';
import { useRotationReportContext } from '@/components/views/RotationReport/context';

export function FooterButtons() {
	const history = useHistory();
	const { handlePreviousStep } = useRotationReportContext();

	function handleOnPrevious(): void {
		handlePreviousStep();
	}

	function handleOnClose(): void {
		history.push('/ehs/rotation');
	}

	return (
		<Row justify="center" align="middle" gutter={[20, 0]}>
			<Col>
				<Button
					type="ghost"
					size="middle"
					htmlType="button"
					onClick={handleOnPrevious}
					style={{ backgroundColor: '#fff' }}
				>
					{I18n.get('Back')}
				</Button>
			</Col>
			<Col>
				<Button htmlType="submit" size="middle" type="primary" onClick={handleOnClose}>
					{I18n.get('Close')}
				</Button>
			</Col>
		</Row>
	);
}
