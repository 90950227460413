import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Form, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import { debounce } from 'lodash';

import type { States, Context, Methods, BasicParams, FileOperation } from './types';
import { GetMtmReportResponse } from '@/hooks';
import moment from 'moment';

const { useForm } = Form;

const MtmReportContext = createContext<Context>({} as Context);

type MtmReportProviderProps = {
	children: ReactNode;
	basicParams: BasicParams;
	mtmReport?: GetMtmReportResponse;
};

export function MtmReportProvider({ children, basicParams, mtmReport }: Readonly<MtmReportProviderProps>) {
	const [form] = useForm();

	const [currentStep, setCurrentStep] = useState<number>(mtmReport?.current_step ?? 0);
	const [hasError, setHasError] = useState<boolean>(false);
	const [filesWithOperations, setFilesWithOperations] = useState<FileOperation[]>([]);

	function scrollToError(error: any) {
		const errorName = error?.errorFields[0]?.name;
		form.scrollToField(errorName, { behavior: 'smooth', block: 'center', inline: 'center' });

		notification.error({
			message: I18n.get('Ops... something happened!'),
			description: I18n.get("Some required step wasn't filled"),
			duration: 5
		});
	}

	const handleDebounced = debounce(() => {
		handleSetError(false);
	}, 2000);

	async function handleStepNavigationClick(step: number) {
		try {
			if (!mtmReport?.is_completed && step > currentStep) {
				return;
			}
			await form.validateFields();
			setCurrentStep(step);
		} catch (error) {
			handleSetError(true);
			handleDebounced();
		}
	}

	function handleNextStep(): void {
		if (mtmReport?.consolidated) {
			return;
		}
		setCurrentStep(currentStep + 1);
	}

	function handlePreviousStep(): void {
		if (mtmReport?.consolidated) {
			return;
		}
		setCurrentStep(currentStep - 1);
	}

	function handleSetError(value: boolean): void {
		setHasError(value);
	}

	function handleSetOperationsWithFiles(data: FileOperation[]) {
		setFilesWithOperations(data);
	}

	const states: States = {
		form,
		hasError,
		basicParams,
		currentStep,
		filesWithOperations,
		mtmReport
	};

	const methods: Methods = {
		scrollToError,
		handleNextStep,
		handleSetError,
		handleDebounced,
		handlePreviousStep,
		handleStepNavigationClick,
		handleSetOperationsWithFiles
	};

	const context: Context = {
		...states,
		...methods
	};

	const initialValues = {
		...mtmReport,
		selectedRowKeys: mtmReport?.file_ids,
		informations: {
			...basicParams,
			...mtmReport?.informations,
			analysis_date: moment(mtmReport?.informations?.analysis_date),
			collection_date: moment(mtmReport?.informations?.collection_date)
		}
	};

	return (
		<MtmReportContext.Provider value={context}>
			<Form form={form} initialValues={initialValues}>
				{children}
			</Form>
		</MtmReportContext.Provider>
	);
}

export function useMtmReportContext() {
	const context = useContext(MtmReportContext);
	return context;
}
