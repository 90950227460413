import React from 'react';
import { Col, Form, Input } from 'antd';

import { I18n } from '@aws-amplify/core';

export const TCMIdInput = () => {
	return (
		<Col sm={12}>
			<Form.Item
				rules={[{ required: true }]}
				name="tcm_id"
				label={I18n.get('TCM ID')}
			>
				<Input placeholder={I18n.get('TCM ID')} />
			</Form.Item>
		</Col>
	);
};
