import React, { Fragment } from 'react';
import { Divider } from 'antd';

import { LeftOutlined } from '@ant-design/icons';

import { CurrentName, MenuContainer, MenuContent } from './styles';
import { useDashboardContext } from './context';

export const Menu: React.FC = () => {
	const { step, menuItems } = useDashboardContext();
	const {
		setStep,
		setCompanyId,
		setSectorId,
		setLineId,
		setCompanyTitle,
		setSectorTitle,
		setLineTitle,
		handleMenuItems,
	} = useDashboardContext();

	function handleMenuClick(pageNumber: number) {
		menuItems.splice(pageNumber);
		handleMenuItems(menuItems);
		switch (pageNumber) {
			case 1:
				setStep(pageNumber);
				setSectorId('');
				setSectorTitle('');
				setLineId('');
				setLineTitle('');
				break;
			case 2:
				setStep(pageNumber);
				setLineId('');
				setLineTitle('');
				break;
			default:
				setStep(pageNumber);
				setCompanyId('');
				setCompanyTitle('');
				break;
		}
	}

	return step === 1 ? (
		<MenuContainer>
			<MenuContent page={step} onClick={() => handleMenuClick(0)}>
				<LeftOutlined style={{ fontSize: '12px', marginRight: '0.1rem' }} />
			</MenuContent>
			{menuItems.map((item, index) => {
				return <CurrentName key={index}>{item}</CurrentName>;
			})}
		</MenuContainer>
	) : (
		<MenuContainer>
			{menuItems.map((item, index) => {
				return index === step - 1 ? (
					<CurrentName key={index}>{item}</CurrentName>
				) : (
					<Fragment key={index}>
						{index === 1 && (
							<div style={{ backgroundColor: '#2f54eb33' }}>
								<Divider
									type="vertical"
									style={{
										margin: '0.5rem 0 0 0',
										height: '1rem',
										border: '1px solid #2F54EB',
										borderRadius: '0.1rem',
									}}
								/>
							</div>
						)}
						<MenuContent
							index={index}
							page={step}
							onClick={() => handleMenuClick(index + 1)}
						>
							{item}
						</MenuContent>
					</Fragment>
				);
			})}
		</MenuContainer>
	);
};
