import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { SearchOutlined } from '@ant-design/icons';
import { Text } from '@/components/Typography';
import * as S from '../../../../../components/styles';

interface SearchProductProps {
	operation_id: string;
}

export function SearchProduct({ operation_id }: SearchProductProps) {
	return (
		<>
			<Col span={24} style={{ marginTop: '5px' }}>
				<Text style={{ fontSize: '16px' }}>Product</Text>
			</Col>
			<Col span={24} style={{ marginBottom: '20px', marginTop: '10px' }}>
				<Form.Item name={['operation_product', operation_id, 'product_name']} style={{ margin: 0 }}>
					<S.CustomInput
						size="large"
						prefix={<SearchOutlined />}
						placeholder={I18n.get('Search for a file')}
					/>
				</Form.Item>
			</Col>
		</>
	);
}
