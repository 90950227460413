import { InfoCircleOutlined, LogoutOutlined } from '@ant-design/icons';

import { Menu } from 'antd';
import React from 'react';
import { AvatarUser } from './AvatarUser';
import { Text } from '@/components/Typography';

interface MenuPopoverProps {
	onLogout: () => void;
	onMyAccount: () => void;
}

export function MenuPopover({ onMyAccount, onLogout }: Readonly<MenuPopoverProps>) {
	return (
		<Menu key="user" mode="horizontal">
			<Menu.SubMenu title={<AvatarUser size="large" />}>
				<Menu.Item key="account" onClick={onMyAccount}>
					<InfoCircleOutlined />
					<Text>My account</Text>
				</Menu.Item>
				<Menu.Item key="logout" onClick={onLogout}>
					<LogoutOutlined />
					<Text>Exit</Text>
				</Menu.Item>
			</Menu.SubMenu>
		</Menu>
	);
}
