import { useQuery } from '@tanstack/react-query';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { BaseContext } from '@/types';

export type MtmReportResponse = BaseContext & {
	id: string;
	created_at: string | Date;
	report_name: string;
	evaluator: string;
	consolidated: boolean;
	is_completed: boolean;
	line: string;
};

export type MtmReportPagination = {
	count: number;
	rows: MtmReportResponse[];
};

export type MtmFilterRequest = {
	limit?: string | number;
	offset?: string | number;
	line_id?: string;
	sector_id?: string;
	created_at?: string | Date;
	operation_id?: string;
	report_name?: string;
	workstation_id?: string;
	company_id?: string;
	organization_id?: string;
};

async function listMtmReports(params: MtmFilterRequest): Promise<MtmReportPagination> {
	const url = `/mtm/report`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useListMtmReports = (params: MtmFilterRequest) => {
	return useQuery([QUERY_KEYS.GET_MTM_REPORTS_PAGINATED, params], () => listMtmReports(params), {
		enabled: !!params.organization_id && !!params.company_id
	});
};
