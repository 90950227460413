import React from 'react';
import { Col, Form, Select } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useChecklistEIContext } from '../context';

const { useFormInstance } = Form;

export const LineSelect = () => {
	const { resetFields } = useFormInstance();

	const { lines } = useChecklistEIContext();
	const { handleSelectLine, mapDataToDropdown, filterOption, handleSelectWorkstation } =
		useChecklistEIContext();

	function handleLineSelect(value: string) {
		resetFields(['workstation']);
		handleSelectWorkstation('');
		handleSelectLine(value);
	}

	const lineOptions = mapDataToDropdown(lines.data);

	return (
		<Col sm={12}>
			<Form.Item
				name="line"
				label={I18n.get('Production line')}
				rules={[{ required: true }]}
			>
				<Select
					showSearch
					allowClear
					options={lineOptions}
					loading={lines.gettingLines}
					placeholder={I18n.get('Select a production line')}
					onChange={(value: string) => handleLineSelect(value)}
					disabled={lines.data.length === 0 || lines.errorGettingLines}
					filterOption={(input, option) => filterOption(option, input)}
				/>
			</Form.Item>
		</Col>
	);
};
