import React, { useEffect, useState } from 'react';
import { Form, Select, Row, Col, Input, Button, message, Divider, DatePicker } from 'antd';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { checkIfValidUUID } from '@/utils';
import Api from '@/services/api';
import { updateInformations, validationStep } from '@/redux/reports/actions';
import { useFetch } from '@/hooks/useFetch';
import moment from 'moment';
import _ from 'lodash';

export function Informations() {
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const [nameSector, setNameSector] = useState('');
	const [recoverySector, setRecoverySector] = useState(null);

	const [sectorCreated, setSectorCreated] = useState({
		isFetching: false,
		data: null,
		err: null
	});

	const { organization, company } = useSelector(
		(state) => ({
			organization: state.organization.organization,
			company: state.organization.company
		}),
		shallowEqual
	);

	const { informations } = useSelector((state) => state.reports.customized);

	const {
		data: sectors,
		error: errSectors,
		isFetching: sectorsLoading,
		doFetch: findAllSectors
	} = useFetch({
		method: 'get',
		url: `/sector/${organization.id}/${company.id}`
	});

	const {
		data: rangeRiskList,
		error: errRangeRiskList,
		isFetching: rangeRiskListLoading
	} = useFetch({
		method: 'get',
		url: '/customization/risk-ranges/'
	});

	const normalizeData = () => {
		const initialValues = {
			sector: null,
			company: null,
			range_risk: null,
			workstation: null,
			collection_date: null
		};

		if (company?.name) {
			_.set(initialValues, 'company', company.name);
		}

		if (informations?.workstation) {
			_.set(initialValues, 'workstation', informations.workstation);
		}

		if (informations?.sector) {
			/*
				this function form.setFieldsValue not working with
				select options.
			*/
			const sector = JSON.parse(informations.sector);
			_.set(initialValues, 'sector', sector.name);
			setRecoverySector(sector);
		}

		if (informations.range_risk) {
			const range_risk = JSON.parse(informations.range_risk);
			_.set(initialValues, 'range_risk', range_risk.name);
		}

		if (informations.collection_date) {
			_.set(initialValues, 'collection_date', moment(informations.collection_date));
		}

		return initialValues;
	};

	useEffect(() => {
		if (form && informations) {
			const dataForm = normalizeData();
			form.setFieldsValue(dataForm);
		}
	}, [form, informations]);

	useEffect(() => {
		if (errSectors) {
			message.error(I18n.get('Failed to fetch sectors'));
		}

		if (errRangeRiskList) {
			message.error(I18n.get('Failed to fetch risk bands'));
		}

		if (sectorCreated.err) {
			message.error(I18n.get('Failed to create a new sector'));
		}
	}, [errSectors, errRangeRiskList, sectorCreated.err]);

	const filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

	const onFormatData = (values) => {
		let sectorId = values.sector;

		if (!checkIfValidUUID(values.sector)) {
			sectorId = recoverySector.id;
			setRecoverySector(null);
		}

		const [sector] = sectors?.filter((item) => sectorId === item.id && item);
		const dataForm = { ...values, sector: JSON.stringify(sector) };

		dispatch(updateInformations(dataForm));
		dispatch(validationStep('informations', true));
	};

	const onFinish = () => {
		form.validateFields()
			.then(onFormatData)
			.catch((errorInfo) => {});
	};

	const onNameChange = (e) => {
		setNameSector(e.target.value);
	};

	const createSector = () => {
		const body = {
			organization_id: organization.id,
			company_id: company.id,
			name: nameSector
		};

		function onLoading(boolean) {
			setSectorCreated((current) => ({
				...current,
				isFetching: boolean
			}));
		}

		function onSuccess(response) {
			setNameSector('');
			setSectorCreated((current) => ({
				...current,
				data: response.data
			}));
			message.success(I18n.get('Sector created successfully'));
			findAllSectors();
		}

		function onError(err) {
			setSectorCreated((current) => ({ ...current, err: err }));
		}

		Api.post('/sector/create', body)
			.then(onLoading(true))
			.then(onSuccess)
			.catch(onError)
			.finally(() => onLoading(false));
	};

	return (
		<Form form={form} layout="vertical" style={{ padding: '25px 10px 10px 10px' }} onValuesChange={onFinish}>
			<Row gutter={[10, 10]}>
				<Col sm={12}>
					<Form.Item name="company" label={I18n.get('Company')}>
						{/* style={{ borderRadius: '7px' }} */}
						<Input disabled={true} />
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item
						name="sector"
						label={I18n.get('Sector')}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Select a sector'));
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<Select
							showSearch
							placeholder={I18n.get('Sector')}
							optionFilterProp="children"
							filterOption={filterOption}
							loading={sectorsLoading}
							disabled={sectorsLoading}
							dropdownRender={(menu) => (
								<div>
									{menu}
									<Divider style={{ margin: '4px 0' }} />
									<div
										style={{
											display: 'flex',
											flexWrap: 'nowrap',
											padding: 8
										}}
									>
										<Input
											style={{
												flex: 'auto',
												borderRadius: '7px'
											}}
											value={nameSector}
											onChange={onNameChange}
										/>
										<Button
											style={{
												flex: 'none',
												padding: '8px',
												display: 'block',
												cursor: 'pointer'
											}}
											onClick={createSector}
											type="link"
											loading={sectorCreated.isFetching}
											disabled={!nameSector || nameSector.length < 3}
										>
											Add item
										</Button>
									</div>
								</div>
							)}
						>
							{sectors?.map((item, key) => (
								<Select.Option key={key} value={item.id}>
									{item.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item
						label={I18n.get('Workstation')}
						name="workstation"
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Insert a workstation'));
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<Input placeholder={I18n.get('Workstation')} onChange={onFinish} />
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item
						label={I18n.get('Date of colletion')}
						name="collection_date"
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Insert the date of collection'));
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<DatePicker
							// onChange={onFinish}
							format={'DD/MM/YYYY'}
							placeholder={I18n.get('Date of colletion')}
							style={{ width: '100%' }}
						/>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item
						name="range_risk"
						label={I18n.get('Risk range')}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Select a risk band'));
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<Select
							showSearch
							onChange={onFinish}
							optionFilterProp="children"
							filterOption={filterOption}
							loading={rangeRiskListLoading}
							disabled={rangeRiskListLoading}
							placeholder={I18n.get('Risk range')}
						>
							{rangeRiskList?.some((e) => e.standard) && (
								<Select.OptGroup label="Default">
									{rangeRiskList?.map(
										(item, key) =>
											item.standard && (
												<Select.Option key={key} value={JSON.stringify(item)}>
													{item.name}
												</Select.Option>
											)
									)}
								</Select.OptGroup>
							)}
							{rangeRiskList?.some((e) => !e.standard) && (
								<Select.OptGroup label="Custom">
									{rangeRiskList?.map(
										(item, key) =>
											!item.standard && (
												<Select.Option key={key} value={JSON.stringify(item)}>
													{item.name}
												</Select.Option>
											)
									)}
								</Select.OptGroup>
							)}
						</Select>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
}
