import { createContext } from 'react';

const defaultState = {
	visible: false,
	type: '',
	file: '',
	totalFiles: '',
	filter: {},
};

const defaultContext = {
	...defaultState,
	getFiles: () => {},
	setPage: () => {},
	setSize: () => {},
	onDownload: () => {},
	onDelete: () => {},
	onShow: () => {},
	onClose: () => {},
	setFile: () => {},
	setType: () => {},
};

export const FilesContext = createContext(defaultContext);
