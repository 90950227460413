import React from 'react';
import { Row, Col } from 'antd';

import { ButtonsCustom } from './ButtonsCustom';
import { Comment } from '../Comment';
import * as S from './styles';

export interface FooterProps {
	isLoadingPDF: boolean;
	isDownloadingSpreadsheet: boolean;
	isConsolidating: boolean;
	isConsolidated: boolean;
	hasConsolidated: boolean;
	onConsolidatePDF: () => void;
	onDownloadSpreadsheet: () => Promise<void>;
	onDownloadPDF: () => Promise<void>;
	onCommentSave: (comment: string) => void;
}

export function Footer({
	isLoadingPDF,
	isConsolidating,
	isConsolidated,
	hasConsolidated,
	onDownloadPDF,
	onCommentSave,
	onConsolidatePDF,
	onDownloadSpreadsheet,
	isDownloadingSpreadsheet,
}: FooterProps) {
	function handleCommentSave(e: React.FocusEvent<HTMLTextAreaElement, Element>): void {
		onCommentSave(e.currentTarget.value);
	}

	return (
		<S.FooterStyled span={24}>
			<Row align="bottom" justify="space-between" style={{ width: '100%' }} gutter={[0, 50]}>
				<Col span={24}>
					<Comment onChangeComment={handleCommentSave} isDisabled={isConsolidated} />
				</Col>
				<Col span={24}>
					<ButtonsCustom
						isConsolidating={isConsolidating}
						isDownloadingSpreadsheet={isDownloadingSpreadsheet}
						onDownloadSpreadsheet={onDownloadSpreadsheet}
						isLoadingPDF={isLoadingPDF}
						consolidated={isConsolidated}
						hasConsolidated={hasConsolidated}
						onDownloadPDF={onDownloadPDF}
						onConsolidatePDF={onConsolidatePDF}
					/>
				</Col>
			</Row>
		</S.FooterStyled>
	);
}
