import React from 'react';
import { Col, Row } from 'antd';

import { GetRotationReportResponse } from '@/hooks/useGetRotationReport';
import { useRotationReportContext } from '../../../../../context';
import { Title } from '@/components/Typography';
import { ActivityTabContent } from './Content';
import * as S from '../styles';

export function getActivities(file_activities: GetRotationReportResponse['activities']) {
	const group_activities = file_activities.reduce((acc, curr) => {
		const { activity_id, activity } = curr;
		if (!acc[activity_id]) {
			acc[activity_id] = activity.name;
		}
		return acc;
	}, {} as { [key: string]: string });

	return group_activities;
}

export function ActivityTabs() {
	const { rotationReport } = useRotationReportContext();
	const activities = getActivities(rotationReport?.activities as GetRotationReportResponse['activities']);
	const activitiesTabItems = Object.entries(activities).map(([activity_id, activity_name]) => {
		return {
			label: activity_name,
			key: activity_id,
			children: <ActivityTabContent activity_id={activity_id} activity_name={activity_name} />
		};
	});

	return (
		<Row>
			<Col span={24}>
				<S.Card>
					<Title level={5}>Exposure of selected operations</Title>
					<S.CustomTabs
						type="card"
						items={activitiesTabItems}
						defaultActiveKey={Object.keys(activities)[0]}
					/>
				</S.Card>
			</Col>
		</Row>
	);
}
