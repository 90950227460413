import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import type { BaseContextOptional, MtmAggregationValue } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';

type AggregationValuesDTO = BaseContextOptional & {
	enabled?: boolean;
};

const getAggregationValues = async (params: BaseContextOptional): Promise<MtmAggregationValue[]> => {
	const url = `/mtm/aggregation-value`;
	const { data } = await Api.get<MtmAggregationValue[]>(url, { params });

	return data;
};

export const useAggregationValues = ({ organization_id, company_id, enabled: e }: AggregationValuesDTO) => {
	const enabled = !!organization_id && !!company_id && (e ?? true);

	const aggregationValues = useQuery(
		[QUERY_KEYS.GET_AGGREGATION_VALUES, { organization_id, company_id, enabled: e }],
		() => getAggregationValues({ organization_id, company_id }),
		{ enabled }
	);
	return aggregationValues;
};
