import React, { Fragment } from 'react';
import { Row, Col, Button } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Description, Value, Title } from '../styles';
import { documentMaskBRL } from '@/utils/mask';

export function UserInformation({
	user,
	customer,
	onChangePassword
	// onUpdateCustomer,
}) {
	return (
		<Fragment>
			<Row gutter={[8, 16]} style={{ paddingBottom: 10 }}>
				<Col sm={24}>
					<Title>{I18n.get('Informations')}</Title>
				</Col>
				<Col xs={24} sm={8} md={8} lg={6} xxl={6}>
					<Description>
						<label>{I18n.get('Full name')}</label>
					</Description>
					<Value>
						<label>{user.name}</label>
					</Value>
				</Col>
				<Col xs={24} sm={8} md={8} lg={8} xxl={6}>
					<Description>
						<label>{I18n.get('E-mail')}</label>
					</Description>
					<Value>
						<label>{user.email}</label>
					</Value>
				</Col>
				<Col xs={24} sm={8} md={8} lg={6} xxl={6}>
					<Description>
						<label>{I18n.get('User type')}</label>
					</Description>
					<Value>
						<label>{I18n.get(user.role)}</label>
					</Value>
				</Col>
			</Row>
			{customer.data && (
				<Row gutter={[8, 16]} style={{ paddingBottom: 10 }}>
					<Col xs={24} sm={24} md={24} lg={24} xxl={24}>
						<Description>
							<label>{I18n.get('Document')}</label>
						</Description>
						<Value>
							<label>
								{customer.data?.document
									? documentMaskBRL(customer.data.document)
									: I18n.get('Uninformed')}
							</label>
						</Value>
					</Col>

					<Col xs={24} sm={8} md={8} lg={6} xxl={6}>
						<Description>
							<label>{I18n.get('Address')}</label>
						</Description>
						<Value>
							<label>{customer.data.address || I18n.get('Uninformed')}</label>
						</Value>
					</Col>

					<Col xs={24} sm={8} md={8} lg={6} xxl={6}>
						<Description>
							<label>{I18n.get('Street number')}</label>
						</Description>
						<Value>
							<label>{customer.data.street_number || I18n.get('Uninformed')}</label>
						</Value>
					</Col>
					<Col xs={24} sm={8} md={8} lg={6} xxl={6}>
						<Description>
							<label>{I18n.get('Zipcode')}</label>
						</Description>
						<Value>
							<label>{customer.data.zipcode || I18n.get('Uninformed')}</label>
						</Value>
					</Col>
					<Col xs={24} sm={8} md={8} lg={6} xxl={6}>
						<Description>
							<label>{I18n.get('City')}</label>
						</Description>
						<Value>
							<label>{customer.data.city || I18n.get('Uninformed')}</label>
						</Value>
					</Col>
					<Col xs={24} sm={8} md={8} lg={6} xxl={6}>
						<Description>
							<label>{I18n.get('State')}</label>
						</Description>
						<Value>
							<label>{customer.data.state || I18n.get('Uninformed')}</label>
						</Value>
					</Col>
					<Col xs={24} sm={8} md={8} lg={6} xxl={6}>
						<Description>
							<label>{I18n.get('Country')}</label>
						</Description>
						<Value>
							<label>{customer.data.country || I18n.get('Uninformed')}</label>
						</Value>
					</Col>
				</Row>
			)}
			<Row type="flex" gutter={16} style={{ paddingBottom: 25 }}>
				<Col>
					<Button type="primary" onClick={onChangePassword}>
						{I18n.get('Change password')}
					</Button>
				</Col>
				{/* {customer.data && (
					<Col>
						<Button onClick={onUpdateCustomer}>
							{I18n.get('Update informations')}
						</Button>
					</Col>
				)} */}
			</Row>
		</Fragment>
	);
}
