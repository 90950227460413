import styled from 'styled-components';

export const BoardContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 450px;
	height: 100%;
	padding-bottom: 30px;
	padding-inline: 10px;
	border-inline: 1px dashed rgba(89, 89, 89, 0.3);
`;

export const Counter = styled.div`
	width: 25px;
	height: 25px;
	background-color: #58aaf5;
	color: white;
	border-radius: 5px;
	text-align: center;
	font-weight: bold;
	font-size: 0.8rem;
	line-height: 1.6rem;
	position: absolute;
	margin-right: 20px;
	margin-top: 28px;
	right: 0px;
	top: 0px;
	cursor: default;
`;

export const ActionList = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	width: 90%;
	height: 100%;
`;

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 300px;
	width: 100%;
	height: 85px;
	padding: 10px;
	padding-right: 5px;
	padding-bottom: 5px;
	margin-block: 10px;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0px 2px 2px 0px #00000040;
	&:hover {
		box-shadow: 0px 4px 4px 0px #00000040;
	}
	transition: box-shadow ease 0.1s;
	&.overdue {
		background-color: #fff0f0;
	}
	/* &.this-week {
		background-color: #f9ffe6;
	} */
`;

export const DeleteCardBtn = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	color: #b7babd;
	border-radius: 100%;
	cursor: pointer;
	background-color: transparent;
	box-shadow: none;
	border: 0.5px solid #d9d9d9;
	transition: background-color ease 0.15s;
	&:hover {
		// background-color: #ff7875;
		background-color: #ffc3c2;
		color: #a8071a;
	}
`;

export const AddCardBtn = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 30px;
	margin-bottom: 5px;
	font-size: 1rem;
	background-color: #f0f2f5;
	border-radius: 5px;
	cursor: pointer;
	border: none;
	box-shadow: 0px 2px 2px 0px #00000040;
	&:hover {
		box-shadow: 0px 4px 4px 0px #00000040;
	}
	transition: box-shadow ease 0.1s;
`;

export const CardTitle = styled.h3`
	font-size: 14px;
	font-weight: bold;
`;

export const CardDate = styled.p`
	margin: auto;
	margin-right: 5px;
	text-align: right;
	font-size: 12px;
	&.overdue {
		font-weight: bold;
		color: #800000;
	}
`;
