import React from 'react';
import { Col, Form, Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import * as S from '../styles';

interface ReportNameProps {
	fontSize?: string;
	isRequired?: boolean;
	namePlace: string;
}

const { useWatch } = Form;

export function ReportName({ fontSize, isRequired, namePlace }: Readonly<ReportNameProps>) {
	const reportName = useWatch([namePlace, 'report_name']);

	return (
		<Col xs={23}>
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[namePlace, 'report_name']}
				rules={[{ required: true, message: I18n.get('Insert the report name') }]}
				label={<RequiredLabel fontSize={fontSize} title="Report name" isRequired={isRequired} />}
			>
				<S.ContainerInput $borderRadius="8px">
					<Input size="large" value={reportName} placeholder={I18n.get('Report name')} />
				</S.ContainerInput>
			</Form.Item>
		</Col>
	);
}
