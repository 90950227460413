import React from 'react';
import Table, { ColumnsType } from 'antd/lib/table';

import { I18n } from '@aws-amplify/core';

import { useChecklistEIContext } from '../context';

interface DataType {
	key: React.Key;
	data: string;
	value: number;
}

export const TimeCorrectionTable = () => {
	const { tcm, speedFactorValue, addedTime } = useChecklistEIContext();

	const columns: ColumnsType<DataType> = [
		{
			dataIndex: 'data',
			key: 'data',
			align: 'left',
		},
		{
			dataIndex: 'value',
			key: 'value',
			align: 'right',
			render: (value) => <span>{value}%</span>,
		},
	];

	const data: DataType[] = [
		{
			key: '1',
			data: I18n.get('TCM'),
			value: tcm.data.tcm || 0,
		},
		{
			key: '2',
			data: I18n.get('Speed factor'),
			value: speedFactorValue || 0,
		},
		{
			key: '3',
			data: I18n.get('Time correction'),
			value: addedTime.data.addedTime || 0,
		},
	];
	return (
		<Table
			pagination={false}
			showHeader={false}
			columns={columns}
			dataSource={data}
		/>
	);
};
