import React, { ReactNode } from 'react';
import { Button, Col, Divider, Form, Input, Modal, Row, Select } from 'antd';

import { I18n } from '@aws-amplify/core';
import {
	DeleteOutlined,
	EditOutlined,
	ExclamationCircleOutlined,
} from '@ant-design/icons';

import { useManageOrganization } from '../../../context';

import { Create, Delete, Edit, SelectId } from '../styles';
import { DeleteRequest, SectorDTO } from '../../../types';

const { useFormInstance } = Form;

type OptionType = {
	label: string;
	value: string;
};

type SelectSectorProps = {
	sectors: SectorDTO[];
	sectorName: string;
	onSectorNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
};

export const SelectSector: React.FC<SelectSectorProps> = ({
	sectors,
	sectorName,
	onSectorNameChange,
	onEditing,
}) => {
	const { resetFields, setFieldValue } = useFormInstance();

	const {
		organizationId,
		companyId,
		sectorId,
		updatingOrganizationData,
		creatingOrganizationData,
		isEditing,
		handleSectorId,
		handleSector,
		deleteOrganizationData,
		createOrganizationData,
		handleIsEditing,
	} = useManageOrganization();

	async function handleCreateSector() {
		const created = await createOrganizationData({
			organization_id: organizationId,
			company_id: companyId,
			sector_name: sectorName,
		});

		onSectorNameChange('');
		handleSector(created.id, resetFields, setFieldValue);
	}

	function handleEditSector(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();

		const editingSector = sectors.find((sector) => sector.id === sectorId);
		if (editingSector) {
			setFieldValue('edit_sector_name', editingSector.name);
			onSectorNameChange(editingSector.name);
			handleIsEditing(true);
			onEditing(true);
		}
	}

	async function handleDeleteSector(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();

		const body: DeleteRequest = {
			organization_id: organizationId,
			company_id: companyId,
			sector_id: sectorId,
		};

		const content = I18n.get(
			`All data will be deleted, along with their respective uploads, reports and contents. Do you wish to proceed?`
		);

		Modal.confirm({
			title: I18n.get('Warning!'),
			icon: <ExclamationCircleOutlined />,
			content: content,
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: async () => {
				deleteOrganizationData(body).then(() => {
					onSectorNameChange('');
					handleSectorId('');
					resetFields(['sector_id']);
				});
			},
		});
	}

	function filterSector(option: OptionType | undefined, input: string): boolean {
		return (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function handleSelectSector(id: string) {
		handleSector(id, resetFields, setFieldValue);
	}

	function manageSectorDropdown(menu: ReactNode) {
		return (
			<Col span={24}>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<Create>
					<Form.Item name="create_sector_name" style={{ margin: '0' }}>
						<Input
							style={{
								flex: 'auto',
								borderRadius: '7px',
							}}
							value={sectorName}
							onChange={(e) => onSectorNameChange(e.target.value)}
						/>
					</Form.Item>
					<Button
						onClick={handleCreateSector}
						type="link"
						loading={creatingOrganizationData}
						disabled={!sectorName || sectorName.length < 3}
					>
						{I18n.get('Create sector')}
					</Button>
				</Create>
			</Col>
		);
	}

	const sectorsList = sectors.map(({ id, name }) => ({
		label: name,
		value: id,
	}));

	const actions = (
		<Col span={4}>
			<Row align="bottom" justify="space-between">
				<Edit span={12}>
					<Button
						disabled={!sectorId || isEditing}
						size="middle"
						type="text"
						onClick={(e) => handleEditSector(e)}
						icon={<EditOutlined />}
					/>
				</Edit>
				<Delete span={12}>
					<Button
						disabled={!sectorId || isEditing}
						size="middle"
						type="text"
						onClick={(e) => handleDeleteSector(e)}
						icon={<DeleteOutlined />}
					/>
				</Delete>
			</Row>
		</Col>
	);

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<SelectId
						selected={!!sectorId || isEditing}
						name="sector_id"
						label={I18n.get('Select a sector')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please, select a sector'),
							},
						]}
					>
						<Select
							showSearch
							allowClear
							onClear={() => handleSectorId('')}
							options={sectorsList}
							loading={updatingOrganizationData}
							filterOption={(input, option) => filterSector(option, input)}
							onChange={(value: string) => handleSelectSector(value)}
							placeholder={I18n.get('Select a sector')}
							dropdownRender={(menu: ReactNode) =>
								manageSectorDropdown(menu)
							}
						/>
					</SelectId>
				</Col>
				{actions}
			</Row>
		</Col>
	);
};
