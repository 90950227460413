import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import type { Organization } from '@/types';

const getOrganizations = async (): Promise<Organization[]> => {
	const url = `/organization`;
	const { data } = await Api.get<Organization[]>(url);
	return data;
};

export const useGetOrganizations = () => {
	const organizations = useQuery(['organizations'], () => getOrganizations());
	return organizations;
};
