import React from 'react';
import { Col, Form, Select } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useChecklistEIContext } from '../context';

export const WorkstationsSelect = () => {
	const { workstations, handleSelectWorkstation, mapDataToDropdown, filterOption } =
		useChecklistEIContext();

	function handleWorkstationSelect(value: string) {
		handleSelectWorkstation(value);
	}

	const workstationOptions = mapDataToDropdown(workstations.data);

	return (
		<Col sm={12}>
			<Form.Item
				name="workstation"
				label={I18n.get('Workstation')}
				rules={[{ required: true }]}
			>
				<Select
					showSearch
					allowClear
					onChange={(value: string) => handleWorkstationSelect(value)}
					placeholder={I18n.get('Select a workstation')}
					options={workstationOptions}
					filterOption={(input, option) => filterOption(option, input)}
					loading={workstations.gettingWorkstations}
					disabled={
						workstations.data.length === 0 ||
						workstations.errorGettingWorkstations
					}
				/>
			</Form.Item>
		</Col>
	);
};
