import { useQuery } from '@tanstack/react-query';

import { GetRiskRangesDTO, RiskRangeDTO } from '@/types/dto/RiskRange';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { ErrorResponse } from '@/types';
import Api from '@/services/api';

const { GET_RISK_RANGES } = QUERY_KEYS;

async function getRiskRanges(params: GetRiskRangesDTO): Promise<RiskRangeDTO[]> {
	const { data } = await Api.get('/risk-range', { params });
	return data;
}

export const useGetRiskRanges = (params: GetRiskRangesDTO) => {
	const { organization_id, company_id } = params;
	return useQuery<RiskRangeDTO[], ErrorResponse>([GET_RISK_RANGES, params], () => getRiskRanges(params), {
		enabled: !!organization_id && !!company_id
	});
};
