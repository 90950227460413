import React from 'react';
import { Col } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';

export const Title = styled.div`
	display: inline-block;
	margin-right: 5px;
`;

export const Header = () => {
	return (
		<Col sm={24} style={{ paddingBottom: '15px' }}>
			<Title>
				<h2>{I18n.get('New Dashboard')}</h2>
			</Title>
		</Col>
	);
};
