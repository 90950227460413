import styled from 'styled-components';
import { Col, Row } from 'antd';
import { Spin } from 'antd';

export const Container = styled.div`
	background-color: white;
	border-radius: 5px;
	padding: 5px;
	box-shadow: 1px 1px 1px #888888;
	text-align: center;
	font-size: 30px;
	min-height: 4em;
`;

export const Title = styled.h2`
	margin-bottom: -10px;
	font-size: 50%;
`;

export const Value = styled.span`
	font-size: 50%;
	font-weight: bold;
`;

export const Spinner = styled(Spin)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const FrameError = styled.div`
	margin: auto;
	padding: 10px;

	> * {
		color: #fa5c5c;
	}
`;

export const CardContainer = styled.div`
	background-color: white;
	border-radius: 5px;
	padding: 5px;
	box-shadow: 1px 1px 1px #888888;
	text-align: center;
	font-size: 30px;
	min-height: 4em;
	cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
	transition: box-shadow 0.2s ease, border 0.2s ease, color 0.2s ease;

	&:hover {
		box-shadow: ${(props) =>
			props.onClick ? '2px 2px 10px lightgrey' : '2px 2px 5px lightgrey'};
		border: ${(props) => (props.onClick ? '1px solid #5CD3F8' : 'none')};
		color: ${(props) => (props.onClick ? '#5CD3F8' : 'black')};
		${Title} {
			color: ${(props) => (props.onClick ? '#5CD3F8' : 'black')};
		}
	}

	&.active {
		box-shadow: ${(props) =>
			props.onClick ? '2px 2px 10px lightgrey' : '2px 2px 5px lightgrey'};
		border: ${(props) => (props.onClick ? '1px solid #5CD3F8' : 'none')};
		color: ${(props) => (props.onClick ? '#5CD3F8' : 'black')};
		${Title} {
			color: ${(props) => (props.onClick ? '#5CD3F8' : 'black')};
		}
	}
`;

// ----------

export const StyledRow = styled(Row)`
	width: 100%;
	margin: 0px !important;
	padding: 0px !important;
`;

export const StyledColLeft = styled(Col)`
	padding-left: 0px !important;
	padding-right: 10px !important;
	padding-top: 0px !important;
`;

export const StyledColRight = styled(Col)`
	padding-right: 0px !important;
	padding-left: 10px !important;
	padding-top: 0px !important;
`;

export const StyledColInner = styled(Col)`
	padding-left: 10px !important;
	padding-right: 10px !important;
	padding-top: 0px !important;
`;

export const ChartCard = styled.div`
	background-color: white;
	border-radius: 5px;
	padding: 25px;
	box-shadow: 1px 1px 1px #888888;
	text-align: center;
`;

export const PieCard = styled.div`
	background-color: white;
	border-radius: 5px;
	padding-left: 80px;
	padding-right: 80px;
	padding-top: 18px;
	padding-bottom: 30px;
	box-shadow: 1px 1px 1px #888888;
	overflow: auto;
	height: 100%;
`;

export const ListCard = styled.div`
	background-color: white;
	border-radius: 5px;
	box-shadow: 1px 1px 1px #888888;
	overflow: auto;
	height: 100%;
`;

export const StyledDiv = styled.div`
	width: 100%;
	display: flex;
`;
