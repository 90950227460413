import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

export type GetRotationReportActivitiesDTO = {
	organization_id?: string;
	company_id?: string;
	page?: number;
	id?: string;
};

export type RotationReportActivities = {
	id: string;
	sequence: number;
	frequency: number;
	total_time: number;
	activity_id: string;
	activity_name: string;
};

export type RotationReportActivitiesResponse = RotationReportActivities[];

async function getRotationReportActivities(
	params: GetRotationReportActivitiesDTO
): Promise<RotationReportActivitiesResponse> {
	const url = `/rotation/report/${params.id}/activity`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useGetRotationReportActivities = (params: GetRotationReportActivitiesDTO) => {
	const { organization_id, company_id, id } = params;
	return useQuery([QUERY_KEYS.GET_ROTATION_REPORT_ACTIVITIES, params], () => getRotationReportActivities(params), {
		enabled: !!organization_id && !!company_id && !!id
	});
};
