import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import TextArea from 'antd/lib/input/TextArea';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import * as S from '../styles';

interface GenericProps {
	fontSize?: string;
	name: string;
	title: string;
	namePlace: string[];
	placeholder?: string;
	overflow?: boolean;
}

export function DisabledTextInput({ fontSize, namePlace, overflow, name, title, placeholder }: Readonly<GenericProps>) {
	return (
		<S.DisabledTextInputContainer $overflow={overflow} $borderRadius="8px">
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[...namePlace, name]}
				label={<RequiredLabel bold fontSize={fontSize} title={title} isRequired={false} />}
			>
				<TextArea size="large" rows={overflow ? 3 : 1} disabled placeholder={I18n.get(placeholder)} />
			</Form.Item>
		</S.DisabledTextInputContainer>
	);
}
