import { message } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

export function useFile(promise, payload) {
	return useQuery(['files', payload], () => promise(payload));
}

export function useDownloadFile(promise) {
	return useMutation((payload) => promise(payload), {
		onSuccess: ({ data }) => {
			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.rel = 'noopener noreferrer';
			archive.href = data;
			archive.click();
			archive.remove();
		},
		onError: (error) => {
			const description = error?.response?.data?.message || error.message;
			message.error(I18n.get(description));
		},
	});
}

export function useDeleteFile(promise) {
	const queryClient = useQueryClient();

	return useMutation((file) => promise(file.id), {
		onSuccess: () => {
			queryClient.invalidateQueries(['files']);
			message.success(I18n.get('File deleted successfully'));
		},
		onError: (error) => {
			const description = error?.response?.data?.message || error.message;
			message.error(I18n.get(description));
		},
	});
}
