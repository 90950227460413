import React from 'react';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { VideoThumbnail } from './VideoThumbnail';
import moment from 'moment';

const Wrapper = styled.div`
	border: 1px solid #c4c4c4;
	margin: 40px 0;
	display: flex;
`;

const Text = styled.p`
	padding: 15px;
	margin-bottom: 0;
`;

export function JobInformation() {
	const organization = useSelector(
		(state) => state.organization.organization
	);
	const company = useSelector((state) => state.organization.company);
	const collection_date = useSelector(
		(state) => state.checklist.collection_date
	);
	const sector = useSelector((state) => state.checklist.sector);
	const line = useSelector((state) => state.checklist.line);
	const workstation = useSelector((state) => state.checklist.workstation);

	return (
		<Wrapper>
			<Text>
				<strong>{I18n.get('Company')}:</strong> {organization?.name}
				<br />
				<strong>{I18n.get('Industrial Site')}:</strong> {company?.name}
				<br />
				<strong>{I18n.get('Cost center')}:</strong> {sector?.name}
				<br />
				<strong>{I18n.get('Production line')}:</strong> {line?.name}
				<br />
				<strong>{I18n.get('Workstation')}:</strong> {workstation?.name}
				<br />
				<strong>{I18n.get('Date of colletion')}:</strong>{' '}
				{moment(collection_date).format('DD/MM/YYYY')}
			</Text>
			<VideoThumbnail />
		</Wrapper>
	);
}
