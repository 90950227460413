import styled from 'styled-components';
import { Spin } from 'antd';

export const Container = styled.div`
	background-color: white;
	border-radius: 5px;
	padding: 5px;
	box-shadow: 1px 1px 1px #888888;
	text-align: center;
	font-size: 30px;
	min-height: 4em;
`;

export const Title = styled.h2`
	margin-bottom: -10px;
	font-size: 50%;
`;

export const Spinner = styled(Spin)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const FrameError = styled.div`
	margin: auto;
	padding: 10px;

	> * {
		color: #fa5c5c;
	}
`;
