import React, { ReactNode } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CustomTooltip } from './styles';
import { TooltipProps } from 'antd';

interface InfoTooltipProps {
	top?: string;
	left?: string;
	width?: string;
	text?: JSX.Element;
	hasTipIcon?: boolean;
	children?: ReactNode;
	iconLeftPosition?: string;
}

export function InfoTooltip({
	text,
	children,
	top = '0px',
	left = '0px',
	width = '17rem',
	hasTipIcon = false,
	iconLeftPosition = '0px',
	...props
}: InfoTooltipProps & TooltipProps) {
	return (
		<CustomTooltip
			top={top}
			{...props}
			left={left}
			title={text}
			width={width}
			showArrow={false}
			getPopupContainer={(triggerNode) => triggerNode}
		>
			{hasTipIcon && (
				<InfoCircleOutlined
					style={{
						fontSize: '0.7rem',
						position: 'absolute',
						left: `${iconLeftPosition}`
					}}
				/>
			)}
			{children}
		</CustomTooltip>
	);
}
