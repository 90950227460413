import styled from 'styled-components';
import { Col, Button, Tree } from 'antd';

export const FooterStyled = styled(Col)`
	padding: 0 2.5rem;
	margin-bottom: 2.5rem;

	label {
		font-weight: bold;
		font-size: 1.2rem;
	}

	.ant-form-item {
		width: 100%;
	}

	textArea {
		width: 100%;
	}
`;

export const DownloadButton = styled(Button)`
	background-color: #ffffff !important;
	width: fit-content;
	border-radius: 5px;
	border-width: 2px;

	.ant-typography {
		color: inherit;
	}
`;

export const CustomTree = styled(Tree)`
	.ant-tree-treenode {
		margin-top: 10px;
	}
	.ant-tree-title {
		font-weight: 600 !important;
		font-size: 16px;
	}
`;

export const ConsolidatedButton = styled(Button)`
	background-color: #ffffff !important;
	border: 2px solid #2cc852 !important;
	width: fit-content;
	border-radius: 5px;
	height: 40px;

	&:not(:disabled) {
		.ant-typography,
		svg {
			color: #2cc852 !important;
		}
	}

	&:disabled {
		.ant-typography,
		svg {
			color: rgba(0, 0, 0, 0.25) !important;
		}
		color: rgba(0, 0, 0, 0.25) !important;
		border-color: #d9d9d9 !important;
		background: #f5f5f5 !important;
	}

	span:last-child {
		font-size: 15px;
	}
`;

export const DesconsolidatedButton = styled(Button)`
	background-color: #ffffff !important;
	width: fit-content;
	border-radius: 5px;
	border-width: 2px;
	height: 40px;

	.ant-typography {
		color: inherit;
	}
`;
