import Api from '../../services/api';
import { set } from 'lodash';

const Services = {
	loginUser: async (parameters) => {
		try {
			const { email, password } = parameters;

			// const response = await Api.post('/api/v1/auth/session', { email, password });
			const response = await Api.post('/auth/session', {
				email,
				password
			});

			if (response.data && response.status === 200) {
				return response.data;
			}
		} catch (error) {
			!error.response && set(error, 'response.data.message', 'Error: Network Error');
			throw error;
		}
	}
};

export default Services;
