import styled from 'styled-components';
import { Collapse, Row, Col } from 'antd';

export const CollapseStyled = styled(Collapse)`
	.ant-collapse-item {
		.panel-is-disable {
			margin-left: 28px;
			cursor: default !important;
		}

		&.ant-collapse-item-disabled .ant-collapse-expand-icon {
			opacity: 0;
		}

		.ant-collapse-header {
			align-items: center;
			padding: 10px 0;
		}

		.ant-collapse-content-box {
			padding-top: 0px !important;
		}
	}
`;

export const OperationCardRow = styled(Row)`
	background-color: #f8f8f8;
	border-radius: 15px;
	padding: 1rem;
	margin-top: 20px;
	.ant-typography:not(.text-white) {
		color: #262626;
	}
	.ant-btn .ant-typography {
		color: #2f54eb;
	}
	.text-white {
		color: #fff;
	}
	.font-600 {
		font-weight: 600;
	}
`;

export const AggregationValue = styled(Col)`
	border: 1px solid #e6e8e8;
	border-radius: 5px;
	width: 160px;
	height: 40px;
	font-weight: 600;
	display: flex;
	text-align: center;
	margin-top: 10px;
	.item {
		align-items: center;
		text-align: center;
		justify-content: center;
		display: flex;
		width: 80px;
		height: 100%;
	}
	.VAA {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		background-color: #17a93b;
	}
	.SVAA {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		background-color: #efac1e;
	}
	.NVAA {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		background-color: #e80707;
	}

	@media (max-width: 1336px) {
		font-size: 0.75rem;
		width: 88px;
	}
`;

export const MtmOperationCardStyled = styled(Col)`
	border: 2px solid #e6e6e6;
	border-radius: 7px;
	margin-top: 10px;
	background-color: #fff;

	@media (max-width: 1336px) {
		span.VAA,
		span.SVAA,
		span.NVAA {
			width: 50px !important;
		}
		.ant-typography {
			font-size: 0.8rem;
		}
	}

	span.VAA {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 35px;
		width: 85px;
		border-radius: 6px;
		background-color: #17a93b;
		color: #fff;
		font-weight: 600;
	}
	span.SVAA {
		font-weight: 600;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 35px;
		width: 85px;
		border-radius: 6px;
		background-color: #efac1e;
		color: #fff;
		font-weight: 600;
	}
	span.NVAA {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 35px;
		width: 85px;
		border-radius: 6px;
		background-color: #e80707;
		color: #fff;
		font-weight: 600;
	}

	.border-bottom {
		border-bottom: 2px solid #f5f5f5;
	}

	.py-1 {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}
	.px-2 {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.pl-1 {
		padding-left: 0.25rem;
	}
	.pl-2 {
		padding-left: 0.5rem;
	}

	.description {
		width: 85px;
		min-width: 85px;
	}

	.d-flex {
		display: flex;
	}

	.font-600 {
		font-weight: 600;
	}

	.values {
		color: #262626;
	}

	.bg-blue {
		text-align: center;
		font-weight: 600;
		display: flex;
		border-radius: 7px 0px 0px 7px;
		margin: -2px;
		margin-right: 0px;
		align-items: center;
		justify-content: center;
		background-color: #2f54eb;
		color: #fff;
	}
`;
