import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Spinner } from '@/components/SpinnerDeprecated';
import { CreateOrganization } from './CreateOrganization';
import { ManageOrganizationProvider } from './context';
import { Header } from './Header';
import { List } from './List';
import hooks from './hooks';

const { useGetOrganizations } = hooks;

export default function ManageOrganizations() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { isLoading, data, isError, refetch } = useGetOrganizations();

	function handleOnOpen(): void {
		setIsOpen(true);
	}

	function handleOnClose(): void {
		setIsOpen(false);
		refetch();
	}

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <div>{I18n.get('Unable to show results')}</div>;
	}

	return (
		<ManageOrganizationProvider>
			<Row gutter={[0, 10]}>
				<Header onCreate={handleOnOpen} />
				<CreateOrganization visible={isOpen} onClose={handleOnClose} />
				<Col span={24}>
					<List isLoading={isLoading} data={data} isError={isError} />
				</Col>
			</Row>
		</ManageOrganizationProvider>
	);
}
