import React, { useContext } from 'react';
import { Row, Col } from 'antd';

import { WorkerList } from './WorkerList';
import { PieChart, BarChart } from './Chart';
import { AppointmentContext } from './context';

export function Charts() {
	const {
		filter,
		onClickFilter,
		getBuWorkers,
		getCcWorkers,
		getLineWorkers,
		// getWorkersList,
		getErgonomicGrade,
		getWorkstationWorkers,
		getBuErgonomicQuote,
		getCcErgonomicQuote,
		getLineErgonomicQuote,
		getWorkstationErgonomicQuote,
		selectToggle,
	} = useContext(AppointmentContext);

	return (
		<Row gutter={[48, 24]}>
			<Col sm={12}>
				<BarChart
					title="BU"
					attribute="company"
					onClickFilter={onClickFilter}
					onFetch={selectToggle ? getBuWorkers : getBuErgonomicQuote}
					filter={filter}
					minHeight={25}
				/>
			</Col>
			<Col sm={12}>
				<BarChart
					title="Cost center"
					attribute="sector"
					onClickFilter={onClickFilter}
					onFetch={selectToggle ? getCcWorkers : getCcErgonomicQuote}
					filter={filter}
					minHeight={25}
					orientation="y"
				/>
			</Col>
			<Col sm={12}>
				<BarChart
					title="Line"
					attribute="line"
					onClickFilter={onClickFilter}
					onFetch={selectToggle ? getLineWorkers : getLineErgonomicQuote}
					filter={filter}
					minHeight={25}
					orientation="y"
				/>
			</Col>
			<Col sm={12}>
				<BarChart
					title="Workstation"
					attribute="workstation"
					onClickFilter={onClickFilter}
					onFetch={
						selectToggle
							? getWorkstationWorkers
							: getWorkstationErgonomicQuote
					}
					filter={filter}
					minHeight={25}
					orientation="y"
				/>
			</Col>
			<Col sm={12}>
				<PieChart
					title="Ergonomic quote"
					attribute="ergonomic_grade"
					onClickFilter={onClickFilter}
					onFetch={getErgonomicGrade}
					filter={filter}
					minHeight={25}
				/>
			</Col>
			<Col sm={12}>
				<WorkerList filter={filter} minHeight={25} />
			</Col>
		</Row>
	);
}
