import React from 'react';
import * as S from './styles';

interface FillmentSliderProps {
	defaultValue?: number;
	onChange: (value: number) => void;
}

export function FillmentSlider({ defaultValue = 0, onChange }: Readonly<FillmentSliderProps>) {
	return <S.FillmentSlider defaultValue={defaultValue} onChange={onChange} />;
}
