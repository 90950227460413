import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getOrganizations(payload) {
	const { data } = await Api.get('/organization/');
	return data;
}

export function useOrganization(payload = {}) {
	const organizations = useQuery(['organizations', payload], () =>
		getOrganizations(payload)
	);

	return { organizations };
}
