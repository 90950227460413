import React from 'react';
import { Row, Col, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useCreateRotationReportActivity } from '@/hooks/useCreateRotationReportActivity';
import { useUpdateRotationReportExposure } from '@/hooks/useUpdateRotationReportExposure';
import { useRotationReportContext } from '@/components/views/RotationReport/context';
import * as I from './Inputs/index';
import * as S from '../styles';

type SubmitData = {
	cycle_result: {
		frequency?: number;
		activity_id: string;
		break_description?: string;
		break_time?: number;
	};
};

const { useForm, useWatch } = Form;

export function NewActivity() {
	const [form] = useForm();
	const { basicParams, rotationReport } = useRotationReportContext();
	const { mutateAsync: createRotationReportActivity, isLoading } = useCreateRotationReportActivity();
	const { mutateAsync: updateRotationReportExposure } = useUpdateRotationReportExposure();

	const activity_id = useWatch(['cycle_result', 'activity_id'], form);

	async function handleOnFinish({ cycle_result }: SubmitData) {
		const type = activity_id === 'break' ? 'rest' : 'activity';
		await createRotationReportActivity({
			...cycle_result,
			...basicParams,
			id: rotationReport?.id as string,
			type
		});
		await updateRotationReportExposure({
			id: rotationReport?.id,
			...basicParams
		});
		form.resetFields();
	}

	function handleOnSubmit(): void {
		form.submit();
	}

	return (
		<Row>
			<S.NewActivityCard span={24}>
				<Form form={form} onFinish={handleOnFinish}>
					<Row justify="space-between">
						<Col xxl={activity_id !== 'break' ? 14 : 6} sm={activity_id !== 'break' ? 10 : 8}>
							<I.ActivitySelector />
						</Col>
						{activity_id !== 'break' ? (
							<>
								<Col xxl={3} sm={5}>
									<I.Frequency />
								</Col>
								<Col xxl={4} sm={6}>
									<I.ActivityTime />
								</Col>
							</>
						) : (
							<>
								<Col span={8}>
									<I.BreakDescription />
								</Col>
								<Col span={5}>
									<I.BreakTime />
								</Col>
							</>
						)}
						<Col>
							<S.AddButton type="primary" color="primary" loading={isLoading} onClick={handleOnSubmit}>
								<PlusOutlined />
							</S.AddButton>
						</Col>
					</Row>
				</Form>
			</S.NewActivityCard>
		</Row>
	);
}
