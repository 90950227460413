import React, { useState, useEffect } from 'react';
import { getElementAtEvent } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { Filter } from './Filter';
import { Header } from './Header';
import { Painel } from './Painel';
import { Charts } from './Charts';

import Api from '@/services/api';
import { AppointmentContext } from './context';
import { useIsMounted } from '@/hooks/useIsMounted';

const initState = {
	workers: null,
	complaints: null,
	certificates: null,
	pwds: null,
	restricts: null,
	women: null,
	buWorkers: null,
	ccWorkers: null,
	lineWorkers: null,
	workstationWorkers: null,
	ergonomicGrade: null,
	workersList: null,
	isFetchWorkers: true,
	companies: [],
	selectCompanyId: '',
	sectors: [],
	selectSectorId: '',
	lines: [],
	selectLineId: '',
	workstations: [],
	selectWorkstationId: '',
	selectToggle: false,
	isClicked: false,
};

export function Appointment() {
	const isMounted = useIsMounted();

	const [state, setState] = useState(initState);

	const organizationId = useSelector(
		({ organization }) => organization.organization?.id
	);

	const [filter, setFilter] = useState({
		organizationId: organizationId,
	});

	function setSelectCompanyId(value) {
		setState((prev) => ({
			...prev,
			selectCompanyId: value,
			selectSectorId: null,
			selectLineId: null,
		}));
	}

	function setSelectSectorId(value) {
		setState((prev) => ({
			...prev,
			selectSectorId: value,
			selectLineId: null,
		}));
	}

	function setSelectLineId(value) {
		setState((prev) => ({ ...prev, selectLineId: value }));
	}

	function setSelectWorkstationId(value) {
		setState((prev) => ({ ...prev, selectWorkstationId: value }));
	}

	function setSelectToggle() {
		setState((prev) => ({
			...prev,
			selectToggle: !prev.selectToggle,
		}));
	}

	async function onFilterWorker(values) {
		if (!organizationId) return;

		const { company, sector, line, workstation, pcd, gender, restrict } =
			values || {};

		let url = `/worker?`;

		const params = {
			organization_id: organizationId,
			...(company && { company_id: company }),
			...(sector && { sector_id: sector }),
			...(line && { line_id: line }),
			...(workstation && { workstation_id: workstation }),
			...(pcd && { pcd }),
			...(gender && { gender }),
			...(restrict && { restrict }),
		};

		const paramsMounting = new URLSearchParams(params);

		url += paramsMounting.toString();

		try {
			const { data: workersList } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, workersList }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getWorkers(payload) {
		if (!organizationId) return;
		let url = `/appointment/worker?`;
		const params = onPainelURLParams(payload);
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getComplaints(payload) {
		if (!organizationId) return;
		let url = `/appointment/complaint?`;
		const params = onPainelURLParams(payload);
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getCertificates(payload) {
		if (!organizationId) return;
		let url = `/appointment/certificate?`;
		const params = onPainelURLParams(payload);
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getPwds(payload) {
		if (!organizationId) return;
		let url = `/appointment/pcd?`;
		const params = onPainelURLParams(payload);
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getRestricts(payload) {
		if (!organizationId) return;
		let url = `/appointment/restrict?`;
		const params = onPainelURLParams(payload);
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getWomens(payload) {
		if (!organizationId) return;
		let url = `/appointment/women?`;
		const params = onPainelURLParams(payload);
		const { data } = await Api.get(url + params);
		return data;
	}

	function onPainelURLParams(payload) {
		const {
			organizationId,
			companyId,
			sectorId,
			lineId,
			workstationId,
			pcd,
			gender,
			restrict,
			groupBy,
			complaint,
			globalGrade,
			medical_certificate,
		} = payload;

		const params = new URLSearchParams();

		params.append('organization_id', organizationId);
		params.append('is_active', true);

		if (companyId) {
			params.append('company_id', companyId);
		}

		if (sectorId) {
			params.append('sector_id', sectorId);
		}

		if (lineId) {
			params.append('line_id', lineId);
		}

		if (workstationId) {
			params.append('workstation_id', workstationId);
		}

		if (typeof pcd === 'boolean') {
			params.append('pcd', pcd);
		}

		if (gender) {
			params.append('gender', gender);
		}

		if (restrict) {
			params.append('restrict', restrict);
		}

		if (groupBy) {
			params.append('group_by', groupBy);
		}

		if (typeof complaint === 'boolean') {
			params.append('complaint', complaint);
		}

		if (globalGrade) {
			params.append('global_grade', globalGrade);
		}

		if (typeof medical_certificate === 'boolean') {
			params.append('medical_certificate', medical_certificate);
		}

		return params;
	}

	async function getBuWorkers(payload) {
		if (!organizationId) return;
		let url = `/appointment/workers-by-segment?`;
		const params = onPainelURLParams({ ...payload, groupBy: 'company' });
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getBuErgonomicQuote(payload) {
		if (!organizationId) return;
		let url = `/appointment/ergonomic-quote-by-segment?`;
		const params = onPainelURLParams({
			...payload,
			groupBy: 'company',
		});
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getCcWorkers(payload) {
		if (!organizationId) return;
		let url = `/appointment/workers-by-segment?`;
		const params = onPainelURLParams({ ...payload, groupBy: 'sector' });
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getCcErgonomicQuote(payload) {
		if (!organizationId) return;
		let url = `/appointment/ergonomic-quote-by-segment?`;
		const params = onPainelURLParams({
			...payload,
			groupBy: 'sector',
		});
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getLineWorkers(payload) {
		if (!organizationId) return;
		let url = `/appointment/workers-by-segment?`;
		const params = onPainelURLParams({ ...payload, groupBy: 'line' });
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getLineErgonomicQuote(payload) {
		if (!organizationId) return;
		let url = `/appointment/ergonomic-quote-by-segment?`;
		const params = onPainelURLParams({
			...payload,
			groupBy: 'line',
		});
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getWorkstationWorkers(payload) {
		if (!organizationId) return;
		let url = `/appointment/workers-by-segment?`;
		const params = onPainelURLParams({
			...payload,
			groupBy: 'workstation',
		});
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getWorkstationErgonomicQuote(payload) {
		if (!organizationId) return;
		let url = `/appointment/ergonomic-quote-by-segment?`;
		const params = onPainelURLParams({
			...payload,
			groupBy: 'workstation',
		});
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getErgonomicGrade(payload) {
		if (!organizationId) return;
		let url = `/appointment/ergonomic-quote?`;
		const params = onPainelURLParams({
			...payload,
			groupBy: 'workstation',
		});
		const { data } = await Api.get(url + params);
		return data;
	}

	// async function getWorkersList(payload) {
	// 	if (!organizationId) return;
	// 	let url = `/appointment/worker-list/group?`;
	// 	const params = onPainelURLParams(payload);
	// 	const { data } = await Api.get(url + params);
	// 	return data;
	// }

	async function getCompanies(organizationId) {
		if (!organizationId) return;
		let url = `/company/user_list/${organizationId}`;
		try {
			const { data: companies } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, companies }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getSectors(organizationId, companyId) {
		if (!organizationId || !companyId) return;
		let url = `/sector/${organizationId}/${companyId}`;
		try {
			const { data: sectors } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, sectors }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getLines(organizationId, companyId, sectorId) {
		if (!organizationId || !companyId || !sectorId) return;
		let url = `/line/${organizationId}/${companyId}/${sectorId}`;
		try {
			const { data: lines } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, lines }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getWorkstations(organizationId, companyId, sectorId, lineId) {
		if (!organizationId || !companyId || !sectorId || !lineId) return;
		let url = `/workstation/${organizationId}/${companyId}/${lineId}`;
		try {
			const { data: workstations } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, workstations }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	const { selectCompanyId, selectSectorId, selectLineId } = state;

	useEffect(() => {
		getCompanies(organizationId);
	}, [organizationId]);

	useEffect(() => {
		getSectors(organizationId, selectCompanyId);
	}, [selectCompanyId]);

	useEffect(() => {
		getLines(organizationId, selectCompanyId, selectSectorId);
	}, [selectCompanyId, selectSectorId]);

	useEffect(() => {
		getWorkstations(organizationId, selectCompanyId, selectSectorId, selectLineId);
	}, [selectCompanyId, selectSectorId, selectLineId]);

	function updateFilter(params) {
		setFilter((current) => ({ ...current, ...params }));
	}

	function onFormatFilterClick(attribute) {
		const options = {
			company: 'companyId',
			sector: 'sectorId',
			line: 'lineId',
			workstation: 'workstationId',
			ergonomic_grade: 'globalGrade',
		};

		return options[attribute];
	}

	function onClickFilter(e, ref, data, attribute) {
		const [meta] = getElementAtEvent(ref, e);

		if (!meta) return;

		const [item] = data.filter((x, i) => i === meta.index);

		const option = onFormatFilterClick(attribute);

		const value = option === 'globalGrade' ? item.note : item.id;

		if (!context.selectToggle && item.id != null) {
			setFilter((prev) => ({
				...prev,
				organizationId,
				[option]: value,
				// meta.datasetIndex: 2 => High, 1 => Average e 0 => Low
				// Essas condições abaixo são para deixar como no nosso banco:
				// High: 1, Average: 2 e Low: 3
				globalGrade:
					meta.datasetIndex === 2
						? meta.datasetIndex - 1
						: meta.datasetIndex === 1
						? meta.datasetIndex + 1
						: meta.datasetIndex + 3,
			}));
		} else {
			setFilter((prev) => ({
				...prev,
				organizationId,
				[option]: value,
			}));
		}
	}

	function onClearFilter() {
		setFilter({ organizationId });
	}

	useEffect(() => {
		updateFilter({ organizationId });
	}, [organizationId]);

	const context = {
		...state,
		filter,
		updateFilter,
		getWorkers,
		getComplaints,
		getCertificates,
		getPwds,
		getRestricts,
		getWomens,
		getBuWorkers,
		getCcWorkers,
		getLineWorkers,
		getWorkstationWorkers,
		getErgonomicGrade,
		// getWorkersList,
		setSelectCompanyId,
		setSelectSectorId,
		setSelectLineId,
		setSelectWorkstationId,
		onFilterWorker,
		getCompanies,
		getSectors,
		getLines,
		getWorkstations,
		// -----
		onClickFilter,
		onClearFilter,
		getBuErgonomicQuote,
		getCcErgonomicQuote,
		getLineErgonomicQuote,
		getWorkstationErgonomicQuote,
		setSelectToggle,
	};

	return (
		<AppointmentContext.Provider value={context}>
			<Header />
			<Filter />
			<Painel />
			<Charts />
		</AppointmentContext.Provider>
	);
}
