import React from "react";

export const ArrowUp: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="21"
      fill="none"
      viewBox="0 0 16 21"
	  {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="3"
        d="M8 19V3m0 0L2 8.714M8 3l6 5.714"
      ></path>
    </svg>
  );
}

export default ArrowUp;
