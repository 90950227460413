import styled from 'styled-components';
import { Form } from 'antd';

export const ContainerSelect = styled.div<{ $borderRadius?: string }>`
	.ant-select {
		.ant-select-selector {
			border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'};
			height: 48px !important;

			.ant-select-selection-placeholder {
				height: 48px !important;
				line-height: 46px !important;
			}
			.ant-select-selection-item {
				line-height: 46px !important;
			}
			.ant-select-selection-search {
				height: 48px !important;
				input {
					height: 48px !important;
				}
			}
		}
	}
`;

export const ContainerDate = styled.div<{ $borderRadius?: string }>`
	.ant-picker {
		height: 48px !important;
		border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'};
	}
`;

export const CustomFormItem = styled(Form.Item)`
	.ant-form-item-label {
		padding-bottom: 2px;
	}
`;
