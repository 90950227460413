import React from 'react';
import { Row, Col } from 'antd';
import { TableResult } from './TableResult';
import { LineChartRange } from './LineChart';
import { ChartError } from '../Components/ChartError';
import { ChartSpinner } from '../Components/ChartSpinner';

const parseBodyParts = (part) => {
	const options = {
		trunk: 'Trunk',
		neck: 'Neck',
		left_lower_arm: 'Left elbow',
		right_lower_arm: 'Right elbow',
		left_upper_arm: 'Left shoulder',
		right_upper_arm: 'Right shoulder',
		left_knee: 'Left knee',
		right_knee: 'Right knee',
		left_ankle: 'Left ankle',
		right_ankle: 'Right ankle',
		left_hand: 'Left hand',
		right_hand: 'Right hand',
		hip: 'Hip',
	};

	return options[part] || part;
};

const Chart = ({ data, settings, bodyParts }) => {
	return bodyParts.map(
		(part, index) =>
			data[part] && (
				<Row gutter={[0, 20]} key={index} align="middle">
					<Col sm={14}>
						<LineChartRange
							data={data[part]}
							config={settings[part]}
							title={parseBodyParts(part)}
						/>
					</Col>
					<Col sm={10}>
						<TableResult
							anglesPart={data[part]}
							arrayBands={settings[part]}
							part={part}
						/>
					</Col>
				</Row>
			)
	);
};

export const Charts = ({ loading, data, settings, bodyParts, err }) => {
	const [settingsIsFetching, dataIsFetching] = loading;
	const [settingsErr, dataErr] = err;

	return (
		<Col sm={24}>
			{(settingsIsFetching || dataIsFetching) && <ChartSpinner />}
			{(settingsErr || dataErr) && <ChartError />}
			{data && settings && (
				<Chart data={data} settings={settings} bodyParts={bodyParts} />
			)}
		</Col>
	);
};
