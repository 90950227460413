import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useQueryClient } from '@tanstack/react-query';
import { Row, Col, Form, Input, Button, message } from 'antd';

import type { BulkCreateProductDTO, CreateProductDTO } from '@/types/dto/product';
import { useBulkCreateProduct } from '@/hooks/useBulkCreateProducts';
import { useCreateProduct } from '@/hooks/useCreateProduct';
import { useManageOrganization } from '../../../context';
import { QUERY_KEYS } from '@/utils/query-keys';
import { NameInput } from '../styles';

const { useFormInstance } = Form;
const { GET_PRODUCTS } = QUERY_KEYS;

export function CreateProduct() {
	const form = useFormInstance();
	const queryClient = useQueryClient();
	const createProduct = useCreateProduct();
	const bulkCreateProduct = useBulkCreateProduct();
	const { organizationId, companyId, sectorId, lineId, workstationId, isEditing } = useManageOrganization();

	async function handleCreateProduct(payload: CreateProductDTO): Promise<void> {
		try {
			await createProduct.mutateAsync(payload);
			message.success(I18n.get('Product created successfully'));
			queryClient.invalidateQueries([GET_PRODUCTS]);
		} catch (error: any) {
			const err = error?.response?.data?.message || 'Failed to create product';
			message.error(I18n.get(err));
		}
	}

	async function handleBulkCreateProduct(payload: BulkCreateProductDTO): Promise<void> {
		try {
			await bulkCreateProduct.mutateAsync(payload);
			message.success(I18n.get('Product created successfully'));
			queryClient.invalidateQueries([GET_PRODUCTS]);
		} catch (error: any) {
			const err = error?.response?.data?.message || 'Failed to create product';
			message.error(I18n.get(err));
		}
	}

	function getCreateProductPayload(name: string): CreateProductDTO {
		return {
			organization_id: organizationId,
			company_id: companyId,
			sector_id: sectorId,
			line_id: lineId,
			workstation_id: workstationId,
			name: name
		};
	}

	async function handleOnFinish(): Promise<void> {
		const { product_name } = await form.validateFields(['product_name']);
		const payload = getCreateProductPayload(product_name);
		!payload.organization_id || payload.workstation_id === ''
			? await handleBulkCreateProduct(payload)
			: await handleCreateProduct(payload);
	}

	return (
		<Col span={24}>
			<Row align="bottom" gutter={[4, 0]}>
				<Col span={20}>
					<NameInput
						disabled={isEditing}
						name="product_name"
						label={I18n.get('Create product')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please enter a valid name')
							}
						]}
					>
						<Input disabled={isEditing} placeholder={I18n.get('Product name')} />
					</NameInput>
				</Col>
				<Col span={2}>
					<Button
						type="primary"
						htmlType="submit"
						disabled={isEditing}
						onClick={handleOnFinish}
						loading={createProduct.isLoading}
					>
						{I18n.get('Create')}
					</Button>
				</Col>
			</Row>
		</Col>
	);
}
