import { useMutation } from '@tanstack/react-query';
import { IConsolidateChecklist, IChecklistDownload } from './types';
import { ResultService } from './services';

function useConsolidateData() {
	return useMutation((payload: IConsolidateChecklist) =>
		ResultService.consolidateChecklist(payload)
	);
}

function useDesconsolidateData() {
	return useMutation((payload: IConsolidateChecklist) =>
		ResultService.desconsolidateChecklist(payload)
	);
}

function useDownloadChecklist() {
	return useMutation((payload: IChecklistDownload) =>
		ResultService.downloadChecklist(payload)
	);
}

export const Hooks = { useConsolidateData, useDesconsolidateData, useDownloadChecklist };
