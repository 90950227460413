import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { JobInformations } from '../Components/JobInformations';
import { Conclusion } from '../Components/Conclusion';
import { FileData } from '../Components/FileData';
import { Options } from '../Components/Options';
import { Charts } from './Charts';
import Api from '@/services/api';

import {
	collectionDateParse,
	fileDurationParse,
	selectBodyParts,
	fileSizeFormat,
	rangeRiskJSON,
	sectorJSON
} from '@/redux/reports/selectors';

import { setRangeRiskBands, setData } from '@/redux/reports/actions';

const Container = styled(Row)`
	padding: 25px 10px 10px 10px;
`;

export function Diagnostic() {

	const dispatch = useDispatch();

	const [settings, setSettings] = useState(null);
	const [settingsError, setSettingsError] = useState(null);
	const [settingsLoading, setSettingsLoading] = useState(true);

	const [dataChart, setDataChart] = useState(null);
	const [dataError, setDataError] = useState(null);
	const [dataLoading, setDataLoading] = useState(true);

	const sector = useSelector(sectorJSON);
	const rangeRisk = useSelector(rangeRiskJSON);
	const fileSize = useSelector(fileSizeFormat);
	const bodyParts = useSelector(selectBodyParts);
	const fileDuration = useSelector(fileDurationParse);
	const collectionDate = useSelector(collectionDateParse);

	const file = useSelector(state => state.reports.file);

	const { organization_id, company_id } = useSelector(state => ({
		organization_id: state.organization.organization.id,
		company_id: state.organization.company.id
	}), shallowEqual);

	const { companyInfo, workstation } = useSelector(state => ({
		workstation: state.reports.customized.informations.workstation,
		companyInfo: state.reports.customized.informations.company
	}), shallowEqual);

	useEffect(() => {
		if (!settings && rangeRisk) {
			const url = `/customization/risk-ranges/${rangeRisk.id}`;
			Api.get(url)
				.then(response => {
					setSettings(response.data);
					dispatch(setRangeRiskBands(response.data));
				})
				.catch(error => setSettingsError(error))
				.finally(() => setSettingsLoading(false));
		}
	}, [settings, rangeRisk]);

	useLayoutEffect(() => {
		if (!dataChart && organization_id && company_id && file && bodyParts) {
			const body = {
				organization_id: organization_id,
				company_id: company_id,
				file_id: file.id,
				body_parts: bodyParts
			};

			Api.post('/file/angles', body)
				.then(response => {
					setDataChart(response.data);
					dispatch(setData(response.data));
				})
				.catch(err => {
					setDataError(err);
				})
				.finally(() => setDataLoading(false));
		}
	}, [organization_id, company_id, file, bodyParts]);

	const Title = ({ children }) => (
		<Col sm={24} style={{ paddingBottom: '15px' }}>
			<h2>{I18n.get(children)}</h2>
		</Col>
	);

	return (
		<Container gutter={[10, 10]}>
			<Title>Frequency and duration</Title>
			<JobInformations
				sector={sector?.name}
				company={companyInfo}
				workstation={workstation}
				rangeRisk={rangeRisk?.name}
				collectionDate={collectionDate}
			/>
			<FileData originalName={file.original_name} duration={fileDuration} size={fileSize} />
			<Col sm={24} style={{ paddingTop: '20px' }}>
				<h3>{I18n.get('Graphs of selected body parts')}:</h3>
			</Col>
			<Charts
				data={dataChart}
				settings={settings}
				bodyParts={bodyParts}
				err={[dataError, settingsError]}
				loading={[settingsLoading, dataLoading]}
			/>
			<Options />
			<Conclusion />
		</Container>
	);
}
