import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, DatePicker } from 'antd';
import { Text } from '@/components/Typography';
import * as S from '../styles';

const { useWatch } = Form;

interface PeriodProps {
	fontSize?: string;
}

export function Period({ fontSize }: Readonly<PeriodProps>) {
	const startDate = useWatch(['filter', 'created_at_start']);
	const endDate = useWatch(['filter', 'created_at_end']);

	return (
		<Col xs={23}>
			<Row gutter={[20, 0]} align="top">
				<Col span={11}>
					<S.ContainerDate $borderRadius="8px">
						<S.CustomFormItem
							label={<Text style={{ fontSize }}>Period</Text>}
							name={['filter', 'created_at_start']}
							style={{ marginBottom: 0 }}
							labelCol={{ span: 24 }}
							rules={[
								() => ({
									validator() {
										if (!startDate && endDate) {
											return Promise.reject(Error(I18n.get('Select the start date')));
										}

										return Promise.resolve();
									}
								})
							]}
						>
							<DatePicker
								placeholder={I18n.get('Start date')}
								style={{ width: '100%' }}
								format="DD/MM/YYYY"
								size="large"
								allowClear
							/>
						</S.CustomFormItem>
					</S.ContainerDate>
				</Col>
				<Col span={2} style={{ textAlign: 'center', marginTop: 46, padding: 0 }}>
					<Text style={{ fontSize: '16px' }}>until</Text>
				</Col>

				<Col span={11}>
					<S.ContainerDate $borderRadius="8px">
						<S.CustomFormItem
							label={<Text></Text>}
							labelCol={{ span: 24 }}
							style={{ marginBottom: 0 }}
							name={['filter', 'created_at_end']}
							rules={[
								() => ({
									validator() {
										if (moment(endDate).isBefore(startDate) && endDate) {
											return Promise.reject(
												Error(I18n.get('End date cannot be less than the start date'))
											);
										} else if (startDate && !endDate) {
											return Promise.reject(Error(I18n.get('Select the end date')));
										}

										return Promise.resolve();
									}
								})
							]}
						>
							<DatePicker
								placeholder={I18n.get('End date')}
								style={{ width: '100%' }}
								format={'DD/MM/YYYY'}
								size="large"
								allowClear
							/>
						</S.CustomFormItem>
					</S.ContainerDate>
				</Col>
			</Row>
		</Col>
	);
}
