import React, { useEffect } from 'react';
import { Col, Form, Input } from 'antd';
import { I18n } from '@aws-amplify/core';

import type { TableValues } from '.';
import * as S from './styles';

interface SelectOperationProps {
	file: TableValues;
	selectedRowKeys: React.Key[];
}

const { useFormInstance } = Form;

export function TextOperation({ file, selectedRowKeys }: SelectOperationProps) {
	const { setFieldValue } = useFormInstance();
	const hasSelectedRow = selectedRowKeys.find((id) => id === file.id);
	function handleOperationError() {
		return Promise.reject(Error(I18n.get('Provide a activity')));
	}

	useEffect(() => {
		if (file.activity_name) {
			setFieldValue([file.id, 'activity_name'], file.activity_name);
		}
	}, [file.activity_name, setFieldValue, file.id]);

	return (
		<S.ContainerInput $borderRadius="8px">
			<Form.Item style={{ display: 'none' }} name={[file.id, 'activity_id']} />
			<Col span={24}>
				<Form.Item
					style={{ margin: 0 }}
					name={[file.id, 'activity_name']}
					rules={[
						({ getFieldValue }) => ({
							validator() {
								const value = getFieldValue([file.id, 'activity_name']);
								if (hasSelectedRow && !value && !file?.activity_id) {
									return handleOperationError();
								}
							}
						})
					]}
				>
					<Input
						size="large"
						onBlur={(e) => {
							setFieldValue([file.id, 'activity_name'], e.target.value);
						}}
						disabled={!hasSelectedRow || !!file.activity_id}
						placeholder={I18n.get('Operation')}
					/>
				</Form.Item>
			</Col>
		</S.ContainerInput>
	);
}
