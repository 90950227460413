import { Button, Col, Tabs, Tooltip } from 'antd';
import styled from 'styled-components';

export const CustomButton = styled(Button)<{ $borderRadius?: string }>`
	border-radius: ${({ $borderRadius }) => $borderRadius ?? '7px'};
	background-color: ${({ type }) => (type === 'primary' ? '#2f54eb' : null)};
	width: 100%;
	svg:hover {
		fill: #2f54eb;
	}
`;

export const CustomTabs = styled(Tabs)`
	margin-top: 30px;

	& > .ant-tabs-nav::before {
		border-bottom: none;
	}

	.ant-tabs-tab + .ant-tabs-tab {
		margin-left: 15px !important;
	}

	.ant-tabs-tab {
		&.ant-tabs-tab-active {
			background-color: #2f54eb;
			border: 1px solid #2f54eb !important;
			border-radius: 9px !important;
			.ant-tabs-tab-btn {
				font-weight: 600;
				color: #fff !important;
			}
		}
		&:not(.ant-tabs-tab-active) {
			border: 1px solid #e6e6e6;
			background-color: #fff;
			border-radius: 9px !important;
			.ant-tabs-tab-btn {
				font-weight: 600;
				color: #262626 !important;
			}
		}
	}
`;

export const ChartLegendBadge = styled.span<{ $color: string }>`
	background-color: ${({ $color }) => $color};
	width: 24px;
	height: 24px;
	display: block;
	border-radius: 6px;
	opacity: 0px;
`;

export const ChartLegend = styled.span`
	margin: 30px 10px 0px 10px;
	display: flex;

	.ant-typography {
		margin-left: 5px;
	}
`;

// export const Card = styled(Col)`
// 	background-color: #fff;
// 	border-radius: 30px;
// 	padding: 30px;
// `;

export const NewActivityCard = styled(Col)`
	background-color: #eaeefd;
	border-radius: 18px;
	padding: 20px 40px;
	margin-top: 20px;

	.d-flex {
		display: flex;
		align-items: center;
	}

	.overflow-visible {
		overflow: visible;
		.ant-form-item-row,
		.ant-form-item-label {
			overflow: visible;
		}
	}
`;

export const ContainerSelect = styled.div<{ $borderRadius?: string }>`
	.ant-select {
		.ant-select-selector {
			border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'};
		}
	}
`;

export const ContainerInput = styled.div<{ $borderRadius?: string }>`
	.ant-input {
		border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'} !important;
	}
`;

export const ContainerInputNumber = styled.div<{ $borderRadius?: string }>`
	.ant-input-number {
		border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'} !important;
	}
	.ant-input-number-handler-wrap {
		border-radius: ${({ $borderRadius }) => ($borderRadius ? `0 ${$borderRadius} ${$borderRadius} 0` : null)};
	}
`;

export const CenteredEditableInput = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	.ant-form-item {
		margin-bottom: 0px;
	}
`;

export const AddButton = styled(Button)`
	background-color: #2f54eb;
	width: 42px;
	height: 42px;
	border-radius: 42px;
	font-size: 24px;
	padding: 0px;
	align-items: center;
	margin-top: 38px;
	svg {
		fill: #fff;
	}

	.anticon {
		margin-left: 0px !important;
	}
`;

export const ContainerInfiniteScroll = styled.div`
	height: 250px;
	max-height: 250px;
	overflow: auto;

	span.ant-typography {
		width: 100%;
		display: block;
		font-size: 18px;

		&.bold {
			font-weight: 600;
		}
		&.text-center {
			text-align: center;
		}
	}

	.infinite-scroll-component__outerdiv {
		width: 100%;
	}

	::-webkit-scrollbar {
		width: 5px;
		max-height: 100px;
	}
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	::-webkit-scrollbar-thumb {
		background-color: #555;
		border-radius: 5px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #2f54eb;
	}
`;

export const CustomTooltip = styled(Tooltip)`
	.ant-tooltip {
		top: -50px !important;
		left: 100% !important;
		padding-bottom: 0px !important;
		background-color: rgba(255, 255, 255, 0.6);
	}

	.ant-tooltip-inner {
		width: 156px;
		color: #262626;
		border: 1px solid #262626;
		backdrop-filter: blur(4px);
		border-radius: 10px 10px 10px 0;
		background-color: transparent;
		font-size: 11px;
		font-weight: 600;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}
`;

export const GoalContainer = styled.span`
	position: absolute;
	right: -10px;
	top: -5px;
	text-align: right;
`;

export const Card = styled.div`
	padding: 1.8rem;
	border-radius: 1rem;
	background-color: white;
`;
