import { createContext } from 'react';
import { State, Context } from './types/index';

const initState: State = {
	organizationId: '',
	filter: {
		organizationId: '',
		segment: '',
	},
};

const defaultContext = {
	...initState,
	setFilter: (payload: string) => {},
	onClickFilter: () => {},
};

const AnalyticsDashboardContext = createContext<Context>(defaultContext);

export { AnalyticsDashboardContext };
