import Api from '@/services/api';

import { WorstScoreDTO } from '../hooks/types/response';
import {
	GetCompaniesWorstScoreRequest,
	GetLinesRequest,
	GetSectorsWorstScoreRequest,
	GetWorkstationsRequest,
} from '../hooks/types/request';

export class TreemapServices {
	public async getCompanies({
		organization_id,
		company_id,
		filter,
	}: GetCompaniesWorstScoreRequest): Promise<WorstScoreDTO[]> {
		const url = `/company/mosaic/${organization_id}?company_id=${company_id}&filter=${filter}`;
		const { data } = await Api.get<WorstScoreDTO[]>(url);
		return data;
	}

	public async getSectors({
		organization_id,
		company_id,
		filter,
	}: GetSectorsWorstScoreRequest): Promise<WorstScoreDTO[]> {
		const url = `/sector/mosaic/${company_id}?organization_id=${organization_id}&filter=${filter}`;
		const { data } = await Api.get<WorstScoreDTO[]>(url);
		return data;
	}

	public async getLines({
		organization_id,
		company_id,
		sector_id,
		filter,
	}: GetLinesRequest): Promise<WorstScoreDTO[]> {
		const url = `/line/mosaic/${sector_id}?organization_id=${organization_id}&company_id=${company_id}&filter=${filter}`;
		const { data } = await Api.get<WorstScoreDTO[]>(url);
		return data;
	}

	public async getWorkstations({
		organization_id,
		company_id,
		line_id,
		filter,
	}: GetWorkstationsRequest): Promise<WorstScoreDTO[]> {
		const url = `/workstation/mosaic/${line_id}?organization_id=${organization_id}&company_id=${company_id}&filter=${filter}`;
		const { data } = await Api.get<WorstScoreDTO[]>(url);
		return data;
	}
}
