import React from 'react';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { GlobalClassification } from './GlobalClassification';
import { Logo } from './Logo';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
`;

const Title = styled.h1`
	flex-grow: 1;
	text-align: center;
	margin-right: 60px;
	margin-bottom: 0;
	font-weight: bold;
	font-size: 24px;
`;

export function Header({ globalGrade }) {
	return (
		<Wrapper>
			<Logo />
			<GlobalClassification value={globalGrade} />
			<Title>{I18n.get('CNHI Ergonomics System')}</Title>
		</Wrapper>
	);
}
