import { Col } from 'antd';
import styled from 'styled-components';

export const MtmItem = styled(Col)`
	border-radius: 6px;

	.item-border {
		height: calc(100% - 20px);
		border: 2px solid #e6e6e6;
		border-radius: 6px;
	}

	.table-item,
	.labels .ant-col {
		&:not(.no-center):not(.buttons) {
			text-align: center;
			justify-content: center;
		}
	}

	.labels {
		margin-bottom: 10px;
	}

	span.SVAA,
	span.NVAA,
	span.VAA {
		width: calc(100% - 16px);
		height: 36px;
		border-radius: 6px;
		color: #fff;
		display: flex;
		font-weight: bold;
		align-items: center;
		justify-content: center;
	}

	span.SVAA {
		background-color: #efac1e;
	}
	span.NVAA {
		background-color: #e80707;
	}

	span.VAA {
		background-color: #17a93b;
	}

	.table-item.seconds span {
		height: 36px;
		display: flex;
		border-radius: 6px;
		align-items: center;
		justify-content: center;
		width: calc(100% - 24px);
		color: #2f54eb !important;
		border: 1px solid #2f54eb !important;
		background-color: #eaeefd !important;
	}

	.table-item {
		min-height: 44px;
		display: flex;
		align-items: center;

		&:not(.no-border-left)::after {
			content: '';
			position: absolute;
			top: 8px;
			bottom: 8px;
			border-radius: 3px;
			transform: translateX(-50%);
			left: 0px;
			width: 2px;
			height: calc(100% - 14px);
			border-radius: 6px;
			background-color: #ececec;
		}
	}
	.bold {
		font-weight: bold;
	}
	.buttons {
		display: flex;
		justify-content: space-evenly;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}
	.primary {
		color: #2f54eb;
		font-size: 25px;
		cursor: pointer;
		&:hover,
		&:focus {
			color: #2f54ebfa;
		}
	}
	.error {
		color: #e74150;
		font-size: 25px;
		cursor: pointer;
		&:hover,
		&:focus {
			color: #e80707;
		}
	}
	.bg-red {
		text-align: center;
		justify-content: center;
		background-color: #e80707;
		.ant-typography {
			color: #fff !important;
		}
	}
	.bg-blue {
		text-align: center;
		justify-content: center;
		background-color: #2f54eb;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		.ant-typography {
			color: #fff !important;
		}
	}
`;

export const AggregationValue = styled.div`
	div.label {
		height: 42px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		border-radius: 10px 10px 0 0;
		border-bottom-width: 0px;
		span {
			color: #fff !important;
		}
		&.bg-blue {
			background-color: #2f54eb;
			border: 2px solid #2f54eb;
		}
		&.VAA {
			background-color: #17a93b;
			border: 2px solid #17a93b;
		}
		&.SVAA {
			background-color: #efac1e;
			border: 2px solid #efac1e;
		}
		&.NVAA {
			background-color: #e80707;
			border: 2px solid #e80707;
		}
	}
	div.value {
		color: #262626;
		display: flex;
		align-items: center;
		height: 48%;
		justify-content: center;
		font-weight: bold;
		border-top-width: 0px;
		border: 2px solid #d9d9d9;
		border-radius: 0 0 10px 10px;
	}
	&.total-time {
		max-width: 180px;
		min-width: 180px;
	}
	@media (max-width: 1400px) {
		margin-top: 1rem;
		&.total-time {
			margin-left: 30px;
			max-width: 120px;
			min-width: 126px;
		}

		max-width: 105px;
		min-width: 105px;
	}
	margin-top: 6rem;
	margin-left: 20px;
	max-width: 180px;
	min-width: 150px;
	border-radius: 10px;
	height: 90px;
`;
