import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import { Cancel, Confirm, NameInput } from '../styles';

const { useFormInstance } = Form;

type EditSectorProps = {
	sectorName: string;
	onSectorNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
};

export const EditSector: React.FC<EditSectorProps> = ({
	sectorName,
	onSectorNameChange,
	onEditing,
}) => {
	const [editingName] = useState(sectorName);
	const {
		updateOrganizationData,
		treatFieldValidation,
		handleIsEditing,
		companyId,
		organizationId,
		sectorId,
	} = useManageOrganization();

	const { setFieldValue, validateFields } = useFormInstance();

	function handleSectorNameChange(name: string) {
		onSectorNameChange(name);
	}

	function handleCancel() {
		onSectorNameChange('');
		handleIsEditing(false);
		onEditing(false);
		setFieldValue('sector_id', sectorId);
	}

	async function handleUpdateSector(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();
		try {
			await validateFields(['edit_sector_name']);

			updateOrganizationData({
				organization_id: organizationId,
				company_id: companyId,
				sector_id: sectorId,
				name: sectorName,
			}).then(({ id }) => {
				onSectorNameChange('');
				handleIsEditing(false);
				onEditing(false);
				setFieldValue('sector_id', id);
				setFieldValue('edit_sector_name', '');
			});
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<NameInput
						name="edit_sector_name"
						label={I18n.get('Edit sector')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please, enter a valid sector name'),
							},
						]}
					>
						<Input
							style={{
								flex: 'auto',
								borderRadius: '7px',
								border: '2px solid #000',
							}}
							value={sectorName}
							onChange={(e) => handleSectorNameChange(e.target.value)}
						/>
					</NameInput>
				</Col>
				<Col span={4}>
					<Row align="bottom" justify="space-between">
						<Cancel span={12}>
							<Button
								disabled={!companyId || !sectorId}
								size="middle"
								type="text"
								onClick={() => handleCancel()}
								icon={<CloseOutlined />}
							/>
						</Cancel>
						<Confirm span={12}>
							<Button
								disabled={
									!companyId ||
									!sectorId ||
									!sectorName ||
									editingName === sectorName
								}
								size="middle"
								type="text"
								onClick={(e) => handleUpdateSector(e)}
								icon={<CheckOutlined />}
							/>
						</Confirm>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
