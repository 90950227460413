import React from 'react';
import { Form, Input } from 'antd';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import * as S from '../../styles';

export function ActivityTime() {
	return (
		<S.ContainerInput $borderRadius="8px">
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={['cycle_result', 'activity_time']}
				label={<RequiredLabel fontSize={'16px'} title="Operation time" isRequired={false} />}
			>
				<Input size="large" disabled placeholder="0h 0m 0s" style={{ textAlign: 'center' }} />
			</Form.Item>
		</S.ContainerInput>
	);
}
