import React from 'react';
import { Row, Col, message } from 'antd';
import { I18n } from '@aws-amplify/core';

import { RotationReportFilesResponse } from '@/hooks/useGetRotationReportFiles';
import { useApplicationContext } from '@/context/application';
import { Paragraph, Title } from '@/components/Typography';
import { useDownloadFile } from '@/hooks/useDownloadFile';
import { ModalBorder } from '@/components/ui';
import { CardFile } from '../CardFile';

interface FileModalProps {
	files: RotationReportFilesResponse[];
	open?: boolean;
	isLoading?: boolean;
	onClose: () => void;
}

export function FileModal({ open = false, files, onClose }: FileModalProps) {
	const { organization, company } = useApplicationContext();
	const downloadFile = useDownloadFile();

	function handleOnCancel() {
		onClose();
	}

	function handleDownloadFile({ id, original_name }: { id: string; original_name: string }) {
		try {
			downloadFile.mutateAsync({
				id,
				original_name,
				organization_id: organization?.id as string,
				company_id: company?.id as string
			});
			message.success(I18n.get('File downloaded successfully'));
		} catch (error: any) {
			const err = error?.response?.data?.message || 'Failed to download file';
			message.error(I18n.get(err));
		}
	}

	return (
		<ModalBorder
			open={open}
			width={'90vw'}
			footer={false}
			$borderSize="15px"
			onCancel={handleOnCancel}
			style={{ maxWidth: '700px' }}
		>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Title level={3} style={{ textAlign: 'center' }}>
						Files
					</Title>
				</Col>
				<Col>
					<Paragraph>The following files were used in the analysis</Paragraph>
				</Col>
				<Col span={24} style={{ marginTop: '-7px' }}>
					<Row gutter={[0, 10]}>
						{files?.map(({ activity_id, activity_name, original_name, file_id }) => (
							<CardFile
								id={file_id}
								key={activity_id}
								loading={downloadFile.isLoading}
								original_name={original_name}
								onDownloadFile={handleDownloadFile}
								activity={activity_name}
							/>
						))}
					</Row>
				</Col>
			</Row>
		</ModalBorder>
	);
}
