import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';

export type DownloadRotationReportDTO = {
	id: string;
	locale?: string;
	company_id?: string;
	organization_id?: string;
	sections: string[];
};

export type DownloadRotationReportResponse = {
	url: string;
};

async function downloadRotationReport({ id, ...params}: DownloadRotationReportDTO): Promise<DownloadRotationReportResponse> {
	const url = `/rotation/report/${id}/pdf`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useDownloadRotationReport = () => {
	return useMutation(downloadRotationReport);
};
