const Types: Record<string, string> = {
	SET_DATA: 'SET_DATA',
	SET_FILE: 'SET_FILE',
	NEXT_STEP: 'NEXT_STEP',
	PREV_STEP: 'PREV_STEP',
	SET_OPTIONS: 'SET_OPTIONS',
	CLEAR_REPORTS: 'CLEAR_REPORTS',
	VALIDATION_STEP: 'VALIDATION_STEP',
	SET_RISK_DEGREE: 'SET_RISK_DEGREE',
	SET_RECOVERY_REPORT: 'SET_RECOVERY_REPORT',
	SET_RANGE_RISK_BANDS: 'SET_RANGE_RISK_BANDS',
	UPDATE_BODY_PART_CUSTOMIZED: 'UPDATE_BODY_PART_CUSTOMIZED',
	UPDATE_PARAMETERS_CUSTOMIZED: 'UPDATE_PARAMETERS_CUSTOMIZED',
	UPDATE_INFORMATIONS_CUSTOMIZED: 'UPDATE_INFORMATIONS_CUSTOMIZED',
	SET_FREQUENCY_AND_DURATION_RANGERISK: 'SET_FREQUENCY_AND_DURATION_RANGERISK'
};

export default Types;
