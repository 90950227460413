import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Progress } from 'antd';
import { Draggable } from 'react-beautiful-dnd';
import { DeleteOutlined } from '@ant-design/icons';

import { CardContainer, CardTitle, CardDate, DeleteCardBtn } from './styles';

export function ActionCard({
	actionPlan,
	index,
	onDelete,
	onEdit,
	calcProgress,
}) {
	let dateStatus = '';
	let deadlineDate = moment(actionPlan.deadline);

	if (actionPlan.board !== 'DONE') {
		if (deadlineDate.startOf('day') < moment().startOf('day')) {
			dateStatus = 'overdue';
		} else if (deadlineDate < moment().add(1, 'week')) {
			dateStatus = 'this-week';
		}
	}

	function onClick() {
		onEdit(actionPlan);
	}

	function truncateCardTitle(str, limit) {
		return str.length > limit ? str.slice(0, limit - 1) + '...' : str;
	}

	return (
		<Draggable draggableId={actionPlan.id} index={index}>
			{({ innerRef, draggableProps, dragHandleProps }) => (
				<CardContainer
					ref={innerRef}
					onClick={onClick}
					{...draggableProps}
					{...dragHandleProps}
					className={dateStatus}
				>
					<Row justify="space-between" style={{ height: '40px' }}>
						<Col span={21}>
							<CardTitle>
								{truncateCardTitle(actionPlan.description, 60)}
							</CardTitle>
						</Col>
						<Col span={2}>
							<DeleteCardBtn onClick={onDelete}>
								<DeleteOutlined />
							</DeleteCardBtn>
						</Col>
					</Row>
					<Row justify="space-between" align="middle">
						<Col span={12} xl={8} xxl={12}>
							<Progress
								percent={calcProgress(actionPlan.tasks)}
								size="small"
							/>
						</Col>
						<Col span={10} xl={14} xxl={10}>
							<CardDate className={dateStatus}>
								{I18n.get('Finish by: ')}
								{deadlineDate.format('DD/MM/YY')}
							</CardDate>
						</Col>
					</Row>
				</CardContainer>
			)}
		</Draggable>
	);
}
