import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, message } from 'antd';

import { useRotationReportContext } from '@/components/views/RotationReport/context';
import { useUpdateRotationReport } from '@/hooks/Rotation/useUpdateRotationReport';
import { useCreateRotationReport } from '@/hooks/Rotation/useCreateRotationReport';
import { CreateRotationReportDTO, UpdateRotationReportDTO } from '@/types/dto';
import { Button } from '@/components/ui/Button';
import { QUERY_KEYS } from '@/utils/query-keys';
import { queryClient } from '@/store/query';

const { useFormInstance } = Form;
const { GET_ROTATION_REPORTS, GET_ROTATION_REPORT } = QUERY_KEYS;

export function FooterButtons() {
	const history = useHistory();
	const { validateFields, getFieldsValue, setFieldValue } = useFormInstance();
	const { handleNextStep, rotationReport } = useRotationReportContext();

	const createRotationReport = useCreateRotationReport();
	const updateRotationReport = useUpdateRotationReport();

	async function createReport(value: CreateRotationReportDTO): Promise<void> {
		const { id } = await createRotationReport.mutateAsync(value);
		queryClient.invalidateQueries([GET_ROTATION_REPORT]);
		queryClient.invalidateQueries([GET_ROTATION_REPORTS]);
		history.replace(`/ehs/rotation/report/${id}`);
	}

	async function updateReportData(values: UpdateRotationReportDTO): Promise<void> {
		await updateRotationReport.mutateAsync(values);
		queryClient.invalidateQueries([GET_ROTATION_REPORT]);
		queryClient.invalidateQueries([GET_ROTATION_REPORTS]);
	}

	function getFormValues() {
		const values = getFieldsValue(true);
		return values;
	}

	async function handleOnNextStep(): Promise<void> {
		try {
			await validateFields();
			const values = await getFormValues();
			const payload = values.informations;
			rotationReport?.id
				? await updateReportData({
						...payload,
						name: payload.report_name,
						rotation_report_id: rotationReport?.id
				  })
				: await createReport({
						...payload,
						name: payload.report_name
				  });
			setFieldValue(['filter', 'select_button'], 'all_files');
			handleNextStep();
		} catch (error: any) {
			let err = error?.response?.data?.message || 'Oops... Something went wrong';
			if (error?.errorFields?.length) {
				err = 'Fill the required fields';
			}
			message.error(I18n.get(err));
		}
	}

	const isLoading = createRotationReport.isLoading || updateRotationReport.isLoading;

	return (
		<Row justify="center" align="middle" gutter={[20, 0]}>
			<Col>
				<Button size="middle" type="primary" htmlType="submit" loading={isLoading} onClick={handleOnNextStep}>
					{I18n.get('Next')}
				</Button>
			</Col>
		</Row>
	);
}
