import React from 'react';
import { Row, Col } from 'antd';

import { ButtonsCustom, onDownloadPdfFunction } from './ButtonsCustom';
import { DividerCustom } from '@/components/ui/DividerCustom';
import { Comment } from '../Comment';
import * as S from './styles';

export interface FooterProps {
	isLoadingPDF: boolean;
	onDownloadPDF: (params: onDownloadPdfFunction) => Promise<void>;
	onCommentSave: (comment: string) => void;
}

export function Footer({ isLoadingPDF, onDownloadPDF, onCommentSave }: FooterProps) {
	function handleCommentSave(e: React.FocusEvent<HTMLTextAreaElement, Element>): void {
		onCommentSave(e.currentTarget.value);
	}

	return (
		<S.FooterStyled span={24}>
			<Row align="bottom" justify="space-between" style={{ width: '100%' }} gutter={[0, 50]}>
				<DividerCustom span={24} color="#E6E8E8" thickness="1px" margin={'60px 0 20px 0'} />
				<Col span={24}>
					<Comment onChangeComment={handleCommentSave} />
				</Col>
				<Col span={24}>
					<ButtonsCustom isLoadingPDF={isLoadingPDF} onDownloadPDF={onDownloadPDF} />
				</Col>
			</Row>
		</S.FooterStyled>
	);
}
