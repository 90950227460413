import React from 'react';
import { Alert } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';

const Container = styled.div`
	padding: 0 0 15px 0;
`;

interface AlertsProps {
	message?: string;
}

export function Alerts({ message = '' }: AlertsProps) {
	return (
		<Container>
			<Alert showIcon type="error" style={{ textAlign: 'center' }} message={I18n.get(message)} />
		</Container>
	);
}
