import React, { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import {
	IdcardOutlined,
	FrownOutlined,
	SnippetsOutlined,
	StopOutlined,
	WarningOutlined,
	WomanOutlined
} from '@ant-design/icons';
import { Card } from './Card';
import { AppointmentContext } from './context';

export function Painel() {
	const { filter, getWorkers, getComplaints, getCertificates, getPwds, getRestricts, getWomens, updateFilter } =
		useContext(AppointmentContext);

	const [clicked, setClicked] = useState([false, false, false, false, false]);

	let complaint = null,
		medical_certificate = null,
		pcd = null,
		restrict = null,
		gender = null;

	function onClickCard(title) {
		if (title === 'complaints') {
			if (clicked[0]) {
				complaint = null;
				setClicked((prev) => ({
					...prev,
					[0]: false
				}));
				updateFilter({
					...(!complaint && { complaint })
				});
			}

			if (!clicked[0]) {
				complaint = true;
				setClicked((prev) => ({
					...prev,
					[0]: true
				}));
				updateFilter({
					...(complaint && { complaint })
				});
			}
		}
		if (title === 'certificates') {
			if (clicked[1]) {
				medical_certificate = null;
				setClicked((prev) => ({
					...prev,
					[1]: false
				}));
				updateFilter({
					...(!medical_certificate && { medical_certificate })
				});
			}

			if (!clicked[1]) {
				medical_certificate = true;
				setClicked((prev) => ({
					...prev,
					[1]: true
				}));
				updateFilter({
					...(medical_certificate && { medical_certificate })
				});
			}
		}
		if (title === 'pwds') {
			if (clicked[2]) {
				pcd = null;
				setClicked((prev) => ({
					...prev,
					[2]: false
				}));
				updateFilter({
					...(!pcd && { pcd })
				});
			}

			if (!clicked[2]) {
				pcd = true;
				setClicked((prev) => ({
					...prev,
					[2]: true
				}));
				updateFilter({
					...(pcd && { pcd })
				});
			}
		}
		if (title === 'restricts') {
			if (clicked[3]) {
				restrict = null;
				setClicked((prev) => ({
					...prev,
					[3]: false
				}));
				updateFilter({
					...(!restrict && { restrict })
				});
			}

			if (!clicked[3]) {
				restrict = 'BOTH';
				setClicked((prev) => ({
					...prev,
					[3]: true
				}));
				updateFilter({
					...(restrict && { restrict })
				});
			}
		}
		if (title === 'women') {
			if (clicked[4]) {
				gender = null;
				setClicked((prev) => ({
					...prev,
					[4]: false
				}));
				updateFilter({
					...(!gender && { gender })
				});
			}

			if (!clicked[4]) {
				gender = 'FEMALE';
				setClicked((prev) => ({
					...prev,
					[4]: true
				}));
				updateFilter({
					...(gender && { gender })
				});
			}
		}
	}

	useEffect(() => {
		if (Object.keys(filter).length === 1) {
			setClicked((prev) => ({
				...prev,
				[0]: false,
				[1]: false,
				[2]: false,
				[3]: false,
				[4]: false
			}));
			complaint = null;
			medical_certificate = null;
			pcd = null;
			restrict = null;
			gender = null;
		}
	}, [filter]);

	return (
		<Row gutter={[48, 24]} style={{ paddingBottom: '10px' }}>
			<Col sm={4}>
				<Card
					icon={<IdcardOutlined />}
					title="Registers"
					attribute="worker"
					onFetch={getWorkers}
					filter={filter}
				/>
			</Col>
			<Col sm={4}>
				<Card
					icon={<FrownOutlined />}
					title="Complaints"
					attribute="complaint"
					onFetch={getComplaints}
					onClick={() => onClickCard('complaints')}
					filter={filter}
					active={clicked[0]}
				/>
			</Col>
			<Col sm={4}>
				<Card
					icon={<SnippetsOutlined />}
					title="Med. certificates"
					attribute="certificate"
					onFetch={getCertificates}
					onClick={() => onClickCard('certificates')}
					filter={filter}
					active={clicked[1]}
				/>
			</Col>
			<Col sm={4}>
				<Card
					icon={<WarningOutlined />}
					title="PWDs"
					attribute="pcd"
					onFetch={getPwds}
					onClick={() => onClickCard('pwds')}
					filter={filter}
					active={clicked[2]}
				/>
			</Col>
			<Col sm={4}>
				<Card
					icon={<StopOutlined />}
					title="Restricts"
					attribute="restrict"
					onFetch={getRestricts}
					onClick={() => onClickCard('restricts')}
					filter={filter}
					active={clicked[3]}
				/>
			</Col>
			<Col sm={4}>
				<Card
					icon={<WomanOutlined />}
					title="Women"
					attribute="women"
					onFetch={getWomens}
					onClick={() => onClickCard('women')}
					filter={filter}
					active={clicked[4]}
				/>
			</Col>
		</Row>
	);
}
