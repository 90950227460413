import styled from 'styled-components';
import { Slider } from 'antd';
import CircleDirectionsHandler from './images/CircleDirectionsHandler.png';

export const FillmentSlider = styled(Slider)`
	.ant-slider-rail {
		height: 30px;
		top: -9px;
		border-radius: 6px;
		background-color: #e6e6e6;
	}

	.ant-slider-track {
		height: 30px;
		top: -9px;
		background-color: #2cc852 !important;
		border-radius: 6px;
	}

	.ant-slider-handle {
		background-image: url(${CircleDirectionsHandler});
		border: 1px solid white;
	}
`;
