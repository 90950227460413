import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';

export type GetFilesActivityDTO = {
	name?: string;
	company_id: string;
	sector_id?: string;
	line_id?: string;
	organization_id: string;
	workstation_id?: string;
	offset?: number;
	limit?: number;
	created_at_end?: string | Date;
	created_at_start?: string | Date;
}

export type FilesActivityDTO = {
	id: string;
	company_id: string;
	activity_id?: string;
	original_name: string;
	activity_name?: string;
	organization_id: string;
	created_at: string | Date;
	updated_at: string | Date;
}

export type FilesActivityResponse = {
	count: number;
	rows: FilesActivityDTO[];
}

async function getFilesWithActivity(params: GetFilesActivityDTO): Promise<FilesActivityResponse> {
	let url = `/activity/files`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useGetFilesWithActivity = (params: GetFilesActivityDTO) => {
	return useQuery([QUERY_KEYS.GET_FILES_WITH_ACTIVITY, params], () => getFilesWithActivity(params), {
		enabled: !!params.organization_id && !!params.company_id
	});
};
