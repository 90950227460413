import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CompanySelect } from '@/components/ui/Inputs/CompanySelect';
import { useApplicationContext } from '@/context/application';
import { useListCompanies } from '@/hooks/useListCompanies';
import * as S from '../styles';

const { useFormInstance } = Form;

export function Company() {
	const { setFieldValue } = useFormInstance();
	const { organization, company } = useApplicationContext();

	const {
		data: companies,
		isFetching,
		isLoading
	} = useListCompanies({ organization_id: organization?.id as string, company_id: company?.id as string });

	setFieldValue('company_id', company?.id);

	return (
		<Col xs={24}>
			<Form.Item name={['company_id']} label={I18n.get('Industrial Site')} labelCol={{ span: 24 }}>
				<S.ContainerSelect $borderRadius="6px">
					<CompanySelect
						companies={companies}
						loading={isFetching || isLoading}
						companyId={company?.id}
						disabled
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
}
