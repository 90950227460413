import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Api from '@/services/api';
import { updateUser } from '@/redux/auth/actions';

export function useCustomer() {

	const initState = { loading: false, data: null, errors: null };

	const [customer, setCustomer] = useState(initState);
	const [updated, setUpdated] = useState(null);
	const dispatch = useDispatch();
	const { user } = useSelector(state => state.auth.user);

	useEffect(() => {
		async function fetchCustomer() {
			try {
				const { data } = await Api.get('/customer/info');
				setCustomer(current => ({ ...current, data: data }));
				dispatch(updateUser(data));
			} catch (error) {
				const parseErrors = error.response?.data || error;
				setCustomer(current => ({ ...current, errors: parseErrors }));
				throw error;
			}
		}

		if (user.role === 'MASTER' || updated) {
			fetchCustomer();
		}

		return () => setCustomer(initState);
	}, [user.role, updated]);

	const updatedInformations = (data) => {
		setUpdated(data);
	}

	return { customer, updatedInformations };
}
