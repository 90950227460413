import React, { useState, useEffect } from 'react';
import { Button, Form, message } from 'antd';
import Api from '@/services/api';
import { CreateModal } from './CreateModal';
import { ListItens, EmptyList } from './List';
import { I18n } from '@aws-amplify/core';
import { CreateForm } from './CreateForm';

export function Organization({
	data,
	onSelect,
	visibleCreate,
	closeCreate,
	onReload,
}) {
	const [form] = Form.useForm();

	const [success, setSuccess] = useState(null);
	const [errors, setErrors] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (success) {
			message.success(I18n.get('Company created successfully'));
			closeCreate();
			setSuccess(null);
			onReload();
		}
	}, [success]);

	useEffect(() => {
		if (errors) {
			const err = errors.response?.data?.message || errors.message;
			message.error(I18n.get(err));
			setErrors(null);
		}
	}, [errors]);

	function onSubmit() {
		form.validateFields()
			.then((values) => {
				setLoading(true);
				Api.post('/organization', { ...values, url_logo: '' })
					.then((res) => {
						onReload(res.data);
						setSuccess(res.data);
						onSelect(res.data);
					})
					.catch((err) => setErrors(err))
					.finally(() => setLoading(false));
			})
			.catch(() => {});
	}

	if (data?.length === 0) {
		return (
			<>
				<EmptyList description="Bem vindo ao Kinebot, cria sua primeira organização" />
				<div style={{ padding: '10px 20px 0' }}>
					<CreateForm form={form} type="organization" />
					<Button type="primary" onClick={onSubmit} loading={loading}>
						{I18n.get('Create company')}
					</Button>
				</div>
			</>
		);
	}

	return (
		<div>
			<CreateModal
				form={form}
				loading={loading}
				type="organization"
				onSubmit={onSubmit}
				onCancel={closeCreate}
				visible={visibleCreate}
			/>
			<ListItens data={data} onAction={onSelect} />
		</div>
	);
}
