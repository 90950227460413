import React from 'react';
import styled from 'styled-components';
import { URL_LOGO } from '@/utils/constants';

const Container = styled.div`
	display: flex;
	cursor: pointer;
	text-align: center;
	margin-bottom: 24px;
	align-items: center;
	justify-content: center;

	img {
		width: 60%;
		padding-top: 3%;
		padding-bottom: 5%;
	}

	span {
		font-size: 16px;
		font-weight: 700;
		display: inline-block;
		text-transform: uppercase;
		vertical-align: text-bottom;
	}
`;

export function Logo() {
	return (
		<Container>
			<img alt="logo" src={URL_LOGO} />
		</Container>
	);
}
