import { Checkbox, Row } from 'antd';
import styled from 'styled-components';

interface ProductContainerProps {
	error?: boolean;
}

export const CheckboxGroupCustom = styled(Checkbox.Group)`
	width: 100%;
	margin-bottom: 15px;

	.ant-typography {
		font-size: 16px;
	}
	.ant-checkbox {
		margin-bottom: 5px;
		margin-right: 12px;
	}
	.ant-checkbox-wrapper-checked {
		border: 1.5px solid #2f54eb;
		background-color: #eaeefd;
	}
`;

export const ProductContainer = styled(Row)<ProductContainerProps>`
	width: 100%;
	height: 300px;
	padding: 20px 20px 0 25px;
	border: ${({ error }) => (error ? '2px solid #ff4d4f' : '2px solid #e6e6e6')};
	border-radius: 20px;

	overflow-y: auto;
	/* width */
	::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		border-left: 3px solid #9c9c9c;
		border-radius: 1.5px;
	}
	::-webkit-scrollbar-button {
		border-radius: 0 20px 20px 0;
	}
	::-webkit-scrollbar-button:single-button:vertical:decrement {
		border-width: 0 8px 8px 8px;
		background-color: transparent;
	}
	::-webkit-scrollbar-button:single-button:vertical:increment {
		border-width: 0 8px 8px 8px;
		background-color: transparent;
	}
`;
