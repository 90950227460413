import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

export type GetRotationReportCycleTotalTimeDTO = {
	organization_id?: string;
	company_id?: string;
	id?: string;
};

export type RotationReportCycleTotalTimeResponse = {
	cycle_total_time: number;
};

async function getRotationReportCycleTotalTime(
	params: GetRotationReportCycleTotalTimeDTO
): Promise<RotationReportCycleTotalTimeResponse> {
	const url = `/rotation/report/${params.id}/cycle/total_time`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useGetRotationReportCycleTotalTime = (params: GetRotationReportCycleTotalTimeDTO) => {
	const { organization_id, company_id, id } = params;
	return useQuery(
		[QUERY_KEYS.GET_ROTATION_REPORT_CYCLE_TOTAL_TIME, params],
		() => getRotationReportCycleTotalTime(params),
		{
			enabled: !!organization_id && !!company_id && !!id
		}
	);
};
