import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Radio, InputNumber, Button } from 'antd';
import { updateParameters, next, prev } from '@/redux/reports/actions';

const radioStyle = {
	height: '30px',
	display: 'block',
	lineHeight: '30px',
};

export function Parameters() {
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const { parameters } = useSelector((state) => state.reports.customized);

	function onChange() {
		form.validateFields()
			.then((values) => dispatch(updateParameters(values)))
			.catch((errorInfo) => {});
	}

	function onNext() {
		dispatch(next('customized'));
	}

	function onPrevious() {
		dispatch(prev('customized'));
	}

	return (
		<Form
			form={form}
			layout="vertical"
			initialValues={parameters}
			style={{ padding: '25px 10px 10px 10px' }}
		>
			<Row>
				<Col sm={24} style={{ paddingBottom: '15px' }}>
					<p>{I18n.get('Force/Charge movement data')}</p>
				</Col>
				<Col sm={24}>
					<Form.Item
						name="repetition"
						label={I18n.get('Repetitions')}
					>
						<InputNumber
							min={1}
							max={100}
							style={{ width: 200 }}
							onChange={onChange}
						/>
					</Form.Item>
				</Col>
				<Col sm={24}>
					<Form.Item name="force" label={I18n.get('Force')}>
						<Radio.Group onChange={onChange}>
							<Radio style={radioStyle} value={0}>
								{I18n.get('Load less than 2 kg (intermittent)')}
							</Radio>
							<Radio style={radioStyle} value={1}>
								{I18n.get(
									'Load from 2 kg up to 10 kg (intermittent)'
								)}
							</Radio>
							<Radio style={radioStyle} value={2}>
								{I18n.get(
									'Load from 2 kg up to 10 kg (static or repetitive)'
								)}
							</Radio>
							<Radio style={radioStyle} value={3}>
								{I18n.get(
									'Load more than 10 kg of repetitive load or blows'
								)}
							</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col sm={24}>
					<Form.Item name="legs" label={I18n.get('Legs')}>
						<Radio.Group onChange={onChange}>
							<Radio style={radioStyle} value={1}>
								{I18n.get(
									'Legs and feet supported and with equal load distribution'
								)}
							</Radio>
							<Radio style={radioStyle} value={2}>
								{I18n.get('Legs without support')}
							</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col sm={24}>
					<Row justify="end">
						<Col>
							<Button
								style={{ margin: '0 8px' }}
								onClick={onPrevious}
							>
								{I18n.get('Previous')}
							</Button>
						</Col>
						<Col>
							<Button type="primary" onClick={onNext}>
								{I18n.get('Next')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
}
