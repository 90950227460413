import { localeProvider } from '@/i18n';

export const dateFormat = () => {
	if (localeProvider?.locale === 'pt-br') {
		return 'DD/MM/YYYY';
	}

	return localeProvider?.DatePicker?.lang.dateFormat || 'L';
};

export const dateTimeFormat = localeProvider?.DatePicker?.lang.dateTimeFormat || 'L';
