import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { Workstation as WorkstationData } from '@/types';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

interface WorkstationProps {
	namePlace: string[];
	fontSize?: string;
	isRequired?: boolean;
	data: WorkstationData[] | undefined;
	isFetching: boolean;
}

export function Workstation({ namePlace, fontSize, isRequired = false, data, isFetching }: WorkstationProps) {
	const { setFieldValue, getFieldValue } = useFormInstance();

	const line_id = getFieldValue(['informations', 'line_id']);
	const workstation_id = useWatch([...namePlace, 'workstation_id']);

	function handleOnChange(id: string): void {
		setFieldValue([...namePlace, 'workstation_id'], id);
		setFieldValue([...namePlace, 'products'], undefined);
	}

	return (
		<Col span={23}>
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[...namePlace, 'workstation_id']}
				rules={[{ required: true, message: I18n.get('Select the workstation') }]}
				label={<RequiredLabel fontSize={fontSize} title="Workstation" isRequired={isRequired} />}
			>
				<S.ContainerSelect $borderRadius="8px">
					<WorkstationSelect
						size="large"
						lineId={line_id}
						workstations={data}
						disabled={!line_id}
						loading={isFetching}
						allowCreation={false}
						onChange={handleOnChange}
						workstationId={workstation_id}
						fieldName={[...namePlace, 'workstation_id']}
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
}
