import React from 'react';

export const CustomRightArrow: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="none" viewBox="0 0 37 41" style={{ rotate: '180deg' }}>
			<path
				fill="#2F54EB"
				d="M19.533.104l4.951 5.118L9.9 20.304l14.585 15.082-4.95 5.118-19.535-20.2L19.533.104z"
			></path>
			<path
				fill="#2F54EB"
				d="M31.719 7.26l4.949 5.117-7.666 7.926 7.666 7.927-4.949 5.118-12.618-13.045L31.719 7.26z"
			></path>
		</svg>
	);
};
