import React from 'react';
import { Col } from 'antd';

import { Text } from '@/components/Typography';
import { Logo } from '@/components/ui/Logo';
import { LogoCNHi } from '../LogoCNHi';
import * as S from './styles';

type HeaderType = {
	title: string;
};

const kinebotLogo = 'https://kinebot-statics.s3.amazonaws.com/logo.png';

export const Header: React.FC<HeaderType> = ({ title }) => {
	return (
		<S.RowCustom align="middle">
			<Col xs={0} sm={5} lg={6} xl={7} xxl={5}>
				<Logo src={kinebotLogo} />
			</Col>
			<Col xs={24} sm={15} lg={12} xl={11} xxl={13}>
				<S.HeaderTitle>
					<Text>Result</Text>
				</S.HeaderTitle>
				<S.HeaderSubTitle>
					<Text>{title}</Text>
				</S.HeaderSubTitle>
			</Col>
			<Col xs={0} sm={4} lg={6} xl={6} xxl={6} style={{ display: 'flex', justifyContent: 'end' }}>
				<Logo>
					<LogoCNHi width={'100%'} height={'100%'} style={{ maxWidth: '115px' }} />
				</Logo>
			</Col>
		</S.RowCustom>
	);
};
