import { Tooltip } from 'antd';
import styled from 'styled-components';

type WrapperProps = {
	step: number;
	size: number;
	lines: number;
	worst: number;
};

export const Wrapper = styled.div<WrapperProps>`
	height: 170px;
	flex-grow: 1;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	border-radius: 0.5rem;
	cursor: pointer;
	text-align: center;
	background: ${({ worst }) => color(worst)};
	font-size: 16px;
	word-break: ${({ step }) => (step === 3 ? 'break-all' : 'normal')};
	text-transform: uppercase;
	padding: 10px 5px;
	text-overflow: clip;
	min-width: 35px;

	&.vertical span {
		transform: rotate(180deg);
		writing-mode: vertical-rl;
	}
`;

export const TileContainer = styled(Tooltip)`
	border-radius: 0.5rem;
	position: relative;

	transition: transform 0.3s, filter 0.15s ease-in;
	&:hover {
		z-index: 10;
		transform: scale(1.03);
		filter: brightness(0.9);
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
	}
`;

function color(note: number) {
	switch (true) {
		case note === 4:
			return '#808080';
		case note >= 3:
			return '#1A7E48';
		case note >= 2.5:
			return '#70B159';
		case note >= 2:
			return '#F9D39B';
		case note >= 1.5:
			return '#EAB05A';
		case note >= 1:
			return '#D16368';
		default:
			return '#808080';
	}
}
