import { useQuery } from '@tanstack/react-query';

import { RotationReportObjective } from '@/types/entities/RotationReport';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

export type GetRotationReportDTO = {
	organization_id: string;
	company_id: string;
	id?: string;
};

type Activity = {
	file_id: string;
	activity_id: string;
	activity: {
		id: string;
		name: string;
	};
	file: {
		id: string;
		duration: number;
	};
};

export type GetRotationReportResponse = {
	id: string;
	name: string;
	comment: string | undefined;
	current_step: '0' | '1' | '2' | '3' | '4';
	analysis_date: Date;
	activities: Activity[];
	rotation_objectives: RotationReportObjective[];
	evaluator_id: string;
	range_risk_id: string;
	is_completed: boolean;
	consolidated: boolean;
	range_risk: string;
	created_at: Date;
};

async function getRotationReport({ id, ...params }: GetRotationReportDTO): Promise<GetRotationReportResponse> {
	const url = `/rotation/report/${id}`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useGetRotationReport = (params: GetRotationReportDTO) => {
	const { organization_id, company_id, id } = params;
	return useQuery([QUERY_KEYS.GET_ROTATION_REPORT, params], () => getRotationReport(params), {
		enabled: !!organization_id && !!company_id && !!id
	});
};
