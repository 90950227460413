import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { OrganizationSelect } from '@/components/ui/Inputs/OrganizationSelect';
import { useGetOrganizations } from '@/hooks/useGetOrganization';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

interface OrganizationProps {
	namePlace: string;
	fontSize?: string;
	isRequired?: boolean;
}

export function Organization({ namePlace, fontSize, isRequired = false }: OrganizationProps) {
	const { data } = useGetOrganizations();
	const { setFieldValue } = useFormInstance();

	const organizationId = useWatch([namePlace, 'organization_id']);

	function handleValue(id: string) {
		setFieldValue([namePlace, 'organization_id'], id);
	}

	function handleClear() {
		setFieldValue([namePlace, 'organization_id'], undefined);
		setFieldValue([namePlace, 'company_id'], undefined);
		setFieldValue([namePlace, 'line_id'], undefined);
		setFieldValue([namePlace, 'sector_id'], undefined);
	}

	return (
		<Col span={23}>
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[namePlace, 'organization_id']}
				rules={[{ required: true, message: I18n.get('Select the company') }]}
				label={<RequiredLabel fontSize={fontSize} title="Company" isRequired={isRequired} />}
			>
				<S.ContainerSelect $borderRadius="8px">
					<OrganizationSelect
						size="large"
						loading={false}
						disabled={true}
						organizations={data}
						onClear={handleClear}
						onChange={handleValue}
						organizationId={organizationId}
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
}
