import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getWorkstations({
	organizationId,
	companyId,
	sectorId,
	lineId,
}) {
	if (!organizationId || !companyId || !sectorId || !lineId) return;
	const url = `/workstation/${organizationId}/${companyId}/${lineId}/`;
	const { data } = await Api.get(url);
	return data;
}

export function useWorkstation(payload) {
	const workstations = useQuery(['workstations', payload], () =>
		getWorkstations(payload)
	);
	return { workstations };
}
