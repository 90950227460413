import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { I18n } from '@aws-amplify/core';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { colorsRiskDegree } from './styles';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export function PieChart({ data, label }) {
	return (
		<Doughnut
			plugins={[
				ChartDataLabels,
				{
					beforeDraw: (chart) => {
						const { width, height, ctx } = chart;

						ctx.restore();

						let fontSize = (height / 180).toFixed(2);
						ctx.font = fontSize + 'em sans-serif';
						ctx.textBaseline = 'middle';

						// let text = label || '';
						let lines = label.split(' ');

						if (lines.length > 1) {
							let textX = Math.round(
								(width - ctx.measureText(lines[0]).width) / 2
							);
							let textY = height / 2;

							ctx.fillText(lines[0], textX, textY - 10);

							let centerX = Math.round(
								(width - ctx.measureText(lines[1]).width) / 2
							);
							let centerY = height / 2;

							ctx.fillText(lines[1], centerX, centerY + 20);
						} else {
							let textX = Math.round(
								(width - ctx.measureText(lines[0]).width) / 2
							);
							let textY = height / 2;

							ctx.fillText(lines[0], textX, textY);
						}
						ctx.save();
					},
				},
			]}
			options={{
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					cutoutPercentage: 80,
					datalabels: {
						formatter: (value, ctx) => {
							const datapoints = ctx.chart.data.datasets[0].data;
							const total = datapoints.reduce((a, b) => a + b, 0);
							const result = parseFloat(
								(value / total) * 100
							).toFixed(1);
							return Math.trunc(result) === 0 ? '' : `${result}%`;
						},
						color: '#000',
					},
					tooltip: {
						callbacks: {
							label: (tooltipItem) => {
								let label = I18n.get(tooltipItem.label);
								let data = tooltipItem.dataset.data;
								let currentValue =
									tooltipItem.dataset.data[
										tooltipItem.dataIndex
									];
								let total = data.reduce((a, b) => a + b, 0);
								let percentage = parseFloat(
									((currentValue / total) * 100).toFixed(1)
								);
								return `${label}:${percentage}%`;
							},
						},
					},
					legend: {
						display: false,
					},
				},
			}}
			data={{
				labels: ['Safe', 'Info', 'Warning', 'Danger'],
				datasets: [
					{
						data: data,
						backgroundColor: Object.keys(colorsRiskDegree).map(
							(key) => colorsRiskDegree[key].color
						),
						borderColor: Object.keys(colorsRiskDegree).map(
							(key) => colorsRiskDegree[key].border
						),
						borderWidth: 1,
					},
				],
			}}
		/>
	);
}

// import React from 'react';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { I18n } from '@aws-amplify/core';
// import { Doughnut } from 'react-chartjs-2';
// import { colorsRiskDegree } from './styles';

// ChartJS.register(ArcElement, Tooltip, Legend);

// export function PieChart({ data, label }) {
// 	return (
// 		<Doughnut
// 			plugins={[
// 				// {
// 				// 	beforeDraw: (chart) => {
// 				// 		const { width, height, ctx } = chart;

// 				// 		ctx.restore();

// 				// 		let fontSize = (height / 180).toFixed(2);
// 				// 		ctx.font = fontSize + 'em sans-serif';
// 				// 		ctx.textBaseline = 'middle';

// 				// 		let text = label || '';
// 				// 		let textX = Math.round((width - ctx.measureText(text).width) / 2);
// 				// 		let textY = height / 2;

// 				// 		ctx.fillText(text, textX, textY);
// 				// 		ctx.save();
// 				// 	}
// 				// }
// 				{
// 					beforeDraw: (chart) => {
// 						const { width, height, ctx } = chart;

// 						ctx.restore();

// 						let fontSize = (height / 180).toFixed(2);
// 						ctx.font = fontSize + 'em sans-serif';
// 						ctx.textBaseline = 'middle';

// 						// let text = label || '';
// 						let lines = label.split(' ');

// 						if (lines.length > 1) {
// 							let textX = Math.round((width - ctx.measureText(lines[0]).width) / 2);
// 							let textY = height / 2;

// 							ctx.fillText(lines[0], textX, textY - 10);

// 							let centerX = Math.round((width - ctx.measureText(lines[1]).width) / 2);
// 							let centerY = height / 2;

// 							ctx.fillText(lines[1], centerX, centerY + 20);

// 						} else {
// 							let textX = Math.round((width - ctx.measureText(lines[0]).width) / 2);
// 							let textY = height / 2;

// 							ctx.fillText(lines[0], textX, textY);
// 						}

// 						ctx.save();
// 					}
// 				}

// 			]}
// 			options={{
// 				responsive: true,
// 				maintainAspectRatio: false,

// 				plugins: {
// 					cutoutPercentage: 80,
// 					tooltip: {
// 						callbacks: {
// 							label: (tooltipItem) => {
// 								let label = I18n.get(tooltipItem.label);
// 								let data = tooltipItem.dataset.data;
// 								let currentValue = tooltipItem.dataset.data[tooltipItem.dataIndex];
// 								let total = data.reduce((a, b) => a + b, 0);
// 								let percentage = parseFloat((currentValue / total * 100).toFixed(1));
// 								return `${label}:${percentage}%`;
// 							}
// 						}
// 					},
// 					legend: {
// 						display: false
// 					}
// 				},
// 			}}
// 			data={{
// 				labels: ['safe', 'info', 'warning', 'danger'],
// 				datasets: [
// 					{
// 						data: data,
// 						backgroundColor: Object.keys(colorsRiskDegree).map(key =>
// 							colorsRiskDegree[key].color
// 						),
// 						borderColor: Object.keys(colorsRiskDegree).map(key =>
// 							colorsRiskDegree[key].border
// 						),
// 						borderWidth: 1
// 					}
// 				]
// 			}}
// 		/>
// 	);
// }
