import React, { useState } from 'react';
import lodash from 'lodash';
import { I18n } from '@aws-amplify/core';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, Form, Modal, message, Image as AntImage } from 'antd';

import type { ChecklistParams, ChecklistState, CompletedUpload, SelectedFile, SelectorState } from './types';
import { useCreateChecklistImageSignedUrl, useDeleteCheckListImage, useSendFileS3 } from '@/hooks';
import { nextCurrentStep, previousCurrentStep } from '@/redux/checklist/actions';
import { Upload } from '@/components/ChecklistEHS/Upload';
import { Image } from '@/components/ChecklistEHS/Image';
import { Footer } from './Footer';

const MAX_MB_FILE_SIZE = 2;
const MAX_FILES = 5;
const MB = 1024;

const { useForm } = Form;

export const UploadImage: React.FC = () => {
	const [form] = useForm();
	const dispatch = useDispatch();
	const { company_id, organization_id, file_id } = useParams<ChecklistParams>();
	const { id: checklist_id } = useSelector<SelectorState>((state) => state.checklist.checklist) as ChecklistState;
	const checklist_images = useSelector<SelectorState>((state) => state.checklist.checklist_images) as SelectedFile[];

	const [messageApi, contextHolder] = message.useMessage();
	const [files, setFiles] = useState<SelectedFile[]>(checklist_images);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [imageModalUri, setImageModalUri] = useState<string>();

	const createSignedUrl = useCreateChecklistImageSignedUrl();
	const deleteChecklistFile = useDeleteCheckListImage();
	const sendFileToS3 = useSendFileS3();

	const fileToDataUri = (file: File): Promise<string> =>
		new Promise((resolve) => {
			const reader = new FileReader();
			reader.onload = (event: any) => {
				resolve(event.target.result);
			};
			reader.readAsDataURL(file);
		});

	const errorMessage = (error: string) => {
		messageApi.open({
			type: 'error',
			content: I18n.get(error)
		});
	};

	function hasGreaterSize(size: number): boolean {
		const fileSizeInMB = size / (MB * MB);
		if (fileSizeInMB > MAX_MB_FILE_SIZE) {
			return true;
		}
		return false;
	}

	function hasMaxFile(fileQuantity: number): boolean {
		const total = files.length + fileQuantity;
		if (total > MAX_FILES) {
			return true;
		}
		return false;
	}

	async function uploadFiles(fileList: FileList): Promise<void> {
		const quantityFiles = fileList.length + files.length;
		if (hasMaxFile(quantityFiles)) {
			errorMessage(`Max quantity of files is ${MAX_FILES}`);
			return;
		}

		Array.from({ length: quantityFiles }, async (_, key) => {
			const file: File = fileList[key];
			if (hasGreaterSize(file.size)) {
				errorMessage(`Max file size is ${MAX_MB_FILE_SIZE}MB`);
				return;
			}

			const uri = await fileToDataUri(file);
			const imageData = {
				id: lodash.uniqueId(),
				file,
				isLoading: true,
				uri,
				error: null
			};
			setFiles((old) => [...old, imageData]);
			await processUpload(imageData);
		});
	}

	async function onUploadFile(files: FileList | null) {
		if (files) {
			await uploadFiles(files);
		}
	}

	function setFileToCompletedUpload({ temp_id, id }: CompletedUpload) {
		setFiles((files) =>
			files.map((file) => {
				if (file.id === temp_id) {
					return Object.assign(file, { isLoading: false, id });
				}
				return file;
			})
		);
	}

	const processUpload = async (uploadedFile: SelectedFile) => {
		if (!uploadedFile) return;

		const { id, file } = uploadedFile;

		const error_message = I18n.get('Failed to process file');

		try {
			const data = await createSignedUrl.mutateAsync({
				content_type: file.type,
				file_name: file.name,
				organization_id,
				checklist_id,
				company_id,
				file_id
			});

			const headers = {
				headers: {
					'Content-Type': file.type
				}
			};

			const fileBuffer = await file.arrayBuffer();

			await sendFileToS3.mutateAsync({
				headers,
				url: data.url,
				file: fileBuffer
			});

			setFileToCompletedUpload({
				temp_id: id,
				id: data.id
			});
		} catch (err) {
			errorMessage(`${error_message} ${file.name}`);
			deleteFile(id);
		}
	};

	function onImagePreview(uri: string) {
		setImageModalUri(uri);
		setIsModalOpen(true);
	}

	function deleteFile(id: string) {
		const deleteFile = files.filter((file) => file.id !== id);
		setFiles(deleteFile);
	}

	async function handleDeleteFile(id: string) {
		try {
			await deleteChecklistFile.mutateAsync({
				id,
				file_id,
				company_id,
				checklist_id,
				organization_id
			});
			deleteFile(id);
			messageApi.success(I18n.get('Image deleted successfully'));
		} catch (err: any) {
			errorMessage(err?.response?.data?.message || 'Internal server error');
		}
	}

	function onDeleteFile(id: string) {
		Modal.confirm({
			title: I18n.get('Heads up! want to delete this file?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: () => {
				handleDeleteFile(id);
			}
		});
	}

	const onPrevious = () => {
		dispatch(previousCurrentStep());
	};

	const onFinish = () => {
		form.validateFields()
			.then((values: any) => {
				console.log(values);
			})
			.then(console.log)
			.then(() => dispatch(nextCurrentStep()))
			.catch(() => {});
	};

	return (
		<Form form={form} layout="vertical">
			{contextHolder}
			<Row style={{ paddingTop: '20px' }} gutter={[5, 20]}>
				<Col sm={24}>
					<h2>{I18n.get('Image upload')}</h2>
				</Col>
				<Col sm={24}>
					<label>{I18n.get('Upload images of the activity being evaluated')}</label>
				</Col>
				<Col sm={24} style={{ paddingTop: '20px' }}>
					<h2 style={{ marginBottom: '0px' }}>{I18n.get('Images')}</h2>
				</Col>
				{files.map((file) => (
					<Image
						file={file}
						onImagePreview={onImagePreview}
						onDelete={onDeleteFile}
						isLoading={file.isLoading}
						error={file.error}
					/>
				))}
				{imageModalUri && (
					<AntImage
						wrapperStyle={{
							display: 'none'
						}}
						preview={{
							visible: isModalOpen,
							onVisibleChange: (visible) => setIsModalOpen(visible)
						}}
						src={imageModalUri}
					/>
				)}
				<Col sm={4}>
					<Upload
						name="ehs-file"
						accept=".jpg, .jpeg, .png"
						onUploadFile={onUploadFile}
						hidden={files.length >= MAX_FILES}
					/>
				</Col>
				<Col sm={24} style={{ paddingTop: '20px' }}>
					<Footer onClickNext={onFinish} onClickPrevious={onPrevious} isLoading={false} />
				</Col>
			</Row>
		</Form>
	);
};
