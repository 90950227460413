import React from 'react';
import { Row, Col } from 'antd';
import * as S from './styles';

interface RequiredLabelProps {
	title: string;
	fontSize?: string;
	isRequired?: boolean;
	bold?: boolean;
}

export function RequiredLabel({ title, isRequired = true, fontSize, bold = false }: Readonly<RequiredLabelProps>) {
	return (
		<Row justify="start" align="middle" gutter={[2, 0]}>
			<Col>
				<S.TextCustom $fontSize={fontSize} $bold={bold}>
					{title}
				</S.TextCustom>
			</Col>
			{isRequired && (
				<Col>
					<S.DotCustom>*</S.DotCustom>
				</Col>
			)}
		</Row>
	);
}
