import React, { useRef, useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { Bar } from 'react-chartjs-2';
import { Loading } from './Loading';
import { ErrorChart } from './Error';
import { CardChart } from './styles';
import { AppointmentContext } from '../context';

import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as ChartJS } from 'chart.js';

ChartJS.register(zoomPlugin);

export function BarChart({
	title,
	attribute,
	onClickFilter,
	onFetch,
	filter,
	orientation = 'x',
	minHeight = 10,
}) {
	const { selectToggle } = useContext(AppointmentContext);

	const { data, isError, isLoading, refetch } = useQuery([attribute, filter], () =>
		onFetch(filter)
	);

	useEffect(() => {
		refetch();
	}, [selectToggle, filter]);

	const chartRef = useRef();

	const options = {
		responsive: true,
		animation: {
			duration: 0, // general animation time
		},
		hover: {
			animationDuration: 0, // duration of animations when hovering an item
		},
		responsiveAnimationDuration: 0,
		maintainAspectRatio: false,
		indexAxis: orientation,
		scales: {
			x: {
				stacked: selectToggle ? false : true,
				min: 0,
				grid: {
					display: false,
				},
				ticks: {
					precision: 0,
					source: 'data',
					autoSkip: false,
				},
			},
			y: {
				stacked: selectToggle ? false : true,
				min: 0,
				...(orientation === 'y' && {
					max: 15,
				}),
				grid: {
					display: false,
				},
				ticks: {
					precision: 0,
					source: 'data',
					autoSkip: false,
					crossAlign: 'far', // Align labels
				},
			},
		},
		plugins: {
			title: {
				display: true,
				text: I18n.get(title),
			},
			datalabels: {
				display: false,
			},
			...(orientation === 'y' && {
				zoom: {
					pan: {
						enabled: true,
						mode: 'y',
					},
				},
			}),
		},
	};

	if (isLoading || !data) {
		return <Loading minHeight={minHeight} />;
	}

	if (isError) {
		return <ErrorChart />;
	}

	return (
		<CardChart minHeight={minHeight}>
			<Bar
				ref={chartRef}
				options={options}
				onClick={(e) => onClickFilter(e, chartRef.current, data, attribute)}
				data={{
					labels: selectToggle
						? data?.map((item) => item.unit)
						: data?.map((item) => item.name),
					datasets: selectToggle
						? [
								{
									borderWidth: 1,
									maxBarThickness: 40,
									label: I18n.get('Workers'),
									borderColor: 'rgba(65,105,225, 1)',
									backgroundColor: 'rgb(65,105,225, 0.5)',
									data: data?.map((item) => item.value),
								},
						  ]
						: [
								{
									borderWidth: 1,
									maxBarThickness: 40,
									label: I18n.get('Low'),
									backgroundColor: ['rgba(75, 192, 86, 0.5)'],
									borderColor: ['rgba(75, 192, 86, 1)'],
									data: data?.map((item) => item.Low),
								},
								{
									borderWidth: 1,
									maxBarThickness: 40,
									label: I18n.get('Average'),
									backgroundColor: ['rgba(255, 206, 86, 0.5)'],
									borderColor: ['rgba(255, 206, 86, 1)'],
									data: data?.map((item) => item.Average),
								},
								{
									borderWidth: 1,
									maxBarThickness: 40,
									label: I18n.get('High'),
									backgroundColor: ['rgba(255, 40, 72, 0.5)'],
									borderColor: ['rgba(255, 40, 72, 1)'],
									data: data?.map((item) => item.High),
								},
						  ],
				}}
			/>
		</CardChart>
	);
}
