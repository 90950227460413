import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { SegmentAnalysis } from './SegmentAnalysis';
import { ErgonomicQuote } from './ErgonomicQuote';
import { BusinessUnit } from './BusinessUnit';
import { Workstation } from './Workstation';
import { CostCenter } from './CostCenter';
import { Line } from './Line';

import { Title, Card } from './styles';

export const Chart: React.FC = () => (
	<Col xs={24}>
		<Row gutter={[10, 10]}>
			<Col xs={24} sm={24} md={12}>
				<Card>
					<Title>{I18n.get('Ergonomic quote')}</Title>
					<ErgonomicQuote />
				</Card>
			</Col>
			<Col xs={24} sm={24} md={12}>
				<Card>
					<Title>{I18n.get('BU')}</Title>
					<BusinessUnit />
				</Card>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Card noHeight>
					<Title>{I18n.get('Segment analysis')}</Title>
					<SegmentAnalysis />
				</Card>
			</Col>
			<Col xs={24} sm={24} md={12}>
				<Card>
					<Title>{I18n.get('Cost center')}</Title>
					<CostCenter />
				</Card>
			</Col>
			<Col xs={24} sm={24} md={12}>
				<Card>
					<Title>{I18n.get('Line')}</Title>
					<Line />
				</Card>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Card>
					<Title>{I18n.get('Workstation')}</Title>
					<Workstation />
				</Card>
			</Col>
		</Row>
	</Col>
);
