import React from 'react';
import styled from 'styled-components';

export const SpanWrapper = styled.span`
	font-size: 16px;
	font-weight: 600;
	padding: 6px 10px;
	color: #2f54eb;
	border-radius: 6px;
	background-color: #eaeefd;
	border: 2px solid #2f54eb;
`;

interface TimerProps {
	children?: number;
}

export function Timer({ children = 0 }: Readonly<TimerProps>) {
	function handleFormatValue(seconds: number | string): string {
		const number_seconds = Number(seconds);
		const hours = Math.floor(number_seconds / 3600);
		const minutes = Math.floor((number_seconds % 3600) / 60);
		const secs = number_seconds % 60;
		return `${hours}h ${String(minutes).padStart(2, '0')}m ${String(secs).padStart(2, '0')}s`;
	}

	return <SpanWrapper>{handleFormatValue(children)}</SpanWrapper>;
}
