import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getCompanies({ organizationId }) {
	if (!organizationId) return;
	const { data } = await Api.get(`/company/user_list/${organizationId}`);
	return data;
}

export function useCompany(payload) {
	const companies = useQuery(['companies', payload], () =>
		getCompanies(payload)
	);

	return { companies };
}
