import React from 'react';
import { I18n } from '@aws-amplify/core';
import { queryClient } from '@/store/query';
import { Row, Col, Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { RotationDTO } from '@/types';
import { ModalWrapper } from './styles';
import { Text } from '@/components/Typography';
import { QUERY_KEYS } from '@/utils/query-keys';
import { useDeleteRotationReport } from '@/hooks/Rotation';
import { useApplicationContext } from '@/context/application';

const { GET_ROTATION_REPORTS } = QUERY_KEYS;

interface DeleteModalProps {
	isOpen: boolean;
	report: RotationDTO;
	onDeleteModalVisivility: () => void;
}

export function DeleteModal({ isOpen, report, onDeleteModalVisivility }: Readonly<DeleteModalProps>) {
	const { organization, company } = useApplicationContext();
	const deleteRotationReport = useDeleteRotationReport();

	async function handleDeleteReport(): Promise<void> {
		try {
			await deleteRotationReport.mutateAsync({
				organization_id: organization?.id,
				company_id: company?.id,
				id: report.id
			});

			queryClient.invalidateQueries([GET_ROTATION_REPORTS]);
			message.success(I18n.get('Report deleted successfully'));
			onDeleteModalVisivility();
		} catch (error: any) {
			let err = error?.response?.data?.message || 'Oops... Something went wrong';
			message.error(I18n.get(err));
		}
	}

	return (
		<ModalWrapper centered width={500} footer={null} open={isOpen} onCancel={onDeleteModalVisivility}>
			<Row justify="center">
				<DeleteOutlined style={{ color: '#E74150', fontSize: 70 }} />
			</Row>
			<Row style={{ margin: '15px 0 20px 0' }} justify="center">
				<Col style={{ width: '70%', textAlign: 'center' }}>
					<Text style={{ fontSize: '24px', textAlign: 'center' }}>
						Are you sure you want to delete the report
					</Text>
					<Text style={{ fontSize: '24px', textAlign: 'center' }}>
						{' '}
						<strong>"{report.name}"</strong> ?
					</Text>
				</Col>
			</Row>
			<Row justify="center">
				<Button
					key="back"
					onClick={onDeleteModalVisivility}
					style={{ marginRight: 10, borderRadius: '6px', height: '40px', fontSize: '16px' }}
				>
					{I18n.get('Cancel')}
				</Button>
				<Button
					type="primary"
					onClick={handleDeleteReport}
					style={{ marginLeft: 10, borderRadius: '6px', height: '40px', fontSize: '16px' }}
				>
					{I18n.get('Confirm')}
				</Button>
			</Row>
		</ModalWrapper>
	);
}
