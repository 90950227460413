import { useMutation } from '@tanstack/react-query';
import { DeleteRotationReportDTO, ErrorResponse } from '@/types';
import Api from '@/services/api';

export type DeleteRotationReportResponse = {
	url: string;
};

async function deleteRotationReport(params: DeleteRotationReportDTO): Promise<DeleteRotationReportResponse> {
	const { data } = await Api.delete(`/rotation/report/${params.id}`, { params });
	return data;
}

export const useDeleteRotationReport = () => {
	return useMutation<DeleteRotationReportResponse, ErrorResponse, DeleteRotationReportDTO>(deleteRotationReport);
};
