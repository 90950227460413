import React, { Fragment, useState, useCallback, useLayoutEffect } from 'react';
import _ from 'lodash';
import { I18n } from '@aws-amplify/core';
import { Layout, Row, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Api from '@/services/api';
import { SelectProject } from '../../SelectProject';
import { Popover, SideMenu, BreadcrumbLayout } from '..';
import { SelectWorkspace } from '../../SelectWorkspace';
import { expirationPlanValidate } from '@/redux/user/actions';
import { ChildrenContainer, ContainerChildren } from './styles';

export function Container(props) {
	const dispatch = useDispatch();
	const location = useLocation();

	const [collapsed, setCollapsed] = useState(false);
	const expirationPlan = useSelector((state) => state.user.expirationPlan);

	const onCollapse = () => {
		setCollapsed((current) => !current);
	};

	const expirationPlanCallback = useCallback(async () => {
		async function fetchPlanLimits() {
			try {
				const { data } = await Api.post('/plan/checking-limits');

				if (data.status === 'warning') {
					location.pathname === '/' && message.warning(I18n.get(data.message));
					return;
				}

				return data;
			} catch (error) {
				console.log(error, 'error');
			}
		}

		if (expirationPlan.visible === true) {
			return;
		}

		const informationPlan = await fetchPlanLimits();

		const { plan, plan_consumed, canceled_on } = informationPlan || {};

		let parameters = {
			expired: false,
			visible: false,
			canceled_on: canceled_on
		};

		if (plan && plan_consumed) {
			const currentDate = new Date();
			const expiredOn = plan_consumed?.expiration ? new Date(plan_consumed.expiration) : null;

			const onExpired = () => {
				_.set(parameters, 'expired', true);
				_.set(parameters, 'visible', false);
			};

			const notExpired = () => {
				_.set(parameters, 'expired', false);
				_.set(parameters, 'visible', true);
			};

			plan_consumed?.uploads >= plan?.max_uploads ||
			plan_consumed?.seconds >= plan?.max_minutes ||
			currentDate > expiredOn
				? onExpired()
				: notExpired();
		} else {
			parameters = {
				expired: false,
				visible: true,
				canceled_on: canceled_on
			};
		}

		dispatch(expirationPlanValidate(parameters));
	}, [location.pathname, dispatch]);

	useLayoutEffect(() => {
		expirationPlanCallback();
	}, [expirationPlanCallback]);

	return (
		<Fragment>
			<Layout style={{ minHeight: '100vh' }}>
				<Layout.Sider
					width={280}
					collapsible
					theme="light"
					breakpoint="xl"
					collapsed={collapsed}
					onCollapse={onCollapse}
				>
					<SideMenu selectKey={props.location.pathname} />
				</Layout.Sider>
				<Layout className="site-layout">
					<Layout.Header className="site-layout-background" style={{ padding: 0, background: '#fff' }}>
						<Row type="flex" gutter={14} justify="end" style={{ margin: '0 36px' }}>
							<SelectWorkspace />
							<Popover />
							<SelectProject />
						</Row>
					</Layout.Header>
					<Layout.Content
						className="site-layout-background"
						style={{
							margin: '24px 16px',
							padding: 24,
							minHeight: 280
						}}
					>
						<BreadcrumbLayout {...props} />
						{!(
							props.location.pathname.includes('reporting') ||
							props.location.pathname.includes('upload') ||
							props.location.pathname.includes('/') ||
							props.location.pathname.includes('/settings/preferences')
						) ? (
							<ChildrenContainer>{props.children()}</ChildrenContainer>
						) : (
							<ContainerChildren>{props.children()}</ContainerChildren>
						)}
					</Layout.Content>
				</Layout>
			</Layout>
		</Fragment>
	);
}
