import styled from 'styled-components';

const ContainerStatus = styled.div`
	width: 100%;
	height: 340px;
	background: #f8f7f7;
	text-align: center;
	color: #666;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
`;

export const Title = styled.h3`
	font-weight: bold;
	font-size: 16px;
`;

export const Loading = styled(ContainerStatus)``;
export const Error = styled(ContainerStatus)``;
export const Wrapper = styled.div``;
