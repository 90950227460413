import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { BaseContext } from '@/types';

export type UpdateMtmReportCommentDTO = BaseContext & {
	id: string;
	comment: string;
};

type UpdateMtmReportCommentResponse = BaseContext & {
	id: string;
};

const updateMtmReportComment = async (body: UpdateMtmReportCommentDTO): Promise<UpdateMtmReportCommentResponse> => {
	const { data } = await Api.put<UpdateMtmReportCommentResponse>(`/mtm/report/${body.id}/comment`, body);
	return data;
};

export const useUpdateMtmReportComment = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateMtmReportCommentDTO) => updateMtmReportComment(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: ({ id, company_id, organization_id }) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORT_RESULT]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORT, { id, company_id, organization_id }]);
			message.success(I18n.get('MTM comment updated successfully'));
		}
	});
};
