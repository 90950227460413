import { ApplicationStorage, Company, Organization, Payload, User } from './types';

export const Types = {
	CLEAR_PROJECT: 'CLEAR_PROJECT',
	SET_ORGANIZATION: 'SET_ORGANIZATION',
	SET_COMPANY: 'SET_COMPANY',
	SET_USER: 'SET_USER',
};

type ActionProps = {
	type: string;
	payload: Payload;
};

export const AppReducer = (state: ApplicationStorage, action: ActionProps) => {
	switch (action.type) {
		case Types.CLEAR_PROJECT:
			return {
				organization: {} as Organization,
				company: {} as Company,
				user: {} as User,
				setOrganization: () => {},
				setCompany: () => {},
				setUser: () => {},
				clearProject: () => {},
			};

		case Types.SET_ORGANIZATION:
			return {
				...state,
				organization: action.payload.organization,
			};

		case Types.SET_COMPANY:
			return {
				...state,
				company: action.payload.company,
			};

		case Types.SET_USER:
			return {
				...state,
				user: action.payload.user,
			};

		default:
			return state;
	}
};
