import React, { useEffect, useState, useCallback } from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Progress, Input, List, message } from 'antd';
import {
	PlusOutlined,
	ExpandOutlined,
	CheckCircleOutlined,
	DeleteOutlined,
	EditOutlined,
} from '@ant-design/icons';
import {
	TaskManagerContainer,
	AddTask,
	TaskList,
	taskIconStyle,
	ActionButton,
	ScrollableContainer,
} from './styles';
import Api from '@/services/api';
import { useSelector } from 'react-redux';

export function TaskManager({
	actionPlan,
	calcProgress,
	triggerReloadHistory,
	loadingTasks,
	setLoadingTasks,
}) {
	const [editing, setEditing] = useState(
		Array(actionPlan.tasks.length).fill(false)
	);

	const [triggerReloadTasks, setTriggerReloadTasks] = useState(false);
	const [error, setError] = useState(null);
	const [tasks, setTasks] = useState([]);
	const [taskInput, setTaskInput] = useState('');
	const [taskEditInput, setTaskEditInput] = useState('');

	const organizationId = useSelector(
		({ organization }) => organization.organization?.id
	);

	useEffect(() => {
		setLoadingTasks(true);
		getTasks();
	}, [triggerReloadTasks]);

	useEffect(() => {
		if (error) {
			const result = error.response?.data?.message || error.message;
			const description = I18n.get(result);
			message.error(description);
			setError(null);
		}
	}, [error]);

	const editInputRef = useCallback((node) => {
		if (node) {
			node.focus();
		}
	}, []);

	const getTasks = async () => {
		if (!actionPlan.id) return;
		let url = `/action_plan/${actionPlan.id}/task?organization_id=${organizationId}`;
		try {
			const { data } = await Api.get(url);
			setTasks(data);
			setLoadingTasks(false);
		} catch (errors) {
			setError(errors);
		}
	};

	const handleInputChange = (e, setInput) => {
		setInput(e.target.value);
	};

	const onCreateTask = async () => {
		if (!taskInput) return;

		const task = { description: taskInput, action_plan_id: actionPlan.id };
		const url = `/action_plan/${actionPlan.id}/task?organization_id=${organizationId}`;
		const body = { task };

		try {
			const {
				data: { data: new_task },
			} = await Api.post(url, body);
			createTask(new_task);
			triggerReloadHistory();
		} catch (err) {
			setTriggerReloadTasks((prev) => !prev);
			setError(err);
		}
		setTaskInput('');
	};

	const onToggleTask = async (e, task) => {
		e.stopPropagation();
		const body = { task: { ...task, is_completed: !task.is_completed } };
		const url = `/action_plan/${actionPlan.id}/task/${task.id}?organization_id=${organizationId}`;
		try {
			await Api.put(url, body);
			toggleTask(task);
			triggerReloadHistory();
		} catch (errors) {
			setTriggerReloadTasks((prev) => !prev);
			setError(errors);
		}
	};

	const onDeleteTask = async (e, { task }) => {
		e.stopPropagation();
		const url = `/action_plan/${actionPlan.id}/task/${task.id}?organization_id=${organizationId}`;
		try {
			await Api.delete(url);
			deleteTask(task);
			triggerReloadHistory();
		} catch (errors) {
			setTriggerReloadTasks((prev) => !prev);
			setError(errors);
		}
	};

	const onStartEditTask = (e, { task, taskIndex }) => {
		e.stopPropagation();

		if (editing[taskIndex] === true) {
			onFinishEditTask(task, taskIndex);
			return;
		}

		if (editing.some((item) => item === true)) {
			message.warn(I18n.get('Finish editing your other task first'));
			return;
		}

		setTaskEditInput(task.description);

		setEditing((prev) => [
			...prev.slice(0, taskIndex),
			true,
			...prev.slice(taskIndex + 1),
		]);
	};

	const onFinishEditTask = async (task, taskIndex) => {
		setEditing((prev) => [
			...prev.slice(0, taskIndex),
			false,
			...prev.slice(taskIndex + 1),
		]);

		if (task.description === taskEditInput) {
			setTaskEditInput('');
			return;
		}

		const body = { task: { ...task, description: taskEditInput } };
		const url = `/action_plan/${actionPlan.id}/task/${task.id}?organization_id=${organizationId}`;
		try {
			await Api.put(url, body);
			editTask(body.task);
			triggerReloadHistory();
		} catch (errors) {
			setTriggerReloadTasks((prev) => !prev);
			setError(errors);
		}

		setTaskEditInput('');
	};

	// Functions below are only for state management / front end: createTask, toggleTask, deleteTask, editTask
	const createTask = (task) => {
		task.is_completed = false;
		setTasks((prev) => [...prev, task]);
	};

	const toggleTask = (task) => {
		const taskIndex = tasks.findIndex((item) => item.id === task.id);
		task.is_completed = !task.is_completed;
		setTasks((prev) => [
			...prev.slice(0, taskIndex),
			task,
			...prev.slice(taskIndex + 1),
		]);
	};

	const deleteTask = (task) => {
		setTasks((prev) => prev.filter((item) => item.id !== task.id));
	};

	const editTask = (task) => {
		const taskIndex = tasks.findIndex((item) => item.id === task.id);
		setTasks((prev) => [
			...prev.slice(0, taskIndex),
			task,
			...prev.slice(taskIndex + 1),
		]);
	};

	const TaskIcon = ({ task }) => (
		<React.Fragment>
			{task.is_completed ? (
				<CheckCircleOutlined
					onClick={(e) => onToggleTask(e, task)}
					style={{
						...taskIconStyle,
						color: 'green',
					}}
				></CheckCircleOutlined>
			) : (
				<ExpandOutlined
					onClick={(e) => onToggleTask(e, task)}
					style={taskIconStyle}
				></ExpandOutlined>
			)}
		</React.Fragment>
	);

	const EditButton = (task, taskIndex) => (
		<ActionButton
			onClick={(e) => onStartEditTask(e, task, taskIndex)}
			className="edit-btn"
		>
			<EditOutlined />
		</ActionButton>
	);

	const DeleteButton = (task) => (
		<ActionButton
			onClick={(e) => onDeleteTask(e, task)}
			className="delete-btn"
		>
			<DeleteOutlined />
		</ActionButton>
	);

	return (
		<TaskManagerContainer>
			<Progress percent={calcProgress(tasks)}></Progress>
			<AddTask>
				<Input
					value={taskInput}
					onChange={(e) => handleInputChange(e, setTaskInput)}
					onPressEnter={onCreateTask}
					placeholder={I18n.get('Add a new task')}
					style={{ width: '90%' }}
				></Input>
				<Button
					shape="circle"
					style={{ marginLeft: '10px' }}
					onClick={onCreateTask}
				>
					<PlusOutlined></PlusOutlined>
				</Button>
			</AddTask>
			{!loadingTasks && (
				<TaskList>
					<ScrollableContainer style={{ maxHeight: '400px' }}>
						<List
							size="small"
							dataSource={tasks}
							renderItem={(task, taskIndex) => (
								<List.Item
									onClick={(e) =>
										onStartEditTask(e, { task, taskIndex })
									}
									style={{ cursor: 'pointer' }}
									actions={[
										<EditButton
											key={taskIndex}
											task={task}
											taskIndex={taskIndex}
										/>,
										<DeleteButton
											key={taskIndex}
											task={task}
										/>,
									]}
								>
									<TaskIcon task={task}></TaskIcon>
									{editing[taskIndex] ? (
										<Input
											ref={editInputRef}
											value={taskEditInput}
											onChange={(e) =>
												handleInputChange(
													e,
													setTaskEditInput
												)
											}
											onClick={(e) => e.stopPropagation()}
											onPressEnter={() =>
												onFinishEditTask(
													task,
													taskIndex
												)
											}
										></Input>
									) : (
										task.description
									)}
								</List.Item>
							)}
						/>
					</ScrollableContainer>
				</TaskList>
			)}
		</TaskManagerContainer>
	);
}
