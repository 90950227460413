import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

export interface CreateFilesActivitiesDTO {
	id: string;
	files: string[];
	company_id: string;
	organization_id: string;
}

type CreateFilesActivitiesResponse = {
	status: string;
	message: string;
	data: {
		id: string;
		company_id: string;
		organization_id: string;
		need_to_recalculate: boolean;
	}
};

async function createRotationReportFiles({id, ...params}: CreateFilesActivitiesDTO): Promise<CreateFilesActivitiesResponse> {
	const url = `/rotation/report/${id}/files`;
	const { data } = await Api.post(url, params);
	return data;
}

export const useCreateRotationReportFiles = () => {
	const queryClient = useQueryClient();

	return useMutation((prams: CreateFilesActivitiesDTO) => createRotationReportFiles(prams), {
		onSuccess: ({ data, message: txt }) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_FILES, data]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT, data]);
			message.success(I18n.get(txt));
		}
	});
};
