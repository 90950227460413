import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { CreateMtmReportDTO } from './useCreateMtmReport';
import { BaseContext, MtmReport } from '@/types';

export type UpdateMtmReportDTO = CreateMtmReportDTO &  BaseContext &{
	id: string;
	current_step: number;
};

type MtmReportResponse = BaseContext & MtmReport;

const updateMtmReport = async (body: UpdateMtmReportDTO): Promise<MtmReportResponse> => {
	const { data } = await Api.put<MtmReportResponse>(`/mtm/report/${body.id}`, body);
	return data;
};

export const useUpdateMtmReport = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateMtmReportDTO) => updateMtmReport(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: ({ id, company_id, organization_id }) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORT_RESULT]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_FILES_WITH_OPERATIONS]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORTS_PAGINATED]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORT_OPERATIONS, { organization_id, company_id, id }]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORT, { id, company_id, organization_id }]);
			message.success(I18n.get('MTM updated successfully'));
		}
	});
};
