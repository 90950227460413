import React from 'react';
import { I18n } from '@aws-amplify/core';
import { FrownOutlined } from '@ant-design/icons';
import { CardChart, FrameError, Title } from './styles';

export const ErrorChart = ({ minHeight }) => (
	<CardChart minHeight={minHeight}>
		<FrameError>
			<FrownOutlined />
			<Title>{I18n.get('Failed to fetch')}</Title>
		</FrameError>
	</CardChart>
);
