import { Row } from 'antd';
import React, { ReactNode } from 'react';
import { Header } from './Header';

import { DividerCustom } from '@/components/ui/DividerCustom';
import * as S from './styles';
import { Footer } from './Footer';

interface ReportTemplateProps {
	children?: ReactNode;
	isLoadingPDF: boolean;
	isConsolidating: boolean;
	isConsolidated: boolean;
	hasConsolidated: boolean;
	isDownloadingSpreadsheet: boolean;
	onConsolidatePDF: () => void;
	onDownloadPDF: () => Promise<void>;
	onCommentSave: (comment: string) => void;
	onDownloadSpreadsheet: () => Promise<void>;
}

export function ReportTemplate({
	children,
	isLoadingPDF,
	isConsolidated,
	isConsolidating,
	hasConsolidated,
	onDownloadPDF,
	onCommentSave,
	onConsolidatePDF,
	onDownloadSpreadsheet,
	isDownloadingSpreadsheet,
}: Readonly<ReportTemplateProps>) {
	return (
		<Row justify="center">
			<S.ReportContainer xs={24} sm={22}>
				<Header title="MTM" />
				<Row justify="center">
					<DividerCustom span={22} color="#E6E8E8" thickness="1px" margin={'0 0 43px 0'} />
				</Row>
				{children}
				<Footer
					isDownloadingSpreadsheet={isDownloadingSpreadsheet}
					isLoadingPDF={isLoadingPDF}
					isConsolidating={isConsolidating}
					isConsolidated={isConsolidated}
					hasConsolidated={hasConsolidated}
					onCommentSave={onCommentSave}
					onDownloadSpreadsheet={onDownloadSpreadsheet}
					onDownloadPDF={onDownloadPDF}
					onConsolidatePDF={onConsolidatePDF}
				/>
			</S.ReportContainer>
		</Row>
	);
}
