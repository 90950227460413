import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

const Wrapper = styled.div`
	margin: 40px 0;
`;

const Title = styled.h3`
	font-weight: bold;
`;

export function OperatorReport() {
	const operator_report = useSelector(
		(state) => state.checklist.operator_report
	);

	if (!operator_report) return null;

	return (
		<Wrapper>
			<Title>{I18n.get('Worker report')}</Title>
			<p>{operator_report}</p>
		</Wrapper>
	);
}
