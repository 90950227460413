import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';

import Api from '@/services/api';
import { mountURL } from '../helpers';
import { Loading, Error } from './styles';

export function FetchData({ children }) {
	const organization = useSelector((state) => state.organization.organization);

	const payload = { organization };

	async function getComparative({ organization }) {
		if (!organization) return;
		const url = mountURL('/dashboard/consolidated-period', { organization });
		const { data } = await Api.get(url);
		return data;
	}

	const { isLoading, data, isError } = useQuery(['consolidated-period', payload], () =>
		getComparative(payload)
	);

	if (isLoading) {
		return <Loading>{I18n.get('Loading')}</Loading>;
	}

	if (isError) {
		return <Error>{I18n.get('Unable to show results')}</Error>;
	}

	const chartWithProps = React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, { data });
		}
		return child;
	});

	return <div>{chartWithProps}</div>;
}
