import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getSectors({ organizationId, companyId }) {
	if (!organizationId || !companyId) return;
	const url = `/company/${organizationId}/${companyId}/sector`;
	const { data } = await Api.get(url);
	return data;
}

export function useSector(payload) {
	const sectors = useQuery(['sectors', payload], () => getSectors(payload));
	return { sectors };
}
