import * as React from 'react';

export const Clapperboard: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg
			width="1em"
			height="1em"
			fill="currentColor"
			viewBox="0 0 29 31"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M26.182 30.633H3.97c-.236-.071-.48-.126-.708-.22-1.129-.465-1.758-1.468-1.758-2.794-.002-4.616 0-9.231-.004-13.848a1.95 1.95 0 00-.082-.555C.971 11.729.515 10.245.066 8.759c-.197-.651.053-1.134.687-1.328L22.093.896c.947-.29 1.89-.597 2.835-.896h.477c.427.182.633.523.76.96.433 1.48.892 2.952 1.34 4.429.253.829.04 1.242-.78 1.494L9.343 12.209c-.103.032-.206.07-.309.103l.018.053H27.443c.832 0 1.17.342 1.17 1.185v4.636c0 3.15.003 6.302 0 9.452-.001 1.379-.755 2.457-1.981 2.86-.148.048-.3.09-.449.134v.001zm-22.77-16.34v13.269c0 .83.328 1.158 1.154 1.158h20.989c.803 0 1.147-.341 1.147-1.142V14.623v-.33H3.412zm9.998-5.332c-.03-.048-.04-.079-.06-.096-.949-.757-1.896-1.518-2.852-2.264-.08-.062-.245-.067-.35-.036-.981.29-1.957.593-2.935.894l-1.508.463c1.024.815 2.002 1.597 2.988 2.369.066.052.207.046.3.02.648-.189 1.292-.39 1.937-.589l2.481-.76zm-.82-3.15c1.024.815 1.993 1.592 2.972 2.356.073.058.223.059.32.03.96-.285 1.919-.581 2.877-.874l1.536-.472c-.036-.056-.047-.085-.068-.102-.948-.757-1.894-1.517-2.85-2.262-.08-.062-.241-.064-.348-.033-.535.15-1.063.323-1.594.486l-2.844.87zm11.933-3.656L19.52 3.688c.042.058.055.086.076.103.948.758 1.897 1.517 2.852 2.266.068.054.203.065.293.04.373-.1.742-.222 1.112-.336.538-.166 1.076-.33 1.62-.496l-.95-3.11h-.001zm-18 8.916c-.012-.035-.014-.047-.02-.053-.97-.778-1.94-1.556-2.913-2.33-.049-.04-.133-.076-.186-.061-.416.12-.827.253-1.265.39l.949 3.107 3.435-1.051v-.002z" />
			<path d="M11.153 21.492c0-1.075-.002-2.152 0-3.228.002-.969.74-1.398 1.577-.915 1.86 1.073 3.718 2.147 5.575 3.223.874.507.87 1.346-.007 1.854-1.857 1.076-3.717 2.15-5.576 3.224-.823.475-1.564.05-1.569-.9-.005-1.087 0-2.172 0-3.259v.001zm1.934-1.728v3.467l2.994-1.735-2.994-1.732z" />
		</svg>
	);
};
