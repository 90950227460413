import styled, { css } from 'styled-components';
import { Col } from 'antd';
import { DownloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Clapperboard } from '@/assets/icons/Clapperboard';

export const iconSize = css`
	font-size: 25px !important;
`;

export const EditIcon = styled(EditOutlined)`
	${iconSize}
`;

export const DownloadFileIcon = styled(DownloadOutlined)`
	${iconSize}
`;

export const DeleteFileIcon = styled(DeleteOutlined)`
	color: #e74150;
	${iconSize}
`;

export const FilesIcon = styled(Clapperboard)`
	${iconSize}
`;

export const ActionsInline = styled(Col)`
	display: none;

	@media (min-width: 768px) {
		display: flex;
		justify-content: center;
	}
`;
