import Api from '@/services/api';
import { BaseContext } from '@/types';
import { useMutation } from '@tanstack/react-query';

export type GetChronoAnalysisReportCSV = BaseContext & {
	chrono_analysis_id: string;
	locale?: string;
}

async function getChronoAnalysisSpreadsheetData(payload: GetChronoAnalysisReportCSV): Promise<string[][]> {
	const { data } = await Api.post('/chrono-analysis/spreadsheet', payload);
	return data;
}

export function useGetChronoAnalysisSpreadsheetData(params: GetChronoAnalysisReportCSV) {
	return useMutation(() => getChronoAnalysisSpreadsheetData(params));
}
