import React, { useContext } from 'react';
import { Collapse, Form, Row, Col, Button, Select, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ActionPlansContext } from './context';
import { actionPlanDateStatus } from '@/utils/enum';
import { I18n } from '@aws-amplify/core';
import styled from 'styled-components';
const { Option } = Select;

export const Container = styled.div`
	padding-bottom: 10px;
	width: 100%;
`;

export function Filter() {
	const [form] = Form.useForm();

	const {
		companies,
		selectCompanyId,
		setSelectCompanyId,
		sectors,
		selectSectorId,
		setSelectSectorId,
		lines,
		selectLineId,
		setSelectLineId,
		workstations,
		selectWorkstationId,
		setSelectWorkstationId,
		complaints,
		setSelectComplaintId,
		responsible_workers,
		onFilterActionPlans
	} = useContext(ActionPlansContext);

	function resetFieldsForm(field) {
		const options = {
			company: ['sector', 'line', 'workstation'],
			sector: ['line', 'workstation'],
			line: ['workstation'],
			workstation: ['complaint']
		};

		form.resetFields(options[field] || []);
	}

	function onClearFilter() {
		form.resetFields();
		setSelectCompanyId(null);
		setSelectSectorId(null);
		setSelectLineId(null);
		onFilterActionPlans();
	}

	function onSubmit() {
		form.validateFields()
			.then(onFilterActionPlans)
			.catch((errInfo) => {});
	}

	return (
		<Container>
			<Collapse defaultActiveKey={['0']}>
				<Collapse.Panel header={I18n.get('Filters')} key="1">
					<Form form={form} layout="vertical" onFinish={onSubmit}>
						<Row gutter={[10, 0]}>
							<Col xs={24} sm={10}>
								<Form.Item name="description" label={I18n.get('Action plan description')}>
									<Input placeholder={I18n.get('Description')} />
								</Form.Item>
							</Col>
							<Col xs={24} sm={5}>
								<Form.Item name="responsible_worker_id" label={I18n.get('Person responsible')}>
									<Select
										showSearch
										allowClear
										style={{ width: '100%' }}
										placeholder={I18n.get('Select an option')}
										optionFilterProp="children"
									>
										{responsible_workers.map((person, index) => (
											<Option key={index} value={person.id}>
												{person.name}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={5}>
								<Form.Item name="deadline_status" label={I18n.get('Status')}>
									<Select
										allowClear
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Select an option')}
										optionFilterProp="children"
									>
										{actionPlanDateStatus.map((option) => (
											<Select.Option key={option.value} value={option.value}>
												{I18n.get(option.description)}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={5}>
								<Form.Item name="company" label={I18n.get('Industrial site')}>
									<Select
										allowClear
										showSearch
										onChange={(value) => {
											setSelectCompanyId(value);
											resetFieldsForm('company');
										}}
										style={{ width: '100%' }}
										optionFilterProp="children"
										placeholder={I18n.get('Select a industrial site')}
									>
										{companies.map((company, key) => (
											<Select.Option key={key} value={company.id}>
												{company.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={5}>
								<Form.Item name="sector" label={I18n.get('Sector')}>
									<Select
										allowClear
										showSearch
										onChange={(value) => {
											setSelectSectorId(value);
											resetFieldsForm('sector');
										}}
										style={{ width: '100%' }}
										optionFilterProp="children"
										disabled={!selectCompanyId}
										placeholder={I18n.get('Select a sector')}
									>
										{sectors.map((sector, key) => (
											<Select.Option key={key} value={sector.id}>
												{sector.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={5}>
								<Form.Item name="line" label={I18n.get('Line')}>
									<Select
										allowClear
										showSearch
										onChange={(value) => {
											setSelectLineId(value);
											resetFieldsForm('line');
										}}
										style={{ width: '100%' }}
										optionFilterProp="children"
										placeholder={I18n.get('Select a line')}
										disabled={!selectSectorId}
									>
										{lines.map((line, key) => (
											<Select.Option key={key} value={line.id}>
												{line.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={5}>
								<Form.Item name="workstation" label={I18n.get('Workstation')}>
									<Select
										allowClear
										showSearch
										onChange={(value) => {
											setSelectWorkstationId(value);
											resetFieldsForm('workstation');
										}}
										style={{ width: '100%' }}
										optionFilterProp="children"
										placeholder={I18n.get('Select a workstation')}
										disabled={!selectLineId}
									>
										{workstations.map((workstation, key) => (
											<Select.Option key={key} value={workstation.id}>
												{workstation.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={4}>
								<Form.Item name="complaint" label={I18n.get('Complaint')}>
									<Select
										allowClear
										showSearch
										onChange={setSelectComplaintId}
										style={{ width: '100%' }}
										optionFilterProp="children"
										placeholder={I18n.get('Select a complaint')}
										disabled={!selectWorkstationId}
									>
										{complaints.map((complaint, key) => (
											<Select.Option key={key} value={complaint.id}>
												{complaint.description}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={6} style={{ paddingTop: '20px' }}>
							<Col sm={3}>
								<Button
									type="primary"
									htmlType="submit"
									style={{ width: '100%' }}
									icon={<SearchOutlined />}
								>
									{I18n.get('Search')}
								</Button>
							</Col>
							<Col sm={3}>
								<Button onClick={onClearFilter} style={{ width: '100%' }}>
									{I18n.get('Clear')}
								</Button>
							</Col>
						</Row>
					</Form>
				</Collapse.Panel>
			</Collapse>
		</Container>
	);
}
