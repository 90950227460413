const Types: Record<string, string> = {
	SET_CONSOLIDATED_CHECKLIST: 'SET_CONSOLIDATED_CHECKLIST',
	SET_STEP_CHECKLIST: 'SET_STEP_CHECKLIST',
	CLEAR_STATE_CHECKLIST: 'CLEAR_STATE_CHECKLIST',
	NEXT_STEP_CHECKLIST: 'NEXT_STEP_CHECKLIST',
	PREVIOUS_STEP_CHECKLIST: 'PREVIOUS_STEP_CHECKLIST',
	SET_CHECKLIST: 'SET_CHECKLIST',
	SET_FILE_CHECKLIST: 'SET_FILE_CHECKLIST',
	ADD_EFFORT_CHECKLIST: 'ADD_EFFORT_CHECKLIST',
	ADD_REACTION_CHECKLIST: 'ADD_REACTION_CHECKLIST',
	REMOVE_REACTION_CHECKLIST: 'REMOVE_REACTION_CHECKLIST',
	ADD_VIBRATION_CHECKLIST: 'ADD_VIBRATION_CHECKLIST',
	REMOVE_VIBRATION_CHECKLIST: 'REMOVE_VIBRATION_CHECKLIST',
	ADD_PERCUSSION_CHECKLIST: 'ADD_PERCUSSION_CHECKLIST',
	ADD_HIT_AGAINST_CHECKLIST: 'ADD_HIT_AGAINST_CHECKLIST',
	ADD_REGULATION_NOTES_CHECKLIST: 'ADD_REGULATION_NOTES_CHECKLIST',
	ADD_OPERATOR_REPORT_CHECKLIST: 'ADD_OPERATOR_REPORT_CHECKLIST',
	ADD_CONTROL_DIFFICULTY_CHECKLIST: 'ADD_CONTROL_DIFFICULTY_CHECKLIST',
	ADD_DISPLACEMENT_CHECKLIST: 'ADD_DISPLACEMENT_CHECKLIST',
	REMOVE_EFFORT_CHECKLIST: 'REMOVE_EFFORT_CHECKLIST',
	ADD_WRIST_CHECKLIST: 'ADD_WRIST_CHECKLIST',
	SET_INFORMATIONS_CHECKLIST: 'SET_INFORMATIONS_CHECKLIST',
	SET_COMPANY_MOSAIC: 'SET_COMPANY_MOSAIC'
};

export default Types;
