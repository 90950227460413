import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ActivitySelect } from '@/components/ui/Inputs/ActivitySelect';
import { useGetActivities } from '@/hooks/Rotation/useGetActivities';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

export function Activity() {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const organizationId = useWatch('organization_id', form);
	const companyId = useWatch('company_id', form);
	const activityId = useWatch('activity_id', form);

	const {
		isError,
		isLoading,
		isFetching,
		data: activities
	} = useGetActivities({ organization_id: organizationId, company_id: companyId });

	function handleActivitySelect(id: string): void {
		setFieldValue(['activity_id'], id);
	}

	function handleClearActivity(): void {
		setFieldValue(['activity_id'], undefined);
	}

	return (
		<Col xs={24}>
			<Form.Item name={['activity_id']} label={I18n.get('Activity')} labelCol={{ span: 24 }}>
				<S.ContainerSelect $borderRadius="6px">
					<ActivitySelect
						activities={activities}
						activityId={activityId}
						fieldName="activity_id"
						loading={isLoading || isFetching}
						onClear={handleClearActivity}
						onChange={handleActivitySelect}
						disabled={isLoading || isFetching || isError}
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
}
