import { Button, Row } from 'antd';
import styled from 'styled-components';

export const CustomRow = styled(Row)`
	background-color: #eaeefd;
	border-radius: 18px;
	margin-bottom: 30px;
	padding: 20px;

	.ant-form-item {
		margin-bottom: 0;
	}
`;

export const AddButton = styled(Button)`
	background-color: #2f54eb;
	color: #fff;
	border-radius: 12px;
	height: 100%;
	width: 100%;
	min-height: 40px;

	@media (min-width: 1200px) {
		width: 42px;
	}

	@media (min-width: 1600px) {
		width: 48px;
	}
`;
