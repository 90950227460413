import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CreateLineDTO } from '@/components/ui/Inputs/types/line';
import Api from '@/services/api';
import { Line } from '@/types';

const createLine = async (body: CreateLineDTO): Promise<Line> => {
	const url = `/line/create`;
	const { data } = await Api.post<Line>(url, body);
	return data;
};

export const useCreateLine = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: CreateLineDTO }) => createLine(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['lines']);
			queryClient.invalidateQueries(['lines_list']);
			message.success(I18n.get('Line created successfully'));
		}
	});
};
