import { useMutation } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { queryClient } from '@/store/query';
import Api from '@/services/api';

export type UpdateRotationReportExposureDTO = {
	organization_id?: string;
	company_id?: string;
	id?: string;
};

export type RotationReportExposureResponse = {
	message: string;
};

async function updateRotationReportExposure(params: UpdateRotationReportExposureDTO): Promise<any> {
	const url = `/rotation/report/${params.id}/cycle/exposure`;
	const { data } = await Api.post(url, params);
	return data;
}

export const useUpdateRotationReportExposure = () => {
	return useMutation((prams: UpdateRotationReportExposureDTO) => updateRotationReportExposure(prams), {
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_BODY_PART_EXPOSURE]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_RESULT]);
		}
	});
};
