import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { DragDropContext } from 'react-beautiful-dnd';

import { Header } from './Header';
import { CreateActionPlan } from './Create';
import { EditActionPlan } from './EditActionPlan';
import { BoardColumn } from './BoardColumn';
import { Filter } from './Filter';
import { LexoRank } from './LexoRank';

import Api from '@/services/api';
import Spinner from '@/components/SpinnerDeprecated';
import { useIsMounted } from '@/hooks/useIsMounted';

import { actionPlanBoards } from '@/utils/enum';
import { ActionPlansContext } from './context';

const initState = {
	companies: [],
	selectCompanyId: '',
	sectors: [],
	selectSectorId: '',
	lines: [],
	selectLineId: '',
	workstations: [],
	selectWorkstationId: '',
	complaints: [],
	setSelectComplaintId: '',
	responsible_workers: [],
	actionPlans: [],
	triggerReload: false,
	errors: null,
	actionPlanEditModal: false,
	actionPlanCreateModal: false,
	currentActionPlan: {},
	creationBoard: '',
	currentFilter: ''
};

const lexorank = new LexoRank();

export function ActionPlans() {
	const isMounted = useIsMounted();

	const [state, setState] = useState(initState);
	const [loading, setLoading] = useState(true);

	const columnOrder = actionPlanBoards;

	const organizationId = useSelector(({ organization }) => organization.organization?.id);

	function setSelectCompanyId(value) {
		setState((prev) => ({
			...prev,
			selectCompanyId: value,
			selectSectorId: null,
			selectLineId: null
		}));
	}

	function setSelectSectorId(value) {
		setState((prev) => ({
			...prev,
			selectSectorId: value,
			selectLineId: null
		}));
	}

	function setSelectLineId(value) {
		setState((prev) => ({ ...prev, selectLineId: value }));
	}

	function setSelectWorkstationId(value) {
		setState((prev) => ({ ...prev, selectWorkstationId: value }));
	}

	function setSelectComplaintId(value) {
		setState((prev) => ({ ...prev, selectComplaintId: value }));
	}

	function setTriggerReload() {
		setState((prev) => ({ ...prev, triggerReload: !prev.triggerReload }));
	}

	function setErrors(errors) {
		setState((prev) => ({ ...prev, errors }));
	}

	async function getActionPlans(organizationId) {
		if (!organizationId) return;
		const url = state.currentFilter
			? `/action_plan/?${state.currentFilter}`
			: `/action_plan/?organization_id=${organizationId}`;

		try {
			const { data: actionPlans } = await Api.get(url);

			isMounted() && setState((prev) => ({ ...prev, actionPlans }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		} finally {
			isMounted() && setLoading(false);
		}
	}

	function onDeleteActionPlan(actionPlanId) {
		Modal.confirm({
			title: I18n.get('Warning!'),
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			icon: <ExclamationCircleOutlined />,
			content: I18n.get('Do you want to delete this action plan?'),
			onOk() {
				return new Promise(async (resolve, reject) => {
					const url = `/action_plan/${actionPlanId}?organization_id=${organizationId}`;
					try {
						const { data } = await Api.delete(url);
						resolve();
						message.success(I18n.get(data.message));
						setTriggerReload();
					} catch (errors) {
						setState((prev) => ({ ...prev, errors }));
						reject();
					}
				});
			}
		});
	}

	function onEditActionPlan(actionPlan) {
		setState((prev) => ({ ...prev, currentActionPlan: actionPlan }));
		setState((prev) => ({ ...prev, actionPlanEditModal: true }));
	}

	function showCreateActionPlan(board) {
		setState((prev) => ({
			...prev,
			creationBoard: board,
			actionPlanCreateModal: true
		}));
	}

	function onClose() {
		setState((prev) => ({ ...prev, actionPlanEditModal: false }));
		setState((prev) => ({ ...prev, actionPlanCreateModal: false }));
		setTriggerReload();
	}

	async function onFilterActionPlans(values) {
		if (!organizationId) return;

		const { description, responsible_worker_id, deadline_status, company, sector, line, workstation, complaint } =
			values || {};

		let url = `/action_plan?`;

		const params = {
			organization_id: organizationId,
			...(description && { description }),
			...(responsible_worker_id && {
				responsible_worker_id: responsible_worker_id
			}),
			...(deadline_status && { deadline_status }),
			...(company && { company_id: company }),
			...(sector && { sector_id: sector }),
			...(line && { line_id: line }),
			...(workstation && { workstation_id: workstation }),
			...(complaint && { complaint_id: complaint })
		};

		const paramsMounting = new URLSearchParams(params);

		url += paramsMounting.toString();

		try {
			const { data: actionPlans } = await Api.get(url);
			isMounted() &&
				setState((prev) => ({
					...prev,
					actionPlans,
					currentFilter: paramsMounting.toString()
				}));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	function calcProgress(tasks) {
		const completedTasks = tasks.filter((task) => task.is_completed === true);
		const progress = (completedTasks.length / tasks.length) * 100;
		return Math.round(progress);
	}

	function persistDraggedPosition({ source, destination, draggableId }) {
		const draggedItem = state.actionPlans.find((item) => item.id === draggableId);

		const destinationActionPlans = state.actionPlans.filter(
			(item) => item.board === destination.droppableId.toUpperCase()
		);

		const toIndex = destination.index;
		let prev, next;

		if (source.droppableId !== destination.droppableId) {
			prev = destinationActionPlans[toIndex - 1];
			next = destinationActionPlans[toIndex];
		} else {
			const sameBoardOffset = toIndex < destinationActionPlans.indexOf(draggedItem) ? 0 : 1;
			prev = destinationActionPlans[toIndex - 1 + sameBoardOffset];
			next = destinationActionPlans[toIndex + sameBoardOffset];
		}

		const prevLexo = prev ? prev.lexo_rank : '0';
		const nextLexo = next ? next.lexo_rank : 'z';

		let [newLexoRank] = lexorank.insert(prevLexo, nextLexo);

		draggedItem.board = destination.droppableId.toUpperCase();
		draggedItem.lexo_rank = newLexoRank;

		return [state.actionPlans, draggedItem];
	}

	function onDragEnd(result) {
		const { destination, source, draggableId } = result;

		if (!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const [actionPlans, draggedActionPlan] = persistDraggedPosition({
			source,
			destination,
			draggableId
		});

		actionPlans.sort((a, b) => (a.lexo_rank < b.lexo_rank ? -1 : a.lexo_rank > b.lexo_rank ? 1 : 0));

		setState((prev) => ({ ...prev, actionPlans }));
		updateActionPlanBoard(draggedActionPlan);
	}

	async function updateActionPlanBoard(action_plan) {
		const body = { action_plan };
		const url = `/action_plan/${action_plan.id}?organization_id=${organizationId}`;

		try {
			await Api.put(url, body);
		} catch (err) {
			setErrors(err);
		}
	}

	async function getCompanies(organizationId) {
		if (!organizationId) return;
		let url = `/company/user_list/${organizationId}`;
		try {
			const { data: companies } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, companies }));
		} catch (err) {
			isMounted() && setState((prev) => ({ ...prev, err }));
		}
	}

	async function getSectors(organizationId, companyId) {
		if (!organizationId || !companyId) return;
		let url = `/sector/${organizationId}/${companyId}`;
		try {
			const { data: sectors } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, sectors }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getLines(organizationId, companyId, sectorId) {
		if (!organizationId || !companyId || !sectorId) return;
		let url = `/line/${organizationId}/${companyId}/${sectorId}`;
		try {
			const { data: lines } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, lines }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getWorkstations(organizationId, companyId, sectorId, lineId) {
		if (!organizationId || !companyId || !sectorId || !lineId) return;
		let url = `/workstation/${organizationId}/${companyId}/${lineId}`;
		try {
			const { data: workstations } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, workstations }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getComplaintsByWorkstation(organizationId, companyId, sectorId, lineId, workstationId) {
		if (!organizationId || !companyId || !sectorId || !lineId || !workstationId) return;

		let url = `/complaint/${organizationId}/${workstationId}`;
		try {
			const { data: complaints } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, complaints }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getResponsibleWorkers(organizationId) {
		if (!organizationId) return;
		let url = `/worker?organization_id=${organizationId}&worker_type=MANAGER`;
		try {
			const { data: responsible_workers } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, responsible_workers }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	const {
		selectCompanyId,
		selectSectorId,
		selectLineId,
		selectWorkstationId,
		selectComplaintId,
		triggerReload,
		errors
	} = state;

	useEffect(() => {
		getResponsibleWorkers(organizationId);
		getCompanies(organizationId);
		getActionPlans(organizationId);
	}, [organizationId]);

	useEffect(() => {
		getActionPlans(organizationId);
	}, [triggerReload, organizationId]);

	useEffect(() => {
		getSectors(organizationId, selectCompanyId);
	}, [selectCompanyId, organizationId]);

	useEffect(() => {
		getLines(organizationId, selectCompanyId, selectSectorId);
	}, [selectCompanyId, selectSectorId, organizationId]);

	useEffect(() => {
		getWorkstations(organizationId, selectCompanyId, selectSectorId, selectLineId);
	}, [selectCompanyId, selectSectorId, selectLineId, organizationId]);

	useEffect(() => {
		getComplaintsByWorkstation(organizationId, selectCompanyId, selectSectorId, selectLineId, selectWorkstationId);
	}, [selectCompanyId, selectSectorId, selectLineId, selectWorkstationId, organizationId]);

	useEffect(() => {
		if (errors) {
			const result = errors.response?.data?.message || errors.message;
			const description = I18n.get(result);
			message.error(description);
			setErrors(null);
		}
	}, [errors]);

	const context = {
		...state,
		setSelectCompanyId,
		setSelectSectorId,
		setSelectLineId,
		setSelectWorkstationId,
		setSelectComplaintId,
		setTriggerReload,
		showCreateActionPlan,
		onDeleteActionPlan,
		onEditActionPlan,
		onFilterActionPlans,
		calcProgress,
		setErrors,
		getCompanies,
		getSectors,
		getLines,
		getWorkstations,
		getComplaintsByWorkstation,
		getResponsibleWorkers
	};

	if (loading) {
		return <Spinner />;
	}

	return (
		<ActionPlansContext.Provider value={context}>
			<Row>
				<Header />
				<Filter />
				<DragDropContext onDragEnd={onDragEnd}>
					{columnOrder.map((board, index) => {
						return (
							<BoardColumn
								key={index}
								title={board.name}
								bg_color={board.bg_color}
								actionPlans={state.actionPlans.filter((item) => {
									return item.board === board.name.toUpperCase();
								})}
							/>
						);
					})}
				</DragDropContext>
				<CreateActionPlan onClose={onClose} visible={state.actionPlanCreateModal} />
				{state.actionPlanEditModal && (
					<EditActionPlan
						actionPlan={state.currentActionPlan}
						responsible_workers={state.responsible_workers}
						onClose={onClose}
						calcProgress={calcProgress}
						visible={state.actionPlanEditModal}
					/>
				)}
			</Row>
		</ActionPlansContext.Provider>
	);
}
