import React from 'react';
import { Col, Form, Select } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useChecklistEIContext } from '../context';

const { useFormInstance } = Form;

export const SpeedFactorSelect = () => {
	const { resetFields } = useFormInstance();

	const { speedFactors, handleSelectSpeedFactor, mapDataToDropdown, filterOption } =
		useChecklistEIContext();

	function handleSpeedFactorSelect(value: string) {
		const speedFactor = speedFactors.data.find(
			(speedFactor) => speedFactor.id === value
		);
		if (speedFactor) {
			handleSelectSpeedFactor(value, speedFactor.value);
		}
	}

	function handleClear() {
		resetFields(['added_time']);
		handleSelectSpeedFactor('', 0);
	}

	const speedFactorOptions = mapDataToDropdown(speedFactors.data);

	return (
		<Col sm={12}>
			<Form.Item
				name="speed_factor"
				label={I18n.get('Speed factor')}
				rules={[{ required: true }]}
			>
				<Select
					showSearch
					allowClear
					onClear={handleClear}
					onChange={(value: string) => handleSpeedFactorSelect(value)}
					placeholder={I18n.get('Select a speed factor')}
					options={speedFactorOptions}
					filterOption={(input, option) => filterOption(option, input)}
					loading={speedFactors.gettingSpeedFactors}
					disabled={
						speedFactors.data.length === 0 ||
						speedFactors.errorGettingSpeedFactors
					}
				/>
			</Form.Item>
		</Col>
	);
};
