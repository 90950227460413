import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { useApplicationContext } from '@/context/application';
import { useGetWorkstations } from '@/hooks';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

export function Workstation() {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { company, organization } = useApplicationContext();

	const sectorId = useWatch('sector_id', form);
	const lineId = useWatch('line_id', form);
	const workstationId = useWatch('workstation_id', form);

	const {
		data: workstations,
		isLoading,
		isFetching,
		isError
	} = useGetWorkstations({
		organization_id: organization?.id as string,
		company_id: company?.id as string,
		sector_id: sectorId,
		line_id: lineId
	});

	function handleSelectWorkstation(id: string) {
		setFieldValue(['workstation_id'], id);
	}

	function handleClearWorkstation() {
		setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<Form.Item name={['workstation_id']} label={I18n.get('Workstation')} labelCol={{ span: 24 }}>
				<S.ContainerSelect $borderRadius="6px">
					<WorkstationSelect
						workstations={workstations}
						workstationId={workstationId}
						fieldName="workstation_id"
						lineId={lineId}
						loading={isFetching}
						allowCreation={false}
						onClear={handleClearWorkstation}
						onChange={handleSelectWorkstation}
						disabled={isLoading || isFetching || isError || !lineId}
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
}
