import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import type { OperationHierarchy } from '@/types';

export type OperationHierarchyDTO = {
	organization_id: string;
	company_id: string;
	operations_id: string[];
};

const getHierarchyByOperation = async (params: OperationHierarchyDTO): Promise<OperationHierarchy> => {
	const url = `/operation/hierarchy`;
	const { data } = await Api.get<OperationHierarchy>(url, { params });
	return data;
};

export const useGetHierarchyByOperation = (params: OperationHierarchyDTO) => {
	const allParams = Object.values(params).every((data) => data);

	const operations = useQuery(['operation-hierarchy', params], () => getHierarchyByOperation(params), {
		enabled: allParams
	});
	return operations;
};
