import { Checkbox } from 'antd';
import styled from 'styled-components';

interface CheckboxCustomProps {
	hidden?: boolean;
}

export const CheckboxCustom = styled(Checkbox)<CheckboxCustomProps>`
	width: 100%;
	height: 46px;
	display: ${(props) => (props.hidden ? 'none' : 'flex')};
	align-items: center;

	border: 1.5px solid #e6e6e6;
	border-radius: 7px;

	padding: 15px;
	margin: 0 !important;
	margin-bottom: 15px !important;
`;
