import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';
import type { ErrorResponse } from '@/types';
import type { UpdateProductDTO, UpdateProductSuccess } from '@/types/dto/product';

async function updateProduct(params: UpdateProductDTO): Promise<UpdateProductSuccess> {
	const { data } = await Api.put(`/product`, params);
	return data;
}

export const useUpdateProduct = () => {
	return useMutation<UpdateProductSuccess, ErrorResponse, UpdateProductDTO>(updateProduct);
};
