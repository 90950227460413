import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { Company } from '@/types';

const getCompanies = async (organization_id: string): Promise<Company[]> => {
	const url = `/company/user_list/${organization_id}`;
	const { data } = await Api.get<Company[]>(url);
	return data;
};

export const useCompanies = (organization_id: string) => {
	const enabled = !!organization_id;

	const companies = useQuery(['companies', organization_id], () => getCompanies(organization_id), {
		enabled
	});
	return companies;
};
