import React from 'react';
import { Dropdown, Row, Space } from 'antd';

import { I18n } from '@aws-amplify/core';
import {
	DeleteOutlined,
	DownOutlined,
	EditOutlined,
	ToolOutlined,
} from '@ant-design/icons';

import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useManageOrganization } from './context';
import { OrganizationDTO } from './types';
import { ActionDropdown } from './styles';

type ActionsProps = {
	organization: OrganizationDTO;
	isEditing: boolean;
	onManageOrganizationModal: (organization: OrganizationDTO) => void;
	onDelete: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		organizationId: string
	) => void;
	onUpdate: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		organizationId: string
	) => void;
};

export const Actions: React.FC<ActionsProps> = ({
	organization,
	isEditing,
	onManageOrganizationModal,
	onDelete,
	onUpdate,
}) => {
	const { organizationsList } = useManageOrganization();

	function handleManageOrganization() {
		onManageOrganizationModal(organization);
	}

	function handleUpdate(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		onUpdate(event, organization.id);
	}

	function handleDelete(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		if (organizationsList.length === 1) {
			return;
		}
		onDelete(event, organization.id);
	}

	const items: ItemType[] = [
		{
			key: 1,
			icon: <ToolOutlined />,
			label: (
				<Row onClick={handleManageOrganization}>
					{I18n.get('Manage industrial site')}
				</Row>
			),
		},
		{
			key: 2,
			icon: <EditOutlined />,
			label: (
				<div onClick={(e) => handleUpdate(e)}>
					{I18n.get('Update company name')}
				</div>
			),
		},
		{
			key: 3,
			icon: <DeleteOutlined />,
			label: (
				<div onClick={(e) => handleDelete(e)}>{I18n.get('Delete company')}</div>
			),
			disabled: organizationsList.length === 1,
		},
	];

	return (
		<Dropdown
			menu={{ items }}
			disabled={isEditing}
			placement="bottom"
			trigger={['click']}
		>
			<ActionDropdown>
				<Space>
					{I18n.get('Action')}
					<DownOutlined />
				</Space>
			</ActionDropdown>
		</Dropdown>
	);
};
