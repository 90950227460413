import React, { useRef, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';

import { getBusinessUnit } from '../../services';
import { AnalyticsDashboardContext } from '../../context';
import { BarChart, Loading, Error } from '../_Components/Charts';

export const BusinessUnit: React.FC = () => {
	const chartRef = useRef();
	const { filter, onClickFilter } = useContext(AnalyticsDashboardContext);

	const { isLoading, data, isError, refetch } = useQuery(['business-unit-quote', filter], () =>
		getBusinessUnit(filter)
	);

	if (isLoading) {
		return <Loading size={30} />;
	}

	if (isError) {
		return <Error message={I18n.get('Failed to fetch')} onClick={refetch} />;
	}

	return (
		<BarChart
			data={data}
			ref={chartRef}
			showValue={true}
			onClick={(e) => onClickFilter(e, chartRef.current, data, 'company')}
		/>
	);
}
