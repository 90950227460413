import React, { useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';

import { getWorkstation } from '../../services';
import { AnalyticsDashboardContext } from '../../context';
import { BarChart, Loading, Error } from '../_Components/Charts';

export const Workstation: React.FC = () => {

	const chartRef = useRef();
	const { filter, onClickFilter } = React.useContext(AnalyticsDashboardContext);
	const { isLoading, data, isError, refetch } = useQuery(['workstation-analytics', filter], () =>
		getWorkstation(filter)
	);

	if (isLoading) {
		return <Loading size={30} />;
	}

	if (isError) {
		return <Error message={I18n.get('Failed to fetch')} onClick={refetch} />;
	}

	return (
		<BarChart
			data={data}
			ref={chartRef}
			showValue={true}
			onClick={(e) => onClickFilter(e, chartRef.current, data, 'workstation')}
		/>
	);
}
