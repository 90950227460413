import React, { ReactElement, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Select, SelectProps, Skeleton } from 'antd';

import { DropdownRender } from '../../DropdownRender';
import { useApplicationContext } from '@/context/application';
import { useCreateWorkstation } from '@/hooks/useWorkstationMutations';

import type { NamePath } from 'antd/lib/form/interface';
import type { DefaultOptionType } from 'antd/lib/select';
import type { Workstation, CreateWorkstationDTO } from '@/types';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import type { WorkstationDTO } from '@/components/Settings/ManageOrganizations/types';

const { useFormInstance } = Form;

type WorkstationSelectProps = {
	lineId: string;
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	fieldName: NamePath;
	workstationId?: string;
	allowCreation?: boolean;
	workstations: Workstation[] | WorkstationDTO[] | undefined;
};

type OptionType = { label: string; value: string } | undefined;

export function WorkstationSelect({
	lineId,
	loading,
	disabled,
	fieldName,
	workstations,
	workstationId,
	size = 'middle',
	allowCreation = true,
	...props
}: WorkstationSelectProps & SelectProps): JSX.Element {
	const [workstationName, setWorkstationName] = useState('');

	const { organization, company } = useApplicationContext();
	const { validateFields, setFieldValue } = useFormInstance();
	const { isLoading, mutateAsync: createWorkstation } = useCreateWorkstation();

	async function onCreateWorkstation() {
		const body: CreateWorkstationDTO = {
			organization_id: organization?.id as string,
			company_id: company?.id as string,
			line_id: lineId as string,
			name: workstationName
		};

		const workstation = await createWorkstation({ body });
		const workstationId = workstation?.id;
		if (workstationId) {
			handleChange(workstationId);
		}
		setWorkstationName('');
	}

	async function handleChange(id: string) {
		setFieldValue(fieldName, id);
		await validateFields();
	}

	function handleWorkstationNameChange(event: React.ChangeEvent<HTMLInputElement>) {
		const name = event.target.value;
		setWorkstationName(name);
	}

	function filterOption(option: OptionType | DefaultOptionType, input: string): boolean {
		return (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownRender(menu: ReactElement): JSX.Element {
		if (!allowCreation) {
			return <div>{menu}</div>;
		}
		return (
			<DropdownRender
				menu={menu}
				loading={isLoading}
				name={workstationName}
				allowCreation={allowCreation}
				onClick={onCreateWorkstation}
				onChange={handleWorkstationNameChange}
			/>
		);
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			{...props}
			showSearch
			allowClear
			size={size}
			loading={loading}
			disabled={disabled}
			value={workstationId}
			placeholder={I18n.get('Workstation')}
			dropdownRender={(menu) => showDropdownRender(menu)}
			filterOption={(input, option) => filterOption(option, input)}
			options={workstations?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
