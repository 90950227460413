import React, { useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';

import { Loading } from './Loading';
import { CardChart } from './styles';
import { ErrorChart } from './Error';

export function PieChart({
	title,
	attribute,
	onClickFilter,
	onFetch,
	filter,
	minHeight = 10,
	maxHeight = 25,
}) {
	const { data, isError, isLoading } = useQuery([attribute, filter], () =>
		onFetch(filter)
	);

	const chartRef = useRef();

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			title: {
				display: true,
				text: I18n.get(title),
			},
			datalabels: {
				display: false,
			},
		},
	};

	function allAreNull(arr) {
		return arr?.every((item) => item?.value === 0);
	}

	if (isLoading || !data) {
		return <Loading minHeight={minHeight} />;
	}

	if (isError) {
		return <ErrorChart />;
	}

	return (
		<CardChart minHeight={minHeight} maxHeight={maxHeight}>
			<Pie
				ref={chartRef}
				options={options}
				onClick={(e) => onClickFilter(e, chartRef.current, data, attribute)}
				data={
					allAreNull(data)
						? {
								labels: [I18n.get('No data')],
								datasets: [
									{
										labels: I18n.get('No data'),
										backgroundColor: ['#D3D3D3'],
										data: [100],
									},
								],
						  }
						: {
								labels: data?.map((item) => I18n.get(item.unit)),
								datasets: [
									{
										label: I18n.get(title),
										backgroundColor: [
											'rgba(75, 192, 86, 0.5)',
											'rgba(255, 206, 86, 0.5)',
											'rgba(255, 40, 72, 0.5)',
										],
										borderColor: [
											'rgba(75, 192, 86, 1)',
											'rgba(255, 206, 86, 1)',
											'rgba(255, 40, 72, 1)',
										],
										borderWidth: 1,
										data: data?.map((item) => item.value),
									},
								],
						  }
				}
			/>
		</CardChart>
	);
}
