import React, { ReactNode, createContext, useContext, useState } from 'react';
import type { Context, Methods, States, StringArray } from './types';

const StepContext = createContext<Context>({} as Context);

interface CollapseProviderProps {
	children: ReactNode;
}

export function CollapseProvider({ children }: Readonly<CollapseProviderProps>) {
	const [collapseError, setCollapseError] = useState<StringArray>([]);
	const [collapseOpen, setCollapseOpen] = useState<string>('');

	function handleCollapseError(value: StringArray) {
		setCollapseError(value);
	}

	function handleChangeCollapse(value: string) {
		setCollapseOpen(value);
	}

	const states: States = { collapseError, collapseOpen };
	const methods: Methods = { handleCollapseError, handleChangeCollapse };

	const contextValue = {
		...states,
		...methods
	};

	return <StepContext.Provider value={contextValue}>{children}</StepContext.Provider>;
}

export function useCollapseContext() {
	const context = useContext(StepContext);
	return context;
}
