import React, { ReactNode, createContext, useContext, useState } from 'react';
import { Form } from 'antd';
import moment from 'moment';

import { GetRotationReportResponse } from '@/hooks/useGetRotationReport';
import { BasicParams, Context, Methods, States } from './types';

const { useForm } = Form;

interface RotationReportProviderProps {
	children: ReactNode;
	basicParams: BasicParams;
	rotationReport?: GetRotationReportResponse;
}

type SelectedFile = {
	[key: string]: {
		activity_id: string;
	};
};

type BodyPartObjective = {
	[key: string]: string;
};

const RotationReportContext = createContext<Context>({} as Context);

function initialDataMapper({
	rotationReport,
	basicParams
}: {
	rotationReport?: GetRotationReportResponse;
	basicParams: BasicParams;
}) {
	if (!rotationReport) {
		return {
			informations: {
				...basicParams,
				analysis_date: moment(new Date())
			}
		};
	}

	const selectedFiles = {} as SelectedFile;
	const selectedRowKeys = rotationReport.activities.map(({ file_id, activity_id }) => {
		selectedFiles[file_id] = {
			activity_id
		};
		return file_id;
	});

	const goals = rotationReport.rotation_objectives.reduce<BodyPartObjective>((body_parts_goals, objective) => {
		body_parts_goals[objective.body_part.name] = `${objective.value}`;
		return body_parts_goals;
	}, {});

	const initialValues = {
		informations: {
			...basicParams,
			report_name: rotationReport.name,
			analysis_date: moment(rotationReport.analysis_date),
			evaluator_id: rotationReport.evaluator_id,
			risk_range_id: rotationReport.range_risk_id
		},
		comment: rotationReport.comment,
		selectedRowKeys,
		goals
	};

	return {
		...initialValues,
		...selectedFiles
	};
}

export function RotationReportProvider({
	children,
	basicParams,
	rotationReport
}: Readonly<RotationReportProviderProps>) {
	const [form] = useForm();

	const [currentStep, setCurrentStep] = useState<number>(Number(rotationReport?.current_step) || 0);

	async function handleStepNavigationClick(step: number) {
		await form.validateFields();
		setCurrentStep(step);
	}

	function handleNextStep(): void {
		setCurrentStep(currentStep + 1);
	}

	function handlePreviousStep(): void {
		setCurrentStep(currentStep - 1);
	}

	const states: States = {
		form,
		currentStep,
		basicParams,
		rotationReport
	};

	const methods: Methods = {
		handleNextStep,
		handlePreviousStep,
		handleStepNavigationClick
	};

	const context: Context = {
		...states,
		...methods
	};

	const initialValues = initialDataMapper({
		rotationReport,
		basicParams
	});

	return (
		<RotationReportContext.Provider value={context}>
			<Form form={form} initialValues={initialValues}>
				{children}
			</Form>
		</RotationReportContext.Provider>
	);
}

export function useRotationReportContext() {
	const context = useContext(RotationReportContext);
	return context;
}
