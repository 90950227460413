import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import type { Workstation, WorkstationsDTO } from '@/types';

const getWorkstations = async ({ organization_id, company_id, line_id }: WorkstationsDTO): Promise<Workstation[]> => {
	const url = `/workstation/${organization_id}/${company_id}/${line_id}`;
	const { data } = await Api.get<Workstation[]>(url);
	return data;
};

export const useWorkstations = ({ organization_id, company_id, line_id }: WorkstationsDTO) => {
	return useQuery(
		['workstations', { organization_id, company_id, line_id }],
		() => getWorkstations({ organization_id, company_id, line_id }),
		{ enabled: !!organization_id && !!company_id && !!line_id }
	);
};
