import React from 'react';
import { Bar } from 'react-chartjs-2';
import { I18n } from '@aws-amplify/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Tooltip,
	Legend,
} from 'chart.js';

ChartJS.register(
	ChartDataLabels,
	CategoryScale,
	LinearScale,
	BarElement,
	Tooltip,
	Legend
);

const options = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		y: {
			grace: '5%',
		},
	},
	plugins: {
		legend: {
			position: 'bottom',
		},
		datalabels: {
			anchor: 'end',
			align: 'top',
			formatter: Math.round,
			font: {
				weight: 'bold',
			},
		},
	},
};

export function Chart({ data }) {
	const datasets = {
		labels: data?.map((item) => item.organization_name),
		datasets: [
			{
				label: I18n.get('Workstations total'),
				data: data?.map((item) => item.workstation_total),
				borderWidth: 1,
				maxBarThickness: 40,
				borderColor: 'rgba(65,105,225, 1)',
				backgroundColor: 'rgb(65,105,225, 0.5)',
			},
			{
				label: I18n.get('Workstation not analyzed'),
				data: data?.map((item) => item.workstation_not_analyzed),
				borderWidth: 1,
				maxBarThickness: 40,
				borderColor: 'rgba(65,105,225, 1)',
				backgroundColor: 'rgb(65,105,225, 0.5)',
			},
			{
				label: I18n.get('Analyzed workstations'),
				data: data?.map((item) => item.workstation_analyzed),
				borderWidth: 1,
				maxBarThickness: 40,
				borderColor: 'rgba(65,105,225, 1)',
				backgroundColor: 'rgb(65,105,225, 0.5)',
			},
			{
				label: I18n.get('Low'),
				data: data?.map((item) => item.low),
				borderWidth: 1,
				maxBarThickness: 40,
				borderColor: ['rgba(75, 192, 86, 1)'],
				backgroundColor: ['rgba(75, 192, 86, 0.5)'],
			},
			{
				label: I18n.get('Average'),
				data: data?.map((item) => item.average),
				borderWidth: 1,
				maxBarThickness: 40,
				borderColor: ['rgba(255, 206, 86, 1)'],
				backgroundColor: ['rgba(255, 206, 86, 0.5)'],
			},
			{
				label: I18n.get('High'),
				data: data?.map((item) => item.high),
				borderWidth: 1,
				maxBarThickness: 40,
				borderColor: ['rgba(255, 40, 72, 1)'],
				backgroundColor: ['rgba(255, 40, 72, 0.5)'],
			},
		],
	};

	return <Bar options={options} data={datasets} height={'450px'} />;
}
