import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { Loading } from './Chart/Loading';
import { ErrorChart } from './Chart/Error';
import { CardChart } from './Chart/styles';
import { Row, Col, Divider } from 'antd';

import { Counter } from './styles';

import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as ChartJS } from 'chart.js';

ChartJS.register(zoomPlugin);

export function ListSegment({
	attributeErgonomicBySegment,
	onFetchErgonomic,
	attributeWorkstationsBySegment,
	onFetchWorkstations,
	filter,
	minHeight = 10,
}) {
	const ergonomic_quotes = useQuery([attributeErgonomicBySegment, filter], () =>
		onFetchErgonomic(filter)
	);
	const workstations = useQuery([attributeWorkstationsBySegment, filter], () =>
		onFetchWorkstations(filter)
	);

	const isLoading = workstations.isLoading || ergonomic_quotes.isLoading;

	const isError = workstations.isError || ergonomic_quotes.isError;

	if (isLoading || !ergonomic_quotes.data || !workstations.data) {
		return <Loading minHeight={minHeight} />;
	}

	if (isError) {
		return <ErrorChart />;
	}

	return (
		<CardChart>
			<Row>
				<Col sm={4} offset={1}>
					<Row>
						<Counter
							style={{
								float: 'right',
							}}
						>
							{ergonomic_quotes.data[0] ? workstations.data[0]?.total : ''}
						</Counter>
						<p
							style={{
								textAlign: 'left',
								fontWeight: 'bold',
							}}
						>
							{ergonomic_quotes.data[0]
								? ergonomic_quotes.data[0]?.name
								: I18n.get('No data')}
						</p>
					</Row>
					<Row>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('Low')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#40BF5F',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[0]?.Low}
							</p>
						</Col>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('Medium')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#FDC365',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[0]?.Average}
							</p>
						</Col>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('High')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#F07575',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[0]?.High}
							</p>
						</Col>
					</Row>
				</Col>
				<Divider
					type="vertical"
					style={{ height: '120px', backgroundColor: '#e2e2e2' }}
				/>
				<Col sm={4} style={{ marginLeft: '1%' }}>
					<Row>
						<Counter
							style={{
								float: 'right',
							}}
						>
							{ergonomic_quotes.data[1] ? workstations.data[1]?.total : ''}
						</Counter>
						<p style={{ textAlign: 'left', fontWeight: 'bold' }}>
							{ergonomic_quotes.data[1]
								? ergonomic_quotes.data[1]?.name
								: I18n.get('No data')}
						</p>
					</Row>
					<Row>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('Low')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#40BF5F',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[1]?.Low}
							</p>
						</Col>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('Medium')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#FDC365',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[1]?.Average}
							</p>
						</Col>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('High')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#F07575',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[1]?.High}
							</p>
						</Col>
					</Row>
				</Col>
				<Divider
					type="vertical"
					style={{ height: '120px', backgroundColor: '#e2e2e2' }}
				/>
				<Col sm={4} style={{ marginLeft: '1%' }}>
					<Row>
						<Counter
							style={{
								float: 'right',
							}}
						>
							{ergonomic_quotes.data[2] ? workstations.data[2]?.total : ''}
						</Counter>
						<p style={{ textAlign: 'left', fontWeight: 'bold' }}>
							{ergonomic_quotes.data[2]
								? ergonomic_quotes.data[2]?.name
								: I18n.get('No data')}
						</p>
					</Row>
					<Row>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('Low')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#40BF5F',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[2]?.Low}
							</p>
						</Col>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('Medium')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#FDC365',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[2]?.Average}
							</p>
						</Col>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('High')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#F07575',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[2]?.High}
							</p>
						</Col>
					</Row>
				</Col>
				<Divider
					type="vertical"
					style={{ height: '120px', backgroundColor: '#e2e2e2' }}
				/>
				<Col sm={4} style={{ marginLeft: '1%' }}>
					<Row>
						<Counter
							style={{
								float: 'right',
							}}
						>
							{ergonomic_quotes.data[3] ? workstations.data[3]?.total : ''}
						</Counter>
						<p style={{ textAlign: 'left', fontWeight: 'bold' }}>
							{ergonomic_quotes.data[3]
								? ergonomic_quotes.data[3]?.name
								: I18n.get('No data')}
						</p>
					</Row>
					<Row>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('Low')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#40BF5F',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[3]?.Low}
							</p>
						</Col>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('Medium')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#FDC365',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[3]?.Average}
							</p>
						</Col>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('High')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#F07575',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[3]?.High}
							</p>
						</Col>
					</Row>
				</Col>
				<Divider
					type="vertical"
					style={{ height: '120px', backgroundColor: '#e2e2e2' }}
				/>
				<Col sm={4} style={{ marginLeft: '1%' }}>
					<Row>
						<Counter
							style={{
								float: 'right',
							}}
						>
							{ergonomic_quotes.data[4] ? workstations.data[4]?.total : ''}
						</Counter>
						<p style={{ textAlign: 'left', fontWeight: 'bold' }}>
							{ergonomic_quotes.data[4]
								? ergonomic_quotes.data[4]?.name
								: I18n.get('No data')}
						</p>
					</Row>
					<Row>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('Low')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#40BF5F',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[4]?.Low}
							</p>
						</Col>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('Medium')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#FDC365',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[4]?.Average}
							</p>
						</Col>
						<Col sm={8}>
							<p style={{ textAlign: 'center' }}>{I18n.get('High')}</p>
							<p
								style={{
									textAlign: 'center',
									color: '#F07575',
									fontWeight: 'bold',
									fontSize: '20px',
									marginTop: '-15%',
								}}
							>
								{ergonomic_quotes.data[4]?.High}
							</p>
						</Col>
					</Row>
				</Col>
			</Row>
		</CardChart>
	);
}
