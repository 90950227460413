import { Tooltip } from 'antd';
import styled from 'styled-components';

export const CustomTooltip = styled(Tooltip)<{ top: string; left: string; width: string }>`
	.ant-tooltip {
		top: ${({ top }) => top} !important;
		left: ${({ left }) => left} !important;
	}

	.ant-tooltip-inner {
		color: #262626;
		background-color: #fffa;
		border: 1px solid #262626;
		backdrop-filter: blur(4px);
		width: ${({ width }) => width};
		border-radius: 10px 10px 10px 0;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}
`;
