import React from 'react';
import { Avatar, List } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useQuery } from '@tanstack/react-query';

import { Card } from './styles';
import { getWorkers } from './services';
import { Loading, ErrorChart } from './Chart';
import { workerRestrictEnum } from '@/utils/enum';

export function WorkerList({ filter, minHeight = 10, maxHeight = 25 }) {
	const { isLoading, data, isError } = useQuery(['workers', filter], () => getWorkers(filter));

	if (isLoading) {
		return <Loading minHeight={minHeight} />;
	}

	if (isError) {
		return <ErrorChart minHeight={minHeight} />;
	}

	return (
		<Card minHeight={minHeight} maxHeight={maxHeight} scrollY center="left">
			<div>
				<List
					itemLayout="horizontal"
					dataSource={data}
					renderItem={(item) => (
						<List.Item>
							<List.Item.Meta
								avatar={<Avatar />}
								title={`[${item.register_id}] - ${item.name} (${I18n.get(item.gender)})`}
								description={`${I18n.get('Restriction')}: ${I18n.get(
									workerRestrictEnum[item.restrict]
								)} - ${I18n.get('PWD')}: ${I18n.get(item.pcd ? 'Yes' : 'No')}`}
							/>
						</List.Item>
					)}
				/>
			</div>
		</Card>
	);
}
