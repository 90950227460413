import { useQuery } from '@tanstack/react-query';
import { ListCompaniesDTO } from '@/types';
import Api from '@/services/api';

const getCompanies = async ({ organization_id, company_id, searching_organization_id }: ListCompaniesDTO) => {
	const { data } = await Api.get('/company', {
		params: {
			searching_organization_id,
			organization_id,
			company_id
		}
	});
	return data;
};

export const useListCompanies = ({ organization_id, company_id, searching_organization_id }: ListCompaniesDTO) => {
	return useQuery(
		['companies_list', searching_organization_id ?? organization_id],
		() => getCompanies({ organization_id, company_id, searching_organization_id }),
		{
			enabled: !!organization_id && !!company_id
		}
	);
};
