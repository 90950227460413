import { useQuery } from '@tanstack/react-query';
import { TreemapServices } from '../services/apiServices';
import { WorstScoreDTO } from './types/response';
import { Filter } from '../../index.types';

const treemapServices = new TreemapServices();

const useGetCompaniesWorstScore = (
	organization_id: string | undefined,
	company_id: string | undefined,
	filter: Filter | null,
	page: number
) => {
	const parameters = { organization_id, company_id, filter };
	const refetchTrigger = { ...parameters, page, filter };
	const companies = useQuery(
		['companies_worst_score', refetchTrigger],
		() => treemapServices.getCompanies(parameters),
		{
			enabled: !!organization_id && !!company_id && page === 0,
			retry: 1
		}
	);
	return {
		...companies,
		data: companies.data || ([] as WorstScoreDTO[])
	};
};

const useGetSectorsWorstScore = (
	organization_id: string | undefined,
	company_id: string | undefined,
	filter: Filter | null,
	page: number
) => {
	const parameters = { organization_id, company_id, filter };
	const refetchTrigger = { ...parameters, page };
	const sectors = useQuery(['sectors_worst_score', refetchTrigger], () => treemapServices.getSectors(parameters), {
		enabled: !!organization_id && !!company_id && page === 1,
		retry: 1
	});
	return {
		...sectors,
		data: sectors.data || ([] as WorstScoreDTO[])
	};
};

const useGetLinesWorstScore = (
	organization_id: string | undefined,
	company_id: string | undefined,
	sector_id: string,
	filter: Filter | null,
	page: number
) => {
	const parameters = { organization_id, company_id, sector_id, filter };
	const refetchTrigger = { ...parameters, page };
	const lines = useQuery(['lines_worst_score', refetchTrigger], () => treemapServices.getLines(parameters), {
		enabled: !!organization_id && !!company_id && !!sector_id && page === 2,
		retry: 1
	});
	return {
		...lines,
		data: lines.data || ([] as WorstScoreDTO[])
	};
};

const useGetWorkstationsWorstScore = (
	organization_id: string | undefined,
	company_id: string | undefined,
	line_id: string,
	filter: Filter | null,
	page: number
) => {
	const parameters = { organization_id, company_id, line_id, filter };
	const refetchTrigger = { ...parameters, page };
	const workstations = useQuery(
		['workstations_worst_score', refetchTrigger],
		() => treemapServices.getWorkstations(parameters),
		{
			enabled: !!organization_id && !!company_id && !!line_id && page === 3,
			retry: 1
		}
	);
	return {
		...workstations,
		data: workstations.data || ([] as WorstScoreDTO[])
	};
};

export { useGetLinesWorstScore, useGetSectorsWorstScore, useGetCompaniesWorstScore, useGetWorkstationsWorstScore };
