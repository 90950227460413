import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';
import { notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import type { File } from '@/types';

export type DownloadFile = Pick<File, 'id' | 'original_name'>;

type DownloadFileDTO = {
	file: DownloadFile;
	company_id?: string;
	organization_id?: string;
};

const downloadFile = async ({ company_id, organization_id, file }: DownloadFileDTO): Promise<string> => {
	const url = '/upload/create-url-download';
	const { data } = await Api.post<string>(url, {
		file_id: file.id,
		company_id,
		original_name: file.original_name,
		organization_id
	});
	return data;
};

export const useDownloadFile = () => {
	return useMutation((body: DownloadFileDTO) => downloadFile(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: (data) => {
			const archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		}
	});
};
