import React, { ReactNode } from 'react';
import { Row } from 'antd';

import { useManageOrganization } from '../../context';
import { ManageWorkstation } from './ManageWorkstation';
import { ManageCompany } from './ManageCompany';
import { ManageProduct } from './ManageProduct';
import { ManageSector } from './ManageSector';
import { ManageLine } from './ManageLine';

export const Manage = () => {
	const { companyId, sectorId, lineId } = useManageOrganization();

	function showManageSector(): ReactNode {
		return !!companyId && <ManageSector />;
	}

	function showManageLine(): ReactNode {
		return !!companyId && !!sectorId && <ManageLine />;
	}

	function showManageWorkstation(): ReactNode {
		return !!companyId && !!sectorId && !!lineId && <ManageWorkstation />;
	}

	function showManageProduct(): ReactNode {
		return !!companyId && !!sectorId && !!lineId && <ManageProduct />;
	}

	return (
		<Row style={{ width: '100%' }} gutter={[0, 16]}>
			<ManageCompany />
			{showManageSector()}
			{showManageLine()}
			{showManageWorkstation()}
			{showManageProduct()}
		</Row>
	);
};
