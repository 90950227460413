import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';
import type { ErrorResponse } from '@/types';
import type { RemoveProductDTO, RemoveProductSuccess } from '@/types/dto/product';

async function removeProduct(params: RemoveProductDTO): Promise<RemoveProductSuccess> {
	const { id, ...payload } = params;
	const { data } = await Api.delete(`/product/${id}`, { params: payload });
	return data;
}

export const useRemoveProduct = () => {
	return useMutation<RemoveProductSuccess, ErrorResponse, RemoveProductDTO>(removeProduct);
};
