import React from 'react';
import { Form, Input } from 'antd';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import * as S from '../styles';

interface TmuProps {
	fontSize?: string;
	namePlace: string[];
}

export function Tmu({ fontSize, namePlace }: Readonly<TmuProps>) {
	return (
		<S.ContainerInput $borderRadius="8px">
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[...namePlace, 'tmu']}
				label={<RequiredLabel bold fontSize={fontSize} title="TMU" isRequired={false} />}
			>
				<Input style={{ color: '#262626' }} size="large" disabled placeholder={'000'} />
			</Form.Item>
		</S.ContainerInput>
	);
}
