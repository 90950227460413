import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import type { CreateEvaluatorDTO } from '@/components/ui/Inputs/types/evaluator';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { Evaluator } from '@/types';
import Api from '@/services/api';

const { GET_EVALUATORS } = QUERY_KEYS;

const createEvaluator = async (body: CreateEvaluatorDTO): Promise<Evaluator> => {
	const url = `/evaluator`;
	const { data } = await Api.post<Evaluator>(url, body);
	return data;
};

export const useCreateEvaluator = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: CreateEvaluatorDTO }) => createEvaluator(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries([GET_EVALUATORS]);
			message.success(I18n.get('Evaluator created successfully'));
		}
	});
};
