import React, { ReactNode } from 'react';
import { Button, Col, Divider, Form, Input, Modal, Row, Select } from 'antd';

import { I18n } from '@aws-amplify/core';
import {
	DeleteOutlined,
	EditOutlined,
	ExclamationCircleOutlined,
} from '@ant-design/icons';

import { DeleteRequest, WorkstationDTO } from '../../../types';
import { Create, Delete, Edit, SelectId } from '../styles';
import { useManageOrganization } from '../../../context';

const { useFormInstance } = Form;

type OptionType = {
	label: string;
	value: string;
};

type SelectWorkstationProps = {
	workstations: WorkstationDTO[];
	workstationName: string;
	onWorkstationNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
};

export const SelectWorkstation: React.FC<SelectWorkstationProps> = ({
	workstations,
	workstationName,
	onWorkstationNameChange,
	onEditing,
}) => {
	const { resetFields, setFieldValue } = useFormInstance();

	const {
		organizationId,
		companyId,
		workstationId,
		workstationGHE,
		lineId,
		updatingOrganizationData,
		creatingOrganizationData,
		isEditing,
		handleWorkstationId,
		handleWorkstationGHE,
		handleWorkstation,
		createOrganizationData,
		deleteOrganizationData,
		handleIsEditing,
	} = useManageOrganization();

	async function handleCreateWorkstation() {
		const created = await createOrganizationData({
			organization_id: organizationId,
			company_id: companyId,
			line_id: lineId,
			workstation_ghe: workstationGHE,
			workstation_name: workstationName,
		});

		onWorkstationNameChange('');
		handleWorkstation(
			created.id,
			created.ghe || workstationGHE,
			resetFields,
			setFieldValue
		);
	}

	function handleEditWorkstation(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();

		const editingWorkstation = workstations.find(
			(workstation) => workstation.id === workstationId
		);
		if (editingWorkstation) {
			setFieldValue('edit_workstation_name', editingWorkstation.name);
			onWorkstationNameChange(editingWorkstation.name);
			handleIsEditing(true);
			onEditing(true);
		}
	}

	async function handleDeleteWorkstation(
		event: React.MouseEvent<HTMLElement, MouseEvent>
	) {
		event.stopPropagation();
		event.preventDefault();

		const body: DeleteRequest = {
			organization_id: organizationId,
			company_id: companyId,
			workstation_id: workstationId,
		};

		const content = I18n.get(
			`All data will be deleted, along with their respective uploads, reports and contents. Do you wish to proceed?`
		);

		Modal.confirm({
			title: I18n.get('Warning!'),
			icon: <ExclamationCircleOutlined />,
			content: content,
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: async () => {
				deleteOrganizationData(body).then(() => {
					onWorkstationNameChange('');
					handleWorkstationId('');
					resetFields(['select_workstation_ghe', 'workstation_id']);
				});
			},
		});
	}

	function filterWorkstation(option: OptionType | undefined, input: string): boolean {
		return (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function handleSelectWorkstation(id: string) {
		handleWorkstation(id, workstationGHE, resetFields, setFieldValue);
	}

	function handleClear() {
		handleWorkstationGHE('');
		handleWorkstationId('');
		resetFields(['workstation_id']);
	}

	function handleClearWorkstation() {
		handleWorkstationId('');
	}

	function handleSelectGHE(workstationId: string) {
		const selectedWorkstation = workstations.find(({ id }) => id === workstationId);
		if (selectedWorkstation) {
			handleWorkstationGHE(selectedWorkstation.ghe);
		}
	}

	function manageWorkstationDropdown(menu: ReactNode) {
		return (
			<Col span={24}>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<Create>
					<Form.Item name="create_workstation_name" style={{ margin: '0' }}>
						<Input
							style={{
								flex: 'auto',
								borderRadius: '7px',
							}}
							value={workstationName}
							onChange={(e) => onWorkstationNameChange(e.target.value)}
						/>
					</Form.Item>
					<Button
						onClick={handleCreateWorkstation}
						type="link"
						loading={creatingOrganizationData}
						disabled={!workstationName || workstationName.length < 3}
					>
						{I18n.get('Create workstation')}
					</Button>
				</Create>
			</Col>
		);
	}

	const mappedGHE = workstations.map(({ ghe }) => ghe);

	const gheList = mapGHEList(workstations, mappedGHE);

	function mapGHEList(workstations: WorkstationDTO[], mappedGHE: string[]) {
		return workstations
			.filter(({ ghe }, index) => {
				return mappedGHE.indexOf(ghe) === index;
			})
			.map(({ ghe, id }) => ({
				label: ghe,
				value: id,
			}));
	}

	const workstationsList = workstations
		.filter((workstation) => workstation.ghe === workstationGHE)
		.map(({ id, name }) => ({
			label: name,
			value: id,
		}));

	const actions = (
		<Col span={4}>
			<Row align="bottom" justify="space-between">
				<Edit span={12}>
					<Button
						disabled={!workstationId || isEditing}
						size="middle"
						type="text"
						onClick={(e) => handleEditWorkstation(e)}
						icon={<EditOutlined />}
					/>
				</Edit>
				<Delete span={12}>
					<Button
						disabled={!workstationId || isEditing}
						size="middle"
						type="text"
						onClick={(e) => handleDeleteWorkstation(e)}
						icon={<DeleteOutlined />}
					/>
				</Delete>
			</Row>
		</Col>
	);

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={10}>
					<SelectId
						selected={!!workstationGHE || isEditing}
						name="select_workstation_ghe"
						label={I18n.get('Select a GHE')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please, select a GHE'),
							},
						]}
					>
						<Select
							showSearch
							allowClear
							onClear={() => handleClear()}
							options={gheList}
							loading={updatingOrganizationData}
							filterOption={(input, option) =>
								filterWorkstation(option, input)
							}
							onChange={(value: string) => handleSelectGHE(value)}
							placeholder={I18n.get('Select a GHE')}
						/>
					</SelectId>
				</Col>
				<Col span={10}>
					<SelectId
						selected={!!workstationId || isEditing}
						name="workstation_id"
						label={I18n.get('Select a workstation')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please, select a workstation'),
							},
						]}
					>
						<Select
							showSearch
							allowClear
							onClear={handleClearWorkstation}
							disabled={!workstationGHE}
							options={workstationsList}
							loading={updatingOrganizationData}
							filterOption={(input, option) =>
								filterWorkstation(option, input)
							}
							onChange={(value: string) => handleSelectWorkstation(value)}
							placeholder={I18n.get('Select a workstation')}
							dropdownRender={(menu: ReactNode) =>
								manageWorkstationDropdown(menu)
							}
						/>
					</SelectId>
				</Col>
				{actions}
			</Row>
		</Col>
	);
};
