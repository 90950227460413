import React, { useEffect, useState, useCallback } from 'react';
import {
	Form,
	Select,
	Row,
	Col,
	Input,
	Button,
	message,
	DatePicker,
} from 'antd';
import { updateInformations, validationStep } from '@/redux/reports/actions';
import { nextCurrentStep, setInformations } from '@/redux/checklist/actions';
import { useSelector, useDispatch } from 'react-redux';
import { checkIfValidUUID } from '@/utils';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';
import moment from 'moment';
import _ from 'lodash';

import { useIsMounted } from '@/hooks/useIsMounted';

const { useForm } = Form;

export function Information() {
	const [form] = useForm();
	const dispatch = useDispatch();
	const isMounted = useIsMounted();

	const companyMosaic = useSelector((state) => state.checklist.companyMosaic);
	const checklist = useSelector((state) => state.checklist.checklist);
	const company = useSelector((state) => state.organization.company);
	const file = useSelector((state) => state.checklist.file);

	const informations = useSelector(
		(state) => state.reports.customized.informations
	);
	const organization = useSelector(
		(state) => state.organization.organization
	);

	const [loading, setLoading] = useState(false);
	const [selectSectorId, setSeclectSectorId] = useState(null);
	const [selectLineId, setSelectLineId] = useState(null);
	const [recoverySector, setRecoverySector] = useState(null);
	const [sectors, setSectors] = useState([]);
	const [sectorsLoading, setSectorsLoading] = useState(false);
	const [lines, setLines] = useState([]);
	const [lineLoading, setLinesLoading] = useState(false);
	const [workstations, setWorkstations] = useState([]);
	const [workstationsLoading, setWorkstationsLoading] = useState(false);

	const onErrors = (err) => {
		const description = err.response?.data?.message || err.message;
		message.error(I18n.get(description));
	};

	const getLines = useCallback(() => {
		if (organization && company && selectSectorId) {
			Api.get(
				`/line/${organization.id}/${
					companyMosaic?.id || company.id
				}/${selectSectorId}`
			)
				.then(isMounted() && setLinesLoading(true))
				.then((res) => isMounted() && setLines(res.data))
				.catch((err) => isMounted() && onErrors(err))
				.finally(() => isMounted() && setLinesLoading(false));
		}
	}, [organization, company, selectSectorId]);

	const getWorkstations = useCallback(() => {
		if (organization && company && selectLineId) {
			Api.get(
				`/workstation/${organization.id}/${
					companyMosaic?.id || company.id
				}/${selectLineId}`
			)
				.then(isMounted() && setWorkstationsLoading(true))
				.then((res) => isMounted() && setWorkstations(res.data))
				.catch((err) => isMounted() && onErrors(err))
				.finally(() => isMounted() && setWorkstationsLoading(false));
		}
	}, [organization, company, selectLineId]);

	const getSectors = useCallback(() => {
		if (!organization || !company) return;
		Api.get(`/sector/${organization.id}/${companyMosaic?.id || company.id}`)
			.then(isMounted() && setSectorsLoading(true))
			.then((res) => isMounted() && setSectors(res.data))
			.catch((err) => isMounted() && onErrors(err))
			.finally(() => isMounted() && setSectorsLoading(false));
	}, [organization, company]);

	useEffect(() => {
		getSectors();
	}, [organization, company]);

	useEffect(() => {
		getLines();
	}, [selectSectorId]);

	useEffect(() => {
		getWorkstations();
	}, [selectLineId]);

	const normalizeData = () => {
		const initialValues = {
			sector: null,
			company: null,
			workstation: null,
			collection_date: null,
		};

		let companyName = companyMosaic ? companyMosaic.name : company?.name;

		_.set(initialValues, 'company', companyName);

		// if (company?.name || companyMosaic?.name) {
		// 	const name = company?.name || companyMosaic?.name;
		// 	_.set(initialValues, 'company', name);
		// }

		if (informations?.workstation) {
			_.set(initialValues, 'workstation', informations.workstation);
		}

		if (informations?.sector) {
			/*
				this function form.setFieldsValue not working with
				select options.
			*/
			const sector = JSON.parse(informations.sector);
			_.set(initialValues, 'sector', sector.name);
			setRecoverySector(sector);
		}

		if (informations.collection_date) {
			_.set(
				initialValues,
				'collection_date',
				moment(informations.collection_date)
			);
		}

		return initialValues;
	};

	const onFormatData = (values) => {
		try {
			let sectorId = values.sector;
			let workstationId = values.workstation;
			let lineId = values.line;

			if (!checkIfValidUUID(values.sector)) {
				sectorId = recoverySector.id;
				setRecoverySector(null);
			}

			const [sector] = sectors?.filter(
				(item) => sectorId === item.id && item
			);
			const [workstation] = workstations?.filter(
				(item) => workstationId === item.id && item
			);

			const [line] = lines?.filter((item) => lineId === item.id && item);

			const collection_date = moment.utc(values.collection_date).format();

			const dataForm = {
				...values,
				sector: JSON.stringify(sector),
				workstation: workstation.name,
			};

			const body = {
				file_id: file.id,
				checklist_id: checklist.id,
				organization_id: organization.id,
				company_id: company.id,
				data: {
					line_id: line.id,
					sector_id: sector.id,
					workstation_id: workstation.id,
					collection_date: collection_date,
				},
			};

			// update checklist informations
			const checklistInfo = {
				collection_date: collection_date,
				workstation: {
					id: workstation.id,
					name: workstation.name,
				},
				sector: {
					id: sector.id,
					name: sector.name,
				},
				line: {
					id: line.id,
					name: line.name,
				},
			};

			Api.post('/report/checklist/set-informations', body)
				.then(setLoading(true))
				.then((res) => {
					dispatch(updateInformations(dataForm));
					dispatch(validationStep('informations', true));
					dispatch(nextCurrentStep());
					dispatch(setInformations(checklistInfo));
					// dispatch(next('customized'));
				})
				.catch(onErrors)
				.finally(() => setLoading(false));
		} catch (error) {
			throw error;
		}
	};

	const onFinish = () => {
		form.validateFields()
			.then(onFormatData)
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		if (form && informations) {
			const dataForm = normalizeData();
			form.setFieldsValue(dataForm);
		}
	}, [form, informations]);

	useEffect(() => {
		if (informations) {
			const { collection_date, sector, workstation, line, is_valid } =
				informations;
			sector &&
				line &&
				workstation &&
				collection_date &&
				!is_valid &&
				dispatch(validationStep('informations', true));
		}
	}, [informations]);

	const filterOption = (input, option) =>
		option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

	return (
		<Form form={form} layout="vertical">
			<Row style={{ paddingTop: '20px' }} gutter={[5, 20]}>
				<Col sm={24}>
					<h2>{I18n.get('Informations')}</h2>
				</Col>
				<Col sm={24}>
					<label>
						{I18n.get(
							'Enter the data related to the selected video below'
						)}
					</label>
				</Col>
				<Col sm={12}>
					<Row gutter={[10, 20]}>
						<Col sm={12}>
							<Form.Item
								name="company"
								label={I18n.get('Industrial Site')}
							>
								<Input disabled={true} />
							</Form.Item>
						</Col>
						<Col sm={12}>
							<Form.Item
								name="sector"
								label={I18n.get('Cost center')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(
														I18n.get(
															'Select a cost center'
														)
												  )
												: Promise.resolve();
										},
									}),
								]}
							>
								<Select
									showSearch
									onChange={(value) =>
										setSeclectSectorId(value)
									}
									placeholder={I18n.get(
										'Select a cost center'
									)}
									optionFilterProp="children"
									filterOption={filterOption}
									loading={sectorsLoading}
									disabled={sectorsLoading}
								>
									{sectors?.map((item, key) => (
										<Select.Option
											key={key}
											value={item.id}
										>
											{item.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col sm={12}>
							<Form.Item
								name="line"
								label={I18n.get('Production line')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(
														I18n.get(
															'Insert a production line'
														)
												  )
												: Promise.resolve();
										},
									}),
								]}
							>
								<Select
									showSearch
									onChange={(value) => setSelectLineId(value)}
									optionFilterProp="children"
									filterOption={filterOption}
									loading={lineLoading}
									placeholder={I18n.get(
										'Insert a production line'
									)}
									disabled={!selectSectorId || lineLoading}
								>
									{lines?.map((item, key) => (
										<Select.Option
											key={key}
											value={item.id}
										>
											{item.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col sm={12}>
							<Form.Item
								name="workstation"
								label={I18n.get('Workstation')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(
														I18n.get(
															'Enter a workstation'
														)
												  )
												: Promise.resolve();
										},
									}),
								]}
							>
								<Select
									showSearch
									optionFilterProp="children"
									filterOption={filterOption}
									loading={workstationsLoading}
									placeholder={I18n.get(
										'Enter a workstation'
									)}
									disabled={
										!selectLineId || workstationsLoading
									}
								>
									{workstations?.map((item, key) => (
										<Select.Option
											key={key}
											value={item.id}
										>
											{item.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col sm={12}>
							<Form.Item
								label={I18n.get('Date of colletion')}
								name="collection_date"
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(
														I18n.get(
															'Insert the date of collection'
														)
												  )
												: Promise.resolve();
										},
									}),
								]}
							>
								<DatePicker
									format={'DD/MM/YYYY'}
									placeholder={I18n.get('Date of colletion')}
									style={{ width: '100%' }}
								/>
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Button
								type="primary"
								onClick={onFinish}
								loading={loading}
							>
								{I18n.get('Next')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
}
