import React from 'react';
import { Form, InputNumber } from 'antd';
import { I18n } from '@aws-amplify/core';

import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { InfoTooltip } from '../../CustomToolTip';
import { formatTime } from '..';
import * as S from '../../styles';

const { useFormInstance } = Form;

export function Frequency() {
	const form = useFormInstance();

	function handleChange(value: number | null) {
		const file_duration = form.getFieldValue(['cycle_result', 'file_duration']);
		if (!value || !file_duration) {
			return;
		}
		form.setFieldValue(['cycle_result', 'activity_time'], formatTime(Number(file_duration) * value));
	}

	return (
		<S.ContainerInputNumber $borderRadius="8px" className="overflow-visible">
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={['cycle_result', 'frequency']}
				rules={[{ required: true, message: I18n.get('Required') }]}
				label={
					<div className="d-flex overflow-visible">
						<RequiredLabel fontSize={'16px'} title="Frequency" isRequired={false} />
						<span style={{ overflow: 'visible' }}>
							<InfoTooltip text={'Quantity of times that the activity repeats'} />
						</span>
					</div>
				}
			>
				<InputNumber onChange={handleChange} size="large" placeholder="0" min={1} style={{ width: '100%' }} />
			</Form.Item>
		</S.ContainerInputNumber>
	);
}
