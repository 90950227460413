import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Input } from 'antd';

const { TextArea } = Input;

export function Information({ form }) {
	return (
		<Form form={form} layout="vertical" name="information">
			<Row gutter={[20, 10]}>
				<Col sm={24}>
					<h2 style={{ fontSize: '20px' }}>
						{I18n.get('Informations')}
					</h2>
				</Col>
				<Col sm={12}>
					<Form.Item
						name="name"
						label={I18n.get('Name')}
						hasFeedback
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Please enter a valid name'));
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<Input placeholder={I18n.get('Enter a name')} autoComplete="off" />
					</Form.Item>
				</Col>
				<Col sm={24}>
					<h2 style={{ fontSize: '20px' }}>
						{I18n.get('Description (optional)')}
					</h2>
				</Col>
				<Col sm={24}>
					<Form.Item name="description">
						<TextArea
							rows={4}
							showCount
							allowClear
							maxLength={100}
							placeholder={I18n.get('Insert short description')}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
}
