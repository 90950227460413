import React from 'react';
import { ModalBorder } from '@/components/ui';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Row } from 'antd';

import { Title } from '@/components/Typography';
import { FilterDTO } from '@/hooks/useGetFiles';
import { useSelectFilesContext } from '../../../../context';
import { Company, Line, Organization, Period, Sector } from '../../../../../../components/Inputs';
import * as S from '../../styles';

const { useFormInstance } = Form;

interface FilterModalProps {
	openModal: boolean;
	onOpenModal: () => void;
}

export function FilterModal({ openModal, onOpenModal }: Readonly<FilterModalProps>) {
	const { getFieldsValue, resetFields } = useFormInstance();

	const { handleFilter, handlePage, handlingSelectRowKeys } = useSelectFilesContext();

	function finishFilter() {
		const values: { filter: FilterDTO } = getFieldsValue(true);
		const { original_name, ...rest } = values.filter;
		handleFilter(rest);
		handlingSelectRowKeys([]);
		resetFields([
			['informations', 'sector_id'],
			['informations', 'line_id']
		]);
		onOpenModal();
	}

	function clearFilter() {
		handlingSelectRowKeys([]);
		handleFilter({});
		resetFields(['filter']);
		handlePage(1);
		onOpenModal();
	}

	return (
		<ModalBorder $borderSize="25px" open={openModal} onCancel={() => onOpenModal()} footer={false}>
			<Row gutter={[0, 20]}>
				<Col span={24}>
					<Row>
						<Col>
							<Title level={3}>Filter</Title>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Row justify="center">
						<Organization namePlace="filter" />
						<Company namePlace="filter" />
						<Sector namePlace="filter" />
						<Line namePlace="filter" />
						<Period />
					</Row>
				</Col>
				<Col span={24}>
					<Row justify="center" gutter={[10, 0]}>
						<Col>
							<S.CustomButton size="large" onClick={clearFilter} type="default">
								{I18n.get('Clear')}
							</S.CustomButton>
						</Col>
						<Col>
							<S.CustomButton size="large" onClick={finishFilter} type="primary">
								{I18n.get('Filter')}
							</S.CustomButton>
						</Col>
					</Row>
				</Col>
			</Row>
		</ModalBorder>
	);
}
