import React from 'react';

export const MtmIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
			<g clipPath="url(#clip0_1505_8596)">
				<path
					fill="currentColor"
					d="M11.333 6.667a4.672 4.672 0 00-4.666 4.666A4.672 4.672 0 0011.333 16 4.672 4.672 0 0016 11.333a4.672 4.672 0 00-4.667-4.666zm0 8A3.337 3.337 0 018 11.333 3.337 3.337 0 0111.333 8a3.337 3.337 0 013.334 3.333 3.337 3.337 0 01-3.334 3.334zm.667-3.61l1.138 1.138-.943.943-1.528-1.529V9.333H12v1.724zm2-8.39h-2V1.333C12 .598 11.402 0 10.667 0H5.333C4.598 0 4 .598 4 1.333v1.334H2c-1.103 0-2 .897-2 2V16h7.566c-.47-.38-.883-.83-1.22-1.333H1.332V9.333h4.349A5.97 5.97 0 016.345 8H1.333V4.667C1.333 4.299 1.632 4 2 4h12c.368 0 .667.3.667.667v1.678c.504.338.952.75 1.333 1.221v-2.9c0-1.102-.897-2-2-2zM5.333 1.333h5.334v1.334H5.333V1.333z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_1505_8596">
					<path fill="currentColor" d="M0 0H16V16H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
};
