import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

export interface DeleteRotationReportActivityDTO {
	id: string;
	company_id: string;
	organization_id: string;
	rotation_activity_id?: string;
}

type DeleteRotationReportActivityResponse = {
	message: string;
};

async function deleteRotationReportActivity({
	id,
	...params
}: DeleteRotationReportActivityDTO): Promise<DeleteRotationReportActivityResponse> {
	const url = `/rotation/report/${id}/activity`;
	const { data } = await Api.delete(url, { params });
	return data;
}

export const useDeleteRotationReportActivity = () => {
	const queryClient = useQueryClient();

	return useMutation((prams: DeleteRotationReportActivityDTO) => deleteRotationReportActivity(prams), {
		onSuccess: ({ message: txt }) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_ACTIVITIES]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_CYCLE_TOTAL_TIME]);
			message.success(I18n.get(txt));
		}
	});
};
