import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { File, MtmReport } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types';

export type CreateMtmReportDTO = {
	line_id: string;
	analysis_date: Date;
	report_name: string;
	evaluator_id: string;
	collection_date: Date;
	file_ids: Pick<File, 'id'>[];
};

export type CreateMtmReportResponse = BaseContext & MtmReport;

const createMtmReport = async (body: CreateMtmReportDTO): Promise<CreateMtmReportResponse> => {
	const { data } = await Api.post<CreateMtmReportResponse>('/mtm/report', body);
	return data;
};

export const useCreateMtmReport = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateMtmReportDTO) => createMtmReport(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_FILES_WITH_OPERATIONS]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORTS_PAGINATED]);
			message.success(I18n.get('MTM created successfully'));
		}
	});
};
