import { useMutation } from '@tanstack/react-query';
import { ErrorResponse } from '../types';
import Api from '../services/api';

export type SessionUserDTO = {
	email: string;
	password: string;
};

export type SessionUserResponse = any;

async function getSessionUser(payload: SessionUserDTO): Promise<SessionUserResponse> {
	const { data } = await Api.post('/auth/session', payload);
	return data;
}

export const useAuth = () => {
	return useMutation<SessionUserResponse, ErrorResponse, SessionUserDTO>(getSessionUser);
};
