import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Checkbox, Button, Select, message } from 'antd';

import Api from '@/services/api';
import { useIsMounted } from '@/hooks/useIsMounted';
import {
	addWrist,
	nextCurrentStep,
	previousCurrentStep,
} from '@/redux/checklist/actions';

export function Wrist() {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const isMounted = useIsMounted();

	const wrist = useSelector((state) => state.checklist.wrist);

	const [notInclude, setNotInclude] = useState(wrist.not_analyze);
	const [externalFile, setExternalFile] = useState(wrist.enable);

	const [data, setData] = useState([]);
	const [loading, setLoading] = React.useState(false);

	const checklist = useSelector((state) => state.checklist.checklist);
	const checklist_file = useSelector((state) => state.checklist?.file);

	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	useEffect(() => {
		if (notInclude) {
			form.setFieldsValue({ external: false });
		}

		if (externalFile) {
			form.setFieldsValue({ not_analyze: false });
		}
	}, [notInclude, externalFile]);

	const onErrors = (err) => {
		const description = err.response?.data?.message || err.message;
		message.error(I18n.get(description));
	};

	const deleteWrist = (parameters) => {
		Api.post('/report/checklist/remove-wrist', parameters)
			.then(isMounted() && setLoading(true))
			.then((res) => {
				isMounted() && setLoading(false);
				dispatch(nextCurrentStep());
			})
			.catch(onErrors)
			.finally(() => isMounted() && setLoading(false));
	};

	const setWrist = (parameters) => {
		Api.post('/report/checklist/set-wrist', parameters)
			.then(isMounted() && setLoading(true))
			.then((res) => {
				isMounted() && setLoading(false);
				dispatch(nextCurrentStep());
			})
			.catch(onErrors)
			.finally(() => isMounted() && setLoading(false));
	};

	const normalizeData = ({ external, file, not_analyze }) => {
		const payload = {
			checklist_id: checklist.id,
			organization_id: organization.id,
			company_id: company.id,
			...(not_analyze && { not_analyze: true }),
		};

		dispatch(addWrist({ enable: external, not_analyze: not_analyze }));

		let file_id = wrist?.file?.id;

		if (!external && !not_analyze) {
			file_id = checklist_file?.id;
		}

		not_analyze ? deleteWrist(payload) : setWrist({ ...payload, file_id });
	};

	const onFinish = () => {
		form.validateFields()
			.then(normalizeData)
			.catch((err) => {});
	};

	const getFiles = (value) => {
		if (!organization || !company) return;

		let url = `/file/${organization.id}/${company.id}?`;

		if (value) {
			url += `original_name=${value}&status=PROCESSED`;
		}

		Api.get(url)
			.then((res) => setData(res.data))
			.catch((err) => {})
			.finally(() => {});
	};

	const handleSearch = (newValue) => {
		newValue ? getFiles(newValue) : setData([]);
	};

	const handleChange = (id) => {
		const [value] = data.filter((item) => item.id === id);
		dispatch(
			addWrist({
				file: {
					id: value.id,
					name: value.original_name,
				},
			})
		);
	};

	const onPrevious = () => {
		dispatch(previousCurrentStep());
	};

	const options = data.map((d) => (
		<Select.Option key={d.id}>{d.original_name}</Select.Option>
	));

	return (
		<Form
			form={form}
			layout="vertical"
			initialValues={{
				external: wrist.enable,
				file: wrist?.file?.name,
				not_analyze: wrist.not_analyze,
			}}
		>
			<Row style={{ paddingTop: '20px' }} gutter={[5, 20]}>
				<Col sm={24}>
					<h2>{I18n.get('Wrist')}</h2>
				</Col>
				<Col sm={24}>
					<p>{I18n.get('Add an additional video for wrist analysis')}</p>
				</Col>
				<Col sm={16}>
					<Row gutter={[10, 20]}>
						<Col sm={12} style={{ minHeight: '250px' }}>
							<Form.Item name="not_analyze" valuePropName="checked">
								<Checkbox
									disabled={externalFile}
									onChange={(e) => setNotInclude(e.target.checked)}
								>
									{I18n.get('Do not include in the analysis')}
								</Checkbox>
							</Form.Item>
							<Form.Item name="external" valuePropName="checked">
								<Checkbox
									disabled={notInclude}
									onChange={(e) => setExternalFile(e.target.checked)}
								>
									{I18n.get('Include external video')}
								</Checkbox>
							</Form.Item>
							{externalFile && (
								<Form.Item
									name="file"
									label="Vídeo"
									rules={[
										() => ({
											validator(_, value) {
												return !value
													? Promise.reject(
															I18n.get(
																'Insert a video for review'
															)
													  )
													: Promise.resolve();
											},
										}),
									]}
								>
									<Select
										showSearch
										// value={value}
										value={wrist.file?.name}
										placeholder={I18n.get('Enter video name')}
										style={{ width: '100%' }}
										defaultActiveFirstOption={false}
										showArrow={false}
										filterOption={false}
										onSearch={handleSearch}
										onChange={handleChange}
										notFoundContent={null}
									>
										{options}
									</Select>
								</Form.Item>
							)}
						</Col>
						<Col sm={24}>
							<Row>
								<Col>
									<Button
										style={{ margin: '0 8px' }}
										onClick={onPrevious}
									>
										{I18n.get('Previous')}
									</Button>
								</Col>
								<Col>
									<Button
										type="primary"
										onClick={onFinish}
										loading={loading}
									>
										{I18n.get('Next')}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
}
