import styled from 'styled-components';
import { Spin } from 'antd';

export const CardChart = styled.div`
	padding: 25px;
	padding-top: 15px;
	text-align: center;
	border-radius: 5px;
	background-color: white;
	box-shadow: 1px 1px 1px #888888;
	min-height: ${(props) => props.minHeight}vw;
	max-height: ${(props) => props.maxHeight}vw;
`;

export const Spinner = styled(Spin)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const FrameLoading = styled.div`
	padding: 10px;
	min-height: 17em;
	display: flex;
	justify-content: center;
	flex-direction: column;
`;

export const FrameError = styled.div`
	padding: 10px;
	min-height: 17em;
	display: flex;
	justify-content: center;
	flex-direction: column;

	> * {
		color: #fa5c5c;
		font-size: 2em;
	}
`;

export const Title = styled.h2`
	font-size: 1.3em;
	padding-top: 15px;
`;

export const HighSphere = styled.figure`
	display: block;
	background: #f07575;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	margin: 0;
	float: left;
	margin-right: 15px;
`;

export const ModerateSphere = styled.figure`
	display: block;
	background: #fdc365;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	margin: 0;
	float: left;
	margin-right: 15px;
`;

export const LowSphere = styled.figure`
	display: block;
	background: #40bf5f;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	margin: 0;
	float: left;
	margin-right: 15px;
`;
