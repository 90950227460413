import React from 'react';
import { Col, DatePicker, Form } from 'antd';
import { Moment } from 'moment';

import { I18n } from '@aws-amplify/core';

import { useChecklistEIContext } from '../context';

export const CollectionDate = () => {
	const { handleSelectCollectionDate } = useChecklistEIContext();

	function handleCollectionDateSelect(date: Moment | null) {
		handleSelectCollectionDate(date);
	}

	return (
		<Col sm={12}>
			<Form.Item
				label={I18n.get('Date of colletion')}
				name="collection_date"
				rules={[{ required: true }]}
			>
				<DatePicker
					onChange={handleCollectionDateSelect}
					format={'DD/MM/YYYY'}
					placeholder={I18n.get('Date of colletion')}
					style={{ width: '100%' }}
				/>
			</Form.Item>
		</Col>
	);
};
