import styled from 'styled-components';

export const LineInfoContainer = styled.div`
	gap: 20px;
	display: flex;
	margin-block: 20px;
	align-items: center;
	justify-content: center;
`;

export const HierarchyContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 120px;
	min-width: 350px;
	background-color: white;
	border-radius: 5px;
	box-shadow: 2px 2px 5px lightgrey;

	p {
		margin-inline: auto;
		margin-block: 2px;
		text-align: center;
		font-size: 0.9rem;
	}

	p:last-child {
		font-weight: bold;
	}
`;
