import React from 'react';

export const EmptyListIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="84"
			height="49"
			fill="none"
			color="#262626"
			viewBox="0 0 84 49"
			{...props}
		>
			<path
				fill="currentColor"
				fillOpacity="0.25"
				d="M74.95 26.192a4.743 4.743 0 00-3.254-1.306c-.782 0-1.563.187-2.214.56l-.911-.871c5.207-5.848 4.883-14.683-.846-20.22C64.794 1.555 60.823 0 56.657 0A16.109 16.109 0 0045.59 4.355c-6.12 5.848-6.12 15.305 0 21.091 2.93 2.8 6.9 4.355 11.067 4.355 3.711 0 7.291-1.244 10.09-3.546l.912.87c-.976 1.68-.716 3.858.781 5.227l7.683 7.341A4.743 4.743 0 0079.377 41c1.238 0 2.41-.436 3.255-1.307 1.824-1.742 1.824-4.541 0-6.283l-7.681-7.218zm-9.178-2.488a13.288 13.288 0 01-9.18 3.67c-3.32 0-6.64-1.244-9.18-3.67-5.078-4.853-5.078-12.754 0-17.607a13.507 13.507 0 019.18-3.608c3.32 0 6.64 1.244 9.18 3.67 5.142 4.853 5.142 12.692 0 17.545zm14.972 14.31c-.39.372-.91.56-1.432.56-.52 0-1.041-.188-1.432-.56l-7.682-7.342a1.891 1.891 0 010-2.738c.39-.373.912-.56 1.432-.56.521 0 1.042.187 1.432.56l7.682 7.342a1.89 1.89 0 010 2.737z"
			></path>
			<mask
				id="mask0_1764_2470"
				style={{ maskType: 'luminance' }}
				width="53"
				height="40"
				x="0"
				y="10"
				maskUnits="userSpaceOnUse"
			>
				<path fill="#fff" d="M0 10.258h52.502v38.744H0V10.258z"></path>
			</mask>
			<g mask="url(#mask0_1764_2470)">
				<path
					fill="currentColor"
					fillOpacity="0.25"
					d="M5.23 48.979h41.85c2.877 0 5.231-2.356 5.231-5.236V32.881a17.226 17.226 0 01-2.615-.786v11.648a2.625 2.625 0 01-2.616 2.618H5.23a2.625 2.625 0 01-2.615-2.618v-1.308h34.002c.72 0 1.309-.59 1.309-1.309 0-.72-.59-1.31-1.309-1.31H2.615V13.642c0-.392.262-.655.654-.655h10.005c.196 0 .327.066.458.197l3.596 3.86c.523.525 1.308.59 1.83.066.328-.196.458-.523.458-.85h18.375a17.56 17.56 0 010-2.618H17.72l-2.028-2.225c-.588-.654-1.504-1.047-2.42-1.047H3.27A3.239 3.239 0 000 13.641v30.102c0 2.88 2.354 5.236 5.23 5.236z"
				></path>
			</g>
			<path
				fill="currentColor"
				fillOpacity="0.25"
				d="M57.02 7c-1.938 0-3.709 1.198-4.515 3.037l-.363.768c-.16.387-.2.816-.041 1.198.123.386.404.729.768.898.725.386 1.612.044 1.975-.768l.36-.768c.324-.772 1.048-1.241 1.816-1.241.965 0 1.811.725 1.971 1.753.082.599-.119 1.111-.523 1.453l-2.417 1.966c-.364.3-.605.772-.605 1.24v1.884c0 .854.646 1.58 1.492 1.58.806 0 1.489-.686 1.489-1.58v-1.115l1.856-1.497c1.288-1.068 1.934-2.777 1.651-4.487C61.49 8.841 59.397 7 57.02 7zM58.5 22.498a1.501 1.501 0 11-2.998.001 1.501 1.501 0 012.998 0z"
			></path>
		</svg>
	);
};

export default EmptyListIcon;
