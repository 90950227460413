import React, { useEffect } from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { useSectors } from '@/hooks';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

type SectorValue = {
	label: string;
	value: string;
};

interface SectorProps {
	namePlace: string;
	fontSize?: string;
	isRequired?: boolean;
	currentSector?: SectorValue | null;
}

export function Sector({ namePlace, currentSector, fontSize, isRequired = false }: SectorProps) {
	const { setFieldValue } = useFormInstance();

	const organizationId = useWatch([namePlace, 'organization_id']);
	const companyId = useWatch([namePlace, 'company_id']);
	const sectorId = useWatch([namePlace, 'sector_id']);

	const { isFetching, data } = useSectors({ organizationId, companyId });

	function handleOnChange(id: string): void {
		setFieldValue([namePlace, 'sector_id'], id);
		setFieldValue([namePlace, 'line_id'], undefined);
	}

	function handleOnClear(): void {
		setFieldValue([namePlace, 'line_id'], undefined);
	}

	useEffect(() => {
		if (currentSector?.value) {
			setFieldValue([namePlace, 'sector_id'], currentSector.value);
		}
	}, [setFieldValue, namePlace, currentSector?.value]);

	return (
		<Col span={23}>
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[namePlace, 'sector_id']}
				rules={[{ required: true, message: I18n.get('Select the cost center') }]}
				label={<RequiredLabel fontSize={fontSize} title="Cost center" isRequired={isRequired} />}
			>
				<S.ContainerSelect $borderRadius="8px">
					<SectorSelect
						size="large"
						sectors={data}
						sectorId={currentSector || sectorId}
						loading={isFetching}
						allowCreation={false}
						companyId={companyId}
						disabled={companyId && currentSector}
						onClear={handleOnClear}
						onChange={handleOnChange}
						fieldName={[namePlace, 'sector_id']}
						placeholder={I18n.get('Cost center')}
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
}
