import React from 'react';
import { Row, Col, Grid, Collapse } from 'antd';

import { Title } from '@/components/Typography';
import { OperationElement } from '@/hooks';
import { DividerCustom } from '@/components/ui/DividerCustom';
import { DownloadPDFParams } from '@/components/views/MtmReport/types';
import { ResultCard } from '../../../../components/ResultCard';
import { ButtonsCustom } from '../../../../components/ReportTemplates/Footer/ButtonsCustom';
import { OperationCard } from './OperationCard';
import { CollapseStyled } from './style';

const { useBreakpoint } = Grid;

interface CollapseResultsProps {
	id: string;
	title: string;
	total_time: string;
	isLoadingPDF: boolean;
	onDownloadPDF: (params: DownloadPDFParams) => Promise<void>;
	operations?: OperationElement[];
}

export function CollapseResults({
	title,
	total_time,
	id,
	operations = [],
	isLoadingPDF,
	onDownloadPDF
}: CollapseResultsProps) {
	const breakpoint = useBreakpoint();

	return (
		<Col span={24}>
			<CollapseStyled ghost key={id}>
				<Collapse.Panel
					key={0}
					header={
						<Row align="middle" justify={'space-between'}>
							<Col>
								<Title style={{ margin: 0, color: '#262626' }} level={!breakpoint.xl ? 5 : 4}>
									{title}
								</Title>
							</Col>
							<Col style={{ display: 'flex', alignItems: 'center' }}>
								<ButtonsCustom
									isLoadingPDF={isLoadingPDF}
									isConsolidating={false}
									consolidated={false}
									hasConsolidated={false}
									onDownloadPDF={() => onDownloadPDF({ workstation_id: id })}
									onConsolidatePDF={() => {}}
								/>
								<div style={{ marginLeft: '1rem' }} />
								<ResultCard
									padding="1rem"
									height="38px"
									fontSize="15px"
									borderRadius="7px"
									value={`${total_time}min`}
								/>
							</Col>
						</Row>
					}
				>
					<Row>
						<Col span={24} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
							{operations.map((operation) => (
								<OperationCard
									key={operation.id}
									onDownloadPDF={() =>
										onDownloadPDF({ workstation_id: id, operation_id: operation.id })
									}
									isLoadingPDF={isLoadingPDF}
									operation={operation}
								/>
							))}
						</Col>
					</Row>
				</Collapse.Panel>
			</CollapseStyled>
			<Row>
				<DividerCustom span={24} color="#E6E8E8" thickness="1px" margin={'40px 0 0 0'} />
			</Row>
		</Col>
	);
}
