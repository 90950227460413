import { Collapse, Form } from 'antd';
import styled from 'styled-components';

export const CollapseCustom = styled(Collapse)`
	display: flex;
	flex-direction: column;
	gap: 30px;

	border-radius: 10px !important;
	background-color: #f8f8f8;
	border: none;

	.ant-collapse-header {
		height: 60px;
		align-items: center !important;
	}

	.ant-collapse-content {
		border-top: none !important;
		background-color: #ffffff;
		border-radius: 0 0 10px 10px !important;
	}
	.ant-collapse-item {
		background-color: #ffffff;
		border-radius: 10px !important;
		&.error-active {
			border: 2px solid #ff4d4f;
		}
		&#has-button {
			margin-top: 30px;
		}
	}

	.ant-collapse-content-box {
		background-color: #ffffff;
		border-radius: 0 0 10px 10px !important;
	}
	.anticon-right {
		transform: rotate(-90deg);
	}
	.ant-collapse-item-active {
		.anticon-right {
			transform: rotate(0deg);
		}
	}
	.ant-collapse-arrow {
		font-size: 15px !important;
	}

	.ant-collapse-header-text {
		font-weight: bold;
		font-size: 17px;
	}
`;

export const FormItem = styled(Form.Item)`
	.ant-collapse-item {
	}
	.ant-row {
		.ant-collapse {
		}
	}
`;
