import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { EvaluatorSelect } from '@/components/ui/Inputs/EvaluatorSelect';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { useGetEvaluators } from '@/hooks/useGetEvaluators';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

interface EvaluatorProps {
	fontSize?: string;
	namePlace: string;
	companyId: string;
	isRequired?: boolean;
	organizationId: string;
}

export function Evaluator({
	fontSize,
	namePlace,
	companyId,
	organizationId,
	isRequired = false
}: Readonly<EvaluatorProps>) {
	const { setFieldValue } = useFormInstance();

	const evaluatorId = useWatch([namePlace, 'evaluator_id']);

	const {
		isError,
		isLoading,
		isFetching,
		data: evaluators
	} = useGetEvaluators({
		organization_id: organizationId,
		company_id: companyId
	});

	function handleEvaluatorSelect(id: string) {
		setFieldValue([namePlace, 'evaluator_id'], id);
	}

	function handleClearEvaluators() {
		setFieldValue([namePlace, 'evaluator_id'], undefined);
	}

	return (
		<Col span={23}>
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[namePlace, 'evaluator_id']}
				rules={[{ required: true, message: I18n.get('Select the evaluator') }]}
				label={<RequiredLabel fontSize={fontSize} title="Evaluator" isRequired={isRequired} />}
			>
				<S.ContainerSelect $borderRadius="8px">
					<EvaluatorSelect
						size="large"
						allowCreation
						loading={isFetching}
						companyId={companyId}
						evaluators={evaluators}
						evaluatorId={evaluatorId}
						organizationId={organizationId}
						onClear={handleClearEvaluators}
						onChange={handleEvaluatorSelect}
						fieldName={[namePlace, 'evaluator_id']}
						disabled={isLoading || isFetching || isError}
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
}
