import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import Api from '../services/api';
import { PrivateRoutes } from './PrivateRoutes';
import { Container, Spinner } from '../components/Layout';
import { ApplicationProvider } from '../context/application';
import { Login, ResetPassword, ForgotPassword } from '../pages';
import { controlSpinnerRoute, isAuthenticated } from '../redux/auth/actions';

const CustomRouter = ({ contextComponent, component, isPrivate, ...rest }) => {
	const { isAuthenticated, isLoading } = useSelector(
		(state) => ({
			isAuthenticated: state.auth.authenticated,
			isLoading: state.auth.spinnerRoute
		}),
		shallowEqual
	);

	const Provider = contextComponent;
	const Component = component;

	if (isLoading) {
		return <Spinner />;
	}

	if (isPrivate && !isAuthenticated) {
		return <Redirect to="/login" />;
	}

	return (
		<Route>
			{!contextComponent ? (
				<Component {...rest} />
			) : (
				<Provider>
					<Component {...rest} />
				</Provider>
			)}
		</Route>
	);
};

export const Routes = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		/* Verificando se usuário já tem uma sessão  */

		const info_user = JSON.parse(localStorage.getItem('info_user'));

		if (info_user && info_user.token) {
			Api.defaults.headers.Authorization = `Bearer ${info_user.token}`;
			dispatch(isAuthenticated(true));
		}

		dispatch(controlSpinnerRoute());
	}, [dispatch]);

	return (
		<ApplicationProvider>
			<Switch>
				<CustomRouter exact path="/login" component={Login} />
				<CustomRouter exact path="/forgot-password" component={ForgotPassword} />
				<CustomRouter exact path="/password-reset/:token/:email" component={ResetPassword} />
				<CustomRouter isPrivate path="/" children={PrivateRoutes} component={Container} />
			</Switch>
		</ApplicationProvider>
	);
};
