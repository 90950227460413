import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Collapse } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

import { Organization } from './Components/Inputs/Organization';
import { AnalysisDate } from './Components/Inputs/AnalysisDate';
import { Workstation } from './Components/Inputs/Workstation';
import { ReportName } from './Components/Inputs/ReportName';
import { Evaluator } from './Components/Inputs/Evaluator';
import { Activity } from './Components/Inputs/Activity';
import { Company } from './Components/Inputs/Company';
import { Sector } from './Components/Inputs/Sector';
import { Line } from './Components/Inputs/Line';
import { Button } from '@/components/ui/Button';
import { GetRotationReportsDTO } from '@/types';
import * as S from './styles';

const { Panel } = Collapse;
const { useForm } = Form;

interface FilterProps {
	onClick(value: GetRotationReportsDTO): void;
	initialValues: GetRotationReportsDTO;
	loading: boolean;
}

export function Filter({ onClick, loading, initialValues }: Readonly<FilterProps>) {
	const [form] = useForm();
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	function handleOnSubmit(values: GetRotationReportsDTO): void {
		if (values.analysis_date) {
			values.analysis_date = moment(values.analysis_date).format();
		}
		updateURLParams(values);
		onClick(values);
	}

	function handleOnClear(): void {
		history.push({ pathname: '/ehs/rotation', search: '?cleared=true' });
		form.resetFields();
	}

	function updateURLParams(params: GetRotationReportsDTO): void {
		for (const key in params) {
			searchParams.delete('cleared');
			searchParams.delete(key);
			if (Object.prototype.hasOwnProperty.call(params, key)) {
				const typedKey: keyof GetRotationReportsDTO = key as any;
				const param = params[typedKey];
				if (param) {
					searchParams.set(key, param as string);
				}
			}
		}

		history.push({ pathname: '/ehs/rotation', search: searchParams.toString() });
	}

	return (
		<S.CollapseWrapper defaultActiveKey={search ? '1' : '0'}>
			<Panel key="1" header={I18n.get('Filter')}>
				<Form
					form={form}
					layout="vertical"
					onFinish={handleOnSubmit}
					initialValues={{
						...initialValues,
						analysis_date: initialValues.analysis_date ? moment(initialValues.analysis_date) : null
					}}
				>
					<Row gutter={[12, 6]}>
						<Col span={12}>
							<ReportName />
						</Col>
						<Col span={6}>
							<Evaluator />
						</Col>
						<Col span={6}>
							<AnalysisDate />
						</Col>
						<Col span={6}>
							<Organization />
						</Col>
						<Col span={6}>
							<Company />
						</Col>
						<Col span={6}>
							<Sector />
						</Col>
						<Col span={6}>
							<Line />
						</Col>
						<Col span={6}>
							<Workstation />
						</Col>
						<Col span={6}>
							<Activity />
						</Col>
						<Col span={24}>
							<Row gutter={[12, 6]}>
								<Col span={3}>
									<Button
										type="primary"
										loading={loading}
										htmlType="submit"
										icon={<SearchOutlined />}
										style={{ width: '100%' }}
									>
										{I18n.get('Search')}
									</Button>
								</Col>
								<Col span={3}>
									<Button onClick={handleOnClear} style={{ width: '100%' }}>
										{I18n.get('Clear')}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</Panel>
		</S.CollapseWrapper>
	);
}
