import * as React from 'react';

export const TableIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
			<path
				fill="#2F54EB"
				d="M13.417 3.25H.583A.586.586 0 0 0 0 3.84v8.57c0 .326.26.59.583.59h12.832a.587.587 0 0 0 .583-.59V3.84a.584.584 0 0 0-.58-.59ZM4.34 11.67H1.26V8.716h3.08v2.955Zm0-4.136H1.26V4.58h3.08v2.954Zm4.2 4.137H5.46V8.716h3.08v2.955Zm0-4.137H5.46V4.58h3.08v2.954Zm4.2 4.137H9.66V8.716h3.08v2.955Zm0-4.137H9.66V4.58h3.08v2.954ZM13.86 0H.14A.144.144 0 0 0 0 .148v1.477c0 .081.063.148.14.148h13.72c.077 0 .14-.067.14-.148V.148A.144.144 0 0 0 13.86 0Z"
			/>
		</svg>
	);
};

export default TableIcon;
