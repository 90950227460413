import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, DatePicker } from 'antd';

import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { dateFormat } from '@/utils/date-format';
import * as S from '../styles';

interface AnalysisDateProps {
	fontSize?: string;
	isRequired?: boolean;
	namePlace: string;
}
export function AnalysisDate({ fontSize, isRequired, namePlace }: Readonly<AnalysisDateProps>) {
	return (
		<Col xs={23}>
			<S.ContainerDate $borderRadius="8px">
				<Form.Item
					required={false}
					labelCol={{ span: 24 }}
					name={[namePlace, 'analysis_date']}
					rules={[{ required: true, message: I18n.get('Select the analysis date') }]}
					label={<RequiredLabel fontSize={fontSize} title="Analysis date" isRequired={isRequired} />}
				>
					<DatePicker
						allowClear
						size="large"
						format={dateFormat()}
						disabledDate={(current) => current && current.valueOf() >= Date.now()}
						style={{ width: '100%' }}
					/>
				</Form.Item>
			</S.ContainerDate>
		</Col>
	);
}
