import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { BaseContext } from '@/types';

export type MtmReportConsolidateDTO = BaseContext & {
	id: string;
	locale?: string;
};

type MtmReportConsolidateResponse = BaseContext & {
	id: string;
	url?: string;
};

const mtmReportConsolidate = async (body: MtmReportConsolidateDTO): Promise<MtmReportConsolidateResponse> => {
	const { data } = await Api.put<MtmReportConsolidateResponse>(`/mtm/report/${body.id}/consolidate`, body);
	return data;
};

export const useMtmReportConsolidate = () => {
	const queryClient = useQueryClient();
	return useMutation((body: MtmReportConsolidateDTO) => mtmReportConsolidate(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: ({ id, company_id, organization_id }) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORT_RESULT]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORT, { id, company_id, organization_id }]);
			message.success(I18n.get('MTM report updated successfully'));
		}
	});
};
