import React from 'react';
import { Col, Row } from 'antd';

import { Title } from '@/components/Typography';

export const Header = () => {
	return (
		<Row justify="center">
			<Col>
				<Title level={2}>MTM</Title>
			</Col>
		</Row>
	);
};
