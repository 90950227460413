import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { SearchOutlined } from '@ant-design/icons';
import { Row, Col, Collapse, Form, Select, Button } from 'antd';

import { AppointmentContext } from './context';

const optionsRestriction = [
	{
		label: 'Temporary & Indeterminate',
		value: 'BOTH',
	},
	{
		label: 'Temporary',
		value: 'TEMPORARY',
	},
	{
		label: 'Indeterminate',
		value: 'INDETERMINATE',
	},
	{
		label: 'Not restrict',
		value: 'NOT_RESTRICT',
	},
];

export function Filter() {
	const [form] = Form.useForm();

	const {
		companies,
		sectors,
		lines,
		workstations,
		updateFilter,
		setSelectCompanyId,
		setSelectSectorId,
		setSelectLineId,
		setSelectWorkstationId,
		selectCompanyId,
		selectSectorId,
		selectLineId,
		onClearFilter,
	} = useContext(AppointmentContext);

	function resetFieldsForm(field) {
		const options = {
			company: ['sector', 'line', 'workstation'],
			sector: ['line', 'workstation'],
			line: ['workstation'],
		};

		form.resetFields(options[field] || []);
	}

	function onSubmit(values) {
		const { company, sector, line, workstation, gender, restrict, pcd } = values;

		updateFilter({
			...(company && { companyId: company }),
			...(sector && { sectorId: sector }),
			...(line && { lineId: line }),
			...(workstation && { workstationId: workstation }),
			...(restrict && { restrict }),
			...(gender && { gender }),
			...(pcd && { pcd: JSON.parse(pcd) }),
		});
	}

	function onClearFilterForm() {
		form.resetFields();
		setSelectCompanyId(null);
		setSelectSectorId(null);
		setSelectLineId(null);
		onClearFilter();
	}

	return (
		<Row gutter={[0, 20]} style={{ paddingBottom: '10px' }}>
			<Col sm={24}>
				<Collapse>
					<Collapse.Panel header={I18n.get('Filters')}>
						<Form form={form} layout="vertical" onFinish={onSubmit}>
							<Row gutter={[10, 0]}>
								<Col xs={24} sm={6}>
									<Form.Item
										name="company"
										label={I18n.get('Industrial site')}
									>
										<Select
											showSearch
											onChange={(value) => {
												setSelectCompanyId(value);
												resetFieldsForm('company');
											}}
											style={{ width: '100%' }}
											optionFilterProp="children"
											placeholder={I18n.get(
												'Select a industrial site'
											)}
										>
											{companies.map((company, key) => (
												<Select.Option
													key={key}
													value={company.id}
												>
													{company.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={6}>
									<Form.Item name="sector" label={I18n.get('Sector')}>
										<Select
											showSearch
											onChange={(value) => {
												setSelectSectorId(value);
												resetFieldsForm('sector');
											}}
											style={{ width: '100%' }}
											optionFilterProp="children"
											disabled={!selectCompanyId}
											placeholder={I18n.get('Select a sector')}
										>
											{sectors.map((sector, key) => (
												<Select.Option
													key={key}
													value={sector.id}
												>
													{sector.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={6}>
									<Form.Item name="line" label={I18n.get('Line')}>
										<Select
											showSearch
											onChange={(value) => {
												setSelectLineId(value);
												resetFieldsForm('line');
											}}
											style={{ width: '100%' }}
											optionFilterProp="children"
											placeholder={I18n.get('Select a line')}
											disabled={!selectSectorId}
										>
											{lines.map((line, key) => (
												<Select.Option key={key} value={line.id}>
													{line.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={6}>
									<Form.Item
										name="workstation"
										label={I18n.get('Workstation')}
									>
										<Select
											showSearch
											onChange={setSelectWorkstationId}
											style={{ width: '100%' }}
											optionFilterProp="children"
											placeholder={I18n.get('Select a workstation')}
											disabled={!selectLineId}
										>
											{workstations.map((workstation, key) => (
												<Select.Option
													key={key}
													value={workstation.id}
												>
													{workstation.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={5}>
									<Form.Item name="pcd" label={I18n.get('PWD')}>
										<Select
											showSearch
											style={{ width: '100%' }}
											placeholder={I18n.get('Select an option')}
											optionFilterProp="children"
										>
											<Select.Option value="true">
												{I18n.get('Yes')}
											</Select.Option>
											<Select.Option value="false">
												{I18n.get('No')}
											</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={5}>
									<Form.Item name="gender" label={I18n.get('Gender')}>
										<Select
											showSearch
											style={{ width: '100%' }}
											placeholder={I18n.get('Select an option')}
											optionFilterProp="children"
										>
											<Select.Option value="MALE">
												{I18n.get('Male')}
											</Select.Option>
											<Select.Option value="FEMALE">
												{I18n.get('Female')}
											</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={5}>
									<Form.Item
										name="restrict"
										label={I18n.get('Restrict')}
									>
										<Select
											showSearch
											style={{ width: '100%' }}
											placeholder={I18n.get('Select an option')}
											optionFilterProp="children"
										>
											{optionsRestriction.map((item, index) => (
												<Select.Option
													key={index}
													value={item.value}
												>
													{I18n.get(item.label)}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={6} style={{ paddingTop: '20px' }}>
								<Col sm={3}>
									<Button
										type="primary"
										htmlType="submit"
										style={{ width: '100%' }}
										icon={<SearchOutlined />}
									>
										{I18n.get('Search')}
									</Button>
								</Col>
								<Col sm={3}>
									<Button
										onClick={onClearFilterForm}
										style={{ width: '100%' }}
									>
										{I18n.get('Clear')}
									</Button>
								</Col>
							</Row>
						</Form>
					</Collapse.Panel>
				</Collapse>
			</Col>
		</Row>
	);
}

// import React, { useContext } from 'react';
// import { Collapse, Form, Row, Col, Button, Select, Input } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
// import { AppointmentContext } from './context';
// import { I18n } from '@aws-amplify/core';
// import styled from 'styled-components';

// export const Container = styled.div`
// 	padding-bottom: 10px;
// 	width: 100%;
// `;

// export function Filter() {
// 	const [form] = Form.useForm();

// 	const {
// 		companies,
// 		selectCompanyId,
// 		setSelectCompanyId,
// 		sectors,
// 		selectSectorId,
// 		setSelectSectorId,
// 		lines,
// 		selectLineId,
// 		setSelectLineId,
// 		workstations,
// 		setSelectWorkstationId,
// 		onFilterWorker,
// 	} = useContext(AppointmentContext);

// function resetFieldsForm(field) {
// 	const options = {
// 		company: ['sector', 'line', 'workstation'],
// 		sector: ['line', 'workstation'],
// 		line: ['workstation'],
// 	};

// 	form.resetFields(options[field] || []);
// }

// function onClearFilter() {
// 	form.resetFields();
// 	setSelectCompanyId(null);
// 	setSelectSectorId(null);
// 	setSelectLineId(null);
// 	onFilterWorker();
// }

// 	function onSubmit() {
// 		form.validateFields()
// 			.then(onFilterWorker)
// 			.catch((errInfo) => {});
// 	}

// 	return (
// 		<Container>
// 			<Collapse>
// 				<Collapse.Panel header={I18n.get('Filters')}>
// 					<Form form={form} layout="vertical" onFinish={onSubmit}>
// 						<Row gutter={[10, 0]}>
// <Col xs={24} sm={6}>
// 	<Form.Item
// 		name="company"
// 		label={I18n.get('Industrial site')}
// 	>
// 		<Select
// 			showSearch
// onChange={(value) => {
// 	setSelectCompanyId(value);
// 	resetFieldsForm('company');
// }}
// 			style={{ width: '100%' }}
// 			optionFilterProp="children"
// 			placeholder={I18n.get(
// 				'Select a industrial site'
// 			)}
// 		>
// 			{companies.map((company, key) => (
// 				<Select.Option
// 					key={key}
// 					value={company.id}
// 				>
// 					{company.name}
// 				</Select.Option>
// 			))}
// 		</Select>
// 	</Form.Item>
// </Col>
// <Col xs={24} sm={6}>
// 	<Form.Item
// 		name="sector"
// 		label={I18n.get('Sector')}
// 	>
// 		<Select
// 			showSearch
// 			onChange={(value) => {
// 				setSelectSectorId(value);
// 				resetFieldsForm('sector');
// 			}}
// 			style={{ width: '100%' }}
// 			optionFilterProp="children"
// 			disabled={!selectCompanyId}
// 			placeholder={I18n.get(
// 				'Select a sector'
// 			)}
// 		>
// 			{sectors.map((sector, key) => (
// 				<Select.Option
// 					key={key}
// 					value={sector.id}
// 				>
// 					{sector.name}
// 				</Select.Option>
// 			))}
// 		</Select>
// 	</Form.Item>
// </Col>
// <Col xs={24} sm={6}>
// 	<Form.Item name="line" label={I18n.get('Line')}>
// 		<Select
// 			showSearch
// 			onChange={(value) => {
// 				setSelectLineId(value);
// 				resetFieldsForm('line');
// 			}}
// 			style={{ width: '100%' }}
// 			optionFilterProp="children"
// 			placeholder={I18n.get('Select a line')}
// 			disabled={!selectSectorId}
// 		>
// 			{lines.map((line, key) => (
// 				<Select.Option
// 					key={key}
// 					value={line.id}
// 				>
// 					{line.name}
// 				</Select.Option>
// 			))}
// 		</Select>
// 	</Form.Item>
// </Col>
// <Col xs={24} sm={6}>
// 	<Form.Item
// 		name="workstation"
// 		label={I18n.get('Workstation')}
// 	>
// 		<Select
// 			showSearch
// 			onChange={setSelectWorkstationId}
// 			style={{ width: '100%' }}
// 			optionFilterProp="children"
// 			placeholder={I18n.get(
// 				'Select a workstation'
// 			)}
// 			disabled={!selectLineId}
// 		>
// 			{workstations.map(
// 				(workstation, key) => (
// 					<Select.Option
// 						key={key}
// 						value={workstation.id}
// 					>
// 						{workstation.name}
// 					</Select.Option>
// 				)
// 			)}
// 		</Select>
// 	</Form.Item>
// </Col>
// 							<Col xs={24} sm={4}>
// 								<Form.Item
// 									name="register_id"
// 									label={I18n.get('Register')}
// 									rules={[
// 										{
// 											pattern: new RegExp(/^[0-9]+$/),
// 											message: I18n.get(
// 												'Please enter a valid registration id'
// 											),
// 										},
// 										{
// 											min: 7,
// 											message: I18n.get(
// 												'The record id must be 7 characters long'
// 											),
// 										},
// 									]}
// 								>
// 									<Input
// 										placeholder={I18n.get('Register')}
// 										maxLength={7}
// 									/>
// 								</Form.Item>
// 							</Col>
// 							<Col xs={24} sm={5}>
// 								<Form.Item name="name" label={I18n.get('Name')}>
// 									<Input placeholder={I18n.get('Name')} />
// 								</Form.Item>
// 							</Col>
// <Col xs={24} sm={5}>
// 	<Form.Item name="pcd" label={I18n.get('PWD')}>
// 		<Select
// 			showSearch
// 			style={{ width: '100%' }}
// 			placeholder={I18n.get(
// 				'Select an option'
// 			)}
// 			optionFilterProp="children"
// 		>
// 			<Select.Option value="true">
// 				{I18n.get('Yes')}
// 			</Select.Option>
// 			<Select.Option value="false">
// 				{I18n.get('No')}
// 			</Select.Option>
// 		</Select>
// 	</Form.Item>
// </Col>
// <Col xs={24} sm={5}>
// 	<Form.Item
// 		name="gender"
// 		label={I18n.get('Gender')}
// 	>
// 		<Select
// 			showSearch
// 			style={{ width: '100%' }}
// 			placeholder={I18n.get(
// 				'Select an option'
// 			)}
// 			optionFilterProp="children"
// 		>
// 			<Select.Option value="MALE">
// 				{I18n.get('Male')}
// 			</Select.Option>
// 			<Select.Option value="FEMALE">
// 				{I18n.get('Female')}
// 			</Select.Option>
// 		</Select>
// 	</Form.Item>
// </Col>
// <Col xs={24} sm={5}>
// 	<Form.Item
// 		name="restrict"
// 		label={I18n.get('Restrict')}
// 	>
// 		<Select
// 			showSearch
// 			style={{ width: '100%' }}
// 			placeholder={I18n.get(
// 				'Select an option'
// 			)}
// 			optionFilterProp="children"
// 		>
// 			<Select.Option value="TEMPORARY">
// 				{I18n.get('Temporary')}
// 			</Select.Option>
// 			<Select.Option value="INDETERMINATE">
// 				{I18n.get('Indeterminate')}
// 			</Select.Option>
// 			<Select.Option value="NOT_RESTRICT">
// 				{I18n.get('Not restrict')}
// 			</Select.Option>
// 		</Select>
// 	</Form.Item>
// </Col>
// 							<Col xs={24} sm={5}>
// 								<Form.Item
// 									name="body_part"
// 									label={I18n.get('Body parts')}
// 								>
// 									<Select
// 										showSearch
// 										style={{ width: '100%' }}
// 										placeholder={I18n.get(
// 											'Select an option'
// 										)}
// 										optionFilterProp="children"
// 									>
// 										<Select.Option value="neck">
// 											{I18n.get('Neck')}
// 										</Select.Option>
// 										<Select.Option value="trunk">
// 											{I18n.get('Trunk')}
// 										</Select.Option>
// 										<Select.Option value="left_upper_arm">
// 											{I18n.get('Left shoulder')}
// 										</Select.Option>
// 										<Select.Option value="right_upper_arm">
// 											{I18n.get('Right shoulder')}
// 										</Select.Option>
// 										<Select.Option value="left_lower_arm">
// 											{I18n.get('Left elbow')}
// 										</Select.Option>
// 										<Select.Option value="right_lower_arm">
// 											{I18n.get('Right elbow')}
// 										</Select.Option>
// 										<Select.Option value="hip">
// 											{I18n.get('Hip')}
// 										</Select.Option>
// 										<Select.Option value="left_hand">
// 											{I18n.get('Left hand')}
// 										</Select.Option>
// 										<Select.Option value="right_hand">
// 											{I18n.get('Right hand')}
// 										</Select.Option>
// 										<Select.Option value="left_knee">
// 											{I18n.get('Left knee')}
// 										</Select.Option>
// 										<Select.Option value="right_knee">
// 											{I18n.get('Right knee')}
// 										</Select.Option>
// 										<Select.Option value="left_ankle">
// 											{I18n.get('Left ankle')}
// 										</Select.Option>
// 										<Select.Option value="righ_ankle">
// 											{I18n.get('Right ankle')}
// 										</Select.Option>
// 									</Select>
// 								</Form.Item>
// 							</Col>
// 							<Col xs={24} sm={5}>
// 								<Form.Item
// 									name="ergonomic_grade"
// 									label={I18n.get('Ergonomic grade')}
// 								>
// 									<Select
// 										showSearch
// 										style={{ width: '100%' }}
// 										placeholder={I18n.get(
// 											'Select an option'
// 										)}
// 										optionFilterProp="children"
// 									>
// 										<Select.Option value="LOW">
// 											{I18n.get('Low risk')}
// 										</Select.Option>
// 										<Select.Option value="MODERATE">
// 											{I18n.get('Moderate risk')}
// 										</Select.Option>
// 										<Select.Option value="HIGH">
// 											{I18n.get('High risk')}
// 										</Select.Option>
// 									</Select>
// 								</Form.Item>
// 							</Col>
// 						</Row>
// <Row gutter={6} style={{ paddingTop: '20px' }}>
// 	<Col sm={3}>
// 		<Button
// 			type="primary"
// 			htmlType="submit"
// 			style={{ width: '100%' }}
// 			icon={<SearchOutlined />}
// 		>
// 			{I18n.get('Search')}
// 		</Button>
// 	</Col>
// 	<Col sm={3}>
// 		<Button
// 			onClick={onClearFilter}
// 			style={{ width: '100%' }}
// 		>
// 			{I18n.get('Clear')}
// 		</Button>
// 	</Col>
// </Row>
// 					</Form>
// 				</Collapse.Panel>
// 			</Collapse>
// 		</Container>
// 	);
// }
