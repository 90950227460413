import styled from 'styled-components';
import { Row, Col, Button } from 'antd';

export const SpinContainer = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ProductContainer = styled(Row)`
	width: 100%;
	height: 300px;
	padding: 20px 20px 0 25px;

	border: 2px solid #d9d9d9;
	border-radius: 20px;

	overflow-y: auto;

	&.error-active {
		border: 2px solid #ff4d4f;
	}

	/* width */
	::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		border-left: 3px solid #9c9c9c;
		border-radius: 1.5px;
	}
	::-webkit-scrollbar-button {
		border-radius: 0 20px 20px 0;
	}
	::-webkit-scrollbar-button:single-button:vertical:decrement {
		border-width: 0 8px 8px 8px;
		background-color: transparent;
	}
	::-webkit-scrollbar-button:single-button:vertical:increment {
		border-width: 0 8px 8px 8px;
		background-color: transparent;
	}
`;

export const CustomButton = styled(Button)<{ $borderRadius?: string }>`
	border-radius: ${({ $borderRadius }) => $borderRadius ?? '7px'};
	width: 100%;

	svg:hover {
		fill: #2f54eb;
	}
`;
