import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import type { EvaluatorDTO } from '@/components/ui/Inputs/types/evaluator';
import type { Evaluator } from '@/types';

const getEvaluators = async (params: EvaluatorDTO): Promise<Evaluator[]> => {
	const url = `/evaluator`;
	const { data } = await Api.get<Evaluator[]>(url, { params });

	return data;
};

export const useEvaluators = ({ organization_id, company_id }: EvaluatorDTO) => {
	const enabled = !!organization_id && !!company_id;

	const evaluators = useQuery(
		['evaluators', { organization_id, company_id }],
		() => getEvaluators({ organization_id, company_id }),
		{ enabled }
	);
	return evaluators;
};
