import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, DatePicker, Form } from 'antd';

export function AnalysisDate() {
	return (
		<Col xs={24}>
			<Form.Item name={['analysis_date']} label={I18n.get('Analysis date')} labelCol={{ span: 24 }}>
				<DatePicker
					format="L"
					style={{ width: '100%', borderRadius: '6px' }}
					disabledDate={(current) => current && current.valueOf() >= Date.now()}
				/>
			</Form.Item>
		</Col>
	);
}
