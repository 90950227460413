import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { QUERY_KEYS } from '@/utils/query-keys';
import { queryClient } from '@/store/query';
import Api from '@/services/api';

export type UpdateRotationReportStepDTO = {
	organization_id?: string;
	company_id?: string;
	step?: '1' | '2' | '3' | '4';
	is_completed?: boolean;
	id?: string;
};

export type RotationReportStepResponse = {
	message: string;
};

async function updateRotationReportStep(params: UpdateRotationReportStepDTO): Promise<any> {
	const url = `/rotation/report/${params.id}/step`;
	const { data } = await Api.put(url, params);
	return data;
}

export const useUpdateRotationReportStep = () => {
	return useMutation((prams: UpdateRotationReportStepDTO) => updateRotationReportStep(prams), {
		onSuccess: ({ message: txt }: RotationReportStepResponse) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORTS]);
			message.success(I18n.get(txt));
		}
	});
};
