import React from 'react';
import { Col, Row } from 'antd';

import { MtmReportResult } from '@/hooks';
import { DownloadPDFParams } from '@/components/views/MtmReport/types';
import { CollapseResults } from './CollapseResult';

interface WorkstationOperationsProps {
	workstation_operations?: MtmReportResult['workstation_operations'];
	isLoadingPDF: boolean;
	onDownloadPDF: (params: DownloadPDFParams) => Promise<void>;
}

export function WorkstationOperations({ workstation_operations, onDownloadPDF, isLoadingPDF }: Readonly<WorkstationOperationsProps>) {
	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col span={22}>
				<Row gutter={[50, 40]} justify="start">
					{workstation_operations?.map(({ id, name, operations, total_time }) => (
						<CollapseResults
							key={id}
							id={id}
							onDownloadPDF={onDownloadPDF}
							isLoadingPDF={isLoadingPDF}
							operations={operations}
							title={name}
							total_time={total_time}
						/>
					))}
				</Row>
			</Col>
		</Row>
	);
}
