import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { MtmToOperation } from '@/types/entities/MtmToOperation';
import { BaseContextOptional, MtmAggregationValue, MtmCode } from '@/types';

export type MtmToOperationsResponse = MtmToOperation & {
	mtm_code: MtmCode;
	mtm_aggregation_value: MtmAggregationValue;
};

export type MtmToOperationsDTO = BaseContextOptional & {
	operation_id?: string;
};

const getMtmToOperations = async ({
	company_id,
	operation_id,
	organization_id
}: MtmToOperationsDTO): Promise<MtmToOperationsResponse[]> => {
	const params = {
		company_id,
		operation_id,
		organization_id
	};
	const url = `/mtm/operation`;
	const { data } = await Api.get<MtmToOperationsResponse[]>(url, { params });
	return data;
};

export const useMtmToOperations = ({ company_id, operation_id, organization_id }: MtmToOperationsDTO) => {
	const enabled = !!organization_id && !!company_id && !!operation_id;

	const operations = useQuery(
		[QUERY_KEYS.GET_MTM_TO_OPERATIONS, { organization_id, company_id, operation_id }],
		() => getMtmToOperations({ organization_id, company_id, operation_id }),
		{ enabled }
	);
	return operations;
};
