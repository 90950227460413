import React from 'react';
import { Tooltip } from 'antd';

interface TooltipCustomProps {
	title?: string;
	children: JSX.Element;
}

export function TooltipCustom({ title = '', children }: TooltipCustomProps) {
	return (
		<Tooltip title={title} overlayStyle={{ position: 'fixed' }}>
			{children}
		</Tooltip>
	);
}
