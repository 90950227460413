import { statusTypeDownload, statusTypeReports } from './enum';

export const checkIfValidUUID = (str) => {
	// Regular expression to check if string is a valid UUID
	const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
	return regexExp.test(str);
};

export const checkStatus = ({ status }) => {
	function download() {
		return statusTypeDownload[status] || false;
	}

	function reports() {
		return statusTypeReports[status] || false;
	}

	return { download, reports };
};

export function validationJSON(value) {
	try {
		let result = JSON.parse(value);
		return result;
	} catch (error) {
		return null;
	}
}

function setLocalStorage(key, payload) {
	localStorage.setItem(key, JSON.stringify(payload));
}

function getLocalStorage(key) {
	return JSON.parse(localStorage.getItem(key) || '');
}

export const Utils = { setLocalStorage, getLocalStorage };
