import styled from 'styled-components';
import { Menu } from 'antd';

export const LogoMenu = styled.img`
	width: 80%;
	height: auto;
	margin-top: 28px;
	padding-left: 25px;
	padding-bottom: 20px;
`;

export const SubMenuCentered = styled(Menu.SubMenu)`
	.ant-menu-title-content {
		display: flex;
		align-items: center;
	}
`;
