import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Input } from 'antd';

import { RequiredLabel } from '@/components/ui/RequiredLabel';

interface ReportNameProps {
	fontSize?: string;
	namePlace: string;
	isRequired?: boolean;
}

export function ReportName({ fontSize, isRequired, namePlace }: Readonly<ReportNameProps>) {
	return (
		<Col xs={23}>
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[namePlace, 'report_name']}
				rules={[{ required: true, message: I18n.get('Insert the report name') }]}
				label={<RequiredLabel fontSize={fontSize} title="Report name" isRequired={isRequired} />}
			>
				<Input size="large" placeholder={I18n.get('Report name')} style={{ borderRadius: '8px' }} />
			</Form.Item>
		</Col>
	);
}
