import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, message } from 'antd';
import { Button } from '@/components/ui/Button';
import { useRotationReportContext } from '@/components/views/RotationReport/context';
import { useUpdateRotationReportStep } from '@/hooks/useUpdateRotationReportStep';

const { useFormInstance } = Form;

export function FooterButtons() {
	const { getFieldValue } = useFormInstance();
	const { handleNextStep, handlePreviousStep, rotationReport, basicParams } = useRotationReportContext();
	const { mutateAsync: updateStep } = useUpdateRotationReportStep();

	async function handleOnNextStep(): Promise<void> {
		try {
			const activities_item = getFieldValue('activities_item');
			if (!activities_item) {
				message.error('Add at least one activity');
				return;
			}
			await updateStep({
				step: '4',
				...basicParams,
				is_completed: true,
				id: rotationReport?.id
			});
			handleNextStep();
		} catch (error: any) {
			let err = error?.response?.data?.message || 'Oops... Something went wrong';
			message.error(I18n.get(err));
		}
	}

	return (
		<Row justify="center" align="middle" gutter={[20, 0]}>
			<Col>
				<Button type="ghost" size="middle" htmlType="button" onClick={handlePreviousStep}>
					{I18n.get('Previous')}
				</Button>
			</Col>
			<Col>
				<Button htmlType="submit" size="middle" type="primary" onClick={handleOnNextStep}>
					{I18n.get('Next')}
				</Button>
			</Col>
		</Row>
	);
}
