import React, { ReactNode, createContext, useContext, useState } from 'react';
import { debounce } from 'lodash';
import { Form } from 'antd';

import { FileOperationDTO, useSetFileToOperation } from '@/hooks/useFileOperationMutations';
import { useApplicationContext } from '@/context/application';
import { FilterDTO, useGetFiles } from '@/hooks/useGetFiles';
import { useMtmReportContext } from '@/components/views/MtmReport/context';
import { OperationItem, useCreateOperations } from '@/hooks';
import type { Context, Methods, States } from './types';

const { useFormInstance } = Form;

const SelectFilesContext = createContext<Context>({} as Context);

export const LIMIT = 8;

type OperationParams = {
	file_id: string;
	operation_id: string;
};

export type SelectedRows = {
	[key: string]: {
		operation_id: string;
	};
};
export type HandleSelectedRows = {
	newSelectedRows: SelectedRows;
	hasUndefinedOperation: boolean;
};

export type GetFieldsValue = SelectedRows & {
	[key: string]: Array<string> | Object | string;
} & { filter: FilterDTO };

interface SelectFilesProviderProps {
	children: ReactNode;
}

export function SelectFilesProvider({ children }: Readonly<SelectFilesProviderProps>) {
	const { getFieldsValue, setFieldValue, getFieldValue } = useFormInstance();
	const { handleNextStep, handleSetError, handleSetOperationsWithFiles } = useMtmReportContext();
	const { organization, company } = useApplicationContext();

	const initialSelectedRowKeys: React.Key[] = getFieldValue(['selectedRowKeys']);

	const [page, setPage] = useState<number>(1);
	const [filter, setFilter] = useState<FilterDTO>({} as FilterDTO);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(initialSelectedRowKeys ?? ([] as React.Key[]));

	const baseParameters = {
		organization_id: filter.organization_id ?? organization?.id ?? '',
		company_id: filter.company_id ?? company?.id ?? ''
	};

	const { mutateAsync: fileOperationSet, isSuccess } = useSetFileToOperation();
	const { mutateAsync: createOperations } = useCreateOperations();

	const { data: allFiles, isLoading: loadingAllFiles } = useGetFiles({
		...baseParameters,
		offset: page - 1,
		limit: LIMIT,
		filter: {
			...filter,
			status: 'PROCESSED'
		}
	});

	function handleFilter(value: FilterDTO): void {
		setFilter(value);
	}

	function handlePage(value: number): void {
		setPage(value);
	}

	function handlingSelectRowKeys(value: React.Key[]) {
		setSelectedRowKeys(value);
	}

	function handleSelectedRows(hasSelectedRowKeys: boolean) {
		const allValues: GetFieldsValue = getFieldsValue(true);

		const { filesSelectedArray, operationsToCreate } = verifyIfOperationIsUndefined(allValues, hasSelectedRowKeys);

		return { allValues, operationsToCreate, filesSelectedArray };
	}

	function verifyIfOperationIsUndefined(allValues: GetFieldsValue, hasSelectedRowKeys: boolean) {
		let operationsToCreate: OperationItem[] = [];
		let filesSelectedArray: FileOperationDTO[] = [];

		selectedRowKeys.map(async (fileId) => {
			if (allValues[fileId] && !!allValues[fileId].operation_id) {
				const filesSelected = fixObjectResponseValues(fileId, allValues);
				filesSelectedArray.push(filesSelected);
			} else {
				const fileOperation = normalizerOperationToCreate(fileId);
				operationsToCreate.push(fileOperation);
			}
		});
		return { filesSelectedArray, operationsToCreate };
	}

	function fixObjectResponseValues(fileId: React.Key, allValues: GetFieldsValue) {
		let filesSelected = {} as OperationParams;

		filesSelected.file_id = fileId.toString();
		filesSelected.operation_id = allValues[fileId].operation_id;

		return filesSelected;
	}

	function normalizerOperationToCreate(fileId: React.Key) {
		let fileOperation = {} as OperationItem;

		fileOperation.file_id = fileId.toString();
		fileOperation.name = getFieldValue([fileId, 'operation_name'])?.trim();

		return fileOperation;
	}

	const handleDebounced = debounce(() => {
		handleSetError(false);
	}, 2000);

	async function handleValidation() {
		const hasSelectedRowKeys = selectedRowKeys.length !== 0;

		const { operationsToCreate, filesSelectedArray } = handleSelectedRows(hasSelectedRowKeys);
		let files_operations = filesSelectedArray;
		try {
			if (operationsToCreate.length) {
				const createdOperations = await createOperations({
					...baseParameters,
					operations: operationsToCreate
				});
				createdOperations.forEach((m) => {
					const previousValue = operationsToCreate.find((f) => f.name === m.name);
					files_operations.push({
						...baseParameters,
						file_id: previousValue?.file_id as string,
						operation_id: m.id
					});
				});
			}
			setFilterHierarchyInInformation();
			await fileOperationSet({ files_operations, params: baseParameters });
			files_operations.forEach((f) => setFieldValue([f.file_id, 'operation_id'], f.operation_id));

			handleSetOperationsWithFiles(files_operations);
		} catch (error) {
			handleSetError(true);
			handleDebounced();
		}
	}

	isSuccess && handleNextStep();

	function setFilterHierarchyInInformation() {
		const infoSectorId = getFieldValue(['informations', 'sector_id']);
		const infoLineId = getFieldValue(['informations', 'line_id']);

		const organizationId = filter.organization_id ?? organization?.id;
		const companyId = filter.company_id ?? company?.id;
		const sectorId = filter.sector_id ?? infoSectorId ?? undefined;
		const lineId = filter.line_id ?? infoLineId ?? undefined;

		setFieldValue(['informations', 'organization_id'], organizationId);
		setFieldValue(['informations', 'company_id'], companyId);
		setFieldValue(['informations', 'sector_id'], sectorId);
		setFieldValue(['informations', 'line_id'], lineId);
	}

	const states: States = { allFiles, loadingAllFiles, page, selectedRowKeys, baseParameters };
	const methods: Methods = { handlePage, handleFilter, handleValidation, handlingSelectRowKeys };

	const contextValue = {
		...states,
		...methods
	};

	return <SelectFilesContext.Provider value={contextValue}>{children}</SelectFilesContext.Provider>;
}

export function useSelectFilesContext() {
	const context = useContext(SelectFilesContext);
	return context;
}
