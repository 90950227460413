import { legacy_createStore as createStore, applyMiddleware, Middleware } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { logger } from 'redux-logger';
import { rootReducer } from '@/redux';

const middlewares: Middleware[] = [thunk as ThunkMiddleware];

if (process.env.NODE_ENV === 'development') {
	middlewares.push(logger as Middleware);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
