import React from 'react';
import { Row, Col, Grid } from 'antd';

import { RotationReportResultResponse } from '@/hooks/useGetRotationReportResult';
import { Title } from '@/components/Typography';
import { GoalItem } from './styles';

interface GoalsProps {
	goals: RotationReportResultResponse['goals'];
}

export function Goals({ goals }: Readonly<GoalsProps>) {
	const breakpoint = Grid.useBreakpoint();
	const level = breakpoint.xxl ? 4 : 5;

	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col span={22}>
				<Title level={4}>Goals</Title>
			</Col>
			<Col span={22} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
				<Row>
					<Col span={7} />
					<Col span={9}>
						<Title level={level} style={{ color: '#262626', textAlign: 'center' }}>
							Minimum acceptable exposure
						</Title>
					</Col>
					<Col span={8}>
						<Title level={level} style={{ color: '#262626', textAlign: 'center' }}>
							Exposure found
						</Title>
					</Col>
				</Row>
				{goals.map((goal) => (
					<GoalItem gutter={[20, 0]}>
						<Col span={7}>
							<Title level={level} style={{ color: '#262626' }}>
								{goal.body_part_description}
							</Title>
						</Col>
						<Col span={9} className="text-center">
							<Title level={level} style={{ color: '#262626' }}>
								{goal.minimum_acceptable}%
							</Title>
						</Col>
						<Col span={8} className={goal.reached ? 'text-center reached' : 'text-center not-reached'}>
							<Title level={level} style={{ color: '#262626' }}>
								{goal.result_found}%
							</Title>
						</Col>
					</GoalItem>
				))}
			</Col>
		</Row>
	);
}
