import React from 'react';
import { Col, Form, Select } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useChecklistEIContext } from '../context';

const { useFormInstance } = Form;

export const PostureSelect = () => {
	const { resetFields } = useFormInstance();

	const { postures } = useChecklistEIContext();
	const {
		handleSelectPosture,
		handleSelectWeightRange,
		handleWeightRangeOrder,
		mapDataToDropdown,
		filterOption,
	} = useChecklistEIContext();

	function handlePostureSelect(value: string) {
		resetFields(['weight_range', 'tcm']);
		handleSelectWeightRange('');
		handleWeightRangeOrder(0);
		handleSelectPosture(value);
	}

	const postureOptions = mapDataToDropdown(postures.data);

	return (
		<Col sm={12}>
			<Form.Item
				name="posture"
				label={I18n.get('Posture')}
				rules={[{ required: true }]}
			>
				<Select
					showSearch
					allowClear
					onChange={(value: string) => handlePostureSelect(value)}
					placeholder={I18n.get('Select a posture')}
					options={postureOptions}
					filterOption={(input, option) => filterOption(option, input)}
					loading={postures.gettingPostures}
					disabled={postures.data.length === 0 || postures.errorGettingPostures}
				/>
			</Form.Item>
		</Col>
	);
};
