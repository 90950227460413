import React, { useState } from 'react';
import { Form, Row, Col, Button, Input, message } from 'antd';
import { I18n } from '@aws-amplify/core';

import Api from '@/services/api';
import { useIsMounted } from '@/hooks/useIsMounted';
import { useDispatch, useSelector } from 'react-redux';

import {
	addOperatorReport,
	nextCurrentStep,
	previousCurrentStep,
} from '@/redux/checklist/actions';

export function OperatorReport() {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const isMounted = useIsMounted();

	const [loading, setLoading] = useState(false);

	const checklist = useSelector((state) => state.checklist.checklist);
	const operator_report = useSelector((state) => state.checklist.operator_report);

	const file = useSelector((state) => state.checklist.file);
	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	const onErrors = (err) => {
		const description = err.response?.data?.message || err.message;
		message.error(I18n.get(description));
	};

	const saveData = (values) => {
		const body = {
			checklist_id: checklist.id,
			organization_id: organization.id,
			company_id: company.id,
			file_id: file.id,
			data: values,
		};

		Api.post('/report/checklist/operator-report', body)
			.then(isMounted() && setLoading(true))
			.then((res) => {
				if (isMounted()) {
					setLoading(false);
					dispatch(nextCurrentStep());
				}
			})
			.catch(onErrors)
			.finally(() => isMounted() && setLoading(false));
	};

	const onFinish = () => {
		form.validateFields()
			.then((values) => {
				dispatch(addOperatorReport(values.operator_report));
				return values;
			})
			.then(saveData)
			.catch(() => {});
	};

	const onPrevious = () => {
		dispatch(previousCurrentStep());
	};

	return (
		<Form form={form} layout="vertical" initialValues={{ operator_report }}>
			<Row style={{ paddingTop: '20px' }} gutter={[5, 20]}>
				<Col sm={24}>
					<h2>{I18n.get('Worker report')}</h2>
				</Col>
				<Col sm={24}>
					<p>{I18n.get('Enter worker report below')}</p>
				</Col>
				<Col sm={16}>
					<Row gutter={[10, 20]}>
						<Col sm={24}>
							<Form.Item name="operator_report">
								<Input.TextArea
									showCount
									maxLength={500}
									autoSize={{ minRows: 10, maxRows: 10 }}
								/>
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Row>
								<Col>
									<Button
										style={{ margin: '0 8px' }}
										onClick={onPrevious}
									>
										{I18n.get('Previous')}
									</Button>
								</Col>
								<Col>
									<Button
										type="primary"
										onClick={onFinish}
										loading={loading}
									>
										{I18n.get('Next')}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
}
