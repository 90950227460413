import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { QUERY_KEYS } from '@/utils/query-keys';
import { queryClient } from '@/store/query';
import Api from '@/services/api';

export type UpdateRotationReportActivitySequenceDTO = {
	rotation_activity_id?: string;
	organization_id?: string;
	position: 'up' | 'down';
	company_id?: string;
	id?: string;
};

export type RotationReportActivitySequenceResponse = {
	message: string;
};

async function updateRotationReportActivitySequence(params: UpdateRotationReportActivitySequenceDTO): Promise<any> {
	const url = `/rotation/report/${params.id}/activity/sequence`;
	const { data } = await Api.put(url, params);
	return data;
}

export const useUpdateRotationReportActivitySequence = () => {
	return useMutation(
		(prams: UpdateRotationReportActivitySequenceDTO) => updateRotationReportActivitySequence(prams),
		{
			onSuccess: ({ message: txt }: RotationReportActivitySequenceResponse) => {
				queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_ACTIVITIES]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_RESULT]);
				message.success(I18n.get(txt));
			}
		}
	);
};
