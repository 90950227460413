import React from 'react';
import { Col, Form, Input } from 'antd';

import { I18n } from '@aws-amplify/core';

export const CompanyInput = () => {
	return (
		<Col sm={12}>
			<Form.Item name="company" label={I18n.get('Industrial Site')}>
				<Input disabled={true} />
			</Form.Item>
		</Col>
	);
};
