import { Col } from 'antd';
import styled from 'styled-components';

type MenuContentProps = {
	page: number;
	index?: number;
};

export const Container = styled.div`
	width: 100%;
	padding: 20px 40px;
`;

export const MenuContainer = styled.div`
	width: max-content;
	display: flex;
	flex-direction: row;
	z-index: 10;
`;

export const MenuContent = styled(Col)<MenuContentProps>`
	z-index: 10;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: auto;
	cursor: pointer;
	width: ${(props) => (props.page > 1 ? 'max-content' : '2rem')};
	white-space: 'nowrap';
	overflow: 'hidden';
	text-overflow: 'ellipsis';
	background-color: #2f54eb33;
	padding: ${(props) => (props.page > 1 ? '0.25rem 1.5rem' : '0.25rem')};
	color: #2f54eb;
	font-weight: 600;
	text-align: center;
	border-radius: ${(props) => (props.index === 1 ? '0' : '0.25rem 0 0 0')};
`;

export const TreemapContainer = styled(Col)`
	background-color: #fff;
	padding: 0.5rem;
	border-radius: 0.5rem;
`;

export const CurrentName = styled(Col)`
	z-index: 10;
	height: max-content;
	width: max-content;
	white-space: 'nowrap';
	overflow: 'hidden';
	text-overflow: 'ellipsis';
	background-color: #2f54eb;
	padding: 0.25rem 1.5rem;
	color: #fff;
	font-weight: 600;
	text-align: center;
	margin-left: -3px;
	border-radius: 0.25rem 0.25rem 0 0;
`;
