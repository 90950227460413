import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';

import { useApplicationContext } from '@/context/application';
import { Spinner } from '@/components/SpinnerDeprecated';
import { I18n } from '@aws-amplify/core';

import { DataByStepType } from './index.types';
import { ErrorGettingData, GettingData } from './styles';
import { useDashboardContext } from '../context';
import { MosaicData } from '../index.types';
import { Chart } from './Chart';
import {
	useGetCompaniesWorstScore,
	useGetLinesWorstScore,
	useGetSectorsWorstScore,
	useGetWorkstationsWorstScore
} from './hooks/hooks';
import { WorstScoreDTO } from './hooks/types/response';

export function Mosaic() {
	const { organization, company } = useApplicationContext();
	const { sectorId, filter, companyId, lineId, step } = useDashboardContext();

	const [data, setData] = useState<MosaicData>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);

	const {
		data: companiesData,
		isFetching: fetchingCompanies,
		isError: errorGettingCompanies,
		refetch
	} = useGetCompaniesWorstScore(organization?.id, company?.id, filter, step);

	useEffect(() => {
		if (organization?.id && company?.id) {
			refetch();
		}
	}, [organization?.id, company?.id, filter]);

	const {
		data: workstationsData,
		isFetching: fetchingWorkstations,
		isError: errorGettingWorkstations
	} = useGetWorkstationsWorstScore(organization?.id, company?.id, lineId, filter, step);

	const {
		data: sectorsData,
		isFetching: fetchingSectors,
		isError: errorGettingSectors
	} = useGetSectorsWorstScore(organization?.id, companyId, filter, step);

	const {
		data: linesData,
		isFetching: fetchingLines,
		isError: errorGettingLines
	} = useGetLinesWorstScore(organization?.id, company?.id, sectorId, filter, step);

	const dataByStepMapper: DataByStepType = {
		0: companiesData,
		1: sectorsData,
		2: linesData,
		3: workstationsData
	};

	useEffect(() => {
		const mappedData = mapDataToTreemap(dataByStepMapper[step]);
		setData(mappedData);
	}, [lineId, companyId, sectorId, isLoading, step]);

	useEffect(() => {
		const hasError = errorGettingCompanies || errorGettingWorkstations || errorGettingSectors || errorGettingLines;
		setIsError(hasError);
	}, [errorGettingCompanies, errorGettingWorkstations, errorGettingSectors, errorGettingLines]);

	useEffect(() => {
		const loading = fetchingCompanies || fetchingWorkstations || fetchingSectors || fetchingLines;
		setIsLoading(loading);
	}, [fetchingCompanies, fetchingWorkstations, fetchingSectors, fetchingLines]);

	function mapDataToTreemap(dataToMap: WorstScoreDTO[]) {
		return dataToMap.map((data) => ({
			count: data.size,
			id: data.id,
			title: data.name,
			worst_score: data.worst_score,
			child: data.child
		}));
	}

	if (isError) {
		return (
			<Row justify="center">
				<Col span={24}>
					<ErrorGettingData>
						<span>{I18n.get('Oops... Something went wrong!')}</span>
					</ErrorGettingData>
				</Col>
			</Row>
		);
	}

	if (isLoading) {
		return (
			<Row justify="center">
				<Col span={24}>
					<GettingData>
						<Col>
							<Spinner position="absolute" />
						</Col>
					</GettingData>
				</Col>
			</Row>
		);
	}

	return <Chart data={data} />;
}
