const Types: Record<string, string> = {
	REQUEST_LOADING: 'REQUEST_LOADING',
	LIST_UPLOADS_SUCCESS: 'LIST_UPLOADS_SUCCESS',
	LIST_UPLOADS_FAILURE: 'LIST_UPLOADS_FAILURE',
	LIST_FILE_UPLOAD_SUCCESS: 'LIST_FILE_UPLOAD_SUCCESS',

	GET_ALL_UPLOADS_LOADING: 'GET_ALL_UPLOADS_LOADING',
	GET_ALL_UPLOADS_SUCESS: 'GET_ALL_UPLOADS_SUCESS',
	GET_ALL_UPLOADS_ERROR: 'GET_ALL_UPLOADS_ERROR',

	UPLOAD_STATUS_FILE: 'UPLOAD_STATUS_FILE',
	UPDATE_FILE_SOCKET: 'UPDATE_FILE_SOCKET',

	FIND_AND_FILTER_LOADING: 'FIND_AND_FILTER_LOADING',
	FIND_AND_FILTER_SUCCESS: 'FIND_AND_FILTER_SUCCESS',
	FIND_AND_FILTER_ERROR: 'FIND_AND_FILTER_ERROR',

	LIST_UPLOAD_LOADING: 'LIST_UPLOAD_LOADING',
	LIST_UPLOAD_SUCCESS: 'LIST_UPLOAD_SUCESS',
	LIST_UPLOAD_ERROR: 'LIST_UPLOAD_ERROR'
};

export default Types;
