import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	border-right: 1px solid #c4c4c4;
	padding-right: 40px;
`

export function Logo() {
	return (
		<Wrapper>
			<img src="/assets/images/cnhi.svg" />
		</Wrapper>
	)
}
