import styled from 'styled-components';
import { Button, Checkbox, Input, Row, Select } from 'antd';

export const CustomButton = styled(Button)<{ $borderRadius?: string }>`
	border-radius: ${({ $borderRadius }) => $borderRadius ?? '7px'};
	background-color: ${({ type }) => (type === 'primary' ? '#2f54eb' : null)};
	width: 100%;
	svg:hover {
		fill: #2f54eb;
	}
`;

export const ContainerSelect = styled.div<{ $borderRadius?: string }>`
	.ant-select {
		.ant-select-selector {
			border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'};
		}
	}
`;

export const ContainerDate = styled.div<{ $borderRadius?: string }>`
	.ant-picker {
		border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'} !important;
	}
`;

export const ContainerInput = styled.div<{ $borderRadius?: string }>`
	.ant-input {
		border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'} !important;
	}
`;

export const ContainerTextArea = styled.div<{ $borderRadius?: string }>`
	.ant-input {
		border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'} !important;
	}
	.ant-input-textarea {
		height: 100%;
		display: grid;
		position: relative;
		border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'} !important;
	}

	.ant-form-item-explain-error {
		margin-top: 15px;
	}

	.ant-input-textarea-show-count::after {
		width: 60px;
		bottom: -5px;
		right: 15px;
		position: absolute;
		text-align: right;
		color: #000;
	}

	textarea {
		height: calc(100% + 14px) !important;
		resize: none;
	}
`;

export const DisabledTextInputContainer = styled.div<{ $borderRadius?: string; $overflow?: boolean }>`
	.ant-input {
		border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'} !important;
	}

	textarea:not(:placeholder-shown) {
		height: ${({ $overflow }) => ($overflow ? '100%' : '40px')} !important;
	}

	textarea {
		height: 40px;
		resize: none;
		overflow: hidden;
	}
`;

export const ContainerInputNumber = styled.div<{ $borderRadius?: string; width?: string; content?: string }>`
	.ant-input-number-handler-wrap {
		opacity: 1 !important;
		border-radius: 0 8px 8px 0;
	}

	.ant-input-number:hover .ant-input-number-handler-wrap {
		opacity: 1 !important;
	}
	.ant-input-number {
		border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'} !important;
		width: ${({ width }) => width ?? '20%'};
		min-width: ${({ width }) => width ?? '20%'};
		max-width: 100px;
		&::after {
			position: absolute;
			left: 45px;
			top: 6.5px;
			content: ${({ content }) => content ?? ''};
		}
	}
`;

export const CustomInput = styled(Input)<{ $borderRadius?: string }>`
	border-radius: ${({ $borderRadius }) => $borderRadius ?? '7px'};
	height: 40px;
	font-size: 15px;
	.ant-input {
		margin-left: 7px;
	}
`;

export const ProductContainer = styled(Row)`
	width: 100%;
	height: 300px;
	padding: 20px 20px 0 25px;

	border: 2px solid #d9d9d9;
	border-radius: 20px;

	overflow-y: auto;

	&.error-active {
		border: 2px solid #ff4d4f;
	}

	/* width */
	::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		border-left: 3px solid #9c9c9c;
		border-radius: 1.5px;
	}
	::-webkit-scrollbar-button {
		border-radius: 0 20px 20px 0;
	}
	::-webkit-scrollbar-button:single-button:vertical:decrement {
		border-width: 0 8px 8px 8px;
		background-color: transparent;
	}
	::-webkit-scrollbar-button:single-button:vertical:increment {
		border-width: 0 8px 8px 8px;
		background-color: transparent;
	}
`;

export const CheckboxGroupCustom = styled(Checkbox.Group)`
	width: 100%;
	margin-bottom: 15px;

	.ant-typography {
		font-size: 16px;
	}
	.ant-checkbox {
		margin-bottom: 5px;
		margin-right: 12px;
	}
	.ant-checkbox-wrapper-checked {
		border: 1.5px solid #2f54eb;
		background-color: #eaeefd;
	}
`;

export const CustomSelect = styled(Select)`
	.ant-select-selector {
		border-radius: 8px !important;
	}
`;
