import React, { ReactNode, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { SIDE } from '..';
import type { Side } from '../types';
import * as S from './styles';

interface HorizontalButtonsProps {
	children: ReactNode;
	elementRef: React.MutableRefObject<HTMLDivElement | null>;
}

type HorizontalScroll = {
	step: number;
	speed: number;
	distance: number;
	element: HTMLDivElement | null;
};

export const HorizontalButtons: React.FC<HorizontalButtonsProps> = ({ children, elementRef }) => {
	const [arrowDisableLeft, setArrowDisableLeft] = useState<boolean>(true);
	const [arrowDisableRight, setArrowDisableRight] = useState<boolean>(false);

	const handleHorizontalScroll = ({ element, speed, distance, step }: HorizontalScroll) => {
		let scrollAmount = 0;

		const slideTimer = setInterval(() => {
			if (element) {
				element.scrollLeft += step;
				scrollAmount += Math.abs(step);

				if (scrollAmount >= distance) {
					clearInterval(slideTimer);
				}
				const test = Number(element.innerText.slice(-1));

				if (isNaN(test) && element.scrollLeft !== 0) {
					setArrowDisableRight(true);
					setArrowDisableLeft(false);
				} else if (element.scrollLeft === 0) {
					setArrowDisableRight(false);
					setArrowDisableLeft(true);
				} else {
					setArrowDisableRight(false);
					setArrowDisableLeft(false);
				}
			}
		}, speed);
	};

	const handleClick = (side: Side) => {
		const step = side === SIDE.RIGHT ? 10 : -10;
		handleHorizontalScroll({ element: elementRef.current, distance: 100, speed: 10, step });
	};

	return (
		<Row justify="center" align="bottom">
			<Col>
				<Button
					type="link"
					icon={<LeftOutlined />}
					disabled={arrowDisableLeft}
					onClick={() => handleClick(SIDE.LEFT)}
				/>
			</Col>
			{!arrowDisableLeft && (
				<Col>
					<S.ShadowBar side={SIDE.LEFT} />
				</Col>
			)}
			{children}
			{!arrowDisableRight && (
				<Col>
					<S.ShadowBar side={SIDE.RIGHT} />
				</Col>
			)}
			<Col>
				<Button type="link" icon={<RightOutlined />} onClick={() => handleClick(SIDE.RIGHT)} />
			</Col>
		</Row>
	);
};
