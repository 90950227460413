import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { RiskRangeSelect } from '@/components/ui/Inputs/RiskRangeSelect';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { useGetRiskRanges } from '@/hooks/useGetRiskRanges';
import { RiskRange as RiskRangeType } from '@/types';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

interface RiskRangeProps {
	fontSize?: string;
	namePlace: string;
	isRequired?: boolean;
}

const DEFAULT_RISK = 'cnhi-padrao';

export function RiskRange({ fontSize, namePlace, isRequired = false }: Readonly<RiskRangeProps>) {
	const form = useFormInstance();
	const { setFieldValue, getFieldValue } = useFormInstance();

	const organizationId = useWatch([namePlace, 'organization_id'], form);
	const companyId = useWatch([namePlace, 'company_id'], form);
	const riskRangeId = useWatch([namePlace, 'risk_range_id'], form);

	const {
		isError,
		isLoading,
		isFetching,
		data: riskRanges
	} = useGetRiskRanges({ organization_id: organizationId, company_id: companyId });

	function handleRiskRangeSelect(id: string) {
		setFieldValue([namePlace, 'risk_range_id'], id);
	}

	function handleClearRiskRange() {
		setFieldValue([namePlace, 'risk_range_id'], undefined);
	}

	useEffect(() => {
		const providedRisk = getFieldValue([namePlace, 'risk_range_id']);
		if (!riskRanges?.length || providedRisk) {
			return;
		}
		const default_risk = riskRanges.find((f) => f.name === DEFAULT_RISK);
		if (!default_risk) {
			return;
		}
		setFieldValue([namePlace, 'risk_range_id'], default_risk.id);
	}, [riskRanges, setFieldValue, getFieldValue, namePlace]);

	return (
		<Col span={23}>
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[namePlace, 'risk_range_id']}
				rules={[{ required: true, message: I18n.get('Select the risk range') }]}
				label={<RequiredLabel fontSize={fontSize} title="Risk range" isRequired={isRequired} />}
			>
				<S.ContainerSelect $borderRadius="8px">
					<RiskRangeSelect
						size="large"
						allowCreation
						loading={isFetching}
						riskRanges={riskRanges as RiskRangeType[] | undefined}
						riskRangeId={riskRangeId}
						onClear={handleClearRiskRange}
						onChange={handleRiskRangeSelect}
						fieldName={[namePlace, 'risk_range_id']}
						disabled={isLoading || isFetching || isError}
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
}
