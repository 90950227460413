import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

export interface CreateRotationReportActivityDTO {
	id: string;
	company_id: string;
	frequency?: number;
	break_time?: number;
	activity_id?: string;
	organization_id: string;
	type: 'activity' | 'rest';
	break_description?: string;
}

type CreateRotationReportActivityResponse = {
	message: string;
	data: {
		id: string;
		sequence: number;
		frequency: number;
		total_time: number;
		rotation_report_id: string;
		activity_id: string;
		company_id: string;
		organization_id: string;
	};
};

async function createRotationReportActivity({
	id,
	type,
	...params
}: CreateRotationReportActivityDTO): Promise<CreateRotationReportActivityResponse> {
	const url = `/rotation/report/${id}/${type}`;
	const { data } = await Api.post(url, params);
	return data;
}

export const useCreateRotationReportActivity = () => {
	const queryClient = useQueryClient();

	return useMutation((prams: CreateRotationReportActivityDTO) => createRotationReportActivity(prams), {
		onSuccess: ({ message: txt }) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_ACTIVITIES]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT_CYCLE_TOTAL_TIME]);
			message.success(I18n.get(txt));
		}
	});
};
