import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: Infinity,
			networkMode: 'always'
		},
		mutations: {
			networkMode: 'always'
		}
	}
});
