import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Collapse, Form, Select, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { NewAppointmentContext } from './context';

export function Filter() {
	const [form] = Form.useForm();

	const {
		companies,
		sectors,
		lines,
		workstations,
		updateFilter,
		setSelectCompanyId,
		setSelectSectorId,
		setSelectLineId,
		setSelectWorkstationId,
		selectCompanyId,
		selectSectorId,
		selectLineId,
		onClearFilter,
	} = useContext(NewAppointmentContext);

	function resetFieldsForm(field) {
		const options = {
			company: ['sector', 'line', 'workstation'],
			sector: ['line', 'workstation'],
			line: ['workstation'],
		};

		form.resetFields(options[field] || []);
	}

	function onSubmit(values) {
		const { company, sector, line, workstation } = values;

		updateFilter({
			...(company && { companyId: company }),
			...(sector && { sectorId: sector }),
			...(line && { lineId: line }),
			...(workstation && { workstationId: workstation }),
		});
	}

	function onClearFilterForm() {
		form.resetFields();
		setSelectCompanyId(null);
		setSelectSectorId(null);
		setSelectLineId(null);
		onClearFilter();
	}

	return (
		<Row gutter={[0, 0]}>
			<Col sm={24}>
				<Collapse defaultActiveKey={['1']}>
					<Collapse.Panel header={I18n.get('Filters')} key="1">
						<Form form={form} layout="vertical" onFinish={onSubmit}>
							<Row gutter={[10, 0]}>
								<Col xs={24} sm={4}>
									<Form.Item
										name="company"
										label={I18n.get('Industrial site')}
									>
										<Select
											showSearch
											onChange={(value) => {
												setSelectCompanyId(value);
												resetFieldsForm('company');
											}}
											style={{ width: '100%' }}
											optionFilterProp="children"
											placeholder={I18n.get(
												'Select a industrial site'
											)}
										>
											{companies.map((company, key) => (
												<Select.Option
													key={key}
													value={company.id}
												>
													{company.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={4}>
									<Form.Item name="sector" label={I18n.get('Sector')}>
										<Select
											showSearch
											onChange={(value) => {
												setSelectSectorId(value);
												resetFieldsForm('sector');
											}}
											style={{ width: '100%' }}
											optionFilterProp="children"
											disabled={!selectCompanyId}
											placeholder={I18n.get('Select a sector')}
										>
											{sectors.map((sector, key) => (
												<Select.Option
													key={key}
													value={sector.id}
												>
													{sector.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={4}>
									<Form.Item name="line" label={I18n.get('Line')}>
										<Select
											showSearch
											onChange={(value) => {
												setSelectLineId(value);
												resetFieldsForm('line');
											}}
											style={{ width: '100%' }}
											optionFilterProp="children"
											placeholder={I18n.get('Select a line')}
											disabled={!selectSectorId}
										>
											{lines.map((line, key) => (
												<Select.Option key={key} value={line.id}>
													{line.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={4}>
									<Form.Item
										name="workstation"
										label={I18n.get('Workstation')}
									>
										<Select
											showSearch
											onChange={setSelectWorkstationId}
											style={{ width: '100%' }}
											optionFilterProp="children"
											placeholder={I18n.get('Select a workstation')}
											disabled={!selectLineId}
										>
											{workstations.map((workstation, key) => (
												<Select.Option
													key={key}
													value={workstation.id}
												>
													{workstation.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col sm={4} style={{ margin: 'auto' }}>
									<Button
										type="primary"
										htmlType="submit"
										style={{ width: '100%' }}
										icon={<SearchOutlined />}
									>
										{I18n.get('Search')}
									</Button>
								</Col>
								<Col sm={4} style={{ margin: 'auto' }}>
									<Button
										onClick={onClearFilterForm}
										style={{ width: '100%' }}
									>
										{I18n.get('Clear')}
									</Button>
								</Col>
							</Row>
						</Form>
					</Collapse.Panel>
				</Collapse>
			</Col>
		</Row>
	);
}
