import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';

export type CreateActivitiesDTO = {
	names: string[];
	company_id: string;
	organization_id: string;
}

type Activity = {
	id: string;
	name: string;
}

export type CreatedActivitiesResponse = Activity[];

async function createActivities(params: CreateActivitiesDTO): Promise<CreatedActivitiesResponse> {
	const url = `/activity/bulk-create`;
	const { data } = await Api.post(url, params);
	return data;
}

export const useCreateActivities = () => {
	return useMutation((prams: CreateActivitiesDTO) => createActivities(prams));
};
