import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import { useSelector } from 'react-redux';
import { getElementAtEvent } from 'react-chartjs-2';

import { Header } from './Header';
import { Filter } from './Filter';
import { Painel } from './Painel';
import { Chart } from './Chart';

import { AnalyticsDashboardContext } from './context';

export function AnalyticsDashboard() {

	const organizationId = useSelector((state: any) => state.organization.organization?.id);

	const [state, setState] = useState({
		filter: {
			organizationId,
			segment: 'company'
		},
	});

	function resetFilter() {
		setState({ filter: { organizationId, segment: 'company' } });
	}

	function setFilter(payload: any) {
		setState(prev => ({ ...prev, filter: { ...prev.filter, ...payload } }));
	}

	function onFormatFilterClick(attribute: string) {
		const options = {
			company: 'companyId',
			sector: 'sectorId',
			line: 'lineId',
			workstation: 'workstationId',
			ergonomic_grade: 'globalGrade',
		};

		return options[attribute as keyof typeof options];
	}

	function onClickFilter(e: any, ref: any, data: any, attribute: any) {
		const [meta] = getElementAtEvent(ref, e);
		if (!meta) return;

		const [item] = data.filter((x: any, i: any) => i === meta.index);
		const option = onFormatFilterClick(attribute);
		const value = option === 'globalGrade' ? item.note : item.id;

		if (option === 'companyId') {
			setFilter({ segment: 'sector' });
		} else if (option === 'sectorId') {
			setFilter({ segment: 'line' });
		} else if (option === 'lineId') {
			setFilter({ segment: 'workstation' });
		} else if (option === 'workstationId') {
			setFilter({ segment: 'company' });
		}

		if (item.id === null) {
			setFilter({ [option]: value });
		}

		if (item.id != null) {
			setFilter({
				[option]: value,
				// meta.datasetIndex: 2 => High, 1 => Average e 0 => Low
				// Essas condições abaixo são para deixar como no nosso banco:
				// High: 1, Average: 2 e Low: 3
				globalGrade:
					meta.datasetIndex === 2
						? meta.datasetIndex - 1
						: meta.datasetIndex === 1
							? meta.datasetIndex + 1
							: meta.datasetIndex + 3,
			});
		}
	}

	useEffect(() => {
		if (organizationId) {
			setState(prev => ({ ...prev, filter: { organizationId, segment: 'company' } }));
		}
	}, [organizationId]);

	const context = {
		...state,
		organizationId,
		setFilter,
		resetFilter,
		onClickFilter
	};

	return (
		<AnalyticsDashboardContext.Provider value={context}>
			<Row>
				<Header />
				<Filter />
				<Painel />
				<Chart />
			</Row>
		</AnalyticsDashboardContext.Provider>
	);
}
