import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { DoughnutChart, BarChart } from './Chart';
import { NewAppointmentContext } from './context';
import { ListSegment } from './ListSegment.js';

export function Charts() {
	const {
		filter,
		onClickFilter,
		getErgonomicGrade,
		getBuErgonomicQuote,
		getCcErgonomicQuote,
		getLineErgonomicQuote,
		getWorkstationErgonomicQuote,
		getTotalWorkstations,
		getWorkstationsBySegment,
	} = useContext(NewAppointmentContext);

	return (
		<Row gutter={[12, 12]}>
			<Col sm={12}>
				<DoughnutChart
					title="Ergonomic quote"
					onClickFilter={onClickFilter}
					onFetch={getErgonomicGrade}
					attributeErgonomic="ergonomic_grade"
					onFetchTotalWorkstations={getTotalWorkstations}
					attributeWorkstations="total_workstations"
					filter={filter}
					minHeight={25}
				/>
			</Col>
			<Col sm={12}>
				<BarChart
					title="BU"
					attribute="company"
					onClickFilter={onClickFilter}
					onFetch={getBuErgonomicQuote}
					filter={filter}
					minHeight={25}
				/>
			</Col>
			<Col sm={24}>
				<ListSegment
					attributeErgonomicBySegment="ergonomic_grade_by_segment"
					onFetchErgonomic={getBuErgonomicQuote}
					attributeWorkstationsBySegment="workstations_by_segment"
					onFetchWorkstations={getWorkstationsBySegment}
					filter={filter}
					minHeight={25}
				/>
			</Col>
			<Col sm={12}>
				<BarChart
					title="Cost center"
					attribute="sector"
					onClickFilter={onClickFilter}
					onFetch={getCcErgonomicQuote}
					filter={filter}
					minHeight={25}
					orientation="y"
				/>
			</Col>
			<Col sm={12}>
				<BarChart
					title="Line"
					attribute="line"
					onClickFilter={onClickFilter}
					onFetch={getLineErgonomicQuote}
					filter={filter}
					minHeight={25}
					orientation="y"
				/>
			</Col>
			<Col sm={24}>
				<BarChart
					title="Workstation"
					attribute="workstation"
					onClickFilter={onClickFilter}
					onFetch={getWorkstationErgonomicQuote}
					filter={filter}
					minHeight={25}
				/>
			</Col>
		</Row>
	);
}
