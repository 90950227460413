import Api from '@/services/api';

function onPainelURLParams(payload) {
	const {
		organizationId,
		companyId,
		sectorId,
		lineId,
		workstationId,
		pcd,
		gender,
		restrict,
		groupBy,
		complaint,
		globalGrade,
		medical_certificate,
	} = payload;

	const params = new URLSearchParams();

	params.append('organization_id', organizationId);
	params.append('is_active', true);

	if (companyId) {
		params.append('company_id', companyId);
	}

	if (sectorId) {
		params.append('sector_id', sectorId);
	}

	if (lineId) {
		params.append('line_id', lineId);
	}

	if (workstationId) {
		params.append('workstation_id', workstationId);
	}

	if (typeof pcd === 'boolean') {
		params.append('pcd', pcd);
	}

	if (gender) {
		params.append('gender', gender);
	}

	if (restrict) {
		params.append('restrict', restrict);
	}

	if (groupBy) {
		params.append('group_by', groupBy);
	}

	if (typeof complaint === 'boolean') {
		params.append('complaint', complaint);
	}

	if (globalGrade) {
		params.append('global_grade', globalGrade);
	}

	if (typeof medical_certificate === 'boolean') {
		params.append('medical_certificate', medical_certificate);
	}

	return params;
}

export async function getWorkers(payload) {
	let url = `/appointment/worker-list/group?`;
	const params = onPainelURLParams(payload);
	const { data } = await Api.get(url + params);
	return data;
}
