import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, Button, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import Logo from '@/assets/img/logo.png';
import Api from '@/services/api';
import history from '@/store/history';
import { Container, ContainerImage } from './styles';

export function ResetPassword(props) {
	const [form] = Form.useForm();
	const [submited, setSubmited] = useState(false);
	const [state, setState] = useState({
		loading: false,
		data: null,
		errors: null
	});

	useEffect(() => {
		if (state.errors) {
			const params = {
				message: I18n.get('Oops... Something went wrong!'),
				description: I18n.get(state.errors.message)
			};
			notification.error(params);
		}

		if (state.data) {
			const params = {
				message: I18n.get('Operation performed successfully'),
				description: I18n.get('Click here to continue'),
				duration: 0,
				closable: true,
				onClick: () => {
					let antNotification = document.getElementsByClassName('ant-notification-notice');
					antNotification[0].style['display'] = 'none';
					history.push('/login');
				}
			};
			setSubmited(true);
			notification.success(params);
		}
	}, [state.data, state.errors]);

	const onLoading = (loading) => {
		setState((current) => ({ ...current, loading }));
	};

	const onSuccess = (data) => {
		setState((current) => ({ ...current, data }));
	};

	const onErrors = (errors) => {
		const parseErrors = errors.response?.data || errors;
		setState((current) => ({ ...current, errors: parseErrors }));
	};

	async function onFinish() {
		const { password } = await form.validateFields();
		try {
			onLoading(true);
			const { email, token } = props.computedMatch.params;

			const params = { email, token, password };
			const { data } = await Api.post('/auth/reset-password', params);
			onSuccess(data);
		} catch (error) {
			onErrors(error);
		} finally {
			onLoading(false);
		}
	}

	return (
		<Container>
			<ContainerImage>
				<img alt="logo" src={Logo} />
			</ContainerImage>
			<Form form={form} onFinish={onFinish}>
				<Row>
					<Col sm={24}>
						<h1>{I18n.get('Create new password')}</h1>
					</Col>
					<Col sm={24}>
						<p>{I18n.get('Your new password must be different from previous used passwords')}</p>
					</Col>
					<Col sm={24}>
						<Form.Item
							name="password"
							hasFeedback
							rules={[
								{
									required: true,
									message: I18n.get('Enter your new password')
								}
							]}
						>
							<Input.Password size="large" placeholder={I18n.get('Password')} />
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Form.Item
							name="confirm"
							dependencies={['password']}
							hasFeedback
							rules={[
								{
									required: true,
									message: I18n.get('Confirm new password')
								},
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject(
											I18n.get('The two passwords that you entered do not match')
										);
									}
								})
							]}
						>
							<Input.Password size="large" placeholder={I18n.get('Confirm Password')} />
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Form.Item style={{ paddingTop: '10px' }}>
							<Button
								size="large"
								type="primary"
								htmlType="submit"
								disabled={submited}
								loading={state.loading}
							>
								{I18n.get('Save')}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Container>
	);
}

// import React, { useContext } from 'react';
// import { Row, Col, Form, Input, Button, Alert } from 'antd';
// import Logo from '../../assets/img/logo.png';
// import { Title } from './styles';
// import { Context } from '../../context/ForgotPasswordContext';
// import { I18n } from '@aws-amplify/core';

// const ResetPassword = (props) => {

//   const { resetPassword, loading, onSuccess, onError } = useContext(Context);

//   const styles = {
//     container: {
//       height: '100vh',
//       margin: '10px',
//       textAlign: 'center'
//     },
//     alignLogo: {
//       textAlign: 'center'
//     },
//     logo: {
//       width: '60%'
//     }
//   };

//   const validateMessages = {
//     required: 'E-mail is required!',
//     types: {
//       email: 'E-mail is not validate email!'
//     }
//   };

//   const onSubmit = ({ password }) => {

// const { token, email } = props.computedMatch.params;

//     resetPassword({ token, email, password });

//   };

//   return (
//     <>
//       <Row type="flex" justify="center" gutter={[16, 200]} style={styles.container}>
//         <Col xs={24} sm={24} md={14} lg={14} xl={6}>
//           <Row>
//             <Col style={styles.alignLogo}>
//               <img alt="Kinebot Analytics" style={styles.logo} src={Logo} />
//             </Col>
//             <Col style={{ textAlign: 'center', width: '100%' }}>
//               <Title>{I18n.get('Password recovery')}</Title>
//             </Col>
//             {onSuccess &&
//               <Col style={{ textAlign: 'center', width: '100%', paddingBottom: '15px' }}>
//                 <Alert message={I18n.get(onSuccess.message)} type="success" showIcon />
//               </Col>
//             }
//             {onError &&
//               <Col style={{ textAlign: 'center', width: '100%', paddingBottom: '15px' }}>
//                 <Alert message={I18n.get(onError.error)} type="error" showIcon />
//               </Col>
//             }
//             <Col style={{ width: '100%' }}>
//               <Form
//                 name="reset_password"
//                 onFinish={onSubmit}
//                 validateMessages={validateMessages}
//               >
//                 <Form.Item
//                   name="password"
//                   rules={[
//                     {
//                       required: true,
//                       message: I18n.get('Please input your password!'),
//                     },
//                   ]}
//                   hasFeedback
//                 >
//                   <Input.Password placeholder={I18n.get('Password')} />
//                 </Form.Item>
//                 <Form.Item
//                   name="confirm"
//                   dependencies={['password']}
//                   hasFeedback
//                   rules={[
//                     {
//                       required: true,
//                       message: I18n.get('Please confirm your password!'),
//                     },
//                     ({ getFieldValue }) => ({
//                       validator(rule, value) {
//                         if (!value || getFieldValue('password') === value) {
//                           return Promise.resolve();
//                         }
//                         return Promise.reject(I18n.get('The two passwords that you entered do not match!'));
//                       },
//                     }),
//                   ]}
//                 >
//                   <Input.Password placeholder={I18n.get('Confirm Password')} />
//                 </Form.Item>
//                 <Form.Item>
//                   <Button type="primary" loading={loading} htmlType="submit" style={{ width: '100%' }}>
//                     {I18n.get('Confirm')}
//                   </Button>
//                 </Form.Item>
//               </Form>
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </>
//   );
// }

// export default ResetPassword;
