import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';

import { MtmReportResult } from '@/hooks';
import { DividerCustom } from '@/components/ui/DividerCustom';
import { Title } from '@/components/Typography';
import { InfoItem } from './InfoItem';

interface InformationProps {
	informations?: MtmReportResult['informations'];
}

export function Information({ informations = {} as MtmReportResult['informations'] }: Readonly<InformationProps>) {
	const {
		line_name,
		report_name,
		sector_name,
		company_name,
		analysis_date,
		evaluator_name,
		collection_date,
		organization_name
	} = informations;
	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col span={22}>
				<Title level={4} style={{ color: '#262626' }}>
					Information
				</Title>
			</Col>
			<Col span={22} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
				<Row>
					<Col xs={24} lg={11}>
						<InfoItem title="Company" value={organization_name} />
						<InfoItem title="Industrial site" value={company_name} />
						<InfoItem title="Cost center" value={sector_name} />
						<InfoItem title="Line" value={line_name} />
					</Col>
					<Col xs={0} lg={1}>
						<Row style={{ height: '100%' }}>
							<DividerCustom span={24} color="#D9D9D9" type="vertical" thickness="98%" />
						</Row>
					</Col>
					<Col xs={24} lg={{ offset: 1, span: 11 }}>
						<InfoItem title="Report name" value={report_name} />
						<InfoItem title="Analysis date" value={moment(analysis_date).format('L').toString()} />
						<InfoItem title="Collection date" value={moment(collection_date).format('L').toString()} />
						<InfoItem title="Evaluator" value={evaluator_name} />
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
