import pt_BR from 'antd/es/locale/pt_BR';
import en_US from 'antd/es/locale/en_US';
import { LocaleKeyEnum } from '../utils/enum';
import type { LocaleKey } from './types';
import type { Locale } from 'antd/lib/locale-provider';

export const browserLanguage: string = navigator.language;

const translations: Record<LocaleKey, Locale> = {
	[LocaleKeyEnum.PT_BR]: pt_BR,
	[LocaleKeyEnum.EN_US]: en_US
};

export const localeProvider: Locale = translations[browserLanguage];
