import React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ColumnsType } from 'antd/es/table';
import { useHistory } from 'react-router-dom';

import { GetRotationReportsResponse, RotationDTO } from '@/types';
import { Text } from '@/components/Typography';
import { Actions } from './Actions';
import * as S from './styles';

interface ListProps {
	onShowFiles: (data: string) => void;
	data?: GetRotationReportsResponse;
	organization_id?: string;
	company_id?: string;
	isLoading: boolean;
}

export function List({ isLoading, data, onShowFiles, organization_id, company_id }: ListProps) {
	const history = useHistory();

	const columns: ColumnsType<RotationDTO> = [
		{
			key: 'name',
			ellipsis: true,
			align: 'center',
			dataIndex: 'name',
			sorter: (a, b) => sortByName(a.name, b.name),
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Report name')}</Text>
		},
		{
			ellipsis: true,
			align: 'center',
			key: 'riskRange',
			responsive: ['lg'],
			dataIndex: 'riskRange',
			render: (riskRange) => riskRange.name ?? '-',
			sorter: (a, b) => sortByName(a?.riskRange.name, b?.riskRange.name),
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Risk Range')}</Text>
		},
		{
			ellipsis: true,
			align: 'center',
			key: 'evaluator',
			responsive: ['lg'],
			dataIndex: 'evaluator',
			sorter: (a, b) => sortByName(a.evaluator.name, b.evaluator.name),
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Evaluator')}</Text>,
			render: (evaluator) => evaluator.name ?? '-'
		},
		{
			ellipsis: true,
			align: 'center',
			key: 'analysisDate',
			responsive: ['lg'],
			dataIndex: 'analysisDate',
			sorter: (a, b) => sortByDate(a.analysisDate, b.analysisDate),
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Creation date')}</Text>,
			render: (analysisDate) => (analysisDate ? moment(analysisDate).format('DD/MM/YYYY') : '--/--/----')
		},
		{
			key: 'actions',
			align: 'center',
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Actions')}</Text>,
			render: (_value, data) => (
				<Actions data={data} onShowFiles={onShowFiles} onEditActionClick={handleEditReport} />
			)
		}
	];

	function sortByName(first: string | undefined, second: string | undefined) {
		if (!first || !second) {
			return -1;
		}
		return ('' + first).localeCompare(second);
	}

	function handleEditReport(rotation_report: RotationDTO): void {
		const { id } = rotation_report;
		const path = `/ehs/rotation/report/${id}?organization_id=${organization_id}&company_id=${company_id}`;
		history.push(path);
	}

	const dataSource = data?.rows?.map((report, index) => ({ ...report, key: String(index + 1) }));

	function sortByDate(date_a: Date | string, date_b: Date | string): number {
		return moment.utc(new Date(date_b)).diff(moment.utc(new Date(date_a)));
	}

	return (
		<S.CustomTable span={24}>
			<Table
				rowKey="key"
				columns={columns}
				pagination={false}
				loading={isLoading}
				dataSource={dataSource}
				showSorterTooltip={false}
			/>
		</S.CustomTable>
	);
}
