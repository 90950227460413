import { Spin } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

export const Icon = styled(LoadingOutlined)`
	font-size: ${(props) => props.size}px;
`;

export const Loading = styled(Spin)`
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
`;
