import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { useSectors } from '@/hooks';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

export function Sector() {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const organizationId = useWatch('organization_id', form);
	const companyId = useWatch('company_id', form);
	const sectorId = useWatch('sector_id', form);

	const { isError, isLoading, isFetching, data: sectors } = useSectors({ organizationId, companyId });

	function handleSectorSelect(id: string): void {
		setFieldValue(['sector_id'], id);
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
	}

	function handleClearSector(): void {
		setFieldValue(['sector_id'], undefined);
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<Form.Item name={['sector_id']} label={I18n.get('Cost center')} labelCol={{ span: 24 }}>
				<S.ContainerSelect $borderRadius="6px">
					<SectorSelect
						sectors={sectors}
						sectorId={sectorId}
						loading={isFetching}
						placeholder={I18n.get('Cost center')}
						allowCreation={false}
						fieldName="sector_id"
						onClear={handleClearSector}
						onChange={handleSectorSelect}
						disabled={isLoading || isFetching || isError || !companyId}
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
}
