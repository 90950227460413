import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

export type GetRotationReportResultDTO = {
	organization_id?: string;
	company_id?: string;
	id?: string;
	locale?: string;
};

export type RotationReportResultResponse = {
	informations: Informations;
	comment?: string;
	exposures_legend: ExposuresLegend[];
	total_cycle_time: string;
	files: RotationActivityFileResult[],
	activities: RotationActivityResult[];
	exposures: Exposure[];
	goals: Goal[];
};

export type RotationActivityFileResult = {
	placeholder: Placeholder;
	name: string,
	file_id?: string,
	original_name: string
}

export type RotationActivityResult = {
	id: string;
	name: string;
	sequence: number;
	frequency: number;
	file_id: null | string;
	original_name: null | string;
	total_time: string;
	placeholder: Placeholder;
};

type Placeholder = {
	name: string;
	value: number;
};

type Exposure = {
	id: string;
	body_part: string;
	name: string;
	scores: Score[];
};

type Score = {
	id: string;
	percentage: number;
	color: string;
	text: string;
};

type ExposuresLegend = {
	name: string;
	description: string;
	color: string;
};

type Goal = {
	result_found: string;
	minimum_acceptable: string;
	body_part_description: string;
	reached: boolean;
};

type Informations = {
	analysis_date: string;
	evaluator_name: string;
	company_name: string;
	organization_name: string;
	risk_range: string;
	report_name: string;
};

async function getRotationReportResult(params: GetRotationReportResultDTO): Promise<RotationReportResultResponse> {
	const url = `/rotation/report/${params.id}/result`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useGetRotationReportResult = (params: GetRotationReportResultDTO) => {
	const { organization_id, company_id, id } = params;
	return useQuery([QUERY_KEYS.GET_ROTATION_REPORT_RESULT, params], () => getRotationReportResult(params), {
		enabled: !!organization_id && !!company_id && !!id
	});
};
