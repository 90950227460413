import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import * as S from '../styles';
import { useMtmReportContext } from '@/components/views/MtmReport/context';
import { useCollapseContext } from '../Collapse/context';

const { useFormInstance } = Form;

interface FooterButtonsProps {
	onPreviousStep?: () => void;
	onNextStep: () => Promise<void>;
}

type AntErrorFields = { name: string[] };

export function FooterButtons({ onPreviousStep, onNextStep }: Readonly<FooterButtonsProps>) {
	const { validateFields } = useFormInstance();
	const { handleNextStep, handlePreviousStep, handleSetError, handleDebounced } = useMtmReportContext();
	const { handleCollapseError } = useCollapseContext();

	async function handleOnNextStep() {
		try {
			await validateFields();
			await onNextStep();
			handleNextStep();
		} catch (error: any) {
			const { operations_with_error } = error?.response?.data?.details || {};
			let collapseError = [];

			if (operations_with_error?.length) {
				collapseError = operations_with_error.map((operation_id: string) => [operation_id]);
			}

			if (error?.errorFields?.length) {
				collapseError = error?.errorFields?.map(({ name }: AntErrorFields) => name);
			}

			if (!collapseError?.length) {
				return;
			}

			handleCollapseError(collapseError);
			handleSetError(true);
			handleDebounced();
		}
	}

	function handleOnPreviousStep() {
		if (onPreviousStep) {
			onPreviousStep();
		}
		handlePreviousStep();
	}

	return (
		<Row justify="center" align="middle" gutter={[20, 0]}>
			<Col>
				<S.CustomButton htmlType="submit" size="large" type="primary" onClick={handleOnPreviousStep}>
					{I18n.get('Previous')}
				</S.CustomButton>
			</Col>
			<Col>
				<S.CustomButton htmlType="submit" size="large" type="primary" onClick={handleOnNextStep}>
					{I18n.get('Next')}
				</S.CustomButton>
			</Col>
		</Row>
	);
}
