import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Checkbox, Button, InputNumber, message } from 'antd';

import Api from '@/services/api';
import { useIsMounted } from '@/hooks/useIsMounted';
import { useDispatch, useSelector } from 'react-redux';
import {
	addRegulationNotes,
	nextCurrentStep,
	previousCurrentStep,
} from '@/redux/checklist/actions';

export function RegulationNotes() {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const isMounted = useIsMounted();

	const [loading, setLoading] = useState(false);

	const checklist = useSelector((state) => state.checklist.checklist);
	const regulation_notes = useSelector(
		(state) => state.checklist.regulation_notes
	);

	const file = useSelector((state) => state.checklist.file);
	const organization = useSelector(
		(state) => state.organization.organization
	);
	const company = useSelector((state) => state.organization.company);

	const onErrors = (err) => {
		const description = err.response?.data?.message || err.message;
		message.error(I18n.get(description));
	};

	const saveData = (body) => {
		const parameters = {
			checklist_id: checklist.id,
			organization_id: organization.id,
			company_id: company.id,
			file_id: file.id,
			// sector_id: 'uuid',
			// workstation_id: 'uuid',
			data: {
				...body,
				step: 1,
			},
		};

		Api.post('/report/checklist/regulation-notes', parameters)
			.then(isMounted() && setLoading(true))
			.then((res) => {
				if (isMounted()) {
					setLoading(false);
					dispatch(nextCurrentStep());
				}
			})
			.catch(onErrors)
			.finally(() => isMounted() && setLoading(false));
	};

	const onFinish = () => {
		form.validateFields()
			.then((values) => {
				dispatch(addRegulationNotes(values));
				return values;
			})
			.then(saveData)
			.catch(() => {});
	};

	const onPrevious = () => {
		dispatch(previousCurrentStep());
	};

	return (
		<Form form={form} layout="vertical" initialValues={regulation_notes}>
			<Row style={{ paddingTop: '20px' }} gutter={[5, 20]}>
				<Col sm={24}>
					<h2>{I18n.get('Regulation notes')}</h2>
				</Col>
				<Col sm={24}>
					<p>{I18n.get('Enter job information below')}</p>
				</Col>
				<Col sm={16}>
					<Row gutter={[10, 20]}>
						<Col sm={24}>
							<Form.Item name="interval" valuePropName="checked">
								<Checkbox>
									{I18n.get(
										'Spontaneous breaks are allowed during the workday (e.g. drinking water, drinking coffee, going to the bathroom)'
									)}
								</Checkbox>
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Form.Item
								name="task_diversification"
								valuePropName="checked"
							>
								<Checkbox>
									{I18n.get(
										'In the performance of their functions, there is diversification of tasks'
									)}
								</Checkbox>
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Form.Item
								name="missing_material"
								valuePropName="checked"
							>
								<Checkbox>
									{I18n.get(
										'Materials are always available at the workstation, avoiding rush, delay or overload'
									)}
								</Checkbox>
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Form.Item
								name="work_intensity"
								label={I18n.get(
									'Work intensity: give a score from 1 to 7 (1 being a little work and 7 being very intense work)'
								)}
							>
								<InputNumber min={1} max={7} />
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Row>
								<Col>
									<Button
										style={{ margin: '0 8px' }}
										onClick={onPrevious}
									>
										{I18n.get('Previous')}
									</Button>
								</Col>
								<Col>
									<Button
										type="primary"
										onClick={onFinish}
										loading={loading}
									>
										{I18n.get('Next')}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
}
