import React from 'react';
import { Row, Col, Form, Skeleton } from 'antd';

import { Paragraph, Title } from '@/components/Typography';
import { useUpdateMtmReportMtmStep, useMtmReportOperations } from '@/hooks';
import { useMtmReportContext } from '../../../context';
import { CollapseProvider } from '../../components/Collapse/context';
import { CollapseCustom } from '../../components/Collapse';
import { FooterButtons } from '../../components/FooterButtons';
import { MtmContainer } from './MtmContainer';

interface MtmStepProps {
	title: string;
	description: string;
}

const { useFormInstance } = Form;

export function MtmStep({ title, description }: Readonly<MtmStepProps>) {
	const { getFieldValue } = useFormInstance();
	const { mtmReport, currentStep } = useMtmReportContext();

	const organization_id = getFieldValue(['informations', 'organization_id']);
	const company_id = getFieldValue(['informations', 'company_id']);

	const { data: operations, isLoading: operationLoading } = useMtmReportOperations({
		company_id,
		organization_id,
		id: mtmReport?.id
	});
	const { mutateAsync: verifyMtmStep } = useUpdateMtmReportMtmStep();

	if (operationLoading) {
		return (
			<>
				<Skeleton active />
			</>
		);
	}

	async function handleOnNextStep() {
		await verifyMtmStep({
			id: mtmReport?.id as string,
			organization_id,
			current_step: currentStep + 1,
			company_id
		});
	}

	return (
		<CollapseProvider>
			<Row gutter={[0, 30]} justify="center">
				<Col offset={1} span={23}>
					<Row>
						<Col span={24}>
							<Title level={3}>{title}</Title>
						</Col>
						<Col>
							<Paragraph>{description}</Paragraph>
						</Col>
					</Row>
				</Col>
				<Col offset={1} span={23}>
					<Row gutter={[0, 20]}>
						<Col span={23}>
							<CollapseCustom operations={operations}>
								<MtmContainer />
							</CollapseCustom>
						</Col>
					</Row>
				</Col>
				<Col span={23}>
					<FooterButtons onNextStep={handleOnNextStep} />
				</Col>
			</Row>
		</CollapseProvider>
	);
}
