import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import { SectorsDTO } from '@/components/ui/Inputs/types/sector';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Sector } from '@/types';

const { GET_SECTORS } = QUERY_KEYS;

const getSectors = async ({ organizationId, companyId }: SectorsDTO): Promise<Sector[]> => {
	const url = `/sector/${organizationId}/${companyId}`;
	const { data } = await Api.get<Sector[]>(url);
	return data;
};

export const useSectors = ({ organizationId, companyId }: SectorsDTO) => {
	return useQuery([GET_SECTORS, { organizationId, companyId }], () => getSectors({ organizationId, companyId }), {
		enabled: !!organizationId && !!companyId
	});
};
