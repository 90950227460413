import * as React from 'react';

export const CrIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
			<path
				fill="currentColor"
				d="M7.334 4.014h1.333V8.32l-3.258 2-.695-1.143 2.62-1.607V4.014zm-6 4.013c0-3.688 2.99-6.69 6.666-6.69a6.689 6.689 0 015.776 3.346c.471.82.753 1.734.847 2.675h1.334a8.023 8.023 0 00-1.028-3.345A8.024 8.024 0 008 0C3.59 0 0 3.601 0 8.027c0 2.058.775 4.016 2.182 5.51.3.318.625.6.963.86l.948-.952a6.675 6.675 0 01-2.76-5.418zm13.333.67h-2.666v1.337h1.712l-3.13 3.139-2.166-2.174-4.042 4.055.943.946 3.098-3.11 2.167 2.175 4.084-4.097v1.742H16v-2.676c0-.738-.597-1.338-1.333-1.338z"
			></path>
		</svg>
	);
};
