import axios from 'axios';
import store from '@/store';
import config from '../config';
import { handleLogout } from '../redux/auth/actions';
import { ECONNABORTED, FORBIDDEN, UNAUTHORIZED } from '../utils/constants';

const Api = axios.create({
	baseURL: config.api.url
	// timeout: 10000
});

const onResponseSuccess = (response) => response;

const onResponseFail = (error) => {
	if (error.code === ECONNABORTED) {
		let customError = new Error('Sorry time exceeded, try again.');
		return Promise.reject(customError);
	}

	const status = error.status || error.response?.status;

	if (status === FORBIDDEN || status === UNAUTHORIZED) {
		store.dispatch(handleLogout());
	}

	return Promise.reject(error);
};

Api.interceptors.response.use(onResponseSuccess, onResponseFail);

export default Api;
