import React from 'react';
import { Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Text } from '@/components/Typography';
import type { IconType } from '../types';

export const StepIcon: React.FC<IconType> = ({
	currentStep,
	name,
	position,
	isComplete,
	isConsolidated,
	onStepNavigationClick
}) => {
	const bigName = name.length > 23 ? I18n.get(name) : undefined;

	if (currentStep === position) {
		return (
			<span className="step-name">
				<Tooltip title={bigName}>
					<Text ellipsis>{I18n.get(name)}</Text>
				</Tooltip>
			</span>
		);
	}

	function handleStepChange(): void {
		if (isConsolidated) {
			return;
		}
		onStepNavigationClick(position);
	}

	const isDisabled = isComplete || position === currentStep + 1 ? '' : 'disabled-step';

	return (
		<div className="step-hover">
			<button
				disabled={isConsolidated}
				onClick={handleStepChange}
				className={currentStep > position ? 'step-checked' : `step-number ${isDisabled}`}
			>
				{position + 1}
			</button>
			<button
				disabled={isConsolidated}
				onClick={handleStepChange}
				className={currentStep < position ? `step-number-hovered ${isDisabled}` : 'step-checked-hovered'}
			>
				<Tooltip title={bigName}>
					<Text ellipsis>{I18n.get(name)}</Text>
				</Tooltip>
			</button>
		</div>
	);
};
