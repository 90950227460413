import React from 'react';
import { Col, Row } from 'antd';

import { Title } from '@/components/Typography';
import { OperationElement } from '@/hooks';
import { ResultCard } from '../../../../components/ResultCard';
import { ListCard } from './ListCard';
import * as S from './styled';

interface ProductCardProps {
	product_id: string;
	total_time: string;
	product_name: string;
	operations?: OperationElement[];
}

export function ProductCard({ product_id, product_name, total_time, operations }: Readonly<ProductCardProps>) {
	return (
		<Col xs={24} lg={24} xxl={12}>
			<S.RowCustom gutter={[0, 30]}>
				<Col span={24}>
					<Row justify="space-between" align="middle">
						<Col xs={24} sm={12}>
							<Title style={{ margin: 0, color: '#262626' }} level={5}>
								{product_name}
							</Title>
						</Col>
						<Col>
							<ResultCard
								padding="0.75rem"
								height="30px"
								fontSize="15px"
								borderRadius="7px"
								value={`${total_time}min`}
							/>
						</Col>
					</Row>
				</Col>
				<Col span={24} style={{ height: '75%' }}>
					<Row justify="center" gutter={[0, 10]} style={{ paddingBottom: '40px' }}>
						{operations?.map(({ id, name, total_time }) => (
							<ListCard key={`${product_id}-${id}`} span={23} duration={total_time} operation={name} />
						))}
					</Row>
				</Col>
			</S.RowCustom>
		</Col>
	);
}
