import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import Api from '@/services/api';

export type OperationItem = {
	file_id: string;
	name: string;
};

type OperationResponse = {
	id: string;
	name: string;
};

export type CreateOperationsDTO = {
	organization_id: string;
	company_id: string;
	operations: OperationItem[];
};

const createOperations = async (body: CreateOperationsDTO): Promise<OperationResponse[]> => {
	const url = `/operation/bulk-create`;
	const { data } = await Api.post<OperationResponse[]>(url, body);
	return data;
};

export const useCreateOperations = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateOperationsDTO) => createOperations(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['operation']);
			queryClient.invalidateQueries(['operation_list']);
			message.success(I18n.get('Operations created successfully'));
		}
	});
};
