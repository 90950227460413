import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import type { EvaluatorDTO } from '@/components/ui/Inputs/types/evaluator';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { Evaluator } from '@/types';

const { GET_EVALUATORS } = QUERY_KEYS;

const getEvaluators = async (params: EvaluatorDTO): Promise<Evaluator[]> => {
	const { data } = await Api.get<Evaluator[]>('/evaluator', { params });
	return data;
};

export const useGetEvaluators = (params: EvaluatorDTO) => {
	const { organization_id, company_id } = params;
	return useQuery([GET_EVALUATORS, params], () => getEvaluators(params), {
		enabled: !!organization_id && !!company_id
	});
};
