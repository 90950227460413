import React from 'react';
import { Row, Col, Grid } from 'antd';
import { I18n } from '@aws-amplify/core';

import { RotationReportResultResponse } from '@/hooks/useGetRotationReportResult';
import { CustomRightArrow } from '@/assets/icons/CustomRightArrow';
import { CustomLeftArrow } from '@/assets/icons/CustomLeftArrow';
import { CustomDownArrow } from '@/assets/icons/CustomDownArrow';
import { Title } from '@/components/Typography';
import { ActivityBadge, ActivityItem } from './styles';

interface ActivitiesProps {
	data: RotationReportResultResponse['activities'];
}

function createMatrix(groupSize: number, activities: RotationReportResultResponse['activities']) {
	const matrix = [];

	for (let i = 0; i < activities.length; i += groupSize) {
		const isReversed = Math.floor(i / groupSize) % 2 === 1;
		const group = activities.slice(i, i + groupSize).map((item, index) => {
			return item;
		});
		matrix.push({
			items: group,
			isReversed
		});
	}

	return matrix;
}

export function Activities({ data }: Readonly<ActivitiesProps>) {
	const breakpoints = Grid.useBreakpoint();
	const items_per_row = breakpoints.xxl ? 3 : 2;
	const activities_matrix = createMatrix(items_per_row, data);
	const activities_matrix_length = activities_matrix.length - 1;
	const level = breakpoints.xxl ? 3 : 4;
	const levelSub = breakpoints.xxl ? 4 : 5;

	return (
		<React.Fragment>
			{activities_matrix.map(({ isReversed, items }, i) => {
				return (
					<Row
						align="bottom"
						justify="start"
						style={{ flexDirection: isReversed ? 'row-reverse' : 'row', marginTop: 20, paddingTop: 20 }}
						gutter={[0, 20]}
						key={i}
					>
						{items?.map((activity, index) => (
							<React.Fragment key={activity.id}>
								<Col xxl={6} sm={10} style={{ position: 'relative' }}>
									<ActivityItem>
										<span className="title-container">
											<Title level={level} className="title">
												{activity.placeholder.name}
											</Title>
											<Title level={level} className="title">
												{activity.placeholder.value}
											</Title>
										</span>

										{activity.file_id && (
											<div>
												<Title level={levelSub} className="title">
													{I18n.get('Frequency')}:{' '}
													<span className="text-value">{activity.frequency}</span>
												</Title>
											</div>
										)}
										<div>
											<Title level={levelSub} className="title">
												{I18n.get('Time')}:{' '}
												<span className="text-value">{activity.total_time}</span>
											</Title>
										</div>
										{!activity.file_id && (
											<div>
												<Title level={levelSub} className="title">
													&nbsp;
													<span className="text-value">&nbsp;</span>
												</Title>
											</div>
										)}
									</ActivityItem>
									<ActivityBadge>
										{activity.sequence + 1}
										<span className="upper">°</span>
									</ActivityBadge>
								</Col>
								{items.length - 1 !== index && (
									<Col
										xl={3}
										sm={4}
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: 137
										}}
									>
										{isReversed ? <CustomLeftArrow /> : <CustomRightArrow />}
									</Col>
								)}
							</React.Fragment>
						))}
						{items.length === items_per_row && i !== activities_matrix_length && (
							<Col span={24}>
								<Row justify={isReversed ? 'start' : 'end'}>
									<Col
										xl={6}
										sm={10}
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: 50
										}}
									>
										<CustomDownArrow />
									</Col>
								</Row>
							</Col>
						)}
					</Row>
				);
			})}
		</React.Fragment>
	);
}
