import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Table, Spin } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useDispatch } from 'react-redux';

import * as R from 'ramda';
import slayer from 'slayer';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { setFrequencyAndDurationRangeRisk } from '@/redux/reports/actions';

const slayerConfig = { minPeakDistance: 2 };

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 180px;
`;

export const Loading = () => (
	<Container>
		<Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />} />
	</Container>
);

const Errors = () => (
	<Container>
		<h2>{I18n.get('Non-standard scale')}</h2>
	</Container>
);

const INTERVAL = 6;
const WINDOW_VALUE = 5;
const DIFF_BETWEEN = 5;

export function TableResult({ arrayBands, anglesPart, part }) {
	const mounted = useRef(false);
	const dispatch = useDispatch();

	const [preLoading, setPreLoading] = useState(true);
	const [errors, setErrors] = useState(null);
	const [dataSource, setDataSource] = useState(null);

	// const [angleArray, setAngleArray] = useState([]);
	const [peaks, setPeaks] = useState([]);

	const columns = [
		{
			title: I18n.get('Qualification'),
			dataIndex: 'band',
			colSpan: 2,
		},
		{
			title: 'Angles',
			colSpan: 0,
			dataIndex: 'angles',
			onCell: (_, index) => {
				if (index === 4) {
					return { colSpan: 0 };
				}
			},
		},
		{
			title: I18n.get('Frequency'),
			dataIndex: 'frequency',
			align: 'center',
		},
		{
			title: I18n.get('Duration'),
			dataIndex: 'duration',
			align: 'center',
		},
	];

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);

	const mediaResults = (value) => (value / INTERVAL).toFixed(0);

	const sumValues = (acc, value, index) => {
		const range = Math.floor(index / INTERVAL);
		const parseValue = isNaN(Number(value)) ? 0 : Number(value);
		acc[range] ? (acc[range] += parseValue) : (acc[range] = parseValue);
		return acc;
	};

	useLayoutEffect(() => {
		if (anglesPart && anglesPart?.[0].angles.length > 0) {
			const array = anglesPart[0].angles;

			let groupAngles = array.reduce(sumValues, []).map(mediaResults);

			slayer(slayerConfig)
				.fromArray(groupAngles)
				.then((spikes) => spikes.map((spike) => spike.y))
				.then((spikeArr) => setPeaks(spikeArr));

			// const combineArray = R.aperture(WINDOW_VALUE, groupAngles);

			// const frequency = combineArray.reduce(
			// 	(acc, value, index, array) => {
			// const minValue = Math.min(...value);
			// const maxValue = Math.max(...value);

			// const absolute = Math.abs(minValue - maxValue).toFixed(0);

			// absolute > DIFF_BETWEEN && acc.push(absolute);
			// return acc;
			// 	},
			// 	[]
			// );

			// setPeaks(frequency);
			// setAngleArray(groupAngles);
		}
		return () => {};
	}, [anglesPart]);

	const calculateDuration = (angles, bands, body_part) => {
		let totalDuration = 0;

		const array = bands
			.map((band, index) => {
				const duration = angles.reduce((total, angle) => {
					if (angle >= band.min && angle <= band.max) {
						totalDuration++;
						return total + 1;
					} else {
						return total;
					}
				}, 0);

				const mediaAngles = R.aperture(WINDOW_VALUE, peaks);

				const frequency = mediaAngles.reduce((acc, value) => {
					const minValue = Math.min(...value);
					const maxValue = Math.max(...value);

					const absolute = Math.abs(minValue - maxValue);

					const outlier = absolute >= DIFF_BETWEEN ? absolute : null;

					if (outlier && outlier >= band.min && outlier <= band.max) {
						return acc + 1;
					}

					return acc;
				}, 0);

				// const frequency = peaks.reduce((total, value) => {
				// 	if (value >= band.min && value <= band.max) {
				// 		return total + 1;
				// 	} else {
				// 		return total;
				// 	}
				// }, 0);

				return {
					id: index.toString(),
					band: I18n.get(
						(index === 0 && 'Low') ||
							(index === 1 && 'Medium') ||
							(index === 2 && 'High') ||
							'Invalid'
					),
					angles: `${band.min} a ${band.max}`,
					frequency: frequency,
					duration: duration,
				};
			})
			.map((item, index) => {
				const durationFinal =
					totalDuration > 0
						? ((item.duration * 100) / totalDuration).toFixed(1)
						: 0;
				dispatch(
					setFrequencyAndDurationRangeRisk({
						index,
						duration: durationFinal,
						frequency: item.frequency,
						body_part,
					})
				);
				return {
					...item,
					duration: `${durationFinal}%`,
				};
			});

		return array;
	};

	// const calculateDuration = (angles, bands) => {
	// 	let totalDuration = 0

	// 	const array = bands.map((band, index) => {
	// 		const duration = angles.reduce((total, angle) => {
	// 			if (angle >= band.min && angle <= band.max) {
	// 				totalDuration++;
	// 				return total + 1;
	// 			} else {
	// 				return total;
	// 			}
	// 		}, 0)

	// 		return {
	// 			id: index.toString(),
	// 			band: (index === 0 && 'Baixo') || (index === 1 && 'Médio') || (index === 2 && 'Alto') || 'Inválido',
	// 			angles: `${band.min} a ${band.max}`,
	// 			frequency: 123,
	// 			duration: duration
	// 		}
	// 	})
	// 		.map(item => ({
	// 			...item,
	// 			duration: `${(item.duration * 100 / totalDuration).toFixed(1)}%`
	// 		}))

	// 	return array
	// }

	useEffect(() => {
		if (
			mounted.current &&
			arrayBands?.length > 0 &&
			anglesPart?.[0].angles.length > 0
		) {
			const angles = anglesPart?.[0].angles;

			const data = calculateDuration(angles, arrayBands, part);

			// const angles = anglesPart?.[0].angles;
			// const data = arrayBands.map((item, index) => {
			// 	const duration = calculateDuration(angles, item);
			// return {
			// 	id: index.toString(),
			// 	band: index === 0 && 'Baixo' || index === 1 && 'Médio' || index === 2 && 'Alto' || 'Inválido',
			// 	angles: `${item.min} a ${item.max}`,
			// 	frequency: 123,
			// 	duration: duration
			// }
			// });

			setDataSource(data);
			setPreLoading(false);
		}

		if (mounted.current && !arrayBands) {
			setErrors(true);
			setPreLoading(false);
		}
	}, [arrayBands, anglesPart]);

	if (preLoading) {
		return <Loading />;
	}

	if (errors) {
		return <Errors />;
	}

	return (
		<Table
			bordered
			rowKey="id"
			columns={columns}
			dataSource={dataSource}
			pagination={false}
		/>
	);
}
