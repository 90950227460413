import styled from 'styled-components';

export const Wrapper = styled.div`
	padding: 50px 0;
`;

export const Title = styled.h3`
	font-weight: bold;
	font-size: 16px;
`;

export const CardsList = styled.div`
	display: flex;
	padding: 0;
	flex-wrap: wrap;
	justify-content: space-between;
	row-gap: 0;
	gap: 0;
`;

export const Card = styled.div<{ selected: boolean }>`
	background: rgba(196, 196, 196, ${({ selected }) => (selected ? 1 : '0.6')});
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
`;

export const CardImage = styled.div`
	padding: 20px 20px 10px;
	flex-grow: 1;
	display: flex;

	& img {
		max-width: 70px;
		max-height: 70px;
	}
`;

export const CardTitle = styled.div`
	justify-self: end;
	padding-bottom: 10px;
	text-align: center;
`;
