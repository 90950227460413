import React from 'react';
import { Col, Button } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { FileAddOutlined } from '@ant-design/icons';

export const Title = styled.div`
	display: inline-block;
	margin-right: 5px;
`;

export const Header = ({ onCreate }) => (
	<Col sm={24} style={{ paddingBottom: '15px' }}>
		<Title>
			<h2>{I18n.get('Manage users')}</h2>
		</Title>
		<Button
			size="large"
			type="primary"
			onClick={onCreate}
			icon={<FileAddOutlined />}
			style={{ display: 'inline', float: 'right' }}
		>
			{I18n.get('Create')}
		</Button>
	</Col>
);
