import React from 'react'
import { Form, Input } from 'antd'
import { I18n } from '@aws-amplify/core'

export function CreateForm({ form, type }) {
	return (
		<Form form={form} layout="vertical">
			<Form.Item
				label={I18n.get('Name')}
				name="name"
				rules={[
					{
						required: true,
						message: I18n.get(`Enter a name for the ${type}`),
					},
				]}
			>
				<Input />
			</Form.Item>
		</Form>
	)
}
