import React from 'react';
import { Col, Divider, Row, Skeleton } from 'antd';

import { useGetRotationReportActivityRiskRanges } from '@/hooks/useGetRotationReportActivityRiskRanges';
import { useRotationReportContext } from '../../../../../context';
import { Text } from '@/components/Typography';
import { ActivityChart } from './Chart';
import { ChartLegend, ChartLegendBadge } from '../styles';

interface ActivityTabContentProps {
	activity_id: string;
	activity_name: string;
}

const HORIZONTAL_DIVIDER_INDEXES = [1, 2, 4, 5];
const VERTICAL_DIVIDER_INDEXES = [2];

export function ActivityTabContent({ activity_id }: Readonly<ActivityTabContentProps>) {
	const { rotationReport, basicParams } = useRotationReportContext();
	const { data: riskRanges, isLoading } = useGetRotationReportActivityRiskRanges({
		id: rotationReport?.id,
		...basicParams,
		activity_id
	});

	if (isLoading) {
		return <SkeletonActivityChart />;
	}

	return (
		<Row justify={'space-between'} style={{ marginTop: 20 }}>
			{riskRanges?.map((riskRange, index) => (
				<React.Fragment key={riskRange.id}>
					{HORIZONTAL_DIVIDER_INDEXES.includes(index) && (
						<Col span={1}>
							<Divider type="vertical" style={{ height: '100%', borderColor: '#26262680' }} />
						</Col>
					)}
					<Col span={7}>
						<ActivityChart title={riskRange.name} data={riskRange.scores} />
					</Col>
					{VERTICAL_DIVIDER_INDEXES.includes(index) && (
						<Col span={24}>
							<Divider style={{ borderColor: '#26262680' }} />
						</Col>
					)}
				</React.Fragment>
			))}
			<Col span={24} style={{ display: 'flex' }}>
				{riskRanges?.[0]?.scores.map((m) => (
					<ChartLegend key={m.id}>
						<ChartLegendBadge key={m.id} $color={m.color} />
						<Text>{m.text}</Text>
					</ChartLegend>
				))}
			</Col>
		</Row>
	);
}

export function SkeletonActivityChart() {
	return (
		<Row justify={'space-between'} style={{ marginTop: 20 }}>
			{Array.from(Array(6)).map((_, index) => (
				<React.Fragment key={index}>
					{HORIZONTAL_DIVIDER_INDEXES.includes(index) && (
						<Col span={1}>
							<Divider type="vertical" style={{ height: '100%', borderColor: '#26262680' }} />
						</Col>
					)}
					<Col span={7} style={{ display: 'flex', justifyContent: 'center' }}>
						<SkeletonRiskChart />
					</Col>
					{VERTICAL_DIVIDER_INDEXES.includes(index) && (
						<Col span={24}>
							<Divider style={{ borderColor: '#26262680' }} />
						</Col>
					)}
				</React.Fragment>
			))}
			<Col span={12} style={{ display: 'flex', marginTop: 30 }}>
				<Skeleton.Input active block />
			</Col>
		</Row>
	);
}

export function SkeletonRiskChart() {
	return <Skeleton.Input active style={{ width: 212, height: 212, borderRadius: 212 }} />;
}
