import React from 'react';
import { Form, InputNumber } from 'antd';
import { I18n } from '@aws-amplify/core';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import * as S from '../../styles';

export function BreakTime() {
	return (
		<S.ContainerInputNumber $borderRadius="8px">
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={['cycle_result', 'break_time']}
				rules={[{ required: true, message: I18n.get('Provide the time') }]}
				label={<RequiredLabel fontSize={'16px'} title="Time (minutes)" isRequired={false} />}
			>
				<InputNumber size="large" placeholder="0" min={1} style={{ width: '100%' }} />
			</Form.Item>
		</S.ContainerInputNumber>
	);
}
