import React, { useState } from 'react';

import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import hooks from '../../../hooks';

import { CreateCompany } from './CreateCompany';
import { SelectCompany } from './SelectCompany';
import { EditCompany } from './EditCompany';

const { useGetCompanies } = hooks;

export const ManageCompany = () => {
	const [companyName, setCompanyName] = useState('');
	const [isEditing, setIsEditing] = useState(false);

	const { organizationId } = useManageOrganization();

	const {
		data: companies,
		isError: errorGettingCompanies,
		isLoading: gettingCompanies,
		isFetching: fetchingCompanies,
	} = useGetCompanies(organizationId);

	function handleCompanyNameChange(name: string) {
		setCompanyName(name);
	}

	function handleIsEditing(editing: boolean) {
		setIsEditing(editing);
	}

	if (errorGettingCompanies) {
		return I18n.get('Oops... Something went wrong!');
	}

	if (fetchingCompanies || gettingCompanies) {
		return `${I18n.get('Loading')}...`;
	}

	return companies.length === 0 ? (
		<CreateCompany />
	) : isEditing ? (
		<EditCompany
			companyName={companyName}
			onCompanyNameChange={handleCompanyNameChange}
			onEditing={handleIsEditing}
		/>
	) : (
		<SelectCompany
			companies={companies}
			companyName={companyName}
			onCompanyNameChange={handleCompanyNameChange}
			onEditing={handleIsEditing}
		/>
	);
};
