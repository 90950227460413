import styled from 'styled-components';

export const Value = styled.div`
	label {
		font-family: Arial,sans-serif;
		font-size: 15px;
	}
`;

export const Description = styled(Value)`
	label {
		opacity: 0.7;
	}
`;

export const Title = styled.h2`
	font-size: 20px;
`;


// export const Description = styled.div`
// 	label {
// 		font-family: Arial,sans-serif;
// 		font-size: 15px;
// 		opacity: 0.7;
// 	}
// `;


