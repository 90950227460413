import { Row } from 'antd';
import styled from 'styled-components';

export const TCMContainer = styled(Row)`
	.ant-table-tbody > tr:nth-child(1) > td {
		border: none;
	}
	.ant-table-tbody > tr > td > span {
		font-weight: 600;
	}
`;
