/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';
import Api from '@/services/api';
import { useIsMounted } from '@/hooks/useIsMounted';
import { DatePicker, Select, Divider, List, message } from 'antd';
import { SidebarContainer, SideItemTitle, ScrollableContainer } from './styles';
import { useSelector } from 'react-redux';

export function Sidebar({
	actionPlan,
	responsible_workers,
	reloadHistory,
	triggerReloadHistory,
	loadingHistory,
	setLoadingHistory,
}) {
	const isMounted = useIsMounted();

	const [history, setHistory] = useState([]);
	const [errors, setErrors] = useState(null);

	const organizationId = useSelector(
		({ organization }) => organization.organization?.id
	);

	const getHistory = useCallback(() => {
		Api.get(
			`/action_plan/history/${actionPlan.id}?organization_id=${organizationId}`
		)
			.then((res) => isMounted() && setHistory(() => res.data))
			.catch((err) => isMounted() && setErrors(err))
			.finally(() => isMounted() && setLoadingHistory(false));
	}, []);

	async function onResponsibleChange(actionPlan, responsible_worker_id) {
		try {
			const action_plan = { ...actionPlan, responsible_worker_id };
			const body = { action_plan };
			const { data } = await Api.put(
				`/action_plan/${actionPlan.id}?organization_id=${organizationId}`,
				body
			);
			message.success(I18n.get(data.message));
			triggerReloadHistory();
		} catch (errors) {
			setErrors(errors);
		}
	}

	async function onDeadlineChange(actionPlan, deadline) {
		try {
			const action_plan = { ...actionPlan, deadline };
			const body = { action_plan };
			const { data } = await Api.put(
				`/action_plan/${actionPlan.id}?organization_id=${organizationId}`,
				body
			);
			message.success(I18n.get(data.message));
			triggerReloadHistory();
		} catch (errors) {
			setErrors(errors);
		}
	}

	const truncateText = (str, charactersLimit) => {
		return str.length > charactersLimit
			? str.slice(0, charactersLimit - 1) + '...'
			: str;
	};

	useEffect(() => {
		setLoadingHistory(true);
		getHistory(actionPlan);
	}, []);

	useEffect(() => {
		getHistory(actionPlan);
	}, [actionPlan, reloadHistory]);

	useEffect(() => {
		if (errors) {
			const result = errors.response?.data?.message || errors.message;
			const description = I18n.get(result);
			message.error(description);
			setErrors(null);
		}
	}, [errors]);

	return (
		<SidebarContainer>
			<SideItemTitle>{I18n.get('Deadline')}</SideItemTitle>
			<DatePicker
				allowClear={false}
				onChange={(deadline) => onDeadlineChange(actionPlan, deadline)}
				style={{
					width: '70%',
					marginLeft: '40px',
					marginBottom: '10px',
				}}
				format={'DD/MM/YYYY'}
				defaultValue={moment(actionPlan.deadline)}
				placeholder={I18n.get('Date')}
				disabledDate={(current) =>
					current < moment().subtract(1, 'day')
				}
			/>
			<SideItemTitle>{I18n.get('Person responsible')}</SideItemTitle>
			<Select
				onChange={(person) => onResponsibleChange(actionPlan, person)}
				style={{
					width: '70%',
					marginLeft: '40px',
					marginBottom: '10px',
				}}
				defaultValue={actionPlan.responsible_worker_id}
			>
				{responsible_workers.map((person, index) => (
					<Select.Option key={index} value={person.id}>
						{person.name}
					</Select.Option>
				))}
			</Select>
			{!loadingHistory && (
				<React.Fragment>
					<Divider orientation="left" style={{ marginTop: '20px' }}>
						{I18n.get('History')}
					</Divider>
					<ScrollableContainer style={{ maxHeight: '260px' }}>
						<List
							size="small"
							dataSource={history}
							renderItem={(item) => (
								<List.Item
									extra={moment(item.event_date).format(
										'DD/MM/YY'
									)}
								>
									<List.Item.Meta
										title={truncateText(
											`${I18n.get(
												item.event
											)}: "${I18n.get(
												item.responsible_worker?.name ??
													item.event_value
											)}"`,
											40
										)}
										description={
											I18n.get('by ') + item.user.name
										}
									/>
								</List.Item>
							)}
						></List>
					</ScrollableContainer>
				</React.Fragment>
			)}
		</SidebarContainer>
	);
}
