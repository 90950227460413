import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Checkbox, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Api from '@/services/api';
import { useIsMounted } from '@/hooks/useIsMounted';
import {
	addHitAgainst,
	nextCurrentStep,
	previousCurrentStep,
} from '@/redux/checklist/actions';

export function HitAgainst() {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const isMounted = useIsMounted();

	const [loading, setLoading] = useState(false);

	const checklist = useSelector((state) => state.checklist.checklist);
	const hit_against = useSelector((state) => state.checklist.hit_against);

	const file = useSelector((state) => state.checklist.file);
	const organization = useSelector(
		(state) => state.organization.organization
	);
	const company = useSelector((state) => state.organization.company);

	const onErrors = (err) => {
		const description = err.response?.data?.message || err.message;
		message.error(I18n.get(description));
	};

	const saveData = (body) => {
		const parameters = {
			checklist_id: checklist.id,
			organization_id: organization.id,
			company_id: company.id,
			file_id: file.id,
			// sector_id: 'uuid',
			// workstation_id: 'uuid',
			data: {
				hit_against: body,
				step: 1,
			},
		};

		Api.post('/report/checklist/hit-against', parameters)
			.then(isMounted() && setLoading(true))
			.then((res) => {
				if (isMounted()) {
					setLoading(false);
					dispatch(nextCurrentStep());
				}
			})
			.catch(onErrors)
			.finally(() => isMounted() && setLoading(false));
	};

	const onFinish = () => {
		form.validateFields()
			.then((values) => {
				dispatch(addHitAgainst(values.hit_against));
				return values.hit_against;
			})
			.then(saveData)
			.catch(() => {});
	};

	const onPrevious = () => {
		dispatch(previousCurrentStep());
	};

	return (
		<Form
			form={form}
			layout="vertical"
			initialValues={{
				hit_against: hit_against !== null ? hit_against : false,
			}}
		>
			<Row style={{ paddingTop: '20px' }} gutter={[5, 20]}>
				<Col sm={24}>
					<h2>{I18n.get('Hit against')}</h2>
				</Col>
				<Col sm={24}>
					<p>{I18n.get('Enter job information below')}</p>
				</Col>
				<Col sm={16}>
					<Row gutter={[10, 20]}>
						<Col sm={24} style={{ minHeight: '250px' }}>
							<Form.Item
								name="hit_against"
								valuePropName="checked"
							>
								<Checkbox>
									{I18n.get(
										'During the operation, the operator taps his hands to adjust parts or components.'
									)}
								</Checkbox>
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Row>
								<Col>
									<Button
										style={{ margin: '0 8px' }}
										onClick={onPrevious}
									>
										{I18n.get('Previous')}
									</Button>
								</Col>
								<Col>
									<Button
										type="primary"
										onClick={onFinish}
										loading={loading}
									>
										{I18n.get('Next')}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
}
