import React from 'react';
import { ColProps, Row } from 'antd';
import { Text } from '@/components/Typography';
import * as S from './styles';

interface ResultCardProps {
	height?: string;
	fontSize?: string;
	padding?: string;
	minWidth?: string;
	borderRadius?: string;
	value?: number | string;
}

export function ResultCard({
	value,
	height,
	fontSize,
	borderRadius,
	padding,
	minWidth,
	...props
}: Readonly<ResultCardProps & ColProps>) {
	return (
		<Row>
			<S.ColCustom
				{...props}
				$height={height}
				$padding={padding}
				$fontSize={fontSize}
				$borderRadius={borderRadius}
				$minWidth={minWidth}
			>
				<Text style={{ fontSize: 26 }}>{value}</Text>
			</S.ColCustom>
		</Row>
	);
}
