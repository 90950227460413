import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

export interface CreateFilesActivitiesDTO {
	company_id: string;
	organization_id: string;
	files_activities: FileActivity[];
}

type FileActivity = {
	activity_id: string;
	file_id: string;
}

type CreateFilesActivitiesResponse = {
	status: string;
	message: string;
};

async function createFilesActivities(params: CreateFilesActivitiesDTO): Promise<CreateFilesActivitiesResponse> {
	const url = `/activity/files`;
	const { data } = await Api.post(url, params);
	return data;
}

export const useCreateFilesActivities = () => {
	const queryClient = useQueryClient();

	return useMutation((prams: CreateFilesActivitiesDTO) => createFilesActivities(prams), {
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_FILES_WITH_ACTIVITY]);
		}
	});
};
