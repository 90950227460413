import React from 'react';
import { Text } from '@/components/Typography';

import * as S from './styles';

interface ProductCardProps {
	name: string;
	id: string;
	hidden?: boolean;
}

export function ProductCard({ name, id, hidden }: ProductCardProps) {
	return (
		<S.CheckboxCustom value={id} hidden={hidden}>
			<Text>{name}</Text>
		</S.CheckboxCustom>
	);
}
