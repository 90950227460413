import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

export type MtmOperationsResponse = {
	id: string;
	name: string;
	file_id: string;
};

export type MtmReportOperationsDTO = {
	organization_id: string;
	company_id: string;
	id?: string;
};

const getMtmReportOperations = async ({
	company_id,
	id,
	organization_id
}: MtmReportOperationsDTO): Promise<MtmOperationsResponse[]> => {
	const params = {
		company_id,
		id,
		organization_id
	};
	const url = `/mtm/report/${id}/operations`;
	const { data } = await Api.get<MtmOperationsResponse[]>(url, { params });
	return data;
};

export const useMtmReportOperations = ({ company_id, id, organization_id }: MtmReportOperationsDTO) => {
	const enabled = !!organization_id && !!company_id && !!id;

	const operations = useQuery(
		[QUERY_KEYS.GET_MTM_REPORT_OPERATIONS, { organization_id, company_id, id }],
		() => getMtmReportOperations({ organization_id, company_id, id }),
		{ enabled }
	);
	return operations;
};
