import React from 'react';
import { Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { I18n } from '@aws-amplify/core';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import * as S from '../styles';

interface DescriptionProps {
	fontSize?: string;
	namePlace: string[];
}

export function Description({ fontSize, namePlace }: Readonly<DescriptionProps>) {
	return (
		<S.ContainerTextArea $borderRadius="8px">
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[...namePlace, 'description']}
				rules={[{ required: true, message: I18n.get('Provide a description') }]}
				label={<RequiredLabel bold fontSize={fontSize} title="Description" isRequired={false} />}
			>
				<TextArea size="large" maxLength={150} rows={4} showCount placeholder={I18n.get('Description')} />
			</Form.Item>
		</S.ContainerTextArea>
	);
}

export function DescriptionInput({ fontSize, namePlace }: Readonly<DescriptionProps>) {
	return (
		<S.ContainerInput $borderRadius="8px">
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[...namePlace, 'description']}
				rules={[{ required: true, message: I18n.get('Insert the description') }]}
				label={<RequiredLabel fontSize={fontSize} title="Description" isRequired={true} />}
			>
				<Input size="large" placeholder={I18n.get('Description')} />
			</Form.Item>
		</S.ContainerInput>
	);
}
