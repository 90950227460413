import React from 'react';
import { Breadcrumb } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Link, useLocation } from 'react-router-dom';
import { breadcrumbNameMap } from './constants';

export function BreadcrumbLayout() {
	const location = useLocation();
	const path = location.pathname.split('/').filter((i) => i);

	function combineItens(_, index) {
		const url = `/${path.slice(0, index + 1).join('/')}`;
		const component = I18n.get(breadcrumbNameMap[url]);

		if (!component) {
			return null;
		}
		return (
			<Breadcrumb.Item key={url}>
				<Link to={url}>{component}</Link>
			</Breadcrumb.Item>
		);
	}

	const subItens = path.map(combineItens);

	const grouped = [
		<Breadcrumb.Item key="home">
			<Link to="/">Home</Link>
		</Breadcrumb.Item>
	].concat(subItens);

	return <Breadcrumb>{grouped}</Breadcrumb>;
}
