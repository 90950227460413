import styled from 'styled-components';
import { Row } from 'antd';

export const GoalItem = styled(Row)`
	height: 44px;
	border-radius: 7px;
	border: 2px solid #e6e8e8;
	margin-bottom: 15px;

	.text-center {
		justify-content: center;
		text-align: center;
	}

	& .ant-col:first-child {
		background-color: #f8f8f8;
		border-radius: 7px 0px 0px 7px;
		padding-left: 15px !important;

		& .ant-typography {
			font-weight: 400;
		}
	}

	& .ant-col:not(:last-child) {
		border-right: 1px solid #e6e8e8;
	}

	.ant-col {
		display: flex;
		align-items: center;

		&.reached {
			background-color: #75db8e;
			border-radius: 0px 7px 7px 0px;
		}

		&.not-reached {
			background-color: #f49999;
			border-radius: 0px 7px 7px 0px;
		}

		.ant-typography {
			margin-bottom: 0px;
		}
	}
`;
