import React from 'react';
import { Form, Input } from 'antd';
import { Text } from '@/components/Typography';

const { TextArea } = Input;

interface CommentProps {
	isDisabled?: boolean;
	onChangeComment: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
}

export const Comment: React.FC<CommentProps> = ({ isDisabled = false, onChangeComment }) => (
	<Form.Item
		colon={false}
		labelAlign="left"
		name={['comment']}
		label={<Text>Comment</Text>}
		labelCol={{ xl: { span: 4 }, xs: { span: 24 }, lg: { span: 24 } }}
	>
		<TextArea rows={4} showCount maxLength={500} onBlur={onChangeComment} disabled={isDisabled} />
	</Form.Item>
);
