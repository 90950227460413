import React from 'react';
import { Col, Form, Select } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useChecklistEIContext } from '../context';

const { useFormInstance } = Form;

export const BasicPositionSelect = () => {
	const { resetFields } = useFormInstance();

	const { basicPositions } = useChecklistEIContext();

	const {
		handleSelectBasicPosition,
		handleSelectPosture,
		handleSelectWeightRange,
		handlePostureOrder,
		handleWeightRangeOrder,
		mapDataToDropdown,
		filterOption,
	} = useChecklistEIContext();

	function handleBasicPositionSelect(value: string) {
		resetFields(['posture', 'weight_range', 'tcm']);
		handleSelectPosture('');
		handleSelectWeightRange('');
		handlePostureOrder('');
		handleWeightRangeOrder(0);
		handleSelectBasicPosition(value);
	}

	const basicPositionOptions = mapDataToDropdown(basicPositions.data);

	return (
		<Col sm={12}>
			<Form.Item
				name="basic_position"
				label={I18n.get('Basic positions')}
				rules={[{ required: true }]}
			>
				<Select
					showSearch
					allowClear
					onChange={(value: string) => handleBasicPositionSelect(value)}
					placeholder={I18n.get('Select a basic position')}
					options={basicPositionOptions}
					filterOption={(input, option) => filterOption(option, input)}
					loading={basicPositions.gettingBasicPositions}
					disabled={
						basicPositions.data.length === 0 ||
						basicPositions.errorGettingBasicPositions
					}
				/>
			</Form.Item>
		</Col>
	);
};
