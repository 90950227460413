import Types from './types';

export function setConsolidated(boolean) {
	return { type: Types.SET_CONSOLIDATED_CHECKLIST, payload: boolean };
}

export function setStep(step) {
	return { type: Types.SET_STEP_CHECKLIST, payload: step };
}

export function clearStateChecklist() {
	return { type: Types.CLEAR_STATE_CHECKLIST };
}

export function nextCurrentStep() {
	return { type: Types.NEXT_STEP_CHECKLIST };
}

export function previousCurrentStep() {
	return { type: Types.PREVIOUS_STEP_CHECKLIST };
}

export function setChecklist(values) {
	return { type: Types.SET_CHECKLIST, payload: values };
}

export function setFile(values) {
	return { type: Types.SET_FILE_CHECKLIST, payload: values };
}

// Effort
export function addEffort(values) {
	return { type: Types.ADD_EFFORT_CHECKLIST, payload: values };
}

export function removeEffort(id) {
	return { type: Types.REMOVE_EFFORT_CHECKLIST, payload: id };
}

// Reaction
export function addReaction(values) {
	return { type: Types.ADD_REACTION_CHECKLIST, payload: values };
}

export function removeReaction(id) {
	return { type: Types.REMOVE_REACTION_CHECKLIST, payload: id };
}

// Vibration
export function addVibration(values) {
	return { type: Types.ADD_VIBRATION_CHECKLIST, payload: values };
}

export function removeVibration(id) {
	return { type: Types.REMOVE_VIBRATION_CHECKLIST, payload: id };
}

export function addPercussion(values) {
	return { type: Types.ADD_PERCUSSION_CHECKLIST, payload: values };
}

export function addHitAgainst(values) {
	return { type: Types.ADD_HIT_AGAINST_CHECKLIST, payload: values };
}

export function addRegulationNotes(values) {
	return { type: Types.ADD_REGULATION_NOTES_CHECKLIST, payload: values };
}

export function addOperatorReport(value) {
	return { type: Types.ADD_OPERATOR_REPORT_CHECKLIST, payload: value };
}

export function addControlDifficulty(values) {
	return { type: Types.ADD_CONTROL_DIFFICULTY_CHECKLIST, payload: values };
}

export function addDisplacement(value) {
	return { type: Types.ADD_DISPLACEMENT_CHECKLIST, payload: value };
}

export function addWrist(value) {
	return { type: Types.ADD_WRIST_CHECKLIST, payload: value };
}

export function setInformations(values) {
	return { type: Types.SET_INFORMATIONS_CHECKLIST, payload: values };
}

export function setCompanyMosaic(values) {
	return {
		type: Types.SET_COMPANY_MOSAIC,
		payload: values,
	};
}
