import React, { ReactElement, useEffect } from 'react';
import { Col, Collapse, Row } from 'antd';

import { useCollapseContext } from './context';
import * as S from './styles';
import { MtmOperationsResponse } from '@/hooks';

interface CollapseCustomProps {
	operations?: MtmOperationsResponse[];
	children: ReactElement;
}

export function CollapseCustom({ children, operations }: CollapseCustomProps) {
	const { collapseError, handleChangeCollapse, handleCollapseError } = useCollapseContext();

	const firstOperationId: string | undefined = operations?.[0]?.id;

	function handlingClassName(id: string): string {
		return collapseError?.some((namePath) => namePath?.includes(id)) ? 'error-active' : '';
	}

	useEffect(() => {
		if (!firstOperationId) {
			return;
		}
		handleChangeCollapse(firstOperationId);
	}, [firstOperationId]);

	return (
		<S.CollapseCustom
			onChange={(id) => {
				handleChangeCollapse(id as string);
				const filteredOperations = collapseError.filter((f) => !f?.includes(id as string));
				handleCollapseError(filteredOperations);
			}}
			accordion
			defaultActiveKey={firstOperationId}
		>
			{operations?.map((operation) => (
				<Collapse.Panel
					forceRender
					key={operation.id}
					header={operation.name}
					className={handlingClassName(operation.id)}
				>
					<Row
						onClick={() => {
							const filteredOperations = collapseError.filter((f) => !f?.includes(operation.id));
							handleCollapseError(filteredOperations);
						}}
					>
						<Col span={24}>{React.cloneElement(children, { operation_id: operation.id })}</Col>
					</Row>
				</Collapse.Panel>
			))}
		</S.CollapseCustom>
	);
}
