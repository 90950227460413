import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { LineSelect } from '@/components/ui/Inputs/LineSelect';
import { useApplicationContext } from '@/context/application';
import { useLines } from '@/hooks/useLines';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

export const Line: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { company, organization } = useApplicationContext();

	const sectorId = useWatch('sector_id', form);
	const lineId = useWatch('line_id', form);

	const {
		data: lines,
		isLoading,
		isFetching,
		isError
	} = useLines({
		organization_id: organization?.id as string,
		company_id: company?.id as string,
		sector_id: sectorId
	});

	function handleSelectLine(id: string) {
		setFieldValue(['line_id'], id);
		setFieldValue(['workstation_id'], undefined);
	}

	function handleClearLines() {
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<Form.Item name={['line_id']} label={I18n.get('Line')} labelCol={{ span: 24 }}>
				<S.ContainerSelect $borderRadius="6px">
					<LineSelect
						lines={lines}
						lineId={lineId}
						fieldName="line_id"
						sectorId={sectorId}
						loading={isFetching}
						allowCreation={false}
						onClear={handleClearLines}
						onChange={handleSelectLine}
						disabled={isLoading || isFetching || isError || !sectorId}
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
};
