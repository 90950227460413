import Api from '@/services/api';

import {
	GetTCMRequest,
	GetFileRequest,
	GetLinesRequest,
	GetPosturesRequest,
	GetSectorsRequest,
	GetSpeedFactorsRequest,
	GetWeightRangesRequest,
	GetWorkstationsRequest,
	GetBasicPositionsRequest,
	GetAddedTimeRequest,
} from './types/request';
import {
	GetTCMDTO,
	GetFileDTO,
	GetLinesDTO,
	GetSectorsDTO,
	GetPosturesDTO,
	GetSpeedFactorsDTO,
	GetWeightRangesDTO,
	GetWorkstationsDTO,
	GetBasicPositionsDTO,
	GetAddedTimeDTO,
} from './types/response';

export class ChecklistEIService {
	baseUrl = '';

	constructor() {
		this.baseUrl = '/report/checklist-ei';
	}

	public async getFile({
		organization_id,
		company_id,
		file_id,
	}: GetFileRequest): Promise<GetFileDTO> {
		const url = `/file/${organization_id}/${company_id}/${file_id}`;
		const { data } = await Api.get<GetFileDTO>(url);
		return data;
	}

	public async getLines({
		organization_id,
		company_id,
		sector_id,
	}: GetLinesRequest): Promise<GetLinesDTO[]> {
		const url = `/line/${organization_id}/${company_id}/${sector_id}`;
		const { data } = await Api.get<GetLinesDTO[]>(url);
		return data;
	}

	public async getWorkstations({
		organization_id,
		company_id,
		line_id,
	}: GetWorkstationsRequest): Promise<GetWorkstationsDTO[]> {
		const url = `/workstation/${organization_id}/${company_id}/${line_id}`;
		const { data } = await Api.get<GetWorkstationsDTO[]>(url);
		return data;
	}

	public async getSectors({
		company_id,
		organization_id,
	}: GetSectorsRequest): Promise<GetSectorsDTO[]> {
		const url = `/sector/${organization_id}/${company_id}`;
		const { data } = await Api.get<GetSectorsDTO[]>(url);
		return data;
	}

	public async getBasicPositions({
		company_id,
		organization_id,
	}: GetBasicPositionsRequest): Promise<GetBasicPositionsDTO[]> {
		const url =
			this.baseUrl +
			`/basic-positions?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<GetBasicPositionsDTO[]>(url);
		return data;
	}

	public async getPostures({
		company_id,
		organization_id,
		basic_position_id,
	}: GetPosturesRequest): Promise<GetPosturesDTO[]> {
		const url =
			this.baseUrl +
			`/postures?organization_id=${organization_id}&company_id=${company_id}&basic_position_id=${basic_position_id}`;
		const { data } = await Api.get<GetPosturesDTO[]>(url);
		return data;
	}

	public async getWeightRanges({
		company_id,
		organization_id,
	}: GetWeightRangesRequest): Promise<GetWeightRangesDTO[]> {
		const url =
			this.baseUrl +
			`/weight-ranges?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<GetWeightRangesDTO[]>(url);
		return data;
	}

	public async getSpeedFactors({
		company_id,
		organization_id,
	}: GetSpeedFactorsRequest): Promise<GetSpeedFactorsDTO[]> {
		const url =
			this.baseUrl +
			`/speed-factors?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<GetSpeedFactorsDTO[]>(url);
		return data;
	}

	public async getTCM({
		organization_id,
		company_id,
		basic_position,
		posture,
		weight_range,
	}: GetTCMRequest): Promise<GetTCMDTO> {
		const url =
			this.baseUrl +
			`/tcm?organization_id=${organization_id}&company_id=${company_id}&basic_position=${basic_position}&posture=${posture}&weight_range=${weight_range}`;
		const { data } = await Api.get<GetTCMDTO>(url);
		return data;
	}

	public async getAddedTime({
		organization_id,
		company_id,
		speed_factor,
		tcm,
	}: GetAddedTimeRequest): Promise<GetAddedTimeDTO> {
		const url =
			this.baseUrl +
			`/added-time?organization_id=${organization_id}&company_id=${company_id}&speed_factor=${speed_factor}&tcm=${tcm}`;
		const { data } = await Api.get<GetAddedTimeDTO>(url);
		return data;
	}
}
