import { Col, ColProps, Divider, DividerProps } from 'antd';
import React from 'react';

interface DividerCustomProps {
	span?: number;
	color?: string;
	margin?: string;
	thickness?: string;
	type?: DividerProps['type'];
}

export function DividerCustom({
	span = 24,
	type,
	color,
	margin,
	thickness,
	...allColProps
}: Readonly<DividerCustomProps & ColProps>) {
	return (
		<Col span={span} {...allColProps}>
			<Divider type={type} style={{ background: color, height: thickness, margin: margin ?? 'unset' }} />
		</Col>
	);
}
