import React from 'react';
import { Col, Form, Input } from 'antd';
import { I18n } from '@aws-amplify/core';

export const Evaluator: React.FC = () => {
	return (
		<Col xs={24}>
			<Form.Item labelCol={{ span: 24 }} name={['evaluator_name']} label={I18n.get('Evaluator')}>
				<Input placeholder={I18n.get('Evaluator')} style={{ borderRadius: '6px' }} />
			</Form.Item>
		</Col>
	);
};
