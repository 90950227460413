import React, { useMemo } from 'react';

import { calculateTilesWeight, splitInLines } from './helpers';
import { DataItem, MosaicProps } from './index.types';
import { MosaicDataItem } from '../index.types';
import { Wrapper, TreemapRow } from './styles';
import { Tile } from './Tile';

export function Chart({ data }: MosaicProps) {
	let linesCount = Math.ceil(data.length / 6);

	if (data.length < 10) {
		linesCount = 2;
	}

	const lines = useMemo(() => {
		if (!data.length) return [];
		const total = data.reduce(
			(acc: number, curr: { count: number }) => (acc += curr.count),
			0
		);

		const items: Array<DataItem> = data
			.map((item: MosaicDataItem) => ({ ...item, size: 0 }))
			.map(calculateTilesWeight(total));

		return splitInLines(linesCount, items);
	}, [data, linesCount]);

	return (
		<Wrapper>
			{lines
				.filter((line) => line.length > 0)
				.map((items, line) => (
					<TreemapRow key={line}>
						{items.map((item, index) => (
							<Tile key={`${line}-${index}`} {...item} lines={linesCount}>
								{item.title}
							</Tile>
						))}
					</TreemapRow>
				))}
		</Wrapper>
	);
}
