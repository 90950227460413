import React from 'react';
import { Row, Col, message } from 'antd';
import * as XLSX from 'xlsx';
import { Title } from '@/components/Typography';
import { useMtmReportContext } from '../../../context';
import { FooterButtons } from './components/FooterButtons';
import { DividerCustom } from '@/components/ui/DividerCustom';
import { ReportTemplate } from '../../components/ReportTemplates';
import { ButtonsCustom } from '../../components/ReportTemplates/Footer/ButtonsCustom';
import {
	useGetMtmReportResult,
	useMtmReportConsolidate,
	useMtmReportPDF,
	useMtmReportSpreadsheet,
	useUpdateMtmReportComment
} from '@/hooks';
import { Information, GeneralResults, OperationsFiles, OperationsProduct, WorkstationOperations } from './components';
import { DownloadPDFParams } from '../../../types';
import { I18n } from '@aws-amplify/core';

const RESULTS_STEP = 4;

export function Results() {
	const { mtmReport, currentStep, basicParams } = useMtmReportContext();
	const { mutateAsync: updateMtmReportComment } = useUpdateMtmReportComment();
	const { mutateAsync: getSpreadsheetData, isLoading: getSpreadsheetDataLoading } = useMtmReportSpreadsheet();
	const { mutateAsync: downloadPdf, isLoading: downloadPdfLoading } = useMtmReportPDF();
	const { mutateAsync: consolidateReport, isLoading: consolidateReportLoading } = useMtmReportConsolidate();

	async function handleDownloadPDF({ workstation_id, operation_id }: DownloadPDFParams): Promise<void> {
		const { url } = await downloadPdf({
			organization_id: basicParams.organization_id as string,
			company_id: basicParams.company_id as string,
			id: mtmReport?.id as string,
			workstation_id,
			operation_id,
			locale: navigator.language
		});
		window.open(url, '_blank');
	}

	async function handleConsolidateReport(): Promise<void> {
		await consolidateReport({
			id: mtmReport?.id as string,
			company_id: basicParams.company_id as string,
			organization_id: basicParams.organization_id as string,
			locale: navigator.language
		});
	}

	async function handleDownloadSpreadsheet(): Promise<void> {
		try {
			const data = await getSpreadsheetData({
				id: mtmReport?.id as string,
				company_id: basicParams.company_id as string,
				organization_id: basicParams.organization_id as string,
				locale: navigator.language
			});
			const worksheet = XLSX.utils.aoa_to_sheet(data);
			const workbook = XLSX.utils.book_new();
			const maxWidths: { [key: string]: number } = {};

			data.forEach((row) => {
				row.forEach((cell, index) => {
					const cellValue = cell !== null && cell !== undefined ? String(cell) : '';
					const columnWidth = cellValue.length;
					const columnName = XLSX.utils.encode_col(index);

					maxWidths[columnName] = Math.max(maxWidths[columnName] || 0, columnWidth);
				});
			});

			worksheet['!cols'] = Object.keys(maxWidths).map((col) => ({
				wch: maxWidths[col] + 2
			}));
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
			const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
			const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
			const url = URL.createObjectURL(blob);
			let link = document.createElement('a');
			link.href = url;
			link.download = `mtm_report_${mtmReport?.id}.xlsx`;
			link.click();
			URL.revokeObjectURL(url);
		} catch (error) {
			message.error(I18n.get('Failed to generate report data'));
		}
	}

	function handleSaveComment(comment: string): void {
		updateMtmReportComment({
			comment,
			id: mtmReport?.id as string,
			company_id: basicParams.company_id as string,
			organization_id: basicParams.organization_id as string
		});
	}

	const is_result_step = currentStep === RESULTS_STEP;

	const { data: mtmReportResult } = useGetMtmReportResult({
		...basicParams,
		locale: navigator.language,
		id: is_result_step ? mtmReport?.id : undefined
	});

	return (
		<Row justify="center" gutter={[0, 22]}>
			<Col span={22}>
				<Row justify={'space-between'} align={'middle'}>
					<Col>
						<Title level={4} style={{ color: '#262626' }}>
							Results
						</Title>
					</Col>
					<Col>
						<ButtonsCustom
							hasConsolidated={false}
							isLoadingPDF={downloadPdfLoading}
							isDownloadingSpreadsheet={getSpreadsheetDataLoading}
							isConsolidating={consolidateReportLoading}
							onDownloadPDF={() => handleDownloadPDF({})}
							onConsolidatePDF={handleConsolidateReport}
							onDownloadSpreadsheet={handleDownloadSpreadsheet}
						/>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<ReportTemplate
					hasConsolidated={true}
					onCommentSave={handleSaveComment}
					onDownloadPDF={() => handleDownloadPDF({})}
					onDownloadSpreadsheet={handleDownloadSpreadsheet}
					onConsolidatePDF={handleConsolidateReport}
					isLoadingPDF={downloadPdfLoading}
					isDownloadingSpreadsheet={getSpreadsheetDataLoading}
					isConsolidating={consolidateReportLoading}
					isConsolidated={mtmReport?.consolidated ?? false}
				>
					<Information informations={mtmReportResult?.informations} />
					<ReportDivider />
					<GeneralResults general_results={mtmReportResult?.general_results} />
					<ReportDivider />
					<OperationsFiles operations_files={mtmReportResult?.operations_files} />
					<ReportDivider />
					<WorkstationOperations
						onDownloadPDF={handleDownloadPDF}
						isLoadingPDF={downloadPdfLoading || consolidateReportLoading}
						workstation_operations={mtmReportResult?.workstation_operations}
					/>
					<ReportDividerMargin />
					<OperationsProduct operations_product={mtmReportResult?.product_operations} />
					<ReportDivider />
				</ReportTemplate>
			</Col>
			<Col>
				<FooterButtons />
			</Col>
		</Row>
	);
}

function ReportDividerMargin() {
	return (
		<Row justify="center">
			<Col span={22} style={{ marginBottom: 43 }} />
		</Row>
	);
}

function ReportDivider() {
	return (
		<Row justify="center">
			<DividerCustom span={22} color="#E6E8E8" thickness="1px" margin={'40px 0 43px 0'} />
		</Row>
	);
}
