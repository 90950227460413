import React from 'react';
import { Row, Col } from 'antd';

import { useGetRotationReportBodyPartExposure } from '@/hooks/useGetRotationReportBodyPartExposure';
import { useRotationReportContext } from '../../../../../context';
import { SkeletonRiskChart } from '../ActivityTabs/Content';
import { ActivityChart } from '../ActivityTabs/Chart';
import { Text } from '@/components/Typography';
import { GoalContainer } from '../styles';

interface OverallExposureChartProps {
	bodyPart: string;
}

export function OverallExposureChart({ bodyPart }: Readonly<OverallExposureChartProps>) {
	const { rotationReport, basicParams } = useRotationReportContext();
	const { data: risks, isLoading } = useGetRotationReportBodyPartExposure({
		id: rotationReport?.id,
		body_part_name: bodyPart,
		...basicParams
	});

	if (isLoading) {
		return <SkeletonRiskChart />;
	}

	const goal = rotationReport?.rotation_objectives.find((obj) => obj.body_part?.name === bodyPart);

	return (
		<Row>
			<Col span={24}>
				{goal && <Goal value={goal.value} />}
				<ActivityChart redraw={true} title={risks?.name || bodyPart} data={risks?.scores ?? []} />
			</Col>
		</Row>
	);
}

function Goal({ value }: { value: number }) {
	return (
		<GoalContainer>
			<Text>Goal</Text>:<br />
			<Text style={{ color: '#2CC852' }}>{value}%</Text>
		</GoalContainer>
	);
}
