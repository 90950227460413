import styled from 'styled-components';

const config = {
	small: {
		width: '90px',
		height: '120px',
		fontScale: 1,
	},
	medium: {
		width: '120px',
		height: '150px',
		fontScale: 1.08,
	},
	large: {
		width: '140px',
		height: '180px',
		fontScale: 1.16,
	},
};

export const Counter = styled.span`
	margin-inline: auto;
	margin-right: 10px;
	margin-top: 8px;
	font-weight: bold;
`;

export const IconContainer = styled.div`
	margin: 0;
`;

export const Title = styled.h4`
	margin-bottom: 10px;
	margin-inline: 4px;
	text-align: center;
	line-height: 0.9rem;
`;

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	border-radius: 5px;
	box-shadow: 2px 2px 5px lightgrey;
	cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
	width: 100%;
	/* width: ${(props) => config[props.size].width}; */
	height: ${(props) => config[props.size].height};
	transition: box-shadow 0.2s ease, border 0.2s ease, color 0.2s ease;

	&:hover {
		box-shadow: ${(props) =>
			props.onClick ? '2px 2px 10px lightgrey' : '2px 2px 5px lightgrey'};
		border: ${(props) => (props.onClick ? '1px solid #5CD3F8' : 'none')};
		color: ${(props) => (props.onClick ? '#5CD3F8' : 'black')};

		${Title} {
			color: ${(props) => (props.onClick ? '#5CD3F8' : 'black')};
		}
	}

	&.active {
		box-shadow: ${(props) =>
			props.onClick ? '2px 2px 10px lightgrey' : '2px 2px 5px lightgrey'};
		border: ${(props) => (props.onClick ? '1px solid #5CD3F8' : 'none')};
		color: ${(props) => (props.onClick ? '#5CD3F8' : 'black')};
		${Title} {
			color: ${(props) => (props.onClick ? '#5CD3F8' : 'black')};
		}
	}

	${Counter} {
		font-size: ${(props) => `${1 * config[props.size].fontScale}rem`};
	}

	${IconContainer} {
		font-size: ${(props) => `${1.95 * config[props.size].fontScale}rem`};
	}

	${Title} {
		font-size: ${(props) => `${0.8 * config[props.size].fontScale}rem`};
		transition: color 0.2s ease;
	}
`;
