import React from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { QUERY_KEYS } from '@/utils/query-keys';
import { Text } from '@/components/Typography';
import { useMtmReportContext } from '@/components/views/MtmReport/context';

import * as S from '../../../../components/styles';

export function FooterButtons(): JSX.Element {
	const history = useHistory();
	const queryClient = useQueryClient();
	const { handlePreviousStep } = useMtmReportContext();

	function handleFinishReport(): void {
		queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORTS_PAGINATED]);
		history.push(`/engineering/mtm`);
	}

	return (
		<Row justify="center" align="middle" gutter={[20, 0]}>
			<Col>
				<S.CustomButton htmlType="submit" size="large" type="primary" onClick={handlePreviousStep}>
					<Text style={{ color: 'inherit' }}>Previous</Text>
				</S.CustomButton>
			</Col>
			<Col>
				<S.CustomButton htmlType="submit" size="large" type="primary" onClick={handleFinishReport}>
					<Text style={{ color: 'inherit' }}>Close</Text>
				</S.CustomButton>
			</Col>
		</Row>
	);
}
