import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
	.ant-modal-content {
		padding: 30px 0;
		min-width: 400px;
		max-width: 520px;
		border-radius: 30px;
	}
`;
