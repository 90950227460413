import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { notification } from 'antd';
import Api from '@/services/api';
import { BaseContext } from '@/types';

export type MtmReportPDFDTO = BaseContext & {
	id: string;
	workstation_id?: string;
	operation_id?: string;
	locale?: string;
};

type MtmReportPDFResponse = BaseContext & {
	id: string;
	url: string;
};

const getMtmReportPDF = async (params: MtmReportPDFDTO): Promise<MtmReportPDFResponse> => {
	const { data } = await Api.get<MtmReportPDFResponse>(`/mtm/report/${params.id}/pdf`, {
		params
	});
	return data;
};

export const useMtmReportPDF = () => {
	return useMutation((body: MtmReportPDFDTO) => getMtmReportPDF(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		}
	});
};
