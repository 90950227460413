import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Modal, Table } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';

import { ManageOrganizationModal } from './ManageOrganizationModal';
import { EditOrganization } from './EditOrganization';
import { useManageOrganization } from './context';
import { OrganizationDTO } from './types';
import { Actions } from './Actions';

interface ListProps {
	isLoading?: boolean;
	data: OrganizationDTO[];
	isError?: boolean;
}

export function List({ isLoading = false, data, isError = false }: ListProps) {
	const { handleOrganizationId, handleDeleteOrganization } = useManageOrganization();
	const [openManageOrganizationModal, setOpenManageOrganizationModal] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [editingOrganizationId, setEditingOrganizationId] = useState('');
	const [organization, setOrganization] = useState<OrganizationDTO>({} as OrganizationDTO);

	function handleManageOrganizationModal(organization: OrganizationDTO) {
		setOpenManageOrganizationModal(!openManageOrganizationModal);
		setOrganization(organization);
		handleOrganizationId(organization.id);
	}

	function handleIsEditing(editing: boolean) {
		setIsEditing(editing);
	}

	function updateOrganization(e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) {
		e.stopPropagation();
		e.preventDefault();

		const editingOrganization = data.find((organization: any) => organization.id === id);

		if (editingOrganization) {
			setIsEditing(true);
			setEditingOrganizationId(id);
		}
	}

	function deleteOrganization(e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) {
		e.stopPropagation();
		e.preventDefault();

		const content = I18n.get(
			'All companies in this organization will be deleted, along with their respective uploads and reports. Do you wish to proceed?'
		);

		Modal.confirm({
			title: I18n.get('Warning!'),
			icon: <ExclamationCircleOutlined />,
			content: content,
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: async () => {
				await handleDeleteOrganization({ organization_id: id });
			}
		});
	}

	const columns: ColumnsType<OrganizationDTO> = [
		{
			title: I18n.get('Name'),
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			width: '300px',
			render: (name: string, organization) => {
				return isEditing && organization.id === editingOrganizationId ? (
					<EditOrganization
						onEditing={handleIsEditing}
						editingOrganizationId={editingOrganizationId}
						organization={organization}
					/>
				) : (
					name
				);
			}
		},
		{
			title: I18n.get('Total Industrial Sites'),
			dataIndex: 'companies',
			key: 'companies',
			align: 'center',
			render: (companies: string[]) => companies.length || 0
		},
		{
			title: I18n.get('Date of creation'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center'
		},
		{
			title: I18n.get('Actions'),
			dataIndex: 'url',
			key: 'url',
			align: 'center',
			render: (_, organization) => (
				<Actions
					isEditing={isEditing}
					organization={organization}
					onManageOrganizationModal={handleManageOrganizationModal}
					onDelete={deleteOrganization}
					onUpdate={updateOrganization}
				/>
			)
		}
	];

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<Table rowKey="id" loading={isLoading} dataSource={data} columns={columns} />
			</Col>
			{openManageOrganizationModal && (
				<ManageOrganizationModal
					organization={organization}
					isModalOpen={openManageOrganizationModal}
					onModalOpen={setOpenManageOrganizationModal}
				/>
			)}
		</Row>
	);
}
