import React from 'react';
import { message } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { useSelector, shallowEqual } from 'react-redux';
import { CaretRightOutlined } from '@ant-design/icons';
import { checklistFileDurationParse } from '@/redux/reports/selectors';
import Api from '@/services/api';

const Wrapper = styled.div`
	background: #f4ecec;
	border-left: 1px solid #c4c4c4;
	width: 250px;
	align-self: stretch;
	margin-left: auto;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FileName = styled.p`
	background: #c4c4c4;
	padding: 3px 10px;
	position: absolute;
	left: 0;
	top: 0;
	font-weight: bold;
	color: #000;
`;

const Duration = styled.p`
	background: #c4c4c4;
	padding: 3px 10px;
	position: absolute;
	bottom: 0;
	right: 0;
	margin-bottom: 0;
	color: #fff;
`;

export function VideoThumbnail() {
	const file = useSelector((state) => state.checklist.file);
	const file_duration = useSelector(checklistFileDurationParse);

	const { organizationId, companyId } = useSelector(
		(state) => ({
			organizationId: state.organization.organization?.id,
			companyId: state.organization.company?.id,
		}),
		shallowEqual
	);

	function onSuccess(res) {
		let archive = document.createElement('a');
		archive.target = '_blank';
		archive.rel = 'noopener noreferrer';
		archive.href = res.data;
		archive.click();
		archive.remove();
	}

	function onErrors(errors) {
		const result = errors.response?.data?.message || errors.message;
		const description = I18n.get(result);
		message.error(description);
	}

	function onDownloadFile() {
		if (!file || !organizationId || !companyId) return;
		const body = {
			file_id: file.id,
			company_id: companyId,
			organization_id: organizationId,
			original_name: file.original_name,
		};
		Api.post('/upload/create-url-download', body)
			.then(onSuccess)
			.catch(onErrors);
	}

	return (
		<Wrapper onClick={onDownloadFile}>
			<FileName>{file.original_name}</FileName>
			<Duration>{file_duration}</Duration>
			<CaretRightOutlined style={{ fontSize: 70, color: '#c4c4c4' }} />
		</Wrapper>
	);
}
