import { useQuery } from '@tanstack/react-query';
import { GetActivitiesDTO, ActivitiesDTO } from '@/types/dto/Activity';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { ErrorResponse } from '@/types';
import Api from '@/services/api';

const { GET_ACTIVITIES } = QUERY_KEYS;

async function getActivities(params: GetActivitiesDTO): Promise<ActivitiesDTO[]> {
	const { data } = await Api.get('/activity', { params });
	return data;
}

export const useGetActivities = (params: GetActivitiesDTO) => {
	const { organization_id, company_id } = params;
	return useQuery<ActivitiesDTO[], ErrorResponse>([GET_ACTIVITIES, params], () => getActivities(params), {
		enabled: !!organization_id && !!company_id
	});
};
