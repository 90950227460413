import React from 'react';
import moment from 'moment';
import { Form, Table } from 'antd';
import { I18n } from '@aws-amplify/core';
import type { ColumnsType } from 'antd/lib/table';
import type { TableRowSelection } from 'antd/lib/table/interface';

import { dateFormat } from '@/utils/date-format';
import { PaginationCustom } from '@/components/ui/Pagination';
import type { File, Operation } from '@/types';
import { useOperations } from '@/hooks/useOperations';
import { TextOperation } from './TextOperation';
import { LIMIT, useSelectFilesContext } from '../../context';
import * as S from '../../../../components/TableLayout/styled';

export type TableValues = Pick<File, 'id' | 'original_name' | 'created_at' | 'organization_id' | 'company_id'> & {
	operation_id?: Pick<Operation, 'id'>;
};

const { useFormInstance } = Form;

export function FileTable() {
	const { setFieldValue } = useFormInstance();
	const { allFiles, loadingAllFiles, page, baseParameters, selectedRowKeys, handlePage, handlingSelectRowKeys } =
		useSelectFilesContext();

	const { data: operations, isLoading: loadingOperations } = useOperations(baseParameters);

	const columns: ColumnsType<TableValues> = [
		{
			align: 'center',
			key: 'original_name',
			title: I18n.get('Files'),
			className: 'file-columns',
			dataIndex: 'original_name'
		},
		{
			width: '20%',
			align: 'center',
			key: 'created_at',
			responsive: ['lg'],
			dataIndex: 'created_at',
			title: I18n.get('Upload date'),
			render: (date: string) => {
				return moment(date).format(dateFormat());
			}
		},
		{
			width: '40%',
			align: 'center',
			key: 'operation',
			dataIndex: 'operation',
			title: I18n.get('Operation'),
			render: (_, file: TableValues) => (
				<TextOperation
					file={file}
					loadingOperations={loadingOperations}
					operations={operations?.rows}
					selectedRowKeys={selectedRowKeys}
				/>
			)
		}
	];

	const rowSelection: TableRowSelection<TableValues> = {
		selectedRowKeys,
		preserveSelectedRowKeys: true,
		onChange: (selectedRowKeys: React.Key[]) => {
			handlingSelectRowKeys(selectedRowKeys);
			setFieldValue(['selectedRowKeys'], selectedRowKeys);
		},
		getCheckboxProps: (row: any) => ({
			disabled: row.status !== 'PROCESSED'
		})
	};

	return (
		<S.Table span={23}>
			<Form.Item
				name="items"
				rules={[
					() => ({
						validator() {
							if (selectedRowKeys.length === 0) {
								return Promise.reject(Error(I18n.get('Select a file')));
							}
							return Promise.resolve();
						}
					})
				]}
			>
				<Table
					rowKey="id"
					size={'large'}
					columns={columns}
					pagination={false}
					dataSource={allFiles?.result}
					rowSelection={{ type: 'checkbox', hideSelectAll: true, ...rowSelection }}
				/>
			</Form.Item>
			<PaginationCustom
				page={page}
				maxPage={LIMIT}
				setPage={handlePage}
				loading={loadingAllFiles}
				hasItemPerPage={false}
				total={allFiles?.total}
			/>
		</S.Table>
	);
}
