import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { Row, Col, Form, Select, DatePicker, Button, Input, Typography, message } from 'antd';

import { ActionPlanBoard, CreateActionPlanDTO, useCreateActionPlan, useGetComplaints, useGetWorkers } from '@/hooks';
import type { ChecklistState, HierarchyItem } from './types';
import { DisabledInput } from './DisabledInput';
import { RoundedModal } from '../styles';

interface ModalActionPlanProps {
	visible: boolean;
	onClose: () => void;
}

type SelectorState = {
	checklist: ChecklistState;
	organization: {
		organization: HierarchyItem;
		company: HierarchyItem;
	};
};

export function ModalActionPlan({ visible, onClose }: ModalActionPlanProps) {
	const [form] = Form.useForm();
	const checklist = useSelector<SelectorState, ChecklistState>((state) => state.checklist);
	const company = useSelector<SelectorState, HierarchyItem>((state) => state.organization.company);
	const organization_id = useSelector<SelectorState, string>((state) => state.organization.organization.id);

	const { data } = useGetComplaints({
		organization_id,
		workstation_id: checklist.workstation.id
	});

	const { data: responsible_workers } = useGetWorkers({
		organization_id,
		worker_type: 'MANAGER'
	});

	const { mutateAsync: createActionPlan, isLoading } = useCreateActionPlan();

	function onCloseModal() {
		if (isLoading) {
			return;
		}
		onClose();
		form.resetFields(['description', 'responsible_worker_id', 'deadline', 'complaint_id']);
	}

	async function onCreateActionPlan(values: CreateActionPlanDTO['action_plan']) {
		const { description, responsible_worker_id, deadline, workstation_id, complaint_id } = values;

		const action_plan = {
			deadline,
			description,
			complaint_id,
			workstation_id,
			responsible_worker_id,
			file_id: checklist.file.id,
			board: ActionPlanBoard.TO_DO
		};
		const body = {
			action_plan,
			organization_id
		};

		await createActionPlan(body);
	}

	async function onSubmit() {
		try {
			const values = await form.validateFields();
			await onCreateActionPlan(values);
			onCloseModal();
		} catch (error: any) {
			message.error(error?.response?.data?.message || 'Oops... Something went wrong');
		}
	}

	const initialValues = {
		line_id: checklist.line.id,
		sector_id: checklist.sector.id,
		workstation_id: checklist.workstation.id,
		company_id: company.id
	};

	return (
		<RoundedModal
			open={visible}
			width="1000px"
			title={
				<Typography.Title style={{ marginBottom: 0 }} level={4}>
					{I18n.get('Create action plan')}
				</Typography.Title>
			}
			onCancel={onCloseModal}
			footer={null}
		>
			<Form form={form} layout="vertical" initialValues={initialValues}>
				<Row gutter={[50, 10]}>
					<Col span={12}>
						<Form.Item
							name="description"
							label={I18n.get('Description')}
							rules={[
								{
									message: I18n.get('Please enter a valid description'),
									required: true
								}
							]}
						>
							<Input placeholder={I18n.get('Action plan description')} />
						</Form.Item>
						<Row justify="space-between">
							<Form.Item
								name="responsible_worker_id"
								label={I18n.get('Person responsible')}
								rules={[
									{
										message: I18n.get('Please enter a person responsible'),
										required: true
									}
								]}
								style={{
									width: '70%',
									display: 'inline-block'
								}}
							>
								<Select placeholder={I18n.get('Who is responsible for this action plan?')} allowClear>
									{responsible_workers?.map((person) => (
										<Select.Option key={person.id} value={person.id}>
											{person.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								name="deadline"
								label={I18n.get('Deadline')}
								rules={[
									{
										message: I18n.get('Please enter a valid date'),
										required: true
									}
								]}
								style={{
									display: 'inline-block',
									width: '25%'
								}}
							>
								<DatePicker
									format={'DD/MM/YYYY'}
									placeholder={I18n.get('Date')}
									disabledDate={(current) => current < moment().subtract(1, 'day')}
								/>
							</Form.Item>
						</Row>
					</Col>

					<Col span={12}>
						<DisabledInput name="company_id" label="Industrial site" item={company} />
						<DisabledInput name="sector_id" label="Sector" item={checklist.sector} />
						<DisabledInput name="line_id" label="Line" item={checklist.line} />
						<DisabledInput name="workstation_id" label="Workstation" item={checklist.workstation} />
						<Form.Item
							name="complaint_id"
							label={I18n.get('Complaint')}
							rules={[
								{
									message: I18n.get('Select a complaint'),
									required: false
								}
							]}
						>
							<Select style={{ width: '100%' }} placeholder={I18n.get('Select a complaint')}>
								{data?.map((complaint) => (
									<Select.Option key={complaint.id} value={complaint.id}>
										{complaint.description}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Row justify={'end'} gutter={[20, 20]} style={{ marginTop: '20px' }}>
				<Col>
					<Button key="cancel" onClick={onCloseModal} disabled={isLoading}>
						{I18n.get('Cancel')}
					</Button>
				</Col>
				<Col>
					<Button key="submit" type="primary" onClick={onSubmit} loading={isLoading}>
						{I18n.get('Save')}
					</Button>
				</Col>
			</Row>
		</RoundedModal>
	);
}
