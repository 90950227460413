import Types from './types';

const initState = {
	listFileUploads: [],
	listFiles: null,
	loading: false,
	errors: null,

	listUploads: {
		loading: false,
		data: [],
		error: null
	}
};

const uploadReducer = (state = initState, action) => {
	const { type, payload } = action;

	switch (type) {
		case Types.UPLOAD_STATUS_FILE: {
			return {
				...state,
				listUploads: {
					...state.listUploads,
					data: state.listUploads?.data?.map((item) =>
						item.id === action.payload.id ? { ...item, status: action.payload.status } : item
					)
				}
			};
		}

		case Types.LIST_UPLOAD_LOADING: {
			return {
				...state,
				listUploads: {
					...state.listUploads,
					loading: !state.listUploads.loading
				}
			};
		}

		case Types.LIST_UPLOAD_SUCCESS: {
			return {
				...state,
				listUploads: {
					...state.listUploads,
					data: payload
				}
			};
		}

		case Types.LIST_UPLOAD_ERROR: {
			return {
				...state,
				listUploads: {
					...state.listUploads,
					error: payload
				}
			};
		}

		case Types.FIND_AND_FILTER_LOADING: {
			return {
				...state,
				listUploads: {
					...state.listUploads,
					loading: !state.listUploads.loading
				}
			};
		}

		case Types.FIND_AND_FILTER_SUCCESS: {
			return {
				...state,
				listUploads: {
					...state.listUploads,
					data: payload
				}
			};
		}

		case Types.FIND_AND_FILTER_ERROR: {
			return {
				...state,
				listUploads: {
					...state.listUploads,
					error: payload
				}
			};
		}

		case Types.LIST_FILE_UPLOAD_SUCCESS: {
			return {
				...state,
				listFileUploads: [...state.listFileUploads, action.payload]
			};
		}

		case Types.REQUEST_LOADING: {
			return { ...state, loading: !state.loading };
		}

		case Types.LIST_UPLOADS_SUCCESS: {
			return { ...state, listFiles: action.payload };
		}

		case Types.LIST_UPLOADS_FAILURE: {
			return { ...state, errors: action.payload };
		}

		case Types.GET_ALL_UPLOADS_LOADING: {
			return {
				...state,
				listUploads: {
					...state.listUploads,
					loading: !state.listUploads.loading
				}
			};
		}

		case Types.GET_ALL_UPLOADS_SUCESS: {
			return {
				...state,
				listUploads: {
					...state.listUploads,
					data: action.payload
				}
			};
		}

		case Types.GET_ALL_UPLOADS_ERROR: {
			return {
				...state,
				listUploads: {
					...state.listUploads,
					error: action.payload
				}
			};
		}

		case Types.UPDATE_FILE_SOCKET: {
			return {
				...state,
				listUploads: {
					...state.listUploads,
					data: state.listUploads?.data?.map((item) =>
						item.id === action.payload.id ? action.payload : item
					)
				}
			};
		}

		default:
			return state;
	}
};

export default uploadReducer;
