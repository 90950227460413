import React from 'react';
import { Form, InputNumber } from 'antd';
import { I18n } from '@aws-amplify/core';

import { getDecimalSeparator } from '@/utils/getDecimalSeparator';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { InfoTooltip } from '@/components/ui/InfoTooltip/InfoTooltip';
import * as S from '../styles';

interface CoefficientProps {
	width?: string;
	tooltip?: string;
	fontSize?: string;
	namePlace: string[];
	isRequired?: boolean;
	lastNamePlace?: string;
}

export function Coefficient({
	fontSize,
	namePlace,
	width = '100%',
	isRequired = false,
	tooltip,
	lastNamePlace = 'coefficient'
}: Readonly<CoefficientProps>) {
	const label = tooltip ? (
		<InfoTooltip title={tooltip}>
			<RequiredLabel bold fontSize={fontSize} title="Coefficient" />
		</InfoTooltip>
	) : (
		<RequiredLabel bold fontSize={fontSize} title="Coefficient" isRequired={isRequired} />
	);

	return (
		<S.ContainerInputNumber width={width} $borderRadius="8px">
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[...namePlace, lastNamePlace]}
				rules={[{ required: true, message: I18n.get('Set the coefficient') }]}
				label={label}
			>
				<InputNumber
					min={0}
					keyboard
					step={1}
					max={1000}
					size="large"
					placeholder={'0'}
					decimalSeparator={getDecimalSeparator()}
				/>
			</Form.Item>
		</S.ContainerInputNumber>
	);
}
