import React from 'react';
import { Col, Form, Select } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useChecklistEIContext } from '../context';

const { useFormInstance } = Form;

export const SectorSelect = () => {
	const { resetFields } = useFormInstance();

	const { sectors } = useChecklistEIContext();
	const {
		handleSelectSector,
		mapDataToDropdown,
		filterOption,
		handleSelectLine,
		handleSelectWorkstation,
	} = useChecklistEIContext();

	function handleSectorSelect(value: string) {
		resetFields(['line', 'workstation']);
		handleSelectLine('');
		handleSelectWorkstation('');
		handleSelectSector(value);
	}

	const sectorOptions = mapDataToDropdown(sectors.data);

	return (
		<Col sm={12}>
			<Form.Item
				name="sector"
				label={I18n.get('Cost center')}
				rules={[{ required: true }]}
			>
				<Select
					showSearch
					allowClear
					options={sectorOptions}
					loading={sectors.gettingSectors}
					placeholder={I18n.get('Select a cost center')}
					onChange={(value: string) => handleSectorSelect(value)}
					filterOption={(input, option) => filterOption(option, input)}
					disabled={sectors.data.length === 0 || sectors.errorGettingSectors}
				/>
			</Form.Item>
		</Col>
	);
};
