import React, { useMemo } from 'react';
import { Col, Form, Spin } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useCollapseContext } from '@/components/views/MtmReport/Report/components/Collapse/context';
import { useGetProducts } from '@/hooks/useGetProducts';
import { Text } from '@/components/Typography';
import { ProductCard } from '../ProductCard';
import { SpinContainer } from '../../styles';
import * as S from './styles';

const { useFormInstance, useWatch } = Form;

interface ProductContainerProps {
	operationId: string;
	loadingWorkstation: boolean;
}

function arraysEqual(a: string[], b: string[]): boolean {
	if (a.length !== b.length) return false;
	for (let i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) return false;
	}
	return true;
}

function verifyError(namePath: string[], collapseError: string[][]) {
	const hasError = collapseError?.some((errorPath) => arraysEqual(errorPath, namePath));

	return hasError;
}

export function ProductContainer({ operationId, loadingWorkstation }: ProductContainerProps) {
	const { getFieldValue } = useFormInstance();
	const { collapseError } = useCollapseContext();
	const organization_id = getFieldValue(['informations', 'organization_id']);
	const company_id = getFieldValue(['informations', 'company_id']);
	const sector_id = getFieldValue(['informations', 'sector_id']);
	const line_id = getFieldValue(['informations', 'line_id']);
	const workstation_id = useWatch(['operation_product', operationId, 'workstation_id']);
	const product_name = useWatch(['operation_product', operationId, 'product_name']);
	const { isLoading, data, isError } = useGetProducts({
		organization_id,
		workstation_id,
		company_id,
		sector_id,
		line_id
	});

	const namePath = useMemo(() => ['operation_product', operationId, 'products'], [operationId]);
	const hasError = useMemo(() => verifyError(namePath, collapseError), [namePath, collapseError]);

	if (isError) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	if (isLoading || loadingWorkstation) {
		return (
			<SpinContainer span={24}>
				<Spin />
			</SpinContainer>
		);
	}

	function hideProduct(name: string) {
		if (!product_name) {
			return false;
		}

		return !name.trim().toLowerCase().includes(product_name.trim().toLowerCase());
	}

	return (
		<>
			<S.ProductContainer error={hasError}>
				<Col span={24}>
					<Form.Item
						name={namePath}
						rules={[{ required: true, message: I18n.get('Select at least one product') }]}
						style={{ margin: 0 }}
					>
						<S.CheckboxGroupCustom>
							{data?.map(({ id, name }) => (
								<ProductCard key={id} name={name} hidden={hideProduct(name)} id={id} />
							))}
						</S.CheckboxGroupCustom>
					</Form.Item>
				</Col>
			</S.ProductContainer>
		</>
	);
}
