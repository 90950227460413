import React from 'react';
import { Row, Col, Form } from 'antd';

import { Paragraph, Title } from '@/components/Typography';
import { useGetHierarchyByOperation } from '@/hooks';
import { Line, Sector, Company, Evaluator, ReportName, Organization, CollectionDate } from '../../components/Inputs';
import { AnalysisDate } from '../../components/Inputs/AnalysisDate';
import { useMtmReportContext } from '../../../context';
import { FooterButtons } from './FooterButtons';

const { useWatch } = Form;

export function Informations() {
	const { filesWithOperations } = useMtmReportContext();

	const organizationId = useWatch(['informations', 'organization_id']);
	const companyId = useWatch(['informations', 'company_id']);

	const operationsId = filesWithOperations.map((data) => data.operation_id);

	const { data } = useGetHierarchyByOperation({
		organization_id: organizationId,
		company_id: companyId,
		operations_id: operationsId
	});

	const { workstation } = data?.operation ? data?.operation : { workstation: null };

	const currentSector = workstation?.line?.id
		? { value: workstation.line.sector.id, label: workstation.line.sector.name }
		: null;
	const currentLine = workstation?.line?.id ? { value: workstation.line.id, label: workstation.line.name } : null;

	return (
		<Row gutter={[0, 30]} justify="center">
			<Col offset={1} span={23}>
				<Row>
					<Col span={24}>
						<Title level={3}>Informations</Title>
					</Col>
					<Col>
						<Paragraph>Enter the data related to the new analysis below</Paragraph>
					</Col>
				</Row>
			</Col>
			<Col offset={1} span={23}>
				<Row justify="center" gutter={[0, 40]}>
					<Col span={16}>
						<Row>
							<ReportName namePlace="informations" fontSize="16px" isRequired />
						</Row>
					</Col>
					<Col span={16}>
						<Row>
							<Organization namePlace="informations" fontSize="16px" isRequired />
							<Company namePlace="informations" fontSize="16px" isRequired />
							<Sector namePlace="informations" fontSize="16px" currentSector={currentSector} isRequired />
							<Line namePlace="informations" fontSize="16px" currentLine={currentLine} isRequired />
						</Row>
					</Col>
					<Col span={16}>
						<Row>
							<Evaluator
								isRequired
								fontSize="16px"
								companyId={companyId}
								namePlace="informations"
								organizationId={organizationId}
							/>
							<CollectionDate namePlace="informations" fontSize="16px" isRequired />
							<AnalysisDate namePlace="informations" fontSize="16px" isRequired />
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<FooterButtons />
			</Col>
		</Row>
	);
}
