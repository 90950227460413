import React, { ReactNode } from 'react';
import { Row } from 'antd';

import { onDownloadPdfFunction } from './Footer/ButtonsCustom';
import { DividerCustom } from '@/components/ui/DividerCustom';
import { Header } from './Header';
import { Footer } from './Footer';
import * as S from './styles';

interface ReportTemplateProps {
	children?: ReactNode;
	isLoadingPDF: boolean;
	onDownloadPDF: (params: onDownloadPdfFunction) => Promise<void>;
	onCommentSave: (comment: string) => void;
}

export function ReportTemplate({
	children,
	isLoadingPDF,
	onDownloadPDF,
	onCommentSave
}: Readonly<ReportTemplateProps>) {
	return (
		<Row justify="center">
			<S.ReportContainer xs={24} sm={23}>
				<Header title="Rotation" />
				<Row justify="center">
					<DividerCustom span={22} color="#E6E8E8" thickness="1px" margin={'0 0 43px 0'} />
				</Row>
				{children}
				<Footer isLoadingPDF={isLoadingPDF} onCommentSave={onCommentSave} onDownloadPDF={onDownloadPDF} />
			</S.ReportContainer>
		</Row>
	);
}
