import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Button } from '@/components/ui/Button';
import { useSelectFilesContext } from '../../context';
import { useRotationReportContext } from '@/components/views/RotationReport/context';

export function NextButton() {
	const { handleValidation } = useSelectFilesContext();
	const { handlePreviousStep } = useRotationReportContext();

	async function handleOnNext() {
		await handleValidation();
	}

	return (
		<Row justify="center" align="middle" gutter={[20, 0]}>
			<Col>
				<Button type="ghost" size="middle" htmlType="button" onClick={handlePreviousStep}>
					{I18n.get('Previous')}
				</Button>
			</Col>
			<Col>
				<Button htmlType="button" size="middle" type="primary" onClick={handleOnNext}>
					{I18n.get('Next')}
				</Button>
			</Col>
		</Row>
	);
}
