import React, { ReactElement, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import type { NamePath } from 'antd/lib/form/interface';
import { Form, Select, SelectProps, Skeleton } from 'antd';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';

import { DropdownRender } from '../../DropdownRender';
import { useCreateCompany } from '@/hooks/useCompanyMutation';
import { useApplicationContext } from '@/context/application';

import type { DefaultOptionType } from 'antd/lib/select';
import type { Company, CreateCompanyDTO } from '@/types';
import type { CompanyDTO } from '@/infra/services/dto/company.dto';

const { useFormInstance } = Form;

type CompanySelectProps = {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	companyId?: string;
	fieldName?: NamePath;
	allowCreation?: boolean;
	companies: Company[] | CompanyDTO[] | undefined;
};

type OptionType = { label: string; value: string } | undefined;

export function CompanySelect({
	loading,
	disabled,
	companies,
	companyId,
	size = 'middle',
	allowCreation = false,
	fieldName = 'company_id',
	...props
}: CompanySelectProps & SelectProps) {
	const [companyName, setCompanyName] = useState<string>('');

	const { setFieldValue } = useFormInstance();
	const { organization, company } = useApplicationContext();
	const { isLoading, mutateAsync: createCompany } = useCreateCompany();

	async function onCreateCompany() {
		const body: CreateCompanyDTO = {
			organization_id: organization?.id as string,
			company_id: company?.id as string,
			name: companyName
		};

		const createdCompany = await createCompany({ body });
		const companyId = createdCompany?.id;

		if (companyId && fieldName) {
			setFieldValue(fieldName, companyId);
		}

		setCompanyName('');
	}

	function handleCompanyNameChange(event: React.ChangeEvent<HTMLInputElement>) {
		const name = event.target.value;
		setCompanyName(name);
	}

	function filterOption(option: OptionType | DefaultOptionType, input: string): boolean {
		return (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownrender(menu: ReactElement): JSX.Element {
		return (
			<DropdownRender
				menu={menu}
				name={companyName}
				loading={isLoading}
				allowCreation={allowCreation}
				onClick={onCreateCompany}
				onChange={handleCompanyNameChange}
			/>
		);
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			{...props}
			showSearch
			allowClear
			size={size}
			value={companyId ?? null}
			loading={loading || isLoading}
			disabled={disabled || isLoading}
			placeholder={I18n.get('Industrial site')}
			dropdownRender={(menu) => showDropdownrender(menu)}
			filterOption={(input, option) => filterOption(option, input)}
			options={companies?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
