import styled from 'styled-components';

export const Container = styled.div`
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%); /* for IE 9 */
	-webkit-transform: translate(-50%, -50%); /* for Safari */

	width: 400px;
	height: auto;
	padding: 36px;
	box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);

	button {
		width: 100%;
	}

	p {
		display: flex;
		font-size: 12px;
		margin-top: 16px;
		text-align: center;
		color: rgb(204, 204, 204);
		justify-content: space-between;
	}
`;
