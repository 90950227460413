import styled from 'styled-components';
import { Col, Input } from 'antd';

export const Table = styled(Col)<{ $backGroundColor?: string }>`
	.ant-table {
		border-radius: 0 0 10px 10px !important;
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
				[colspan]
			)::before {
			background-color: #fff;
		}

		.ant-table-wrapper {
			display: flex;
			justify-content: center;
			.ant-spin-nested-loading {
				width: 25rem;
			}
		}

		.ant-table-tbody {
			background-color: ${({ $backGroundColor }) => $backGroundColor ?? 'unset'};
			tr {
				background-color: F8F8F8;
			}
			.file-columns {
				text-align: start !important;
			}
			.ant-table-selection-column {
				padding-left: 25px;
			}
			.ant-table-cell {
				color: #262626;
			}
			.ant-table-row:last-child {
				.ant-table-cell:first-child {
					border-radius: 0 0 0 10px;
				}
				.ant-table-cell:last-child {
					border-radius: 0 0 10px 0;
				}
			}
		}

		.ant-table-thead {
			th {
				background-color: #2f54eb;
				color: #fff;
				font-weight: 600;
				.ant-typography {
					color: #fff;
				}
			}
			.file-columns {
				padding-right: 60px;
			}
			.ant-table-cell::before {
				background-color: #fff;
			}

			.ant-table-cell:first-child {
				border-radius: 10px 0 0 0;
			}
			.ant-table-cell:last-child {
				border-radius: 0 10px 0 0;
			}
		}
	}

	@media (max-width: 1336px) {
		.ant-table-thead .ant-table-cell .ant-typography {
			font-size: 0.8rem;
		}
		.ant-table-thead .ant-table-cell {
			&:first-child,
			&:last-child {
				white-space: nowrap;
			}
		}
	}
`;

export const ContainerInput = styled.div<{ $borderRadius?: string }>`
	.ant-input {
		border-radius: ${({ $borderRadius }) => $borderRadius ?? 'unset'} !important;
	}

	input {
		height: 34px;
		font-size: 14px;
	}
`;

export const CustomInput = styled(Input)<{ $borderRadius?: string }>`
	border-radius: ${({ $borderRadius }) => $borderRadius ?? '7px'};
	height: 40px;
	font-size: 15px;
	.ant-input {
		margin-left: 7px;
	}
`;
