import React, { useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { Row, Col } from 'antd';

import { Loading } from './Loading';
import { CardChart, HighSphere, LowSphere, ModerateSphere } from './styles';
import { ErrorChart } from './Error';

export function DoughnutChart({
	title,
	onClickFilter,
	onFetch,
	attributeErgonomic,
	onFetchTotalWorkstations,
	attributeWorkstations,
	filter,
	minHeight = 10,
	maxHeight = 25,
}) {
	const ergonomic_quotes = useQuery([attributeErgonomic, filter], () =>
		onFetch(filter)
	);
	const total_workstations = useQuery([attributeWorkstations, filter], () =>
		onFetchTotalWorkstations(filter)
	);

	const isLoading = total_workstations.isLoading || ergonomic_quotes.isLoading;

	const isError = total_workstations.isError || ergonomic_quotes.isError;

	const chartRef = useRef();

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
				// text: I18n.get(title),
			},
			datalabels: {
				display: false,
			},
		},
	};

	function allAreNull(arr) {
		return arr?.every((item) => item?.value === 0);
	}

	if (isLoading || !ergonomic_quotes.data || !total_workstations.data) {
		return <Loading minHeight={minHeight} />;
	}

	if (isError) {
		return <ErrorChart />;
	}

	return (
		<CardChart minHeight={minHeight} maxHeight={maxHeight}>
			<Row>
				<Col>
					<h1
						style={{
							fontWeight: 'bold',
						}}
					>
						{I18n.get(title)}
					</h1>
				</Col>
			</Row>
			<Row
				justify="space-between"
				align="bottom"
				style={{
					marginTop: '5%',
				}}
			>
				<Col span={7} offset={1}>
					<span>
						<p
							style={{
								float: 'right',
								fontWeight: 'bold',
								fontSize: '18px',
							}}
						>
							{total_workstations.data?.total}
						</p>
						<p style={{ textAlign: 'left' }}>
							{I18n.get('Total workstations')}
						</p>
					</span>
					<br />
					<span>
						<p
							style={{
								float: 'right',
								fontWeight: 'bold',
								fontSize: '18px',
							}}
						>
							{ergonomic_quotes.data[0]?.value +
								ergonomic_quotes.data[1]?.value +
								ergonomic_quotes.data[2]?.value}
						</p>
						<p style={{ textAlign: 'left' }}>
							{I18n.get('Total avaliations')}
						</p>
					</span>
					<br />
					<span>
						<HighSphere />
						<p
							style={{
								float: 'right',
								color: '#F07575',
								fontWeight: 'bold',
							}}
						>
							{ergonomic_quotes.data[2]?.value}
						</p>
						<p style={{ textAlign: 'left' }}>{I18n.get('High risk')}</p>
					</span>
					<span>
						<ModerateSphere />
						<p
							style={{
								float: 'right',
								color: '#FDC365',
								fontWeight: 'bold',
							}}
						>
							{ergonomic_quotes.data[1]?.value}
						</p>
						<p style={{ textAlign: 'left' }}>{I18n.get('Moderate risk')}</p>
					</span>
					<span>
						<LowSphere />
						<p
							style={{
								float: 'right',
								color: '#40BF5F',
								fontWeight: 'bold',
							}}
						>
							{ergonomic_quotes.data[0]?.value}
						</p>
						<p style={{ textAlign: 'left' }}>{I18n.get('Low risk')}</p>
					</span>
				</Col>
				<Col span={16}>
					<Doughnut
						style={{
							width: '130%',
							marginTop: '5%',
							marginLeft: '15%',
						}}
						ref={chartRef}
						options={options}
						onClick={(e) =>
							onClickFilter(
								e,
								chartRef.current,
								ergonomic_quotes.data,
								attributeErgonomic
							)
						}
						data={
							allAreNull(ergonomic_quotes.data)
								? {
										labels: [I18n.get('No data')],
										datasets: [
											{
												labels: I18n.get('No data'),
												backgroundColor: ['#D3D3D3'],
												data: [100],
											},
										],
								  }
								: {
										labels: [
											...ergonomic_quotes.data?.map((item) =>
												I18n.get(item.unit)
											),
										],
										datasets: [
											{
												label: I18n.get(title),
												backgroundColor: [
													'rgba(64, 191, 95)',
													'rgba(252, 176, 51)',
													'rgba(240, 117, 117)',
												],
												borderColor: [
													'rgba(64, 191, 95)',
													'rgba(252, 176, 51)',
													'rgba(240, 117, 117)',
												],
												data: [
													...ergonomic_quotes.data?.map(
														(item) => item.value
													),
												],
											},
										],
								  }
						}
					/>
				</Col>
			</Row>
		</CardChart>
	);
}
