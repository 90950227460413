import React from 'react';
import { I18n } from '@aws-amplify/core';
import { FrownOutlined } from '@ant-design/icons';
import { Container, Title, FrameError } from './styles';

export const Error = () => (
	<Container>
		<FrameError>
			<FrownOutlined />
			<Title>{I18n.get('Failed to fetch')}</Title>
		</FrameError>
	</Container>
);
