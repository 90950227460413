import React from 'react';
import { Row, Col } from 'antd';
import { Container, TreemapContainer } from './styles';
import { DashboardProvider } from './context';
import { Comparative } from './Comparative';
import { Filters } from './Filters';
import { Mosaic } from './Mosaic';
import { Menu } from './Menu';

export const Dashboard: React.FC = () => (
	<DashboardProvider>
		<Container>
			<Row>
				<Col span={24}>
					<Menu />
				</Col>
				<TreemapContainer span={24}>
					<Mosaic />
				</TreemapContainer>
			</Row>
			<Filters />
			<Comparative />
		</Container>
	</DashboardProvider>
);
