import React, { createContext, useContext, useReducer } from 'react';
import { ApplicationStorage, Company, Organization, User } from './types';
import { AppReducer, Types } from './reducer';

const storage = {
	organization: localStorage.getItem('organization'),
	company: localStorage.getItem('company'),
	user: localStorage.getItem('info_user')
};

const defaultState: ApplicationStorage = {
	organization: storage.organization ? JSON.parse(storage.organization) : {},
	company: storage.company ? JSON.parse(storage.company) : {},
	user: storage.user ? JSON.parse(storage.user)?.user : {},
	setOrganization: () => {},
	setCompany: () => {},
	setUser: () => {},
	clearProject: () => {}
};

type ApplicationProviderProps = {
	children: string | JSX.Element | JSX.Element[];
};

const ApplicationContext = createContext<ApplicationStorage>(defaultState);

export const ApplicationProvider: React.FC<ApplicationProviderProps> = ({ children }) => {
	const [state, dispatch] = useReducer(AppReducer, defaultState);

	function setOrganization(organization: Organization) {
		dispatch({ type: Types.SET_ORGANIZATION, payload: { organization } });
	}

	function setCompany(company: Company) {
		localStorage.setItem('organization', JSON.stringify(state.organization));
		localStorage.setItem('company', JSON.stringify(company));
		dispatch({ type: Types.SET_COMPANY, payload: { company } });
	}

	function setUser(user: User) {
		localStorage.setItem('user', JSON.stringify(user));
		dispatch({ type: Types.SET_USER, payload: { user } });
	}

	function clearProject() {
		localStorage.removeItem('organization');
		localStorage.removeItem('company');
		dispatch({ type: Types.CLEAR_PROJECT, payload: {} });
	}

	const context = {
		...state,
		setOrganization,
		setCompany,
		setUser,
		clearProject
	};

	return <ApplicationContext.Provider value={context}>{children}</ApplicationContext.Provider>;
};

export function useApplicationContext() {
	const context = useContext(ApplicationContext);
	return context;
}
