import React, { useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { Bar } from 'react-chartjs-2';
import { Loading } from './Loading';
import { ErrorChart } from './Error';
import { CardChart } from './styles';
import { Row, Col } from 'antd';

import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as ChartJS } from 'chart.js';

ChartJS.register(zoomPlugin);

export function BarChart({
	title,
	attribute,
	onClickFilter,
	onFetch,
	filter,
	orientation = 'x',
	minHeight = 10,
}) {
	const { data, isError, isLoading } = useQuery([attribute, filter], () =>
		onFetch(filter)
	);

	const chartRef = useRef();

	const options = {
		responsive: true,
		animation: {
			duration: 0, // general animation time
		},
		hover: {
			animationDuration: 0, // duration of animations when hovering an item
		},
		responsiveAnimationDuration: 0,
		maintainAspectRatio: false,
		indexAxis: orientation,
		scales: {
			x: {
				stacked: true,
				min: 0,
				grid: {
					display: false,
				},
				ticks: {
					precision: 0,
					source: 'data',
					autoSkip: false,
				},
			},
			y: {
				stacked: true,
				min: 0,
				...(orientation === 'y' && {
					max: 15,
				}),
				grid: {
					display: false,
				},
				ticks: {
					precision: 0,
					source: 'data',
					autoSkip: false,
					crossAlign: 'far', // Align labels
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
				// text: I18n.get(title),
			},
			datalabels: {
				display: false,
			},
			...(orientation === 'y' && {
				zoom: {
					pan: {
						enabled: true,
						mode: 'y',
					},
				},
			}),
		},
	};

	if (isLoading) {
		return <Loading minHeight={minHeight} />;
	}

	if (isError) {
		return <ErrorChart />;
	}

	return (
		<CardChart minHeight={minHeight}>
			<Row>
				<Col>
					<h1
						style={{
							fontWeight: 'bold',
						}}
					>
						{I18n.get(title)}
					</h1>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Bar
						style={
							title !== 'Workstation'
								? { paddingTop: '8%', width: '100%' }
								: { paddingTop: '5%', width: '60%' }
						}
						ref={chartRef}
						options={options}
						onClick={(e) =>
							onClickFilter(e, chartRef.current, data, attribute)
						}
						data={{
							labels: data?.map((item) => item.name),
							datasets: [
								{
									maxBarThickness: 40,
									label: I18n.get('Low'),
									backgroundColor: ['rgba(64, 191, 95)'],
									borderColor: ['rgba(64, 191, 95)'],
									data: data?.map((item) => item.Low),
								},
								{
									maxBarThickness: 40,
									label: I18n.get('Average'),
									backgroundColor: ['rgba(252, 176, 51)'],
									borderColor: ['rgba(252, 176, 51)'],
									data: data?.map((item) => item.Average),
								},
								{
									maxBarThickness: 40,
									label: I18n.get('High'),
									backgroundColor: ['rgba(240, 117, 117)'],
									borderColor: ['rgba(240, 117, 117)'],
									data: data?.map((item) => item.High),
								},
							],
						}}
					/>
				</Col>
			</Row>
		</CardChart>
	);
}
