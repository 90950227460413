import React from 'react';

const defaultState = {
	companies: null,
	selectCompanyId: null,
	sectors: null,
	selectSectorId: null,
	lines: null,
	selectLineId: null,
	workstations: null,
	selectWorkstationId: null,
	complaints: null,
	setSelectComplaintId: null,
	responsible_workers: null,
	actionPlans: null,
	currentActionPlan: null,
	creationBoard: null,
	actionPlanCreateModal: false,
	actionPlanEditModal: false,
	triggerReload: null,
	errors: null,
};

const defaultContext = {
	...defaultState,
	setSelectCompanyId: () => {},
	setSelectSectorId: () => {},
	setSelectLineId: () => {},
	setSelectWorkstationId: () => {},
	setSelectComplaintId: () => {},
	onFilterActionPlans: () => {},
	setTriggerReload: () => {},
	onEditActionPlan: () => {},
	onDeleteActionPlan: () => {},
	setErrors: () => {},
	getCompanies: () => {},
	getSectors: () => {},
	getLines: () => {},
	getWorkstations: () => {},
	getComplaintsByWorkstation: () => {},
	getResponsibleWorkers: () => {},
	showCreateActionPlan: () => {},
	calcProgress: () => {},
};

export const ActionPlansContext = React.createContext(defaultContext);
