import React from 'react';
import { Col, Form, Input } from 'antd';

import { I18n } from '@aws-amplify/core';

export const ActivityInput = () => {
	return (
		<Col sm={12}>
			<Form.Item
				rules={[{ required: true }]}
				name="activity"
				label={I18n.get('Activity')}
			>
				<Input placeholder={I18n.get('Activity')} />
			</Form.Item>
		</Col>
	);
};
