import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { io } from 'socket.io-client';
import { message } from 'antd';

import Api from '@/services/api';
import { socketConfig, getRoom } from '@/utils/socket';

async function getFiles({ organizationId, companyId, page = 0, limit = 10, query = '' }) {
	if (!organizationId || !companyId) return;
	let url = `/upload/${organizationId}/${companyId}?offset=${page}&limit=${limit}&${query}`;
	const { data } = await Api.get(url);
	return data;
}

export const useFile = (payload) => {
	const files = useQuery(['files', payload], () => getFiles(payload), {
		onError: (error) => {
			const description = error.response.data.message || error.message;
			message.error(I18n.get(description));
		},
	});

	const webSocket = useRef();
	const queryClient = useQueryClient();

	const organization_id = useSelector((state) => state.organization.organization?.id);
	const company_id = useSelector((state) => state.organization.company?.id);

	const updateItem = (oldData, newData) => {
		return oldData.id === newData.id
			? { ...oldData, status: newData.status }
			: oldData;
	};

	useEffect(() => {
		if (!organization_id || !company_id) return;

		const room = getRoom('FILE_STATUS');
		const config = socketConfig(organization_id, company_id);

		webSocket.current = io(room, config);

		webSocket.current.on('UPDATE_FILE_STATUS', (data) => {
			queryClient.setQueriesData(['files'], (previous) => {
				if (Array.isArray(previous.result)) {
					const result = previous.result.map((prev) => updateItem(prev, data));
					return { ...previous, result };
				}

				return previous;
			});
		});

		return () => webSocket.current?.close();
	}, [organization_id, company_id, queryClient]);

	return files;
};
