import React from 'react';
import { Col, Row } from 'antd';

import { Title } from '@/components/Typography';
import { MtmReportResult } from '@/hooks';
import { ResultCard } from '../../../../components/ResultCard';

interface GeneralResultsProps {
	general_results?: MtmReportResult['general_results'];
}

export function GeneralResults({ general_results }: Readonly<GeneralResultsProps>) {
	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col span={22}>
				<Title level={4} style={{ color: '#262626' }}>
					General results of the line
				</Title>
			</Col>
			<Col span={22} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
				<Row gutter={[20, 0]}>
					<Col span={24}>
						<Title level={5} style={{ color: '#262626' }}>
							Line total time (min)
						</Title>
						<ResultCard value={general_results?.total_time ?? 0} />
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
