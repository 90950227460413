import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';

import type { BulkRemoveProductDTO, RemoveProductSuccess } from '@/types/dto/product';
import type { ErrorResponse } from '@/types';

async function deleteProduct(params: BulkRemoveProductDTO): Promise<RemoveProductSuccess> {
	const { id, ...payload } = params;
	const { data } = await Api.delete(`/product/bulk/${id}`, { data: payload });
	return data;
}

export const useBulkDeleteProduct = () => {
	return useMutation<RemoveProductSuccess, ErrorResponse, BulkRemoveProductDTO>(deleteProduct);
};
