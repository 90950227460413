import React from 'react';

import { ChecklistEIForm } from './ChecklistEIForm';
import { ChecklistEIProvider } from './context';

export function ChecklistEI() {
	return (
		<ChecklistEIProvider>
			<ChecklistEIForm />
		</ChecklistEIProvider>
	);
}
