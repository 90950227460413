import React, { ReactNode } from 'react';
import { Button, Col, Form, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useChecklistEIContext } from '../context';

const { useFormInstance } = Form;

export const Buttons = () => {
	const { validateFields } = useFormInstance();
	const { currentStep, setStep } = useChecklistEIContext();

	async function handleNextPageClick() {
		const values = await validateFields();
		if (values) {
			// setStep(currentStep + 1);
		}
	}

	function handlePreviousPageClick() {
		setStep(currentStep - 1);
	}

	function showBackButton(): ReactNode {
		return (
			<Col flex={1}>
				<Form.Item>
					<Button
						type="default"
						style={{ float: 'left' }}
						onClick={handlePreviousPageClick}
					>
						{I18n.get('Back')}
					</Button>
				</Form.Item>
			</Col>
		);
	}

	function showNextButton(): ReactNode {
		return (
			<Col flex={1}>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						style={{ float: 'right' }}
						onClick={handleNextPageClick}
					>
						{I18n.get('Next')}
					</Button>
				</Form.Item>
			</Col>
		);
	}

	return (
		<Row justify="center" style={{ marginTop: '1rem' }}>
			<Col sm={6}>
				<Row justify="center">
					{showBackButton()}
					{showNextButton()}
				</Row>
			</Col>
		</Row>
	);
};
