import React, { useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { useApplicationContext } from '@/context/application';

import { setCompanyMosaic } from '../../../../redux/checklist/actions';
import { useDashboardContext } from '../../context';
import { TileContainer, Wrapper } from './styles';
import { Props } from './index.types';

export function Tile({ id, title, size, count, child, worst_score, lines, ...props }: Props) {
	const worst = worst_score;
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		step,
		filter,
		setStep,
		sectorId,
		setLineId,
		menuItems,
		companyId,
		setSectorId,
		setLineTitle,
		setCompanyId,
		setSectorTitle,
		setCompanyTitle,
		handleMenuItems
	} = useDashboardContext();
	const { organization } = useApplicationContext();

	function handleClick() {
		if (child.length === 0) {
			message.error(I18n.get('There is nothing to access.'));
			return;
		}
		menuItems[step] = title;
		handleMenuItems(menuItems);
		switch (step) {
			case 1:
				setSector();
				break;
			case 2:
				setLine();
				break;
			case 3:
				redirectToFilteredReports();
				break;
			default:
				setCompany();
				break;
		}
	}

	function redirectToFilteredReports() {
		let params = `?organization_id=${
			organization!.id
		}&company_id=${companyId}&sector_id=${sectorId}&workstation_id=${id}`;
		if (filter) {
			params += `&filter_type=${filter}&filter=${worst_score}`;
		}
		history.push({
			pathname: '/files',
			search: params
		});
	}

	function setCompany() {
		setStep(step + 1);
		setCompanyId(id);
		setCompanyTitle(title);
		dispatch(setCompanyMosaic({ id, name: title }));
	}

	function setLine() {
		setStep(step + 1);
		setLineId(id);
		setLineTitle(title);
	}

	function setSector() {
		setStep(step + 1);
		setSectorId(id);
		setSectorTitle(title);
	}

	const ref = useRef<HTMLDivElement>(null);
	const [vertical, setVertical] = useState<boolean>(false);
	const blockWidth = `calc(${(step === 3 ? 1 : size) * lines}% + 7%)`;

	useLayoutEffect(() => {
		if (!ref.current) return;

		setVertical(ref.current.offsetWidth < ref.current.offsetHeight);
	}, [ref, title]);

	return (
		<TileContainer title={props.children} placement="bottom">
			<Wrapper
				step={step}
				size={size}
				lines={step}
				worst={worst}
				style={{
					width: blockWidth,
					height: lines > 3 ? 100 : 170
				}}
				onClick={handleClick}
				className={vertical ? 'vertical' : ''}
				ref={ref}
			>
				<span>{props.children}</span>
			</Wrapper>
		</TileContainer>
	);
}
