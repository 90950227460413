import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';

import { CreateMtmReportDTO, CreateMtmReportResponse, useCreateMtmReport, useUpdateMtmReport } from '@/hooks';
import { useMtmReportContext } from '@/components/views/MtmReport/context';
import * as S from '../../../components/styles';

const { useFormInstance } = Form;

export function FooterButtons() {
	const history = useHistory();
	const { validateFields, getFieldsValue } = useFormInstance();
	const { handleNextStep, handlePreviousStep, mtmReport } = useMtmReportContext();

	const { mutateAsync: createMtmReport } = useCreateMtmReport();
	const { mutateAsync: updateMtmReport } = useUpdateMtmReport();

	function handleRedirect({ id, organization_id, company_id }: CreateMtmReportResponse): void {
		const pathname = `/engineering/mtm/report/${id}`;
		const search = `?organization_id=${organization_id}&company_id=${company_id}`;
		history.replace({ pathname, search });
	}

	async function createReport(value: any): Promise<void> {
		const result = await createMtmReport(value);
		handleRedirect(result);
	}

	async function updateReportData(values: any): Promise<void> {
		const payload = {
			...values,
			id: mtmReport?.id as string
		};
		await updateMtmReport(payload);
	}

	function getPayload(values: any): CreateMtmReportDTO {
		const { informations, selectedRowKeys: file_ids } = values;
		return { ...informations, file_ids };
	}

	function getFormValues() {
		const values = getFieldsValue(true);
		return values;
	}

	async function handleOnNextStep(): Promise<void> {
		await validateFields();
		const values = getFormValues();
		const payload = getPayload(values);
		mtmReport?.id ? await updateReportData(payload) : await createReport(payload);
		handleNextStep();
	}

	function handleOnPreviousStep(): void {
		handlePreviousStep();
	}

	return (
		<Row justify="center" align="middle" gutter={[20, 0]}>
			<Col>
				<S.CustomButton htmlType="submit" size="large" type="primary" onClick={handleOnPreviousStep}>
					{I18n.get('Previous')}
				</S.CustomButton>
			</Col>
			<Col>
				<S.CustomButton htmlType="submit" size="large" type="primary" onClick={handleOnNextStep}>
					{I18n.get('Next')}
				</S.CustomButton>
			</Col>
		</Row>
	);
}
