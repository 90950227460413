import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { QUERY_KEYS } from '@/utils/query-keys';
import { queryClient } from '@/store/query';
import Api from '@/services/api';

export type UpdateRotationReportCommentDTO = {
	id: string;
	comment: string;
	company_id: string;
	organization_id: string;
};

async function updateRotationReportComment(params: UpdateRotationReportCommentDTO): Promise<any> {
	const url = `/rotation/report/${params?.id}/comment`;
	const { data } = await Api.put(url, params);
	return data;
}

export const useUpdateRotationReportComment = () => {
	return useMutation(updateRotationReportComment, {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || 'Ops... something happened!'));
		},
		onSuccess: () => {
			message.success(I18n.get('Comment updated successfully'));
			queryClient.invalidateQueries([QUERY_KEYS.GET_ROTATION_REPORT]);
		}
	});
};
