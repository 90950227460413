import React from 'react';

export const CustomDownArrow: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="none" viewBox="0 0 40 39">
			<path
				fill="#2F54EB"
				d="M.8 18.367l4.948-5.119 14.586 15.081L34.92 13.248l4.95 5.12-19.536 20.198L.8 18.367z"
			></path>
			<path
				fill="#2F54EB"
				d="M7.72 5.768L12.667.65l7.666 7.927L28 .65l4.95 5.118-12.616 13.047L7.72 5.768z"
			></path>
		</svg>
	);
};
