import React, { useRef, useContext } from 'react';
import { Row, Col } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';

import { AnalyticsDashboardContext } from '../../context';
import { DoughnutChart, Loading, Error } from '../_Components/Charts';
import { getErgonomicGrade, getTotalWorkstations } from '../../services';
import { HighSphere, ModerateSphere, LowSphere, Label, Value } from './styles';

export const ErgonomicQuote: React.FC = () => {

	const chartRef = useRef();
	const { filter, onClickFilter } = useContext(AnalyticsDashboardContext);

	const { isLoading, data, isError, refetch } = useQuery(['ergonimic-quote', filter], () =>
		getErgonomicGrade(filter)
	);

	const totalWorkstations = useQuery(['total-workstations', filter], () =>
		getTotalWorkstations(filter)
	);

	if (isLoading) {
		return <Loading size={30} />;
	}

	if (isError) {
		return <Error message={I18n.get('Failed to fetch')} onClick={refetch} />;
	}

	function sumAvaliations(data: any = []) {
		const total = data.reduce((acc: number, item: any) => acc + item?.value, 0);
		return total;
	}

	function getValueAvaliations(data: any = [], attribute: string) {
		const [item] = data.filter(((item: any) => item?.unit === attribute));
		return item?.value || 0;
	}

	return (
		<Row justify="space-around" align="middle">
			<Col span={12} style={{ paddingTop: '5%' }}>
				<Row justify="space-around" align="middle">
					<Col span={16}>
						<Label>
							{I18n.get('Total workstations')}
						</Label>
						<Value>
							{totalWorkstations.data?.total}
						</Value>
					</Col>
					<Col span={16}>
						<Label>
							{I18n.get('Total avaliations')}
						</Label>
						<Value>
							{sumAvaliations(data)}
						</Value>
					</Col>
					<Col span={16} style={{ paddingTop: '5%' }}>
						<HighSphere />
						<Label>
							{I18n.get('High risk')}
						</Label>
						<Value size={15} color="#F07575">
							{getValueAvaliations(data, 'High')}
						</Value>
					</Col>
					<Col span={16}>
						<ModerateSphere />
						<Label>
							{I18n.get('Moderate risk')}
						</Label>
						<Value size={15} color="#FDC365">
							{getValueAvaliations(data, 'Average')}
						</Value>
					</Col>
					<Col span={16}>
						<LowSphere />
						<Label>
							{I18n.get('Low risk')}
						</Label>
						<Value size={15} color="#40BF5F">
							{getValueAvaliations(data, 'Low')}
						</Value>
					</Col>
				</Row>
			</Col>
			<Col span={12}>
				<DoughnutChart
					data={data}
					ref={chartRef}
					title="Ergonomic quote"
					onClick={(e) => onClickFilter(e, chartRef.current, data, 'ergonomic_grade')}
				/>
			</Col>
		</Row>
	);
}
