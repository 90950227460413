import { useQuery } from '@tanstack/react-query';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { BaseContextOptional } from '@/types';

export type MtmReportFiles = {
	id: string;
	original_name: string;
	operation: {
		id: string;
		name: string;
	};
};

export type MtmReportFilesResponse = MtmReportFiles[];

export type MtmReportFilesDTO = BaseContextOptional & {
	id: string;
};

async function listMtmReportFiles(params: MtmReportFilesDTO): Promise<MtmReportFilesResponse> {
	const { organization_id, company_id, id } = params;
	const url = `/mtm/report/${id}/files`;
	const { data } = await Api.get(url, { params: { organization_id, company_id, id } });
	return data;
}

export const useListMtmReportFiles = (params: MtmReportFilesDTO) => {
	return useQuery([QUERY_KEYS.GET_MTM_REPORTS_PAGINATED, params], () => listMtmReportFiles(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.id
	});
};
