import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Select, SelectProps, Skeleton } from 'antd';
import type { NamePath } from 'antd/lib/form/interface';
import type { DefaultOptionType } from 'antd/lib/select';
import type { ActivitiesDTO } from '@/types/dto/Activity';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';

type ActivitySelectProps = {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	activityId?: string;
	fieldName?: NamePath;
	allowCreation?: boolean;
	activities: ActivitiesDTO[] | undefined;
};

type OptionType = { label: string; value: string } | undefined;

export function ActivitySelect({
	loading,
	disabled,
	activities,
	activityId,
	size = 'middle',
	allowCreation = false,
	fieldName = 'company_id',
	...props
}: ActivitySelectProps & SelectProps) {
	function filterOption(option: OptionType | DefaultOptionType, input: string): boolean {
		return (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			{...props}
			showSearch
			allowClear
			size={size}
			value={activityId ?? null}
			loading={loading}
			disabled={disabled}
			placeholder={I18n.get('Activity')}
			filterOption={(input, option) => filterOption(option, input)}
			options={activities?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
