import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Operation } from '@/types';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

export type FileOperationDTO = {
	file_id: string;
	operation_id: string;
};

export type FileToOperationDTO = {
	files_operations: FileOperationDTO[];
	params: {
		organization_id: string;
		company_id: string;
	};
};

const setFileToOperation = async ({ files_operations, params }: FileToOperationDTO): Promise<Operation> => {
	const url = `/operation/files`;
	const { data } = await Api.post<Operation>(url, { files_operations, ...params });
	return data;
};

export const useSetFileToOperation = () => {
	const queryClient = useQueryClient();
	return useMutation((payload: FileToOperationDTO) => setFileToOperation(payload), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_FILES_WITH_OPERATIONS]);
			message.success(I18n.get('Operations was set in files successfully'));
		}
	});
};
