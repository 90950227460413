import React from 'react';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import store from './store';
import { Routes } from './routes';
import history from './store/history';
import { queryClient } from './store/query';
import { collectionVocabularies, browserLanguage, localeProvider } from './i18n';

moment.locale('pt-br');
moment.updateLocale('pt-br', {
	weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
});

I18n.setLanguage(browserLanguage);
I18n.putVocabularies(collectionVocabularies);

const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';

export function App() {
	return (
		<ConfigProvider locale={localeProvider}>
			<QueryClientProvider client={queryClient}>
				<ReactQueryDevtools initialIsOpen={isDevelopment} />
				<Provider store={store}>
					<Router history={history}>
						<Routes />
					</Router>
				</Provider>
			</QueryClientProvider>
		</ConfigProvider>
	);
}
