import React from 'react';
import { Row, Col } from 'antd';

import { RotationReportResultResponse } from '@/hooks/useGetRotationReportResult';
import { Title } from '@/components/Typography';
import { ResultCard } from './ResultCard';
import { Activities } from './Activities';

interface OverallResultsProps {
	total_time?: RotationReportResultResponse['total_cycle_time'];
	activities: RotationReportResultResponse['activities'];
}

export function OverallResults({ total_time, activities }: Readonly<OverallResultsProps>) {
	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col span={22}>
				<Title level={4}>Overall results of the rotation</Title>
			</Col>
			<Col span={22} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
				<Row gutter={[20, 0]}>
					<Col span={24} style={{ marginBottom: 30 }}>
						<Title level={5}>Total cycle time</Title>
						<ResultCard value={total_time} />
					</Col>
					<Col span={24}>
						<Activities data={activities} />
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
