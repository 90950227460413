import React from 'react';
import { Row, Col, Form } from 'antd';
import { Text } from '@/components/Typography';
import { PercentageInput } from './PercentageInput';
import { FillmentSlider } from '@/components/ui/FillmentSlider';

const { useFormInstance } = Form;

interface ReportNameProps {
	bodyPart: 'neck' | 'trunk' | 'left_upper_arm' | 'right_upper_arm' | 'left_lower_arm' | 'right_lower_arm';
	isRequired?: boolean;
	namePlace?: string;
}

const BODY_PARTS_DESCRIPTION = {
	trunk: 'Torso',
	neck: 'Cervical spine',
	left_lower_arm: 'Left elbow',
	right_lower_arm: 'Right elbow',
	left_upper_arm: 'Left shoulder',
	right_upper_arm: 'Right shoulder'
};

export function BodyPartGoal({ isRequired = false, namePlace = 'goals', bodyPart }: Readonly<ReportNameProps>) {
	const { setFieldValue, getFieldValue } = useFormInstance();
	const percentage: number = getFieldValue([namePlace, bodyPart]);

	function handleOnChange(value: number): void {
		setFieldValue([namePlace, bodyPart], value);
	}

	return (
		<Row justify="center">
			<Col span={6}>
				<Text>{BODY_PARTS_DESCRIPTION[bodyPart]}</Text>
			</Col>
			<Col span={14}>
				<FillmentSlider onChange={handleOnChange} defaultValue={percentage} />
			</Col>
			<Col>
				<Form.Item required={isRequired} name={[namePlace, bodyPart]} initialValue={percentage}>
					<PercentageInput />
				</Form.Item>
			</Col>
		</Row>
	);
}
