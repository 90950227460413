import React from 'react';
import { Form, Select } from 'antd';
import { I18n } from '@aws-amplify/core';
import type { DefaultOptionType } from 'antd/lib/select';

import { useRotationReportContext } from '@/components/views/RotationReport/context';
import { GetRotationReportResponse } from '@/hooks/useGetRotationReport';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { formatTime } from '..';
import * as S from '../../styles';

type OptionType = { label: string; value: string } | undefined;

type ActivityType = { value: string; label: string; duration: number };

export function groupActivities(file_activities: GetRotationReportResponse['activities']) {
	const group_activities = file_activities.reduce((acc, curr) => {
		const { activity_id, activity, file } = curr;
		if (!acc[activity_id]) {
			acc[activity_id] = {
				value: activity_id,
				label: activity.name,
				duration: file.duration
			};
		}
		return acc;
	}, {} as { [key: string]: ActivityType });

	return group_activities;
}

const { useFormInstance } = Form;

export function ActivitySelector() {
	const { rotationReport } = useRotationReportContext();
	const form = useFormInstance();

	function filterOption(option: OptionType | DefaultOptionType, input: string): boolean {
		return (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
	}
	const options = Object.entries(groupActivities(rotationReport?.activities ?? [])).map(([, option]) => option);

	function handleChange(value?: string) {
		form.setFieldValue(['cycle_result', 'frequency'], undefined);
		form.setFieldValue(['cycle_result', 'activity_time'], undefined);
		form.setFieldValue(['cycle_result', 'file_duration'], undefined);
		if (!value) {
			return;
		}
		const option = options.find(({ value: optionValue }) => value === optionValue);
		form.setFieldValue(['cycle_result', 'file_duration'], option?.duration ?? 0);
		form.setFieldValue(['cycle_result', 'activity_time'], formatTime(option?.duration ?? 0));
	}

	return (
		<S.ContainerSelect $borderRadius="8px">
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={['cycle_result', 'activity_id']}
				rules={[{ required: true, message: I18n.get('Select the operation') }]}
				label={<RequiredLabel fontSize={'16px'} title="Operation" isRequired={false} />}
			>
				<Select
					showSearch
					allowClear
					size="large"
					onChange={handleChange}
					placeholder={I18n.get('Operation')}
					filterOption={(input, option) => filterOption(option, input)}
					options={[{ label: I18n.get('Break'), value: 'break' }, ...options]}
				/>
			</Form.Item>
		</S.ContainerSelect>
	);
}
