import styled from 'styled-components';

import { SIDE } from '..';

export const ShadowBar = styled.div<{ side: 'left' | 'right' }>`
	box-shadow: ${({ side }) =>
		side === SIDE.LEFT ? '3px 0px 3px rgba(0, 0, 0, 0.06)' : '-3px 0px 3px rgba(0, 0, 0, 0.06)'};
	width: 5px;
	height: 35px;
`;
