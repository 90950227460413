import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Input, InputNumber, Button, Tooltip, message } from 'antd';

import Api from '@/services/api';
import { useIsMounted } from '@/hooks/useIsMounted';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import {
	nextCurrentStep,
	previousCurrentStep,
	addReaction,
	removeReaction,
} from '@/redux/checklist/actions';

const FormItem = Form.Item;

export function Reaction() {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const isMounted = useIsMounted();

	const [loading, setLoading] = useState(false);

	const file = useSelector((state) => state.checklist.file);
	const reaction = useSelector((state) => state.checklist.reaction);
	const checklist = useSelector((state) => state.checklist.checklist);

	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	const onErrors = (err) => {
		const description = err.response?.data?.message || err.message;
		message.error(I18n.get(description));
		setLoading(false);
	};

	const saveData = (body) => {
		const parameters = {
			checklist_id: checklist.id,
			organization_id: organization.id,
			company_id: company.id,
			file_id: file.id,
			// sector_id: 'uuid',
			// workstation_id: 'uuid',
			data: {
				reaction: body,
				step: 2,
			},
		};

		Api.post('/report/checklist/reaction', parameters)
			.then(isMounted() && setLoading(true))
			.then((res) => {
				if (isMounted()) {
					setLoading(false);
					dispatch(nextCurrentStep());
				}
			})
			.catch(onErrors)
			.finally(() => isMounted() && setLoading(false));
	};

	const onPrevious = () => {
		dispatch(previousCurrentStep());
	};

	const onFinish = () => {
		form.validateFields()
			.then((values) => {
				dispatch(addReaction(values.reaction));
				return values.reaction;
			})
			.then(saveData)
			.catch(() => {});
	};

	const onDelete = (key, remove, array) => {
		const [value] = array
			.filter(({ fieldKey }) => fieldKey === key)
			.map(({ name: index }) => ({ index }));

		if (reaction.length > 0 && reaction[key]?.id) {
			const { id } = reaction[key];
			remove(value.index);
			dispatch(removeReaction(id));
		} else {
			remove(value.index);
		}

		// if (reaction.length > 0 && reaction[key]?.id) {
		// 	const { id } = reaction[key];
		// 	remove(key);
		// 	dispatch(removeReaction(id));
		// }
		// remove(key);
	};

	return (
		<Form form={form} layout="vertical" initialValues={{ reaction }}>
			<Row style={{ paddingTop: '20px' }} gutter={[5, 20]}>
				<Col sm={24}>
					<h2>{I18n.get('Reaction')}</h2>
				</Col>
				<Col sm={24}>
					<p>
						{I18n.get(
							`Enter below the data on the employee's reaction in the activity`
						)}
					</p>
				</Col>
				<Col sm={16}>
					<Row gutter={[10, 20]}>
						<Col sm={24} style={{ minHeight: '250px' }}>
							<Form.List name="reaction">
								{(fields, { add, remove }) => (
									<>
										{fields.map(
											(
												{ key, name, fieldKey, ...restField },
												index,
												array
											) => (
												<Row
													key={key}
													gutter={[10, 0]}
													type="flex"
													style={{
														alignItems: 'center',
													}}
												>
													<Col sm={8}>
														<FormItem
															{...restField}
															name={[name, 'name']}
															fieldKey={[fieldKey, 'name']}
															label={I18n.get('Name')}
															rules={[
																() => ({
																	validator(_, value) {
																		return !value
																			? Promise.reject(
																					I18n.get(
																						'Enter a name'
																					)
																			  )
																			: Promise.resolve();
																	},
																}),
															]}
														>
															<Input
																placeholder={`${I18n.get(
																	'Reaction'
																)} ${index + 1}`}
															/>
														</FormItem>
													</Col>
													<Col sm={8}>
														<FormItem
															{...restField}
															name={[name, 'description']}
															fieldKey={[
																fieldKey,
																'description',
															]}
															label={I18n.get(
																'Description'
															)}
															rules={[
																() => ({
																	validator(_, value) {
																		return !value
																			? Promise.reject(
																					I18n.get(
																						'Enter a description'
																					)
																			  )
																			: Promise.resolve();
																	},
																}),
															]}
														>
															<Input />
														</FormItem>
													</Col>
													<Col sm={3}>
														<FormItem
															{...restField}
															name={[name, 'torque']}
															fieldKey={[
																fieldKey,
																'torque',
															]}
															label={`${I18n.get(
																'Torque'
															)} (kg)`}
														>
															<InputNumber
																min={1}
																max={999}
																style={{
																	width: '100%',
																}}
															/>
														</FormItem>
													</Col>
													<Col sm={3}>
														<FormItem
															{...restField}
															name={[name, 'frequency']}
															fieldKey={[
																fieldKey,
																'frequency',
															]}
															label={`${I18n.get(
																'Frequency'
															)} (h)`}
														>
															<InputNumber
																min={1}
																max={999}
																style={{
																	width: '100%',
																}}
															/>
														</FormItem>
													</Col>
													<Col
														sm={2}
														style={{
															textAlign: 'center',
														}}
													>
														<Button
															type="link"
															danger
															onClick={() =>
																onDelete(
																	key,
																	remove,
																	array
																)
															}
														>
															<DeleteOutlined />
														</Button>
													</Col>
												</Row>
											)
										)}
										<Row type="flex" justify="center" align="middle">
											<Col
												sm={24}
												style={{
													textAlign: 'center',
													paddingTop: '20px',
												}}
											>
												{!!(fields.length === 0) && (
													<p>
														{I18n.get(
															'Insert a new reaction'
														)}
													</p>
												)}
												<Tooltip
													title={I18n.get('Add new range')}
												>
													<Button
														size="large"
														type="primary"
														shape="circle"
														disabled={!!(fields.length > 5)}
														onClick={() =>
															add({
																torque: 1,
																frequency: 1,
															})
														}
														icon={<PlusOutlined />}
													/>
												</Tooltip>
											</Col>
										</Row>
									</>
								)}
							</Form.List>
						</Col>
						<Col sm={24}>
							<Row>
								<Col>
									<Button
										style={{ margin: '0 8px' }}
										onClick={onPrevious}
									>
										{I18n.get('Previous')}
									</Button>
								</Col>
								<Col>
									<Button
										type="primary"
										onClick={onFinish}
										loading={loading}
									>
										{I18n.get('Next')}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
}
