import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import { Cancel, Confirm, NameInput } from '../styles';

const { useFormInstance } = Form;

type EditWorkstationProps = {
	workstationName: string;
	onWorkstationNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
};

export const EditWorkstation: React.FC<EditWorkstationProps> = ({
	workstationName,
	onWorkstationNameChange,
	onEditing,
}) => {
	const [editingName] = useState(workstationName);
	const {
		updateOrganizationData,
		treatFieldValidation,
		handleIsEditing,
		companyId,
		organizationId,
		lineId,
		workstationId,
		workstationGHE,
	} = useManageOrganization();

	const { setFieldValue, validateFields } = useFormInstance();

	function handleWorkstationNameChange(name: string) {
		onWorkstationNameChange(name);
	}

	function handleCancel() {
		onWorkstationNameChange('');
		handleIsEditing(false);
		onEditing(false);
		setFieldValue('workstation_id', workstationId);
	}

	async function handleUpdateWorkstation(
		event: React.MouseEvent<HTMLElement, MouseEvent>
	) {
		event.stopPropagation();
		event.preventDefault();
		try {
			await validateFields(['edit_workstation_name']);

			updateOrganizationData({
				organization_id: organizationId,
				company_id: companyId,
				line_id: lineId,
				workstation_id: workstationId,
				ghe: workstationGHE,
				name: workstationName,
			}).then(({ id }) => {
				onWorkstationNameChange('');
				handleIsEditing(false);
				onEditing(false);
				setFieldValue('workstation_id', id);
				setFieldValue('edit_workstation_name', '');
			});
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<NameInput
						name="edit_workstation_name"
						label={I18n.get('Edit workstation')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get(
									'Please, enter a valid workstation name'
								),
							},
						]}
					>
						<Input
							style={{
								flex: 'auto',
								borderRadius: '7px',
								border: '2px solid #000',
							}}
							value={workstationName}
							onChange={(e) => handleWorkstationNameChange(e.target.value)}
						/>
					</NameInput>
				</Col>
				<Col span={4}>
					<Row align="bottom" justify="space-between">
						<Cancel span={12}>
							<Button
								disabled={!companyId || !workstationId}
								size="middle"
								type="text"
								onClick={() => handleCancel()}
								icon={<CloseOutlined />}
							/>
						</Cancel>
						<Confirm span={12}>
							<Button
								disabled={
									!companyId ||
									!workstationId ||
									!workstationName ||
									editingName === workstationName
								}
								size="middle"
								type="text"
								onClick={(e) => handleUpdateWorkstation(e)}
								icon={<CheckOutlined />}
							/>
						</Confirm>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
