import React from 'react';
import { Col, Row, Form } from 'antd';

import { I18n } from '@aws-amplify/core';

import { TimeCorrectionTable } from './TimeCorrectionTable';
import { BasicPositionSelect } from './BasicPositionSelect';
import { WeightRangeSelect } from './WeightRangeSelect';
import { SpeedFactorSelect } from './SpeedFactorSelect';
import { PostureSelect } from './PostureSelect';
import { TCMContainer } from './styles';
import { Buttons } from './Buttons';

const { useForm } = Form;

export function TCM() {
	const [form] = useForm();

	const initialValues = {
		basic_position: null,
		posture: null,
		weight_range: null,
		tcm: null,
		speed_factor: null,
		added_time: null,
	};

	const validateMessages = {
		required: I18n.get('Field is required'),
	};

	return (
		<Form
			form={form}
			layout="vertical"
			initialValues={initialValues}
			validateMessages={validateMessages}
			scrollToFirstError
			autoComplete="off"
		>
			<TCMContainer justify="center" gutter={[5, 20]}>
				<Col style={{ textAlign: 'center' }} sm={24}>
					<h2>{I18n.get('TCM Structure')}</h2>
					<label>
						{I18n.get('Enter the data related to TCM and speed factor below')}
					</label>
				</Col>
				<Col sm={12}>
					<Row justify="center" gutter={[10, 20]}>
						<Col span={24}>
							<Row gutter={[10, 20]}>
								<BasicPositionSelect />
								<PostureSelect />
								<WeightRangeSelect />
								<SpeedFactorSelect />
							</Row>
						</Col>
						<Col span={12}>
							<Row justify="center">
								<TimeCorrectionTable />
							</Row>
						</Col>
					</Row>
				</Col>
				<Col sm={24}>
					<Buttons />
				</Col>
			</TCMContainer>
		</Form>
	);
}
