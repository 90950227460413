import React from 'react';
import { Col } from 'antd';
import { Text, Title } from '@/components/Typography';
import * as S from './styled';
interface InfoItemProps {
	title: string;
	value: string;
}

export function InfoItem({ title, value }: Readonly<InfoItemProps>) {
	return (
		<S.CustomRow align={'middle'}>
			<Col>
				<Title style={{ color: '#262626', fontSize: 16 }} level={5}>
					{title}
				</Title>
			</Col>
			<Col>
				<Title style={{ color: '#262626', fontSize: 16 }} level={5}>
					:
				</Title>
			</Col>
			<Col>
				<Text ellipsis style={{ marginLeft: '5px', color: '#262626', fontSize: 16 }}>
					{value}
				</Text>
			</Col>
		</S.CustomRow>
	);
}
