import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { LineSelect } from '@/components/ui/Inputs/LineSelect';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { useLines } from '@/hooks';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

type LineValue = {
	label: string;
	value: string;
};

interface LineProps {
	namePlace: string;
	fontSize?: string;
	isRequired?: boolean;
	currentLine?: LineValue | null;
}

export function Line({ namePlace, fontSize, isRequired = false, currentLine }: LineProps) {
	const { setFieldValue } = useFormInstance();

	const organization_id = useWatch([namePlace, 'organization_id']);
	const company_id = useWatch([namePlace, 'company_id']);
	const sector_id = useWatch([namePlace, 'sector_id']);
	const line_id = useWatch([namePlace, 'line_id']);

	const { isFetching, data } = useLines({ organization_id, company_id, sector_id });

	function handleOnChange(id: string): void {
		setFieldValue([namePlace, 'line_id'], id);
	}

	useEffect(() => {
		if (currentLine?.value) {
			setFieldValue([namePlace, 'line_id'], currentLine.value);
		}
	}, [setFieldValue, namePlace, currentLine?.value]);

	return (
		<Col span={23}>
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[namePlace, 'line_id']}
				rules={[{ required: true, message: I18n.get('Select the line') }]}
				label={<RequiredLabel fontSize={fontSize} title="Line" isRequired={isRequired} />}
			>
				<S.ContainerSelect $borderRadius="8px">
					<LineSelect
						lines={data}
						size="large"
						lineId={currentLine || line_id}
						sectorId={sector_id}
						loading={isFetching}
						disabled={!sector_id || !!currentLine}
						allowCreation={false}
						onChange={handleOnChange}
						fieldName={[namePlace, 'line_id']}
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
}
