import React from 'react';
import type { ColumnsType } from 'antd/lib/table';
import { Button, Col, Grid, Row, Table } from 'antd';

import { MtmReportResult, OperationsFile } from '@/hooks';
import { Clapperboard } from '@/components/ui/icons/Clapperboard';
import { useDownloadFile } from '@/hooks/useFileDownload';
import { Text, Title } from '@/components/Typography';
import * as S from '../../../../components/TableLayout/styled';

interface OperationsFilesProps {
	operations_files?: MtmReportResult['operations_files'];
}

export type TableValues = OperationsFile;

export function OperationsFiles({ operations_files }: Readonly<OperationsFilesProps>) {
	const { mutateAsync: downloadFile } = useDownloadFile();

	const breakpoint = Grid.useBreakpoint();
	const width = breakpoint.xl ? undefined :  `${100 / 4}%`;

	function downloadFileVideo(operation: OperationsFile) {
		const { file_id, original_name, company_id, organization_id } = operation;
		downloadFile({ file: { id: file_id, original_name }, company_id, organization_id });
	}

	const columns: ColumnsType<TableValues> = [
		{
			key: 'name',
			align: 'center',
			dataIndex: 'name',
			width,
			title: <Text>Operation</Text>
		},
		{
			align: 'center',
			responsive: ['lg'],
			key: 'original_name',
			width,
			title: <Text>File</Text>,
			dataIndex: 'original_name'
		},
		{
			align: 'center',
			key: 'duration',
			responsive: ['sm'],
			width,
			dataIndex: 'duration',
			title: <Text>Time of the file (min)</Text>
		},
		{
			align: 'center',
			key: 'download',
			width,
			title: <Text>Download</Text>,
			render: (operation: OperationsFile) => (
				<Button
					type="link"
					onClick={() => downloadFileVideo(operation)}
					icon={<Clapperboard style={{ fontSize: '1.7rem' }} />}
				/>
			)
		}
	];

	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col span={22}>
				<Title level={4} style={{ color: '#262626' }}>
					Operations and files
				</Title>
			</Col>
			<S.Table span={22} $backGroundColor="#F8F8F8" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
				<Table rowKey="id" size={'large'} columns={columns} pagination={false} dataSource={operations_files} />
			</S.Table>
		</Row>
	);
}
