import styled from 'styled-components';
import { Image, Row } from 'antd';

export const RowCustom = styled(Row)`
	padding: 2rem 3rem;
`;

export const ImageStyled = styled(Image)`
	padding-inline: 30px;
	max-height: 70px;
	border-radius: 50%;
	min-height: 100px;
	@media (max-width: 1400px) {
		padding-inline: 16px;
		border-radius: 50%;
		min-height: 70px;
	}
`;

export const HeaderTitle = styled.h1`
	text-align: start;
	font-weight: 500;
	font-size: 20px;
	margin: 0 0 0 2rem;
`;
export const HeaderSubTitle = styled.h2`
	text-align: start;
	font-weight: bold;
	font-size: 20px;
	margin: 0 0 0 2rem;
`;
