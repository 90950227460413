import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { BaseContext, BaseContextOptional } from '@/types';
import { MtmToOperation } from '@/types/entities/MtmToOperation';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

type MtmToOperationResponse = BaseContext & {
	operation_id: string;
};

export type UpdateMtmToOperationDTO = BaseContextOptional & Omit<MtmToOperation, 'created_at' | 'updated_at'>;

const updateMtmToOperation = async (body: UpdateMtmToOperationDTO): Promise<MtmToOperationResponse> => {
	const url = `/mtm/operation/${body.id}`;
	const { data } = await Api.put<MtmToOperationResponse>(url, body);
	return data;
};

export const useUpdateMtmToOperation = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateMtmToOperationDTO) => updateMtmToOperation(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: ({ operation_id, company_id, organization_id }: MtmToOperationResponse) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_MTM_REPORT_RESULT]);
			queryClient.invalidateQueries([
				QUERY_KEYS.GET_MTM_TO_OPERATIONS,
				{ operation_id, company_id, organization_id }
			]);
			message.success(I18n.get('MTM operation updated successfully'));
		}
	});
};
