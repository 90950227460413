import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Radio, Space, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Api from '@/services/api';
import { useIsMounted } from '@/hooks/useIsMounted';
import {
	addPercussion,
	nextCurrentStep,
	previousCurrentStep,
} from '@/redux/checklist/actions';

export function Percussion(props) {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const isMounted = useIsMounted();

	const [loading, setLoading] = useState(false);

	const checklist = useSelector((state) => state.checklist.checklist);
	const percussion = useSelector((state) => state.checklist.percussion);

	const file = useSelector((state) => state.checklist.file);
	const organization = useSelector(
		(state) => state.organization.organization
	);
	const company = useSelector((state) => state.organization.company);

	const onErrors = (err) => {
		const description = err.response?.data?.message || err.message;
		message.error(I18n.get(description));
	};

	const saveData = (body) => {
		const parameters = {
			checklist_id: checklist.id,
			organization_id: organization.id,
			company_id: company.id,
			file_id: file.id,
			// sector_id: 'uuid',
			// workstation_id: 'uuid',
			data: {
				percussion: body,
				step: 1,
			},
		};

		Api.post('/report/checklist/percussion', parameters)
			.then(isMounted() && setLoading(true))
			.then((res) => {
				if (isMounted()) {
					setLoading(false);
					dispatch(nextCurrentStep());
				}
			})
			.catch(onErrors)
			.finally(() => isMounted() && setLoading(false));
	};

	const onFinish = () => {
		form.validateFields()
			.then((values) => {
				dispatch(addPercussion(values.percussion));
				return values.percussion;
			})
			.then(saveData)
			.catch(() => {});
	};

	const onPrevious = () => {
		dispatch(previousCurrentStep());
	};

	return (
		<Form form={form} layout="vertical" initialValues={{ percussion }}>
			<Row style={{ paddingTop: '20px' }} gutter={[5, 20]}>
				<Col sm={24}>
					<h2>{I18n.get('Percussion')}</h2>
				</Col>
				<Col sm={24}>
					<p>
						{I18n.get(
							'Sledgehammer and/or taps are hits from hard hits (except hit adjustments)'
						)}
					</p>
				</Col>
				<Col sm={16}>
					<Row gutter={[10, 20]}>
						<Col sm={24} style={{ minHeight: '250px' }}>
							<Form.Item name="percussion">
								<Radio.Group>
									<Space direction="vertical">
										<Radio value={0}>
											{I18n.get('Do not use')}
										</Radio>
										<Radio value={1}>
											{I18n.get('Light - Quiet')}
										</Radio>
										<Radio value={2}>
											{I18n.get(
												'Medium - Some effort is perceived (defined)'
											)}
										</Radio>
										<Radio value={3}>
											{I18n.get(
												'Heavy - Clear effort: no change in facial expression'
											)}
										</Radio>
										<Radio value={4}>
											{I18n.get(
												'Very heavy - Sharp effort: changes facial expression'
											)}
										</Radio>
										<Radio value={5}>
											{I18n.get(
												'Next. maximum - Use of trunk and limbs to generate greater force'
											)}
										</Radio>
									</Space>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Row>
								<Col>
									<Button
										style={{ margin: '0 8px' }}
										onClick={onPrevious}
									>
										{I18n.get('Previous')}
									</Button>
								</Col>
								<Col>
									<Button
										type="primary"
										onClick={onFinish}
										loading={loading}
									>
										{I18n.get('Next')}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
}
