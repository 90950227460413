import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { PlusCircleFilled } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '@/components/ui/Button';
import { useGetRotationReportFiles } from '@/hooks/useGetRotationReportFiles';
import { useGetRotationReports } from '@/hooks/useGetRotationReports';
import { PaginationCustom } from '@/components/ui/Pagination';
import { useApplicationContext } from '@/context/application';
import { FileModal } from './components/FileModal';
import { GetRotationReportsDTO } from '@/types';
import { Title } from '@/components/Typography';
import { Filter } from './Filter';
import { List } from './List';

export function RotationReportList() {
	const history = useHistory();
	const { search } = useLocation();
	const { organization, company } = useApplicationContext();

	const searchParams = new URLSearchParams(search);
	const queryParams = convertURLParamToObject(searchParams);
	const [page, setPage] = useState<number>(1);
	const [maxReports, setMaxReports] = useState<number>(10);
	const [filter, setFilter] = useState<GetRotationReportsDTO>(queryParams);
	const [reportModalFiles, setReportModalFiles] = useState<string>('');

	const organization_id = filter?.organization_id ?? organization?.id;
	const company_id = filter?.company_id ?? company?.id;

	const { isFetching, isLoading, data } = useGetRotationReports({
		...filter,
		company_id,
		offset: (page - 1) * maxReports,
		organization_id,
		limit: maxReports
	});

	const { data: files, isLoading: isLoadingFiles } = useGetRotationReportFiles({
		organization_id: organization?.id,
		company_id: company?.id,
		id: reportModalFiles
	});

	function handlePageChange(page: number) {
		setPage(page);
	}

	function handleMaxReportsChange(reports: number) {
		setMaxReports(reports);
	}

	function handleFilterClick(filterData: GetRotationReportsDTO) {
		setFilter(filterData);
	}

	function convertURLParamToObject(searchParams: URLSearchParams) {
		const url_params: GetRotationReportsDTO = Object.fromEntries(searchParams.entries()) as any;
		return url_params;
	}

	function handleOnCreate(): void {
		history.push('/ehs/rotation/report');
	}

	function handleOnCloseFilesByReports(): void {
		setReportModalFiles('');
	}

	function handleOnShowFilesByReports(id: string): void {
		setReportModalFiles(id);
	}

	return (
		<Row gutter={[0, 10]} justify="center">
			<Col span={24}>
				<Row justify="space-between" align="middle">
					<Col>
						<Title level={4} style={{ margin: 0 }}>
							Rotation
						</Title>
					</Col>
					<Col>
						<Button type="primary" icon={<PlusCircleFilled />} onClick={handleOnCreate}>
							{I18n.get('New Report')}
						</Button>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Filter loading={isFetching} initialValues={queryParams} onClick={handleFilterClick} />
			</Col>
			<Col span={24}>
				<List
					data={data}
					onShowFiles={handleOnShowFilesByReports}
					isLoading={isLoading || isFetching}
					company_id={company_id}
					organization_id={organization_id}
				/>
			</Col>
			<Col span={24}>
				<PaginationCustom
					page={page}
					loading={isLoading}
					total={data?.count}
					maxPage={maxReports}
					setPage={handlePageChange}
					setMaxPage={handleMaxReportsChange}
				/>
			</Col>
			<FileModal
				files={files ?? []}
				open={!!reportModalFiles}
				isLoading={isLoadingFiles}
				onClose={handleOnCloseFilesByReports}
			/>
		</Row>
	);
}
