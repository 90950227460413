import React from 'react';
import { Row, Col, Form, Skeleton } from 'antd';

import { useMtmReportOperations } from '@/hooks/useMtmReportOperations';
import { MtmReportOperations, useUpdateMtmReportOperations } from '@/hooks';
import { Paragraph, Title } from '@/components/Typography';
import { useMtmReportContext } from '../../../context';
import { CollapseProvider } from '../../components/Collapse/context';
import { CollapseCustom } from '../../components/Collapse';
import { FooterButtons } from '../../components/FooterButtons';
import { OperationCard } from './OperationCard';

interface GenericStepProps {
	title: string;
	description: string;
}

type FormValues = {
	operation_product: {
		[key: string]: {
			workstation_id: string;
			products: string[];
		};
	};
};

const { useFormInstance } = Form;

export function OperationProduct({ title, description }: Readonly<GenericStepProps>) {
	const { getFieldValue, getFieldsValue } = useFormInstance();
	const { mtmReport, currentStep } = useMtmReportContext();
	const { mutateAsync: updateOperations } = useUpdateMtmReportOperations();

	const organization_id = getFieldValue(['informations', 'organization_id']);
	const company_id = getFieldValue(['informations', 'company_id']);

	const { data: operations, isLoading: operationLoading } = useMtmReportOperations({
		company_id,
		organization_id,
		id: mtmReport?.id
	});

	if (operationLoading) {
		return (
			<>
				<Skeleton active />
			</>
		);
	}

	async function handleOnNextStep() {
		const { operation_product }: FormValues = getFieldsValue();

		const payload: MtmReportOperations[] = Object.keys(operation_product).map((operation_id) => {
			return {
				id: operation_id,
				products: operation_product[operation_id].products,
				workstation_id: operation_product[operation_id].workstation_id
			};
		});

		await updateOperations({
			id: mtmReport?.id as string,
			company_id,
			organization_id,
			current_step: currentStep + 1,
			operations: payload
		});
	}

	return (
		<CollapseProvider>
			<Row gutter={[0, 30]} justify="center">
				<Col offset={1} span={23}>
					<Row>
						<Col span={24}>
							<Title level={3}>{title}</Title>
						</Col>
						<Col>
							<Paragraph>{description}</Paragraph>
						</Col>
					</Row>
				</Col>
				<Col offset={1} span={23}>
					<Row gutter={[0, 20]}>
						<Col span={23}>
							<CollapseCustom operations={operations}>
								<OperationCard />
							</CollapseCustom>
						</Col>
					</Row>
				</Col>
				<Col span={23}>
					<FooterButtons onNextStep={handleOnNextStep} />
				</Col>
			</Row>
		</CollapseProvider>
	);
}
