import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import Api from '@/services/api';
import type { Company, CreateCompanyDTO } from '@/types';

const createCompany = async (body: CreateCompanyDTO): Promise<Company> => {
	const url = `/company/create`;
	const { data } = await Api.post<Company>(url, body);
	return data;
};

export const useCreateCompany = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: CreateCompanyDTO }) => createCompany(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['companies']);
			queryClient.invalidateQueries(['companies_list']);
			message.success(I18n.get('Company created successfully'));
		}
	});
};
