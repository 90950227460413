import { useQuery } from '@tanstack/react-query';

import { ChecklistEIService } from './services';
import {
	GetTCMRequest,
	GetFileRequest,
	GetLinesRequest,
	GetSectorsRequest,
	GetPosturesRequest,
	GetAddedTimeRequest,
	GetWeightRangesRequest,
	GetWorkstationsRequest,
	GetSpeedFactorsRequest,
	GetBasicPositionsRequest,
} from './types/request';
import {
	GetTCMDTO,
	GetFileDTO,
	GetLinesDTO,
	GetSectorsDTO,
	GetPosturesDTO,
	GetAddedTimeDTO,
	GetWeightRangesDTO,
	GetWorkstationsDTO,
	GetSpeedFactorsDTO,
	GetBasicPositionsDTO,
} from './types/response';

const checklistEIService = new ChecklistEIService();

function useGetFile({ company_id, file_id, organization_id }: GetFileRequest) {
	const file = useQuery(['checklist_ei_file', company_id, organization_id], () =>
		checklistEIService.getFile({ company_id, file_id, organization_id })
	);
	return {
		...file,
		data: file.data || ({} as GetFileDTO),
	};
}

function useGetSectors({ company_id, organization_id }: GetSectorsRequest) {
	const sectors = useQuery(
		['checklist_ei_sectors', company_id, organization_id],
		() => checklistEIService.getSectors({ company_id, organization_id }),
		{
			enabled: !!company_id && !!organization_id,
			retry: 1,
		}
	);
	return {
		...sectors,
		data: sectors.data || ([] as GetSectorsDTO[]),
	};
}

function useGetLines({ company_id, sector_id, organization_id }: GetLinesRequest) {
	const lines = useQuery(
		['checklist_ei_lines', sector_id],
		() => checklistEIService.getLines({ company_id, sector_id, organization_id }),
		{
			retry: 1,
			enabled: !!sector_id,
		}
	);
	return {
		...lines,
		data: lines.data || ([] as GetLinesDTO[]),
	};
}

function useGetWorkstations({
	company_id,
	line_id,
	organization_id,
}: GetWorkstationsRequest) {
	const workstations = useQuery(
		['checklist_ei_workstations', line_id],
		() =>
			checklistEIService.getWorkstations({ company_id, line_id, organization_id }),
		{
			retry: 1,
			enabled: !!line_id,
		}
	);
	return {
		...workstations,
		data: workstations.data || ([] as GetWorkstationsDTO[]),
	};
}

function useGetBasicPositions({
	company_id,
	organization_id,
	currentStep,
}: GetBasicPositionsRequest) {
	const basicPostitions = useQuery(
		['checklist_ei_basic_positions', company_id, organization_id, currentStep],
		() => checklistEIService.getBasicPositions({ company_id, organization_id }),
		{
			retry: 1,
			enabled: !!company_id && !!organization_id && currentStep === 1,
		}
	);
	return {
		...basicPostitions,
		data: basicPostitions.data || ([] as GetBasicPositionsDTO[]),
	};
}

function useGetPostures({
	company_id,
	organization_id,
	basic_position_id,
}: GetPosturesRequest) {
	const postures = useQuery(
		['checklist_ei_postures', company_id, organization_id, basic_position_id],
		() =>
			checklistEIService.getPostures({
				company_id,
				organization_id,
				basic_position_id,
			}),
		{
			retry: 1,
			enabled: !!basic_position_id,
		}
	);
	return {
		...postures,
		data: postures.data || ([] as GetPosturesDTO[]),
	};
}

function useGetWeightRanges({
	company_id,
	organization_id,
	basic_position_id,
	posture_id,
}: GetWeightRangesRequest) {
	const weightRanges = useQuery(
		[
			'checklist_ei_weight_ranges',
			company_id,
			organization_id,
			basic_position_id,
			posture_id,
		],
		() =>
			checklistEIService.getWeightRanges({
				company_id,
				organization_id,
				basic_position_id,
				posture_id,
			}),
		{
			retry: 1,
			enabled: !!basic_position_id && !!posture_id,
		}
	);
	return {
		...weightRanges,
		data: weightRanges.data || ([] as GetWeightRangesDTO[]),
	};
}

function useGetSpeedFactors({
	company_id,
	organization_id,
	currentStep,
}: GetSpeedFactorsRequest) {
	const speedFactors = useQuery(
		['checklist_ei_speed_factors', company_id, organization_id, currentStep],
		() =>
			checklistEIService.getSpeedFactors({
				company_id,
				organization_id,
			}),
		{
			retry: 1,
			enabled: currentStep === 1,
		}
	);
	return {
		...speedFactors,
		data: speedFactors.data || ([] as GetSpeedFactorsDTO[]),
	};
}

function useGetTCM({
	organization_id,
	company_id,
	basic_position,
	posture,
	weight_range,
}: GetTCMRequest) {
	const tcm = useQuery(
		[
			'checklist_ei_tcm',
			company_id,
			organization_id,
			basic_position,
			posture,
			weight_range,
		],
		() =>
			checklistEIService.getTCM({
				company_id,
				organization_id,
				basic_position,
				posture,
				weight_range,
			}),
		{
			retry: 1,
			enabled: !!basic_position && !!posture && !!weight_range,
		}
	);
	return {
		...tcm,
		data: tcm.data || ({} as GetTCMDTO),
	};
}

function useGetAddedTime({
	organization_id,
	company_id,
	speed_factor,
	tcm,
	basic_position_id,
	posture_id,
	weight_range_id,
}: GetAddedTimeRequest) {
	const addedTime = useQuery(
		[
			'checklist_ei_addedTime',
			company_id,
			organization_id,
			speed_factor,
			tcm,
			basic_position_id,
			posture_id,
			weight_range_id,
		],
		() =>
			checklistEIService.getAddedTime({
				company_id,
				organization_id,
				speed_factor,
				tcm,
			}),
		{
			retry: 1,
			enabled: !!speed_factor && tcm >= 0,
		}
	);
	return {
		...addedTime,
		data: addedTime.data || ({} as GetAddedTimeDTO),
	};
}

export {
	useGetTCM,
	useGetFile,
	useGetLines,
	useGetSectors,
	useGetPostures,
	useGetAddedTime,
	useGetSpeedFactors,
	useGetWorkstations,
	useGetWeightRanges,
	useGetBasicPositions,
};
