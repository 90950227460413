import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Modal, Input, Form, Button, message } from 'antd';
import Api from '@/services/api';

export function ChangePassword(props) {
	const { onControlVisible, onClose } = props;

	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	function onChangePassword({ current_password, password }) {
		const url = '/user/change_password';
		const body = { current_password, password };
		Api.put(url, body)
			.then(setLoading(true))
			.then(({ data }) => {
				const { message: result } = data;
				message.success(I18n.get(result));
				onClose();
			})
			.catch((error) => {
				const messageError =
					error.response?.data?.message ||
					'Unable to change your password';
				message.error(I18n.get(messageError));
			})
			.finally(() => setLoading(false));
	}

	function onSubmit() {
		form.validateFields()
			.then(onChangePassword)
			.catch(() => {});
	}

	return (
		<Modal
			visible={onControlVisible}
			onCancel={onClose}
			footer={[
				<Button key="cancel" onClick={onClose}>
					{I18n.get('Cancel')}
				</Button>,
				<Button
					key="save"
					type="primary"
					onClick={onSubmit}
					loading={loading}
				>
					{I18n.get('Save')}
				</Button>,
			]}
		>
			<Form form={form} layout="vertical">
				<h2 style={{ paddingBottom: '10px' }}>
					{I18n.get('Change password')}
				</h2>
				<Form.Item
					label={I18n.get('Current password')}
					name="current_password"
					hasFeedback
					rules={[
						{
							required: true,
							message: I18n.get('Enter your current password'),
						},
					]}
				>
					<Input type="password" />
				</Form.Item>
				<Form.Item
					label={I18n.get('New password')}
					name="password"
					hasFeedback
					rules={[
						{
							required: true,
							message: I18n.get('Enter your new password'),
						},
						() => ({
							validator(rule, value) {
								if (value?.length <= 5) {
									return Promise.reject(
										I18n.get(
											'Password must be at least 6 characters long'
										)
									);
								}
								return Promise.resolve();
							},
						}),
					]}
				>
					<Input type="password" />
				</Form.Item>
				<Form.Item
					label={I18n.get('Confirm password')}
					name="password_confirm"
					dependencies={['password']}
					hasFeedback
					rules={[
						{
							required: true,
							message: I18n.get('Repeat your new password'),
						},
						({ getFieldValue }) => ({
							validator(rule, value) {
								if (
									!value ||
									getFieldValue('password') === value
								) {
									return Promise.resolve();
								}
								return Promise.reject(
									I18n.get('The two passwords don\'t match')
								);
							},
						}),
					]}
				>
					<Input type="password" />
				</Form.Item>
			</Form>
		</Modal>
	);
}
