import React from 'react';
import { Form, InputNumber } from 'antd';
import { I18n } from '@aws-amplify/core';

import { getDecimalSeparator } from '@/utils/getDecimalSeparator';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import * as S from '../styles';

interface SequenceProps {
	width?: string;
	fontSize?: string;
	namePlace: string[];
	isRequired?: boolean;
}

export function Sequence({ fontSize, namePlace, width = '100%', isRequired = false }: Readonly<SequenceProps>) {
	return (
		<S.ContainerInputNumber width={width} $borderRadius="8px">
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[...namePlace, 'sequence']}
				rules={[{ required: true, message: I18n.get('Set the sequence') }]}
				label={<RequiredLabel bold fontSize={fontSize} title="Sequence" isRequired={isRequired} />}
			>
				<InputNumber
					min={0}
					keyboard
					step={1}
					max={1000}
					size="large"
					placeholder={'0'}
					decimalSeparator={getDecimalSeparator()}
				/>
			</Form.Item>
		</S.ContainerInputNumber>
	);
}
