import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

export type GetRotationReportBodyPartExposureDTO = {
	organization_id?: string;
	body_part_name: string;
	company_id?: string;
	id?: string;
};

export type RotationReportBodyPartExposureResponse = {
	id: string;
	body_part: string;
	name: string;
	scores: Score[];
};

type Score = {
	id: string;
	percentage: number;
	color: string;
	text: string;
};

async function getRotationReportBodyPartExposure(
	params: GetRotationReportBodyPartExposureDTO
): Promise<RotationReportBodyPartExposureResponse> {
	const url = `/rotation/report/${params.id}/cycle/exposure`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useGetRotationReportBodyPartExposure = (params: GetRotationReportBodyPartExposureDTO) => {
	const { organization_id, company_id, id, body_part_name } = params;
	return useQuery(
		[QUERY_KEYS.GET_ROTATION_REPORT_BODY_PART_EXPOSURE, params],
		() => getRotationReportBodyPartExposure(params),
		{
			enabled: !!organization_id && !!company_id && !!id && !!body_part_name
		}
	);
};
