import styled from 'styled-components';
import { Row } from 'antd';

export const ErrorGettingData = styled(Row)`
	min-height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const GettingData = styled(Row)`
	min-height: 300px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	position: relative;
`;

export const TreemapRow = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
	gap: 5px;
`;
