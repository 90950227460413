import React from 'react';
import { Col, Form, Select } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useChecklistEIContext } from '../context';

const { useFormInstance } = Form;

export const WeightRangeSelect = () => {
	const { resetFields } = useFormInstance();

	const { postures, weightRanges, basicPositions, postureId, basicPositionId } =
		useChecklistEIContext();
	const {
		handleSelectWeightRange,
		mapDataToDropdown,
		filterOption,
		handlePostureOrder,
		handleWeightRangeOrder,
		handleBasicPositionOrder,
	} = useChecklistEIContext();

	function handleWeightRangeSelect(value: string) {
		handleSelectWeightRange(value);
		const posture = postures.data.find((posture) => posture.id === postureId);
		if (posture) {
			handlePostureOrder(posture.order);
		}
		const basicPosition = basicPositions.data.find(
			(basicPosition) => basicPosition.id === basicPositionId
		);
		if (basicPosition) {
			handleBasicPositionOrder(basicPosition.order);
		}
		const weightRange = weightRanges.data.find(
			(weightRange) => weightRange.id === value
		);
		if (weightRange) {
			handleWeightRangeOrder(weightRange.order);
		}
	}

	function handleClear() {
		resetFields(['tcm']);
		handleSelectWeightRange('');
		handlePostureOrder('');
		handleBasicPositionOrder(0);
		handleWeightRangeOrder(0);
	}

	const weightRangeOptions = mapDataToDropdown(weightRanges.data);

	return (
		<Col sm={12}>
			<Form.Item
				name="weight_range"
				label={I18n.get('Weight Range')}
				rules={[{ required: true }]}
			>
				<Select
					showSearch
					allowClear
					onClear={handleClear}
					onChange={(value: string) => handleWeightRangeSelect(value)}
					placeholder={I18n.get('Select a weight range')}
					options={weightRangeOptions}
					filterOption={(input, option) => filterOption(option, input)}
					loading={weightRanges.gettingWeightRanges}
					disabled={
						weightRanges.data.length === 0 ||
						weightRanges.errorGettingWeightRanges
					}
				/>
			</Form.Item>
		</Col>
	);
};
