import React, { useEffect } from 'react';
import { Col, DatePicker, Form, Row } from 'antd';
import { Text } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

const { useFormInstance, useWatch } = Form;

export const Period: React.FC = () => {
	const { validateFields } = useFormInstance();
	const startDate = useWatch(['filter', 'start_date']);
	const endDate = useWatch(['filter', 'end_date']);

	useEffect(() => {
		if (startDate || endDate) {
			validatedDateInputs();
		}
	}, [startDate, endDate]);

	async function validatedDateInputs() {
		await validateFields();
	}

	return (
		<Col xs={23}>
			<Row gutter={[20, 0]} align="middle">
				<Col span={10}>
					<Form.Item
						label={<Text style={{ fontSize: '16px' }}>Period</Text>}
						labelCol={{ span: 24 }}
						name={['filter', 'start_date']}
						rules={[
							() => ({
								validator() {
									if (startDate && !endDate) {
										return Promise.reject(Error('Select the end date'));
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<DatePicker
							allowClear
							size="large"
							format={'L'}
							placeholder={I18n.get('Start date')}
							style={{ width: '100%' }}
						/>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Row justify="center" style={{ marginTop: '12px' }}>
						<Col>
							<Text style={{ fontSize: '16px', textAlign: 'center' }}>to</Text>
						</Col>
					</Row>
				</Col>

				<Col span={10}>
					<Form.Item
						label={<Text></Text>}
						labelCol={{ span: 24 }}
						name={['filter', 'end_date']}
						rules={[
							() => ({
								validator() {
									if (moment(endDate).isBefore(startDate) && endDate) {
										return Promise.reject(Error('Date cannot larger than start date'));
									} else if (!startDate && endDate) {
										return Promise.reject(Error('Select the start date'));
									}

									return Promise.resolve();
								}
							})
						]}
					>
						<DatePicker
							placeholder={I18n.get('End date')}
							allowClear
							size="large"
							format={'L'}
							style={{ width: '100%' }}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
};
