import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

export const PieLegend = () => (
	<Row gutter={[24, 10]} type="flex" justify="center">
		<Col sm={4} style={{ textAlign: 'center' }}>
			<div style={{ border: '1px solid rgba(75, 192, 86, 1)' }}>
				{I18n.get('Acceptable Risk')}
			</div>
			<div>{I18n.get('Corrective action not required')}</div>
		</Col>
		<Col sm={4} style={{ textAlign: 'center' }}>
			<div style={{ border: '1px solid rgba(255, 206, 86, 1)' }}>
				{I18n.get('Low Risk')}
			</div>
			<div>{I18n.get('Corrective action as soon as possible')}</div>
		</Col>
		<Col sm={4} style={{ textAlign: 'center' }}>
			<div style={{ border: '1px solid rgba(255, 40, 72, 1)' }}>
				{I18n.get('Medium Risk')}
			</div>
			<div>{I18n.get('High priority corrective action')}</div>
		</Col>
		<Col sm={4} style={{ textAlign: 'center' }}>
			<div style={{ border: '1px solid rgba(153, 102, 255, 1)' }}>
				{I18n.get('High Risk')}
			</div>
			<div>{I18n.get('Immediate corrective action')}</div>
		</Col>
	</Row>
);
