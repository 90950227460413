import React, { ReactNode } from 'react';
import { Button, Col, Divider, Form, Input, Modal, Row, Select } from 'antd';

import { I18n } from '@aws-amplify/core';
import {
	DeleteOutlined,
	EditOutlined,
	ExclamationCircleOutlined,
} from '@ant-design/icons';

import { useManageOrganization } from '../../../context';

import { Create, Delete, Edit, SelectId } from '../styles';
import { DeleteRequest, LineDTO } from '../../../types';

const { useFormInstance } = Form;

type OptionType = {
	label: string;
	value: string;
};

type SelectLineProps = {
	lines: LineDTO[];
	lineName: string;
	onLineNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
};

export const SelectLine: React.FC<SelectLineProps> = ({
	lines,
	lineName,
	onLineNameChange,
	onEditing,
}) => {
	const { resetFields, setFieldValue } = useFormInstance();

	const {
		organizationId,
		companyId,
		sectorId,
		lineId,
		updatingOrganizationData,
		creatingOrganizationData,
		isEditing,
		handleLineId,
		handleLine,
		deleteOrganizationData,
		createOrganizationData,
		handleIsEditing,
	} = useManageOrganization();

	async function handleCreateLine() {
		const created = await createOrganizationData({
			organization_id: organizationId,
			company_id: companyId,
			sector_id: sectorId,
			line_name: lineName,
		});

		onLineNameChange('');
		handleLine(created.id, resetFields, setFieldValue);
	}

	function handleEditLine(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();

		const editingLine = lines.find((line) => line.id === lineId);
		if (editingLine) {
			setFieldValue('edit_line_name', editingLine.name);
			onLineNameChange(editingLine.name);
			handleIsEditing(true);
			onEditing(true);
		}
	}

	async function handleDeleteLine(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();

		const body: DeleteRequest = {
			organization_id: organizationId,
			company_id: companyId,
			line_id: lineId,
		};

		const content = I18n.get(
			`All data will be deleted, along with their respective uploads, reports and contents. Do you wish to proceed?`
		);

		Modal.confirm({
			title: I18n.get('Warning!'),
			icon: <ExclamationCircleOutlined />,
			content: content,
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: async () => {
				deleteOrganizationData(body).then(() => {
					onLineNameChange('');
					handleLineId('');
					resetFields(['line_id']);
				});
			},
		});
	}

	function filterLine(option: OptionType | undefined, input: string): boolean {
		return (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function handleSelectLine(id: string) {
		handleLine(id, resetFields, setFieldValue);
	}

	function manageLineDropdown(menu: ReactNode) {
		return (
			<Col span={24}>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<Create>
					<Form.Item name="create_line_name" style={{ margin: '0' }}>
						<Input
							style={{
								flex: 'auto',
								borderRadius: '7px',
							}}
							value={lineName}
							onChange={(e) => onLineNameChange(e.target.value)}
						/>
					</Form.Item>
					<Button
						onClick={handleCreateLine}
						type="link"
						loading={creatingOrganizationData}
						disabled={!lineName || lineName.length < 3}
					>
						{I18n.get('Create production line')}
					</Button>
				</Create>
			</Col>
		);
	}

	const linesList = lines.map(({ id, name }) => ({
		label: name,
		value: id,
	}));

	const actions = (
		<Col span={4}>
			<Row align="bottom" justify="space-between">
				<Edit span={12}>
					<Button
						disabled={!lineId || isEditing}
						size="middle"
						type="text"
						onClick={(e) => handleEditLine(e)}
						icon={<EditOutlined />}
					/>
				</Edit>
				<Delete span={12}>
					<Button
						disabled={!lineId || isEditing}
						size="middle"
						type="text"
						onClick={(e) => handleDeleteLine(e)}
						icon={<DeleteOutlined />}
					/>
				</Delete>
			</Row>
		</Col>
	);

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<SelectId
						selected={!!lineId || isEditing}
						name="line_id"
						label={I18n.get('Select a production line')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please, select a production line'),
							},
						]}
					>
						<Select
							showSearch
							allowClear
							onClear={() => handleLineId('')}
							options={linesList}
							loading={updatingOrganizationData}
							filterOption={(input, option) => filterLine(option, input)}
							onChange={(value: string) => handleSelectLine(value)}
							placeholder={I18n.get('Select a production line')}
							dropdownRender={(menu: ReactNode) => manageLineDropdown(menu)}
						/>
					</SelectId>
				</Col>
				{actions}
			</Row>
		</Col>
	);
};
