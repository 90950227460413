import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useMtmReportContext } from '@/components/views/MtmReport/context';
import { useUpdateMtmReportStep } from '@/hooks';
import { useSelectFilesContext } from '../../context';
import * as S from '../../../../components/styles';

export function NextButton() {
	const { handleValidation } = useSelectFilesContext();
	const { mtmReport, basicParams, currentStep } = useMtmReportContext();
	const { mutateAsync: updateStep, isLoading } = useUpdateMtmReportStep();

	async function handleNext() {
		if (mtmReport?.id) {
			await updateStep({
				id: mtmReport.id,
				current_step: currentStep + 1,
				...basicParams
			});
		}

		await handleValidation();
	}

	return (
		<Row justify="center" align="middle">
			<Col>
				<S.CustomButton htmlType="submit" size="large" type="primary" onClick={handleNext} loading={isLoading}>
					{I18n.get('Next')}
				</S.CustomButton>
			</Col>
		</Row>
	);
}
