import React from 'react';
import { useParams } from 'react-router-dom';

import type { RotationReportParams, RotationReportSearchParams } from './context/types';
import { useGetRotationReport } from '@/hooks/useGetRotationReport';
import { useSearchParams } from '@/hooks/useSearchParams';
import { useApplicationContext } from '@/context/application';
import { RotationReportProvider } from './context';
import { LoadingSkeleton } from './Skeleton';
import { Report } from './Report';

export function RotationReport() {
	const { id } = useParams<RotationReportParams>();
	const { organization, company } = useApplicationContext();
	const { organization_id = organization?.id, company_id = company?.id } =
		useSearchParams<RotationReportSearchParams>();

	const basicParams = {
		organization_id: organization_id as string,
		company_id: company_id as string
	};

	const { isLoading, data: rotationReport } = useGetRotationReport({
		...basicParams,
		id
	});

	if (isLoading && id) {
		return <LoadingSkeleton />;
	}

	return (
		<RotationReportProvider basicParams={basicParams} rotationReport={rotationReport}>
			<Report />
		</RotationReportProvider>
	);
}
