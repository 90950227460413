import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Doughnut } from 'react-chartjs-2';

type Score = {
	id: string;
	level?: string;
	color: string;
	percentage: number;
	text: string;
};

interface ActivityChartProps {
	title: string;
	data: Score[];
	redraw?: boolean;
}

export function ActivityChart({ title, data, redraw }: ActivityChartProps) {
	const options = {
		responsive: true,
		cutout: '60%',
		plugins: {
			title: {
				display: false
			},
			legend: {
				display: false
			}
		}
	};

	const items = data.filter((item) => item.percentage > 0);
	const colors = items.map((item) => item.color);

	return (
		<div style={{ maxHeight: 210 }}>
			<Doughnut
				title={I18n.get(title)}
				height={210}
				redraw={redraw}
				options={{
					...options,
					maintainAspectRatio: false,
					plugins: {
						...options.plugins,
						datalabels: {
							display: (c) => {
								const data = Number(c.dataset.data[c.dataIndex]);
								return data > 5;
							},
							color: '#fff',
							formatter: (value) =>
								Number(value / 100).toLocaleString(navigator.language, {
									minimumFractionDigits: 0,
									maximumFractionDigits: 0,
									style: 'percent'
								}),
							font: {
								weight: 'bold',
								size: 16
							}
						}
					}
				}}
				plugins={[
					{
						id: 'centered_title_plugin',
						beforeDraw: (chart) => {
							const ctx = chart.ctx;
							const xCoor = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
							const yCoor = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
							ctx.fillStyle = '#262626';
							ctx.font =
								"600 22px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'";
							ctx.textAlign = 'center';
							ctx.textBaseline = 'middle';
							const texts = (chart?.data?.datasets?.[0]?.label ?? '').split(' ');
							const lineHeight = 26;
							const margin = (texts.length - 1) * lineHeight * -0.5;
							texts.forEach((text, i) => {
								ctx.fillText(text, xCoor, yCoor + lineHeight * i + margin);
							});
							ctx.save();
						}
					}
				]}
				data={{
					labels: items?.map((item) => I18n.get(item.text)),
					datasets: [
						{
							label: I18n.get(title),
							backgroundColor: colors,
							data: items?.map((item) => item.percentage)
						}
					]
				}}
			/>
		</div>
	);
}
