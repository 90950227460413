import { Col } from 'antd';
import styled from 'styled-components';

type ICard = {
	minHeight?: number;
};

export const TitleChart = styled.h1`
	font-weight: bold;
	padding-bottom: 1%;
`;

export const Card = styled(Col)<ICard>`
	padding: 15px;
	border-radius: 5px;
	background-color: white;
	box-shadow: 1px 1px 1px #888888;

	/* ${({ minHeight }) => minHeight && `min-height: ${minHeight}vw;`} */
`;
