import React from 'react';
import { Button } from 'antd';
import { I18n } from '@aws-amplify/core';
import styled from 'styled-components';

const Container = styled.div`
	top: 50%;
	left: 50%;
	text-align: center;
	position: absolute;
	transform: translate(-50%, -50%);
`;

interface IProps {
	message: string;
	onClick(): any;
}

export const Error: React.FC<IProps> = ({ message, onClick }) => (
	<Container>
		<p>{message}</p>
		<Button type="link" danger onClick={onClick}>
			{I18n.get('Try again')}
		</Button>
	</Container>
);
