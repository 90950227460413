import { EnvironmentPath, EnvironmentPathParameters } from '@/types';

const config: EnvironmentPath = {
	production: {
		path: 'cnhi',
		api: {
			url: 'https://api.kinebot.com.br/cnhi',
			ws: 'https://api.kinebot.com.br'
		}
	},
	homolog: {
		path: 'cnhi',
		api: {
			url: 'https://api-homolog.kinebot.com.br/cnhi',
			ws: 'https://api-homolog.kinebot.com.br'
		}
	},
	development: {
		path: 'cnhi',
		api: {
			url: 'http://localhost:4000/cnhi',
			ws: 'http://localhost:4000'
		}
	}
};

export default config[process.env.REACT_APP_NODE_ENV] as EnvironmentPathParameters;
