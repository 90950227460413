import { FilterDTO } from './types';
import Api from '../../../services/api';

function onMountingURLParams(payload: any) {
	const {
		organizationId,
		companyId,
		sectorId,
		lineId,
		workstationId,
		pcd,
		gender,
		restrict,
		groupBy,
		complaint,
		globalGrade,
		medical_certificate,
	} = payload;

	const params = new URLSearchParams();

	params.append('organization_id', organizationId);
	params.append('is_active', Boolean(true).toString());

	if (companyId) {
		params.append('company_id', companyId);
	}

	if (sectorId) {
		params.append('sector_id', sectorId);
	}

	if (lineId) {
		params.append('line_id', lineId);
	}

	if (workstationId) {
		params.append('workstation_id', workstationId);
	}

	if (typeof pcd === 'boolean') {
		params.append('pcd', Boolean(true).toString());
	}

	if (gender) {
		params.append('gender', gender);
	}

	if (restrict) {
		params.append('restrict', restrict);
	}

	if (groupBy) {
		params.append('group_by', groupBy);
	}

	if (typeof complaint === 'boolean') {
		params.append('complaint', Boolean(true).toString());
	}

	if (globalGrade) {
		params.append('global_grade', globalGrade);
	}

	if (typeof medical_certificate === 'boolean') {
		params.append('medical_certificate', Boolean(true).toString());
	}

	return params;
}

export async function getBusinessUnit(payload: FilterDTO) {
	let url = `/appointment/ergonomic-quote-by-segment?`;
	const params = onMountingURLParams({
		...payload,
		groupBy: 'company',
	});
	const { data } = await Api.get(url + params);
	return data;
}

export async function getCostCenter(payload: FilterDTO) {
	let url = `/appointment/ergonomic-quote-by-segment?`;
	const params = onMountingURLParams({
		...payload,
		groupBy: 'sector',
	});
	const { data } = await Api.get(url + params);
	return data;
}

export async function getErgonomicGrade(payload: FilterDTO) {
	const url = `/appointment/ergonomic-quote?`;
	const params = onMountingURLParams({
		...payload,
		groupBy: 'workstation',
	});
	const { data } = await Api.get(url + params);
	return data;
}

export async function getLine(payload: FilterDTO) {
	let url = `/appointment/ergonomic-quote-by-segment?`;
	const params = onMountingURLParams({
		...payload,
		groupBy: 'line',
	});
	const { data } = await Api.get(url + params);
	return data;
}

export async function getWorkstation(payload: FilterDTO) {
	let url = `/appointment/checklist-by-segment?`;
	const params = onMountingURLParams({
		...payload,
		groupBy: 'workstation',
	});
	const { data } = await Api.get(url + params);
	return data;
}

export async function getSectors({ organizationId, companyId }: any) {
	if (!organizationId || !companyId) return;
	const url = `/company/${organizationId}/${companyId}/sector`;
	const { data } = await Api.get(url);
	return data;
}

export async function getLines({ organizationId, companyId, sectorId }: any) {
	if (!organizationId || !companyId || !sectorId) return;
	const url = `/line/${organizationId}/${companyId}/${sectorId}`;
	const { data } = await Api.get(url);
	return data;
}

export async function getWorkstations({
	organizationId,
	companyId,
	sectorId,
	lineId,
}: any) {
	if (!organizationId || !companyId || !sectorId || !lineId) return;
	let url = `/workstation/${organizationId}/${companyId}/${lineId}`;
	const { data } = await Api.get(url);
	return data;
}

export async function getTotalWorkstations(payload: any) {
	let url = `/appointment/workstations?`;
	const params = onMountingURLParams(payload);
	const { data } = await Api.get(url + params);
	return data;
}

export async function getComplaints(payload: any) {
	let url = `/appointment/complaint?`;
	const params = onMountingURLParams(payload);
	const { data } = await Api.get(url + params);
	return data;
}

export async function getCertificates(payload: any) {
	let url = `/appointment/certificate?`;
	const params = onMountingURLParams(payload);
	const { data } = await Api.get(url + params);
	return data;
}

export async function getWorkers(payload: any) {
	let url = `/appointment/worker?`;
	const params = onMountingURLParams(payload);
	const { data } = await Api.get(url + params);
	return data;
}

export async function getWomen(payload: any) {
	let url = `/appointment/women?`;
	const params = onMountingURLParams(payload);
	const { data } = await Api.get(url + params);
	return data;
}

export async function getPwds(payload: any) {
	let url = `/appointment/pcd?`;
	const params = onMountingURLParams(payload);
	const { data } = await Api.get(url + params);
	return data;
}

export async function getRestricts(payload: any) {
	let url = `/appointment/restrict?`;
	const params = onMountingURLParams(payload);
	const { data } = await Api.get(url + params);
	return data;
}

export async function getWorkstationsBySegment(payload: FilterDTO) {
	let url = `/appointment/workstations-by-segment?`;
	const params = onMountingURLParams({
		...payload,
		groupBy: payload.segment,
	});
	const { data } = await Api.get(url + params);
	return data;
}
