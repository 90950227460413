import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, Radio, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { debounce } from 'lodash';

import { FilterModal } from './components/FilterModal';
import { useSelectFilesContext } from '../../context';
import FilterIcon from '@/assets/icons/Filter';
import * as C from '../FileTable/styles';
import * as S from './styles';

const { useFormInstance } = Form;

export function Filter() {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const { selectedRowKeys, handleFilter } = useSelectFilesContext();

	const { getFieldValue } = useFormInstance();

	const handleDebounced = debounce((value) => {
		const allFilters = getFieldValue(['filter']) || {};

		if (value.length > 0) {
			handleFilter({ ...allFilters, name: value, offset: 0 });
		} else {
			const { original_name, ...rest } = allFilters;
			handleFilter({ ...rest });
		}
	}, 1000);

	function handleOpenModal() {
		setOpenModal(!openModal);
	}

	function handleSelectedButton() {
		const filters = getFieldValue(['filter']) || {};
		handleFilter({ ...filters, file_ids: selectedRowKeys, offset: 0 });
	}

	function handleAllFilesButton() {
		const filters = getFieldValue(['filter']) || {};
		handleFilter(filters);
	}

	return (
		<Row justify="center" align="middle" gutter={[0, 20]}>
			<Col xs={23} sm={23} lg={10} xl={8} xxl={13}>
				<Form.Item name={['filter', 'original_name']} style={{ margin: 0 }}>
					<C.CustomInput
						size="large"
						prefix={<SearchOutlined />}
						placeholder={I18n.get('Search for a file')}
						onChange={() => handleDebounced(getFieldValue(['filter', 'original_name']))}
					/>
				</Form.Item>
			</Col>
			<Col xs={23} sm={23} lg={13} xl={14} xxl={10}>
				<Row justify="space-between">
					<Col span={22} xxl={22} xl={21}>
						<Form.Item name={['filter', 'select_button']} style={{ margin: 0, marginTop: '2px' }}>
							<S.RadioGroupCustom size="large" buttonStyle="solid">
								<Radio.Button value="all_files" onClick={handleAllFilesButton}>
									{I18n.get('All files')}
								</Radio.Button>
								<Radio.Button
									value="selected_files"
									style={{ padding: '0 15px 0 15px' }}
									onClick={handleSelectedButton}
									disabled={!selectedRowKeys.length}
								>
									{I18n.get('Selected files')}
								</Radio.Button>
							</S.RadioGroupCustom>
						</Form.Item>
					</Col>
					<Col style={{ display: 'flex', alignItems: 'center' }}>
						<S.IconButton
							type="link"
							icon={<FilterIcon style={{ fontSize: '30px' }} />}
							onClick={() => setOpenModal(!openModal)}
						/>
					</Col>
				</Row>
			</Col>
			<FilterModal onOpenModal={handleOpenModal} openModal={openModal} />
		</Row>
	);
}
