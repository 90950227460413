import styled from 'styled-components';
import { Col } from 'antd';

interface ColCustomProps {
	$height?: string;
	$minWidth?: string;
	$fontSize?: string;
	$padding?: string;
	$borderRadius?: string;
}

export const ColCustom = styled(Col)<ColCustomProps>`
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	border: 1px solid #2f54eb;
	padding-left: ${({ $padding }) => $padding ?? '2rem'};
	min-width: ${({ $minWidth }) => $minWidth ?? '50px'};
	padding-right: ${({ $padding }) => $padding ?? '2rem'};
	background-color: rgba(47, 84, 235, 0.05);
	height: ${({ $height }) => $height ?? '45px'};
	border-radius: ${({ $borderRadius }) => $borderRadius ?? '4px'};

	.ant-typography {
		color: #2f54eb !important;
		font-size: ${({ $fontSize }) => $fontSize ?? '20px'};
		font-weight: 600;
	}
`;
