import React from 'react';
import { Col, Form, InputNumber } from 'antd';

import { I18n } from '@aws-amplify/core';

export const AnalysisReviewInput = () => {
	return (
		<Col sm={12}>
			<Form.Item
				rules={[{ required: true, type: 'number' }]}
				name="analysis_review"
				label={I18n.get('Analysis review')}
			>
				<InputNumber
					style={{ width: '100%' }}
					controls={false}
					placeholder={I18n.get('Analysis review')}
				/>
			</Form.Item>
		</Col>
	);
};
