import styled from 'styled-components';

export const ActivityItem = styled.div`
	background-color: #f8f8f8;
	border-radius: 12px;
	padding: 20px 20px 10px 20px;
	z-index: 0;

	.text-value {
		font-weight: 500;
	}

	.title-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 15px;
		.title {
			color: #262626;
			margin: 0px !important;
			& + .title {
				padding-left: 8px;
			}
		}
	}
`;

export const ActivityBadge = styled.span`
	width: 48px;
	height: 48px;
	font-size: 26px;
	font-weight: 600;
	border-radius: 100%;
	color: #ffffff;
	background-color: #2f54eb;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -18px;
	left: -18px;
`;
