import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

export type GetRotationReportsDTO = {
	organization_id?: string;
	company_id?: string;
	id: string;
};

export type RotationReportFiles = {
	id: string;
	activity: string;
	original_name: string;
};

export type RotationReportFilesResponse = {
	activity_id: string;
	activity_name: string;
	file_id: string;
	original_name: string;
};

async function getRotationReportFiles(params: GetRotationReportsDTO): Promise<RotationReportFilesResponse[]> {
	const url = `/rotation/report/${params.id}/file`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useGetRotationReportFiles = (params: GetRotationReportsDTO) => {
	const { organization_id, company_id, id } = params;
	return useQuery([QUERY_KEYS.GET_ROTATION_REPORT_FILES, params], () => getRotationReportFiles(params), {
		enabled: !!organization_id && !!company_id && !!id
	});
};
