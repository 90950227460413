import React, { SVGProps } from 'react';

function RotationIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="16" height="14" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M2.26125 10.1889C1.89057 8.51739 2.18146 6.76728 3.07292 5.30555C3.5216 4.56807 4.09764 3.93295 4.78682 3.42106C5.5007 2.89156 6.30384 2.5143 7.17429 2.30235C8.04658 2.08996 8.93281 2.05395 9.80882 2.19827C10.6562 2.33604 11.4597 2.63525 12.1972 3.08392C12.4241 3.22198 12.6405 3.37227 12.8459 3.53296L12.1072 4.46865C12.0895 4.49076 12.0785 4.51739 12.0753 4.5455C12.0721 4.5736 12.0769 4.60203 12.0892 4.62752C12.1015 4.65301 12.1207 4.67452 12.1446 4.68957C12.1686 4.70463 12.1963 4.71262 12.2246 4.71263L15.2561 4.71485C15.3527 4.71453 15.4252 4.62341 15.4026 4.53034L14.6996 1.57963C14.6698 1.45736 14.5124 1.42223 14.4362 1.52198L13.7301 2.41687C11.8537 0.93488 9.33986 0.304013 6.83798 0.913184C2.5587 1.95513 -0.085519 6.23503 0.874409 10.5189C0.878664 10.5384 0.886798 10.557 0.898332 10.5734C0.909866 10.5898 0.924567 10.6037 0.941568 10.6144C0.958568 10.625 0.977525 10.6321 0.997321 10.6353C1.01712 10.6385 1.03735 10.6377 1.05683 10.6329L2.15175 10.3663C2.23021 10.3472 2.27853 10.2678 2.26125 10.1889Z"
				fill="currentColor"
			/>
			<path
				d="M5.31642 14.4323C5.0895 14.2942 4.87313 14.144 4.66777 13.9833L5.40464 13.048C5.42228 13.0259 5.43332 12.9993 5.4365 12.9712C5.43967 12.9431 5.43485 12.9146 5.42259 12.8891C5.41033 12.8637 5.39112 12.8422 5.36717 12.8271C5.34323 12.812 5.31552 12.804 5.28724 12.804L2.25569 12.8018C2.15911 12.8021 2.08658 12.8933 2.10924 12.9863L2.81312 15.9407C2.84289 16.063 3.00029 16.0981 3.07652 15.9983L3.78262 15.1034C4.78027 15.8878 5.95682 16.4336 7.21551 16.6819L7.21551 15.2191C6.54637 15.0576 5.9055 14.7924 5.31642 14.4323Z"
				fill="currentColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M15.0318 7.85348H11.8748V9.10798H10.2382V7.44843C10.2382 6.79378 10.79 6.25761 11.4659 6.25761H15.4687C16.1436 6.25761 16.6964 6.79285 16.6964 7.44843V9.10798H15.0511L15.0318 7.85348Z"
				fill="currentColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M15.223 13.5998C15.223 14.5504 14.4245 15.3254 13.4444 15.3254C12.4643 15.3254 11.6653 14.5504 11.6653 13.5998V13.1563H7.76632V17.2977C7.76632 17.4171 7.86143 17.5094 7.97489 17.5094H18.9405C19.0549 17.5094 19.1587 17.4171 19.1587 17.2977V13.1563H15.2414L15.223 13.5998Z"
				fill="currentColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M12.6344 13.1563V13.5988C12.6344 14.032 12.995 14.3926 13.4527 14.3926C13.8993 14.3926 14.271 14.0329 14.271 13.5988V13.1563H12.6344Z"
				fill="currentColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10.9521 10.0308H8.05201C7.89028 10.0308 7.75751 10.1596 7.75751 10.3164V12.2256H19.1407V10.3164C19.1407 10.1596 19.0079 10.0308 18.8462 10.0308H15.9461C15.9171 10.0402 15.8891 10.0402 15.8505 10.0402C15.8215 10.0402 15.7844 10.0402 15.7554 10.0308H11.1332C11.1042 10.0402 11.0665 10.0402 11.0376 10.0402C11.0197 10.0402 10.9811 10.0402 10.9521 10.0308Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default RotationIcon;
