import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row as Container } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import { Title, HeaderStep, Main, Footer } from '../Components/Modal';
import { next, prev, setFile, setRecoveryReport } from '@/redux/reports/actions';
import { ModalBorder } from '@/components/ui';
import { Informations } from './Informations';
import { Diagnostic } from './Diagnostic';
import { BodyParts } from './BodyParts';
import Api from '@/services/api';

import {
	collectionDateParse,
	fileDurationParse,
	selectBodyParts,
	rangeRiskJSON,
	sectorJSON,
	angleTimeDataParse
} from '@/redux/reports/selectors';

export function FrequencyDuration({ item, visible, onClose }) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const sector = useSelector(sectorJSON);
	const rangeRisk = useSelector(rangeRiskJSON);
	const date = useSelector(collectionDateParse);
	const bodyParts = useSelector(selectBodyParts);
	const durationFile = useSelector(fileDurationParse);

	const data = useSelector(angleTimeDataParse);
	const file = useSelector((state) => state.reports.file);
	const user = useSelector((state) => state.auth.user.user);
	const options = useSelector((state) => state.reports.options);

	const { current, informations, body_parts, range_risk_bands } = useSelector((state) => state.reports.customized);

	const recoveryReport = useSelector((state) => state.reports.recovery_data);
	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	useEffect(() => {
		if (item) {
			dispatch(setFile(item));
		}
	}, [item]);

	useLayoutEffect(() => {
		if (organization.id && company.id && file) {
			const url = `/recovery_report/${organization.id}/${company.id}/${file.id}/frequency_duration`;
			Api.get(url)
				.then((response) => {
					dispatch(setRecoveryReport(response.data));
				})
				.catch((error) => console.log(error));
		}
	}, [organization, company, file]);

	useEffect(() => {
		if (file && organization.id && company.id && _.isEmpty(recoveryReport)) {
			const body = {
				file_id: file.id,
				type: 'frequency_duration',
				company_id: company.id,
				organization_id: organization.id
			};
			Api.post('/recovery_report/create', body)
				.then((response) => {
					dispatch(setRecoveryReport(response.data));
				})
				.catch((error) => console.log(error));
		}
	}, [file, organization, company, recoveryReport]);

	useEffect(() => {
		if (informations.is_valid && current === 1) {
			if (file && sector && organization.id && company.id && recoveryReport) {
				const url = '/recovery_report/update';
				const body = {
					recovery_report_id: recoveryReport.id,
					organization_id: organization.id,
					company_id: company.id,
					file_id: file.id,
					data: {
						collection_date: moment(informations.collection_date).format(),
						workstation: informations.workstation,
						sector_id: sector.id
					}
				};

				Api.put(url, body).catch((error) => console.log(error, 'error'));
			}
		}
	}, [file, organization, company, informations, current, recoveryReport, sector]);

	const steps = [
		{
			title: 'Informations',
			content: <Informations />,
			size: 750,
			is_valid: informations.is_valid
		},
		{
			title: 'Body parts',
			content: <BodyParts />,
			size: 750,
			is_valid: body_parts.is_valid
		},
		{
			title: 'Diagnostic',
			content: <Diagnostic />,
			size: 1200,
			is_valid: true
		}
	];

	function normalizeData() {
		const { company, workstation } = informations;

		const body = {
			tool: 'rula',
			document_type: 'pdf',
			report: 'frequency_duration', // remover do payload
			parameters: {
				configuration: rangeRisk?.name,
				data: data,
				sector_name: sector?.name,
				collection_date: date,
				company_name: company,
				workstation_name: workstation,
				body_parts: bodyParts,
				settings: range_risk_bands,
				// "settings": {
				// 	"neck": [
				// 		{
				// 			"min": -10,
				// 			"max": 10,
				// 			"color": "rgba(0, 8, 255, 0.5)"
				// 		},
				// 		{
				// 			"min": 10,
				// 			"max": 20,
				// 			"color": "rgba(250, 3, 3, 0.5)"
				// 		},
				// 		{
				// 			"min": 20,
				// 			"max": 40,
				// 			"color": "rgba(90, 158, 102, 0.5)"
				// 		}
				// 	],
				// 	"trunk": [
				// 		{
				// 			"min": -20,
				// 			"max": 20,
				// 			"color": "rgba(255, 180, 0, 0.5)"
				// 		}
				// 	]
				// },
				file: {
					size: file?.size,
					duration: durationFile,
					name: file?.original_name
				},
				signature: options.signature.checked && {
					author: user.name
				},
				conclusion: options.conclusion.checked && {
					text: options.conclusion.text
				}
			}
		};
		return body;
	}

	function onSuccessFinish(response) {
		const blob = new Blob([response.data], { type: 'application/pdf' });
		window.open(URL.createObjectURL(blob));
	}

	function onErrorFinish(err) {
		console.log(err, 'err');
	}

	function onFinish() {
		const url = '/report/frequency-duration/document/pdf';
		const body = normalizeData();

		const opts = { responseType: 'blob' };

		Api.post(url, body, opts)
			.then(setLoading(true))
			.then(onSuccessFinish)
			.catch(onErrorFinish)
			.finally(() => setLoading(false));
	}

	function onNext() {
		dispatch(next('customized'));
	}

	function onPrevious() {
		dispatch(prev('customized'));
	}

	return (
		<ModalBorder
			footer={null}
			visible={visible}
			onCancel={onClose}
			destroyOnClose={true}
			width={steps[current].size}
		>
			<Container>
				<Title>Frequency and duration</Title>
				<HeaderStep current={current} steps={steps} />
				<Main>{steps[current].content}</Main>
				<Footer
					steps={steps}
					onNext={onNext}
					current={current}
					onFinish={onFinish}
					isFetching={loading}
					onPrevious={onPrevious}
				/>
			</Container>
		</ModalBorder>
	);
}
