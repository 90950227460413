import Types from './types';
import authService from './services';
import Api from '@/services/api';
import history from '@/store/history';

export const setSession = (payload) => ({ type: Types.AUTH_SET_SESSION, payload });

// Deprecated \/
export function updateUser(params) {
	const { name } = params;
	return { type: Types.UPDATE_USER, payload: { name } };
}

export function logoutUserSession() {
	localStorage.clear();
	Api.defaults.headers.Authorization = undefined;
	history.push('/login');
	return (dispatch) => dispatch({ type: Types.LOGOUT_USER_SESSION });
}

export function updatePlanConsumed(data) {
	return (dispatch) =>
		dispatch({
			type: Types.UPDATE_PLAN_CONSUMED,
			payload: data
		});
}

export function updateUserSession(data) {
	return (dispatch) =>
		dispatch({
			type: Types.UPDATE_USER_SESSION,
			payload: data
		});
}

export function updateUserInformations(data) {
	return (dispatch) =>
		dispatch({
			type: Types.UPDATE_USER_INFO,
			payload: data
		});
}

export const handleLogin = (parameters) => {
	return async (dispatch) => {
		try {
			dispatch(onLoading());

			const response = await authService.loginUser(parameters);

			dispatch(onSuccess(response));

			history.push('/');
		} catch (error) {
			dispatch(onError(error));
		} finally {
			dispatch(onLoading());
		}
	};
	function onLoading() {
		return { type: Types.LOGIN_REQUEST };
	}
	function onSuccess(response) {
		const { token, user } = response;
		if (user && token) {
			Api.defaults.headers.Authorization = `Bearer ${token}`;
			return { type: Types.LOGIN_SUCCESS, payload: { token, user } };
		}
	}
	function onError(err) {
		return { type: Types.LOGIN_FAILURE, payload: err.response.data };
	}
};

export const handleLogout = () => {
	// localStorage.removeItem('company');
	// localStorage.removeItem('info_user');
	// localStorage.removeItem('organization');
	localStorage.clear();
	Api.defaults.headers.Authorization = undefined;
	history.push('/login');
	return { type: Types.LOGOUT };
};

export const controlSpinnerRoute = () => {
	return { type: Types.SPINNER_ROUTE };
};

export const isAuthenticated = (parameters) => {
	return { type: Types.AUTHENTICATED, payload: parameters };
};

const Services = {
	logout: () => {
		localStorage.clear();
		Api.defaults.headers.Authorization = undefined;
		history.push('/login');
		return { type: Types.LOGOUT };
	}
};

export default Services;
