import * as React from 'react';

export const EiIcon: React.FC = (props) => {
	return (
		<svg
			width="1em"
			height="1em"
			fill="currentColor"
			viewBox="0 0 15 13"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M7.559 12.604H0V0h7.269v2.312h-4.43v2.803H6.96v2.303H2.839v2.883h4.72v2.303zM14.219 12.604H11.38V0h2.839v12.604z" />
		</svg>
	);
};
