import Api from '@/services/api';

const Services = {
	changePasswordUser: async (parameters) => {
		const { current_password, password } = parameters;
		try {
			const body = { current_password, password };
			const { data } = await Api.put('/user/change_password', body);
			return data;
		} catch (err) {
			throw err.response.data;
		}
	}
};

export default Services;
