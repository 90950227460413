const Types: Record<string, string> = {
	LOGOUT: 'LOGOUT',
	UPDATE_USER: 'UPDATE_USER',
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	SPINNER_ROUTE: 'SPINNER_ROUTE',
	AUTHENTICATED: 'AUTHENTICATED',
	AUTH_SET_SESSION: 'AUTH_SET_SESSION',
	UPDATE_USER_INFO: 'UPDATE_USER_INFO',
	LOGOUT_USER_SESSION: 'LOGOUT_USER_SESSION',
	UPDATE_USER_SESSION: 'UPDATE_USER_SESSION',
	UPDATE_PLAN_CONSUMED: 'UPDATE_PLAN_CONSUMED'
};

export default Types;
