import React from 'react'
import { I18n } from '@aws-amplify/core'
import { Modal, Button } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { CreateForm } from './CreateForm'

export function CreateModal({
	type,
	visible,
	onCancel,
	onSubmit,
	form,
	loading,
}) {
	const title = `Create a new ${type}`

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			destroyOnClose={true}
			title={I18n.get(title)}
			footer={[
				<Button
					key="save"
					type="primary"
					loading={loading}
					onClick={onSubmit}
					icon={<SaveOutlined />}
				>
					{I18n.get('Save')}
				</Button>,
			]}
		>
			<CreateForm form={form} />
		</Modal>
	)
}
